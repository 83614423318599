import { axiosInstance } from "./API";

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const getApi = async (url, customOptions = {}) => {
    const finalOptions = { ...defaultOptions, ...customOptions };
    try {
        const response = await axiosInstance.get(url, finalOptions);
        return response.data;
    } catch (error) {
        console.error(`GET request to ${url} failed:`, error.response?.data || error.message);
        throw error;
    }
};

export const postApi = async (url, body, customOptions = {}) => {
    const finalOptions = { ...defaultOptions, ...customOptions };
    try {
        const response = await axiosInstance.post(url, body, finalOptions);
        return response.data;
    } catch (error) {
        console.error(`POST request to ${url} failed:`, error.response?.data || error.message);
        throw error;
    }
};

export const patchApi = async (url, data, customOptions = {}) => {
    const finalOptions = { ...defaultOptions, ...customOptions };
    try {
        const response = await axiosInstance.patch(url, data, finalOptions);
        return response.data;
    } catch (error) {
        console.error(`PATCH request to ${url} failed:`, error.response?.data || error.message);
        throw error;
    }
};

export const deleteApi = async (url, body, customOptions = {}) => {
    const finalOptions = { ...defaultOptions, ...customOptions, data: body };
    try {
        const response = await axiosInstance.delete(url, finalOptions);
        return response.data;
    } catch (error) {
        console.error(`DELETE request to ${url} failed:`, error.response?.data || error.message);
        throw error;
    }
};

export const putApi = async (url, body, customOptions = {}) => {
    const finalOptions = { ...defaultOptions, ...customOptions };
    try {
        const response = await axiosInstance.put(url, body, finalOptions);
        return response.data;
    } catch (error) {
        console.error(`PUT request to ${url} failed:`, error.response?.data || error.message);
        throw error;
    }
};