import React, { useEffect, useState } from "react";
import "./style.css";
import DailyView from "../../components/Calennder/DailyView";
import WeeklyView from "../../components/Calennder/WeeklyView";
import MonthlyView from "../../components/Calennder/MonthlyView";
import { useDispatch, useSelector } from "react-redux";
import { fetchScheduleData } from "../../store/features/scheduleData/scheduleDataSlice";
import { IMAGE_URL, IMAGE_URL_BACKEND } from "../../api/API";
import { clearPostState } from "../../store/features/post/postSlice";
import { Link } from "react-router-dom";
import ResponsiveSchedule from "../../components/DropDown/ResponsiveSchedule";
import MobileNotification from "../../components/Mobile/MobileNotification"; 

function getWeekNumber(date) {
  const tempDate = new Date(date);
  tempDate.setMonth(0);
  tempDate.setDate(1);
  const days = Math.floor((date - tempDate) / 86400000);
  const weekNumber = Math.ceil((days + tempDate.getDay() + 1) / 7);
  return weekNumber;
}

function getWeekDatesForDate(date) {
  const weekDates = [];
  const weekStartDate = new Date(date);
  weekStartDate.setDate(date.getDate() - date.getDay());

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  for (let i = 0; i < 7; i++) {
    const day = new Date(weekStartDate);
    day.setDate(weekStartDate.getDate() + i);
    weekDates.push(day.toLocaleDateString(undefined, options));
  }

  return weekDates;
}

function printAllDatesOfMonth(year, month) {
  const firstDayOfMonth = new Date(year, month, 1);
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const dayOfWeek = firstDayOfMonth.getDay();
  const weeks = [];
  let currentWeek = Array(dayOfWeek).fill("");

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = new Date(year, month, day);
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    currentWeek.push(formattedDate);

    if (currentWeek.length === 7) {
      weeks.push([...currentWeek]);
      currentWeek = [];
    }
  }

  if (currentWeek.length > 0) {
    weeks.push([...currentWeek]);
  }

  return weeks;
}

function convertToTimezone(date, timezone) {
  if (!timezone) return date;
  try {
    const parsedTimezone = typeof timezone === "string" ? JSON.parse(timezone) : timezone;

    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: parsedTimezone.value,
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });

    const parts = formatter.formatToParts(date);
    const dateObj = {};
    parts.forEach((part) => {
      if (["year", "month", "day", "hour", "minute", "second"].includes(part.type)) {
        dateObj[part.type] = parseInt(part.value, 10);
      }
    });

    return new Date(dateObj.year, dateObj.month - 1, dateObj.day, dateObj.hour, dateObj.minute, dateObj.second);
  } catch (error) {
    console.error("Error converting timezone:", error);
    return date;
  }
}

const Calender = () => {
  const [hidden, setHidden] = useState({
    date: "block",
    time: "block",
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { isDark } = useSelector((state) => state.common);
  const { scheduledData } = useSelector((state) => state.scheduleData);

  const userInfo = user?.userData;
  const currentDate = new Date();
  const years = currentDate.getFullYear();
  const months = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${years}-${months}-${day}`;
  const filterdata = ["Monthly", "Weekly", "Daily"];
  const [selectedItem, setSelectedItem] = useState(filterdata[0]);
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [weekNumber, setWeekNumber] = useState("");
  const [weekDates, setWeekDates] = useState([]);
  const [allDatesOfMonth, setAllDatesOfMonth] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [weekStartDate, setWeekStartDate] = useState("");
  const [getTime, setGetTime] = useState("");
  const [previewLay, setPreviewLay] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileNotification, setShowMobileNotification] = useState(false);

  const handleCanel = () => {
    setPreviewLay("");
  };

  const startDate = allDatesOfMonth[0]?.find((item) => item !== "");
  let endDate =
    allDatesOfMonth?.length > 0
      ? allDatesOfMonth[allDatesOfMonth?.length - 1] && allDatesOfMonth[allDatesOfMonth?.length - 1]?.length > 0
        ? allDatesOfMonth[allDatesOfMonth?.length - 1][allDatesOfMonth[allDatesOfMonth?.length - 1]?.length - 1]
        : null
      : null;

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setPreviewLay("");
  };

  // Detect if the user is on a mobile device
  useEffect(() => {
    const checkScreenSize = () => {
      const isMobileDevice = window.innerWidth < 768;
      setIsMobile(isMobileDevice);
      setShowMobileNotification(isMobileDevice);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const dateInUserTz = convertToTimezone(new Date(selectedDate), userInfo?.userChosenTopics?.timeZone);

      setYear(dateInUserTz.getFullYear());
      setMonth(dateInUserTz.getMonth());
      setDate(dateInUserTz.getDate());
      setWeekNumber(getWeekNumber(dateInUserTz));
      setWeekDates(getWeekDatesForDate(dateInUserTz));
      setAllDatesOfMonth(printAllDatesOfMonth(dateInUserTz.getFullYear(), dateInUserTz.getMonth()));

      const weekStartDate = new Date(dateInUserTz);
      weekStartDate.setDate(dateInUserTz.getDate() - dateInUserTz.getDay());
      setWeekStartDate(weekStartDate.toLocaleDateString());

      const weekDates = getWeekDatesForDate(weekStartDate);
      setSelectedWeek(weekDates);
    }
  }, [selectedDate, userInfo?.userChosenTopics?.timeZone]);

  const userTimezone = userInfo?.userChosenTopics?.timeZone;

  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    setSelectedDate(inputDate);

    if (user.userData.linkedinId) {
      const dateObject = convertToTimezone(new Date(inputDate), userTimezone);

      let getStartYear = dateObject.getFullYear();
      let getEndYear = dateObject.getFullYear();
      let getMonthNumber = dateObject.getMonth();
      getMonthNumber = getMonthNumber < 10 ? `0${getMonthNumber}` : getMonthNumber;

      if (getMonthNumber === "0-1") {
        getMonthNumber = 12;
        getStartYear = getStartYear - 1;
      } else if (getMonthNumber === `00`) {
        getMonthNumber = 12;
        getStartYear = getStartYear - 1;
      }

      let getEndMonthNumber = dateObject.getMonth() + 2;
      getEndMonthNumber = getEndMonthNumber < 10 ? `0${getEndMonthNumber}` : getEndMonthNumber;

      if (getEndMonthNumber === 13) {
        getEndMonthNumber = `01`;
        getEndYear = getEndYear + 1;
      }

      dispatch(
        fetchScheduleData({
          userId: user.userData.linkedinId,
          startDate: `${getStartYear}-${getMonthNumber}-25`,
          endDate: `${getEndYear}-${getEndMonthNumber}-10`,
          timezone: userTimezone,
        })
      );
    }
  };

  useEffect(() => {
    const currentDate = convertToTimezone(new Date(), userTimezone);

    let getStartYear = currentDate.getFullYear();
    let getEndYear = currentDate.getFullYear();
    let getMonthNumber = currentDate.getMonth();

    if (getMonthNumber === "0-1") {
      getMonthNumber = 12;
      getStartYear = getStartYear - 1;
    } else if (getMonthNumber === `00`) {
      getMonthNumber = 12;
      getStartYear = getStartYear - 1;
    }

    let getEndMonthNumber = currentDate.getMonth() + 2;
    getEndMonthNumber = getEndMonthNumber < 10 ? `0${getEndMonthNumber}` : getEndMonthNumber;

    if (getEndMonthNumber === 13) {
      getEndMonthNumber = `01`;
      getEndYear = getEndYear + 1;
    }

    if (user.userData.linkedinId) {
      dispatch(
        fetchScheduleData({
          userId: user.userData.linkedinId,
          startDate: `${getStartYear}-${getMonthNumber}-25`,
          endDate: `${getEndYear}-${getEndMonthNumber}-10`,
          timezone: userTimezone,
        })
      );
    }
  }, [user.userData.linkedinId, userTimezone]);

  useEffect(() => {
    if (selectedDate && userInfo?.userChosenTopics?.timeZone) {
      const dateInUserTz = convertToTimezone(new Date(selectedDate), userInfo.userChosenTopics.timeZone);

      setYear(dateInUserTz.getFullYear());
      setMonth(dateInUserTz.getMonth());
      setDate(dateInUserTz.getDate());
      setWeekNumber(getWeekNumber(dateInUserTz));
      setWeekDates(getWeekDatesForDate(dateInUserTz));
      setAllDatesOfMonth(printAllDatesOfMonth(dateInUserTz.getFullYear(), dateInUserTz.getMonth()));

      const weekStartDate = new Date(dateInUserTz);
      weekStartDate.setDate(dateInUserTz.getDate() - dateInUserTz.getDay());
      setWeekStartDate(weekStartDate.toLocaleDateString());
      setSelectedWeek(getWeekDatesForDate(weekStartDate));

      if (user.userData.linkedinId) {
        const startYear = dateInUserTz.getFullYear();
        const endYear = dateInUserTz.getFullYear();
        let monthNumber = dateInUserTz.getMonth();
        monthNumber = monthNumber < 10 ? `0${monthNumber}` : monthNumber;

        let endMonthNumber = dateInUserTz.getMonth() + 2;
        endMonthNumber = endMonthNumber < 10 ? `0${endMonthNumber}` : endMonthNumber;

        dispatch(
          fetchScheduleData({
            userId: user.userData.linkedinId,
            startDate: `${startYear}-${monthNumber}-25`,
            endDate: `${endYear}-${endMonthNumber}-10`,
            timezone: userInfo.userChosenTopics.timeZone,
          })
        );
      }
    }
  }, [userInfo?.userChosenTopics?.timeZone]);

  let componentToRender;
  switch (selectedItem) {
    case "Daily":
      componentToRender = <DailyView scheduledData={scheduledData} selectedDate={selectedDate} setPreviewLay={setPreviewLay} timezone={userInfo?.userChosenTopics?.timeZone} />;
      break;
    case "Weekly":
      componentToRender = <WeeklyView scheduledData={scheduledData} selectedWeek={selectedWeek} setPreviewLay={setPreviewLay} timezone={userInfo?.userChosenTopics?.timeZone} />;
      break;
    case "Monthly":
      componentToRender = <MonthlyView scheduledData={scheduledData} allDatesOfMonth={allDatesOfMonth} setPreviewLay={setPreviewLay} timezone={userInfo?.userChosenTopics?.timeZone} />;
      break;
    default:
      componentToRender = null;
      break;
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Conditionally render MobileNotification on mobile devices */}
      {showMobileNotification && <MobileNotification onClose={() => setShowMobileNotification(false)} />}

      {/* Main content (hidden on mobile when notification is shown) */}
      {!showMobileNotification && (
        <div className="calender-container pe-2 ps-2 sm:pe-3 sm:ps-3">
          <div className="d-flex mb-2 sm:mb-3 flex-column">
            <h3 className="text-lg sm:text-xl md:text-2xl">Being consistent never been easier</h3>
            <p className="mt-2 sm:mt-3 text-sm sm:text-base">
              Manage{" "}
              <u>
                <Link to="/my-accounts?aiConfig=0" className="text-blue-400">
                  Auto-Schedule
                </Link>
              </u>
            </p>
          </div>
          <div className="row mt-2 sm:mt-3">
            <div className="col-lg-12">
              <div className="schedule-header flex flex-col sm:flex-row gap-2 sm:gap-0">
                <ResponsiveSchedule filterdata={filterdata} isDark={isDark} handleItemClick={handleItemClick} />
                <div className="schedule-right">
                  <input
                    type="date"
                    value={selectedDate}
                    onClick={(e) => {
                      e.target.type = "date";
                      e.target.showPicker();

                      setHidden({
                        ...hidden,
                        date: "none",
                      });
                    }}
                    onBlur={(e) => {
                      e.target.type = "date";
                      setHidden({
                        ...hidden,
                        date: "block",
                      });
                    }}
                    onChange={handleDateChange}
                    className="form-control w-full sm:w-auto"
                  />
                </div>
              </div>
              <div className={`schedule-data overflow-y-auto w-full mt-2 sm:mt-3 ${isDark ? "" : "card-containers-dark"}`}>{componentToRender}</div>
            </div>
            {/* <div className={previewLay ? 'col-lg-5 col-xxl-4 col-xl-4 ' : 'pre-col-hide'}>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='pt-2' style={isDark ? {color:'#000'} : {color:'#fff'}}>Preview</h4>
                <i className="fa-regular fa-eye-slash" style={isDark ? {color:'#000'} : {color:'#fff'}} onClick={handleCanel}></i>
              </div>
              <div className={`rounded p-2 mt-5 prev-cont ${isDark ? '':'prev-cont-dark'}`}>
                <span className='cancel-btn' onClick={handleCanel} >X</span>
                
                <div className='userinfo'>
                  <div className='d-flex align-items-center'>
                      {
                      user?.userPhoto?.length > 0
                          ? <img src={user?.userPhoto} className='avatar-img'/>
                          : <img src={`${IMAGE_URL}/images/Icons/avatar.jpg`} className='avatar-img'/>
                      }
                    <div className='d-flex flex-column align-items-start user-profile'>
                        <p>{ userInfo?.userName?.length > 30 ? `${userInfo?.userName?.substring(0, 30)}...` : userInfo?.userName}</p>
                        <span style={{color:'gray',fontSize:'0.7rem'}}>{user?.userChosenTopics?.userPromtAIProfession?.length > 30 ? user?.userChosenTopics?.userPromtAIProfession?.slice(0,30)+'...' : user?.userChosenTopics?.userPromtAIProfession}</span>
                    </div>
                  </div>
                  <i className="fa-brands fa-linkedin fa-2x" style={{color: "#105ada"}}></i>
                </div>
                <hr />
                <div className='prev-content'>
                  <p>{`${previewLay.postContent}`}</p>
                    {
                        (previewLay.originalUrl && previewLay.postType === 'IMAGE') &&
                        <img src={`${IMAGE_URL_BACKEND}/uploads/${previewLay.originalUrl}`} className='w-100' /> 
                    }
                    {
                        (previewLay.originalUrl && previewLay.postType === 'ARTICLE') &&
                        <p style={{margin:'0 0 8% 3%'}}><a href={previewLay.originalUrl} >{previewLay.originalUrl}</a> </p>
                    }
                    {
                        (previewLay.originalUrl && previewLay.postType === 'VIDEO') &&
                        <video src={`${IMAGE_URL_BACKEND}/uploads/${previewLay.originalUrl}`} className='w-100' controls />
                    }
                </div>
                <hr />
                <div className='prev-footer d-flex justify-content-between' >
                    <span className='p-2'><i className="fa fa-thumbs-up p-2" aria-hidden="true" style={{color:'#4267B2'}}></i> 227</span>
                    <span className='p-2'>3 comments . 4 reposts</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Calender;