import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IMAGE_URL, IMAGE_URL_BACKEND } from "../../../api/API";
import PropTypes from "prop-types";

const DraftPostPreview = ({ onClose }) => {
  const user = useSelector((state) => state.user);
  const { isDark } = useSelector((state) => state.common);
  // Log the entire previewPost state to debug
  const previewPostState = useSelector((state) => state.previewPost);
  console.log("Preview Post State:", previewPostState);

  // Access the post data - adjust this based on your Redux store structure
  const previewPost = useSelector((state) => state.previewPost.post || state.previewPost);

  const [isExpanded, setIsExpanded] = useState(false);

  // Function to count lines in text
  const countLines = (text) => {
    if (!text) return 0;
    return text.split(/\r\n|\r|\n/).length;
  };

  // Function to limit lines
  const limitLines = (text, limit) => {
    if (!text) return "";
    const lines = text.split(/\r\n|\r|\n/);
    return lines.slice(0, limit).join("\n");
  };

  const renderMediaContent = () => {
    if (!previewPost?.mediaTitle?.length) return null;

    // For PDFs
    if (previewPost.postType === "PDF") {
      const pdfUrl = `${IMAGE_URL_BACKEND}/uploads/${previewPost.mediaTitle}`;
      return (
        <div className="px-4 mt-4">
          <div className="pdf-preview rounded-lg overflow-hidden w-full h-[400px]">
            <iframe src={`${pdfUrl}#page=1&view=FitH&toolbar=0&navpanes=0&scrollbar=0`} title="PDF Preview" className="pdf-iframe w-full h-[400px]" />
          </div>
        </div>
      );
    }

    // For Videos
    if (previewPost.postType === "VIDEO") {
      const videoUrl = `${IMAGE_URL_BACKEND}/uploads/${previewPost.mediaTitle}`;
      return (
        <div className="px-4 mt-4 relative">
          <div className="rounded-lg overflow-hidden">
            <video src={videoUrl} className="w-full rounded-lg preview-video" controls controlsList="nodownload" playsInline />
          </div>
        </div>
      );
    }

    // For Images - Modified to handle multiple images
    if (previewPost.postType === "IMAGE") {
      const images = Array.isArray(previewPost.mediaTitle) ? previewPost.mediaTitle : [previewPost.mediaTitle];

      return (
        <div className="px-4 mt-4">
          {images.length === 1 ? (
            // Single image layout
            <div className="max-h-[400px] overflow-hidden rounded-lg">
              <img src={`${IMAGE_URL_BACKEND}/uploads/${images[0]}`} alt="Preview" className="w-full rounded-lg object-contain max-h-[400px]" />
            </div>
          ) : images.length === 2 ? (
            // Two images layout
            <div className="grid grid-cols-2 gap-4 max-h-[400px]">
              {images.map((image, index) => (
                <div key={index} className="h-full">
                  <img src={`${IMAGE_URL_BACKEND}/uploads/${image}`} alt={`Preview ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
                </div>
              ))}
            </div>
          ) : images.length === 3 ? (
            // Three images layout
            <div className="grid grid-cols-2 gap-4 max-h-[400px]">
              <div className="h-full">
                <img src={`${IMAGE_URL_BACKEND}/uploads/${images[0]}`} alt="Preview 1" className="w-full h-full object-cover rounded-lg" />
              </div>
              <div className="grid grid-rows-2 gap-4">
                {images.slice(1, 3).map((image, index) => (
                  <div key={index} className="h-full">
                    <img src={`${IMAGE_URL_BACKEND}/uploads/${image}`} alt={`Preview ${index + 2}`} className="w-full h-full object-cover rounded-lg" />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            // Four or more images layout
            <div className="grid grid-cols-2 gap-2 max-h-[400px]">
              <div className="grid grid-rows-2 gap-2">
                {images.slice(0, 2).map((image, index) => (
                  <div key={index} className="h-full">
                    <img src={`${IMAGE_URL_BACKEND}/uploads/${image}`} alt={`Preview ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
                  </div>
                ))}
              </div>
              <div className="grid grid-rows-2 gap-2">
                {images.slice(2, 4).map((image, index) => (
                  <div key={index} className="relative h-full">
                    <img src={`${IMAGE_URL_BACKEND}/uploads/${image}`} alt={`Preview ${index + 3}`} className="w-full h-full object-cover rounded-lg" />
                    {index === 1 && images.length > 4 && (
                      <div className="absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg">
                        <span className="text-2xl font-bold text-white">+{images.length - 4}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  // Debug logging for the content
  console.log("Post Content:", previewPost?.postContent);
  // This is the code to the view linkedin post in the new tab in the my-queue
  const handleLinkedInClick = () => {
    // Check if we have a LinkedIn post URL
    if (previewPost?.linkedinPostUrl) {
      window.open(previewPost.linkedinPostUrl, "_blank");
    } else if (user?.userData?.linkedinProfileUrl) {
      // If no post URL, open user's LinkedIn profile
      window.open(user.userData.linkedinProfileUrl, "_blank");
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto relative">
        {/* Close button */}
        <button onClick={onClose} className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full">
          <i className="fa-solid fa-times text-gray-600"></i>
        </button>

        <div className="w-full p-6">
          <h3 className="text-[20px] font-semibold mb-4 text-black">Preview Post</h3>

          <div className="bg-white rounded-xl shadow-sm border border-gray-200">
            {/* Header */}
            <div className="p-4 pb-2">
              <div className="flex items-start justify-between">
                <div className="flex gap-3">
                  <img src={user?.userPhoto || `${IMAGE_URL}/images/Icons/avatar.jpg`} className="w-12 h-12 rounded-full border object-cover" alt="Profile" />
                  <div className="flex flex-col">
                    <span className="font-semibold text-[14px] text-black hover:text-blue-600 cursor-pointer">{user?.userData?.userName?.length > 30 ? `${user?.userData?.userName.slice(0, 30)}...` : user?.userData?.userName}</span>
                    <span className="text-[10px] text-black">{user?.userData?.localizedHeadline?.length > 30 ? `${user?.userData?.localizedHeadline.slice(0, 30)}...` : user?.userData?.localizedHeadline}</span>
                    <span className="text-[10px] text-black flex items-center gap-1">
                      Now • <i className="fa-solid fa-earth-americas text-[10px]"></i>
                    </span>
                  </div>
                </div>
                {/* This is the code to the view linkedin post in the new tab in
                the my-queue */}
                <button onClick={handleLinkedInClick} className="hover:opacity-80 transition-opacity" title="Open in LinkedIn">
                  <i className="fa-brands fa-linkedin text-[#0a66c2] text-xl"></i>
                </button>
              </div>
            </div>

            {/* Content */}
            <div className="px-4 pt-1">
              {(() => {
                const content = previewPost?.postContent || "";
                const parts = content.split(/(#[^\s#]+)/g);
                const mainContent = parts
                  .filter((part) => !part.startsWith("#"))
                  .join(" ")
                  .trim();
                const hashtags = parts.filter((part) => part.startsWith("#")).join(" ");

                const totalLines = countLines(mainContent);
                const shouldShowViewMore = totalLines > 5;
                const displayContent = isExpanded ? mainContent : limitLines(mainContent, 2);

                return (
                  <div className="text-[13px] text-black whitespace-pre-wrap">
                    {displayContent}
                    {shouldShowViewMore && (
                      <button onClick={() => setIsExpanded(!isExpanded)} className="text-blue-500 hover:text-blue-600 font-medium ml-1">
                        {isExpanded ? "View Less" : "... View More"}
                      </button>
                    )}
                    {hashtags && (
                      <>
                        {"\n\n"}
                        <span className={`${isDark ? "text-white" : "text-black"} font-bold`}>{hashtags}</span>
                      </>
                    )}
                  </div>
                );
              })()}
            </div>

            {/* The code `{renderMediaContent()}` is rendering the media content of the
            preview post within the DraftPostPreview component. */}

            {renderMediaContent()}

            {/* Engagement Section */}
            <div className="mt-4 px-4 pb-2">
              <hr className="border-gray-200" />
              <div className="grid grid-cols-4 -mx-1 mt-1">
                <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors">
                  <i className="fa-regular fa-thumbs-up text-black"></i>
                  <span className="text-[13px] font-medium text-black">Like</span>
                </button>
                <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors">
                  <i className="fa-regular fa-comment text-black"></i>
                  <span className="text-[13px] font-medium text-black">Comment</span>
                </button>
                <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors">
                  <i className="fa-solid fa-retweet text-black"></i>
                  <span className="text-[13px] font-medium text-black">Repost</span>
                </button>
                <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors">
                  <i className="fa-regular fa-paper-plane text-black"></i>
                  <span className="text-[13px] font-medium text-black">Send</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* The code `DraftPostPreview.propTypes = { onClose: PropTypes.func.isRequired };` is defining the
PropTypes for the `DraftPostPreview` component. */
DraftPostPreview.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DraftPostPreview;
