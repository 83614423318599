import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarChartComp from "./BarChartComp";
import PostSummeryBox from "../../components/Dashboard/PostSummeryBox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import TopInteractors from "./TopInteractors";
import StreakComp from "./StreakComp";
import Table from "./Table";
import PostTypeGraph from "./PostTypeGraph";
import WordCloudComponent from './WordCloudComponent';
import DemographicsChart from "./DemographicsChart";
import {
  setUserId,
  totalLikesAndComments,
  getTeamMembers,
  fetchAnalyticsData,
  setDateRange,
} from "../../store/features/analytics/analyticsAction";
import { changeLayoutShift } from "../../store/features/common/commonSlice";
import { Link } from "react-router-dom";
import { getApi } from "../../api/methods";
import { DateRangePicker } from "react-date-range";
import HashtagAnalyticsTable from "./HashtagAnalyticsTable";
import MobileNotification from "../../components/Mobile/MobileNotification"; // Import the MobileNotification component

const Analytics = () => {
  const [openDateRange, setOpenDateRange] = useState(false);
  const { layoutShift, isDark } = useSelector((state) => state.common);
  const { userData } = useSelector((state) => state.user);
  const userinfo = useSelector((state) => state.user.userData);
  var { dateRange, team, summary } = useSelector((state) => state.analytics);
  summary = summary || [];
  const analyticsState = useSelector((state) => state.analytics);

  console.log("analyticsState");
  console.log(analyticsState);
  console.log("analyticsState");

  const dispatch = useDispatch();
  const [teamMember, setTeamMember] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileNotification, setShowMobileNotification] = useState(false);

  console.log("userData:", userData);
  console.log("team:", team?.data?.users);

  // Detect if the user is on a mobile device
  useEffect(() => {
    const checkScreenSize = () => {
      const isMobileDevice = window.innerWidth < 768;
      setIsMobile(isMobileDevice);
      setShowMobileNotification(isMobileDevice);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const transactionData = await getApi(
          `transaction/get-subscription-by-id/${userData.teamId}`
        );
        if (transactionData.length > 0 && transactionData[0].plan_Name === "Free-Tier") {
          setIsFree(true);
        }
      } catch (error) {
        console.error("Error checking subscription:", error);
      }
    };

    checkSubscription();
  }, [userData.teamId]);

  const handleClick = () => {
    setOpenDateRange(!openDateRange);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleSelect = (ranges) => {
    dispatch(
      setDateRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      })
    );
  };

  const handleApply = () => {
    setOpenDateRange(false);
  };

  const predefinedRanges = [
    {
      label: "Previous Week",
      range: () => {
        const startDate = new Date(
          new Date().setDate(new Date().getDate() - 13)
        );
        const endDate = new Date(new Date().setDate(new Date().getDate() - 7));
        return { startDate, endDate };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Previous Month",
      range: () => {
        const startDate = new Date(
          new Date().setMonth(new Date().getMonth() - 1, 1)
        );
        const endDate = new Date(new Date().setDate(0));
        return { startDate, endDate };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Last 3 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Custom",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Life Time",
      range: () => {
        const startDate = new Date(userinfo.createdAt);
        const endDate = new Date();
        return { startDate, endDate };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getTeamMembers(userData.teamId));
  }, [dispatch, userData]);

  useEffect(() => {
    if (team?.data?.users?.length > 0) {
      const firstUser = team.data.users[0];
      setTeamMember(firstUser.linkedinId);
      handleTeamMemberChange(firstUser.linkedinId);
    }
  }, [team]);

  const handleTeamMemberChange = (newTeamMember) => {
    console.log("Selected Team Member:", newTeamMember);
    const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
    const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);
    setTeamMember(newTeamMember);
    dispatch(setUserId(newTeamMember));
    dispatch(totalLikesAndComments(newTeamMember));
    dispatch(
      fetchAnalyticsData(newTeamMember, formattedStartDate, formattedEndDate)
    );
  };

  const mainContent = (
    <div
      className={`flex flex-col gap-8 sm:gap-12 py-8 sm:py-12 mb-16 sm:mb-24 ${
        layoutShift ? "pl-16 sm:pl-24 pr-12 sm:pr-20" : "px-6 sm:px-9 xs:px-12 md:px-20"
      }`}
      style={{ overflowY: "auto" }}
      id="hide-scrollbar"
    >
      <div className="flex flex-col sm:flex-row justify-between w-full gap-4">
        <div className="flex flex-col justify-end items-start sm:items-end gap-3 sm:gap-4 text-xs sm:text-sm">
          <span
            className={`${
              isDark ? "border-black" : "border-white"
            } flex gap-2 border-[1px] p-2 rounded-lg cursor-pointer`}
            onClick={handleClick}
          >
            {formatDate(dateRange.startDate)} - {formatDate(dateRange.endDate)}
            <img
              src={`${
                isDark ? "/images/calenderBlack.svg" : "/images/calender.svg"
              }`}
              alt="calendar"
            />
          </span>
          {openDateRange && (
            <div
              className={`absolute top-[80px] sm:top-[100px] left-4 sm:left-[35px] z-10 flex flex-col items-end ${
                isDark ? "bg-white" : "bg-black"
              }`}
            >
              <DateRangePicker
                ranges={[
                  {
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    key: "selection",
                  },
                ]}
                onChange={handleSelect}
                className={`rdrp z-10 ${isDark ? "rdrp" : "rdrp-dark"}`}
                staticRanges={predefinedRanges}
                maxDate={new Date()}
              />
              <button
                className={`my-2 mx-3 gradient-button-new p-2 px-3 rounded-md text-white`}
                onClick={handleApply}
              >
                Apply
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-2">
          <p className={`${isDark ? "text-black" : "text-white"} text-xs sm:text-sm`}>
            Social Account:
          </p>
          <div className="w-36 sm:w-44">
            <Select value={teamMember} onValueChange={handleTeamMemberChange}>
              <SelectTrigger
                className={`px-2 py-1 rounded-lg outline-none custom-select ${
                  isDark
                    ? "bg-transparent border border-black text-black"
                    : "bg-transparent border border-white text-white"
                }`}
              >
                <SelectValue placeholder="Select Team Member" />
              </SelectTrigger>
              <SelectContent
                className={isDark ? "bg-white text-black" : "bg-black text-white"}
              >
                {team?.data?.users &&
                Array.isArray(team?.data?.users) &&
                team?.data?.users.length > 0 ? (
                  team?.data?.users.map((member) => (
                    <SelectItem key={member.linkedinId} value={member.linkedinId}>
                      {member.userName}
                    </SelectItem>
                  ))
                ) : (
                  <SelectItem value="no-team">No team members available</SelectItem>
                )}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
      <div className="h-auto sm:h-80 p-3 sm:p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6">
          {summary.map((item, index) => (
            <div
              key={index}
              className={`relative flex flex-col items-center justify-center p-4 sm:p-6 shadow-md rounded-lg w-full transform transition-transform duration-200 hover:scale-105 ${
                isDark ? "bg-white text-gray-800" : "bg-gray-800 text-white"
              }`}
            >
              <div
                className="absolute top-0 left-0 right-0 h-1 rounded-t-lg"
                style={{
                  background:
                    "linear-gradient(126deg, #8A51FF -9.26%, #39B2FF 79.86%)",
                }}
              />
              <h3 className="text-lg sm:text-xl font-semibold mb-2">
                {item.label}
              </h3>
              <p
                className={`text-2xl sm:text-3xl font-extrabold`}
                style={{
                  color: isDark ? "#6610f2" : "#007bff",
                }}
              >
                {item.number}
              </p>
            </div>
          ))}
        </div>
      </div>

      <BarChartComp />
      <DemographicsChart />
      <div className="flex flex-col items-center gap-3 sm:gap-4 w-full">
        <h3
          className={`text-xl sm:text-2xl font-bold ${
            isDark ? "text-black" : "text-gray-300"
          } text-left w-full`}
        >
          Top Interactions
        </h3>
        <div
          className={`${
            isDark ? "bg-white" : "bg-[#2D2D2D]"
          } p-4 sm:p-6 rounded-lg w-full`}
        >
          <TopInteractors />
        </div>
      </div>

      <div className="flex flex-col items-center gap-3 sm:gap-4 w-full">
        <h3
          className={`text-xl sm:text-2xl font-bold ${
            isDark ? "text-black" : "text-gray-300"
          } text-left w-full`}
        >
          Consistency Tracking
        </h3>
        <div
          className={`${
            isDark ? "bg-white" : "bg-[#2D2D2D]"
          } p-4 sm:p-6 rounded-lg w-full`}
        >
          <StreakComp />
        </div>
      </div>

      <div className="flex flex-col items-center gap-3 sm:gap-4 w-full">
        <h3
          className={`text-xl sm:text-2xl font-bold ${
            isDark ? "text-black" : "text-gray-300"
          } text-left w-full`}
        >
          Engaging Time Tracking
        </h3>
        <div
          className={`${
            isDark ? "bg-white" : "bg-[#2D2D2D]"
          } p-4 sm:p-6 rounded-lg w-full`}
        >
          <Table />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-6 sm:gap-12 items-stretch">
        <div className="w-full sm:w-1/2 h-full flex flex-col rounded">
          <WordCloudComponent />
        </div>
        <div className="w-full sm:w-1/2 h-full flex flex-col rounded">
          <PostTypeGraph />
        </div>
      </div>

      <div className="flex flex-col items-center gap-3 sm:gap-4 w-full">
        <h3
          className={`text-xl sm:text-2xl font-bold ${
            isDark ? "text-black" : "text-gray-300"
          } text-left w-full`}
        >
          HashTag
        </h3>
        <div
          className={`${
            isDark ? "bg-white" : "bg-[#2D2D2D]"
          } p-4 sm:p-6 rounded-lg w-full`}
        >
          <HashtagAnalyticsTable />
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative w-full">
      {/* Conditionally render MobileNotification on mobile devices */}
      {showMobileNotification && (
        <MobileNotification onClose={() => setShowMobileNotification(false)} />
      )}

      {/* Main content (hidden on mobile when notification is shown) */}
      {!showMobileNotification && (
        <>
          {isFree && (
            <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-3">
              <div
                className={`${
                  isDark ? "post-ad grediant-border" : "post-ad-dark"
                }`}
                style={{ width: "90%", maxWidth: "500px", position: "fixed", bottom: "30%" }}
              >
                <div className="post-box-text">
                  <p className="d-flex justify-content-center w-100 text-xs sm:text-sm md:text-lg">
                    You have discovered a premium feature. To access all our features, you
                    need to subscribe to one of our plans by clicking the button below.
                  </p>
                </div>
                <div className="btn-create-post">
                  <Link
                    to="/buy-subscription"
                    className="grediant-button"
                    style={{
                      background:
                        "linear-gradient(126deg,#8A51FF -9.26%,#39B2FF 79.86%)",
                    }}
                    title="Upgrade"
                  >
                    Upgrade
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              filter: isFree ? "grayscale(100%)" : "none",
              pointerEvents: isFree ? "none" : "auto",
            }}
          >
            {mainContent}
          </div>
        </>
      )}
    </div>
  );
};

export default Analytics;