import React, { useEffect, useState } from "react";
import { getApi, postApi } from "../../../api/methods";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Facebook = ({ setIsAccount, getConnData, userId, facebookId }) => {
  const userinfo = useSelector((state) => state.user.userData);
  const linkedinId = userinfo?.linkedinId;

  const [getFbId, setGetFbId] = useState(null);
  const [getFB, setGetFB] = useState([]);
  const [fbConnectedAc, setFBConnectedAc] = useState([]);
  const [getSearch, setGetSearch] = useState("");
  const [pendingAccount, setPendingAccount] = useState("");
  const [outOf, setOutOf] = useState("");

  const fetchPendingAccountsStatus = async () => {
    try {
      const response = await getApi(`credit/get-credit-left-account/${userinfo.teamId}`);
      setPendingAccount(response.accountLeft);
      setOutOf(response.outOf);
    } catch (error) {
      console.warn(`fetchPendingAccountsStatus ${error}`);
    }
  };

  let unconnectedData = getFB?.filter((item) => !getConnData?.fbConnectedAc?.some((chosenItem) => chosenItem.id === item.id));

  const getfbUser = async () => {
    if (linkedinId) {
      try {
        const res = await getApi(`facebook/get-facebook-user?facebookId=${facebookId}&userId=${userinfo?.userId}`);
        if (res?.data) {
          setGetFbId(res?.data);
          handleGetFbpage(res?.data?.facebookId);
        }
      } catch (error) {
        console.error("Error fetching Facebook user:", error);
      }
    }
  };

  const handleGetFbpage = async (facebookId) => {
    try {
      const res = await getApi(`get-fb-page-list?facebookId=${facebookId}&facebook=facebook`);
      if (res?.data) {
        setGetFB(res?.data?.pageList);
      }
    } catch (error) {
      console.error("Error fetching Facebook pages:", error);
    }
  };

  const handleConnectPage = async () => {
    if (fbConnectedAc.length > pendingAccount) {
      alert("Cannot add more accounts");
      return;
    }

    if (facebookId !== undefined) {
      try {
        await postApi("connect-social-account", {
          facebookId: facebookId,
          fbConnectedAc: fbConnectedAc,
        });
        setIsAccount(false);
        toast.success("Facebook Account Connected Successfully");
      } catch (err) {
        console.error("Error connecting Facebook account:", err);
        toast.error("Failed to connect Facebook Account");
      }
    }
  };

  useEffect(() => {
    fetchPendingAccountsStatus();
    getfbUser();
  }, []);

  return (
    <>
      <div className="fblist">
        <div className="search mt-3">
          <span>Select the accounts that you want to add.</span>
          <input value={getSearch} onChange={(e) => setGetSearch(e.target.value)} type="search" style={{ border: "2px solid gray" }} placeholder="Search Account" />
          <i className="fa-solid fa-magnifying-glass pb-3"></i>
        </div>
        <p>Your Plan allows you to connect {pendingAccount} more account</p>

        <ul style={{ height: "200px", overflowY: "auto", paddingTop: "1rem" }}>
          {unconnectedData?.length > 0
            ? unconnectedData
                ?.filter((item) => item.name.toLowerCase().includes(getSearch.toLowerCase()))
                ?.map((item, index) => (
                  <li key={index}>
                    {item.name}
                    <input type="checkbox" onClick={() => setFBConnectedAc((prevState) => [...prevState, item])} />
                  </li>
                ))
            : "No Data Found"}
        </ul>
        {pendingAccount !== 0 && <button onClick={handleConnectPage}>Connect Facebook Page</button>}
      </div>
    </>
  );
};

export default Facebook;
