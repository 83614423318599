import React, { useEffect, useState } from "react";
import { getApi } from "../../api/methods";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserInfo } from "../../store/features/user/userSlice";
import "./style.css";
import { toast } from "react-toastify";
import { IMAGE_URL } from "../../api/API";

// In this page user can enter the topics they want to post about.
// User can select up to 5 topics.
// User can add new topics by typing in the input field and pressing enter.
// User can remove topics by clicking the remove button.
// User can click the next button to go to the next page.

const DefineTopics = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [getPostCate, setGetPostCate] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newItemName, setNewItemName] = useState("");

  useEffect(() => {
    // Check if props.getUserTopics.topics is not null and has a length greater than 0
    if (props.getUserTopics?.topics?.length > 0) {
      // Set selected items to props.getUserTopics.topics
      setSelectedItems(props.getUserTopics.topics);
    }
  }, []);

  useEffect(() => {
    props.setUserTpoics((prev) => ({
      ...prev,
      loggeduserId: user.userData.linkedinId,
      topics: selectedItems,
    }));
  }, [selectedItems]);

  const handleItemClick = (item) => {
    if (selectedItems.length >= 5) {
      toast.warning("You can select up to 5 topics only");
      return;
    }

    // This will not allow duplicate topics

    const isDuplicate = selectedItems.some((selectedItem) => selectedItem === item.name);

    if (!isDuplicate) {
      setSelectedItems([...selectedItems, item.name]);
    } else {
      toast.warning(`"${item.name}" is already selected`);
    }
  };

  // Remove item from selected topics
  const handleRemoveItem = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
  };

  const handleAddItem = () => {
    if (selectedItems.length >= 5) {
      toast.warning("You can select up to 5 topics only");
      return;
    }

    if (newItemName.trim() !== "") {
      const isDuplicate = selectedItems.some((selectedItem) => selectedItem === newItemName);

      if (!isDuplicate) {
        setSelectedItems([...selectedItems, newItemName]);
        setNewItemName("");
      } else {
        toast.warning(`"${newItemName}" is already selected`);
      }
    }
  };

  const handleUserInput = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem();
    }
  };

  useEffect(() => {
    getApi(`otg-category/get-post-category`)
      .then((res) => setGetPostCate(res.data))
      .catch((err) => console.warn(err));
  }, []);

  return (
    <div className="setup-container">
      <div className="setup-header">
        <img className="setup-logo" src={`${IMAGE_URL}/images/logo.svg`} alt="Growero Logo" />
        <div className="setup-progress">
          <div className="progress-step active">
            <span className="step-number">1</span>
            <span className="step-text">Topics</span>
          </div>
          <div className="progress-line" />
          <div className="progress-step">
            <span className="step-number">2</span>
            <span className="step-text">Details</span>
          </div>
        </div>
      </div>

      <div className="container setup-content">
        <div className="row">
          <div className="col-12">
            <p className="setup-subtitle">Configure your personal AI</p>
            <h2 className="setup-title">Define your topics</h2>
            <p className="setup-description">What topics would you like to post about? (Select up to 5)</p>
          </div>

          <div className="col-12 mb-5">
            <div className="topic-input-container">
              <div className="input-wrapper">
                <input type="text" className="topic-input" list="suggest-topics" value={newItemName} onChange={(e) => setNewItemName(e.target.value)} onKeyDown={handleUserInput} placeholder="Type or select topics from suggestions" autoFocus />
                <datalist id="suggest-topics">
                  {getPostCate?.map((item, index) => (
                    <option key={index} value={item.name} />
                  ))}
                </datalist>
              </div>

              {selectedItems.length > 0 && (
                <div className="selected-topics-container">
                  {selectedItems.map((item, index) => (
                    <div key={index} className="topic-tag">
                      <span>{item}</span>
                      <button className="remove-topic" onClick={() => handleRemoveItem(index)}>
                        ×
                      </button>
                    </div>
                  ))}
                  <div className="topics-count">{selectedItems.length}/5 topics selected</div>
                </div>
              )}
            </div>
          </div>

          <div className="col-12 navigation-buttons">
            {selectedItems.length > 0 && (
              <button className="next-button" onClick={props.click} style={{ marginLeft: "auto" }}>
                Next Step
                <span className="next-icon">→</span>
              </button>
            )}
          </div>

          {/* Suggestions */}
          <div className="col-12">
            <div className="suggestions-container">
              <h4 className="suggestions-title">
                Suggested Topics
                <span className="suggestions-subtitle">Click to add</span>
              </h4>
              <div className="suggestions-grid">
                {getPostCate?.map((item, index) => (
                  <button key={index} className="suggestion-item" onClick={() => handleItemClick(item)} disabled={selectedItems.includes(item.name)}>
                    {item.name}
                    {!selectedItems.includes(item.name) && <span className="add-icon">+</span>}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefineTopics;
