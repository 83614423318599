import { React, useState, useEffect, useRef } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import "./aiBar.css";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import aiImg from "../../../../src/assets/AIwhite.svg";
import { Button } from "../../../components/ui/button";
import { useSelector, useDispatch } from "react-redux";
import { aiPostTopic, aiPostArticle, aiPostText, resetSlides } from "../../../store/features/carousel/action/adjustAction";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { axiosInstance, BASE_URL } from "../../../api/API";
import { getApi, postApi } from "../../../api/methods";
import Modal from "react-bootstrap/esm/Modal";
import addImg from "../../../assets/addImg.svg";
import swipeArr from "../../../assets/swipeArr.svg";
import bookmarkImg from "../../../assets/bookmark.svg";
import rightArr from "../../../assets/rightArr.svg";
import circle from "../../../assets/circle.svg";
import thunder from "../../../assets/thunder.svg";
import { Form } from "react-bootstrap";
import {
  adjustDesc,
  adjustImage,
  adjustSubtitle,
  adjustTitle,
  addSlideData,
  deleteSlideData,
  backgroundImageSet,
  backgroundImageOpacitySet,
  backgroundImageSizeSet,
  backgroundImagePositionSet,
  backgroundImageRemove,
  imageSet,
  duplicateSlideData,
  imageSizeSet,
  imagePositionSet,
  backgroundImageAllSet,
  revertbackgroundImageSet,
  backgroundAllSet,
  changeSubtitle,
  changeTitle,
  changeDesc,
} from "../../../store/features/carousel/action/adjustAction";
import { IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../../api/API";
import { userProfileData } from "../../../store/features/analytics/analyticsAction";
import Popup from "./Popup";
import { isLoading } from "../../../store/features/common/commonSlice";
import EditPostOverlay from "./EditPostOverlay";
import { toPng } from "html-to-image";
import { debounce } from "lodash"; // Add lodash for debouncing

const PatternContainer = ({ pattern, bgOpacity, color = "rgb(128, 128, 128)" }) => {
  const baseOpacity = Math.min(bgOpacity * 1.5, 1);

  const svgPatterns = {
    grid: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="grid" width="60" height="60" patternUnits="userSpaceOnUse">
            <path d="M 60 0 L 0 0 0 60" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="0.8" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
      </svg>
    ),
    dots: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="dots" width="25" height="25" patternUnits="userSpaceOnUse">
            <circle cx="12.5" cy="12.5" r="2" fill={`${color}`} fillOpacity={baseOpacity} />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#dots)" />
      </svg>
    ),
    stripped: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="stripes" width="40" height="40" patternUnits="userSpaceOnUse">
            <rect width="1" height="40" x="0" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="3" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="6" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="20" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="23" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="26" fill={`${color}`} fillOpacity={baseOpacity} />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#stripes)" />
      </svg>
    ),
    diagonals: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="diagonals" width="20" height="20" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <line x1="0" y1="0" x2="0" y2="20" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="3" />
            <line x1="10" y1="0" x2="10" y2="20" stroke={`${color}`} strokeOpacity={baseOpacity * 0.5} strokeWidth="1.5" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#diagonals)" />
      </svg>
    ),
    cage: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="cage" width="100" height="100" patternUnits="userSpaceOnUse">
            <path d="M25 50 A 25 25 0 0 1 50 25 A 25 25 0 0 1 75 50 A 25 25 0 0 1 50 75 A 25 25 0 0 1 25 50" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="1.5" />
            <path d="M25 50 A 25 25 0 0 1 50 25 A 25 25 0 0 1 75 50 A 25 25 0 0 1 50 75 A 25 25 0 0 1 25 50" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity * 0.3} strokeWidth="0.8" transform="rotate(45, 50, 50)" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#cage)" />
      </svg>
    ),
    boxes: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="boxes" width="60" height="60" patternUnits="userSpaceOnUse">
            <rect width="30" height="30" x="0" y="0" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="1.5" />
            <rect width="30" height="30" x="30" y="30" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="1.5" />
            <line x1="0" y1="0" x2="30" y2="30" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
            <line x1="30" y1="0" x2="0" y2="30" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
            <line x1="30" y1="30" x2="60" y2="60" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
            <line x1="60" y1="30" x2="30" y2="60" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#boxes)" />
      </svg>
    ),
    chevron: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="chevron" width="60" height="30" patternUnits="userSpaceOnUse">
            <path d="M0 30 L30 0 L60 30" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="2" />
            <path d="M0 30 L30 60 L60 30" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="2" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#chevron)" />
      </svg>
    ),
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
        overflow: "hidden",
      }}
    >
      {svgPatterns[pattern]}
    </div>
  );
};

function AiBar() {
  const state = useSelector((state) => state);
  const { isDark } = useSelector((state) => state.common);
  const { userData } = useSelector((state) => state.user);

  const common = useSelector((state) => state.common);
  const [active, setActive] = useState("Topic");
  const [isLoadingLocal, setisLoading] = useState(false);
  const [topic, setTopic] = useState("");
  const [text, setText] = useState("");
  const [tone, setTone] = useState("Creative");
  const [articleLink, setArticleLink] = useState("");
  const [targetAudience, setTargetAudience] = useState("Beauty Enthusiasts");
  const [slides, setSlides] = useState("3");
  const [toneError, setToneError] = useState(false);
  const [slidesError, setSlidesError] = useState(false);
  const [topicLimitExceeded, setTopicLimitExceeded] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [schedulePanelOpen, setSchedulePanelOpen] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [checkCustomTime, setCheckCustomTime] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const linkedinAccessToken = user.userData.accessToken;
  const upper = useSelector((s) => s.adjustReducer);
  const textChange = useSelector((s) => s.textReducer);
  const canvaChange = useSelector((s) => s.canvaReducer);
  const [verticalAlignment, setVerticalAlignment] = useState("");
  const [textAlignment, setTextAlignment] = useState("");
  const [h, setH] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [pattern, setPattern] = useState("");
  const [titleFont, setTitleFont] = useState("");
  const [bodyFont, setBodyFont] = useState("");
  const [subtitleSize, setSubtitleSize] = useState("");
  const [titleSize, setTitleSize] = useState("");
  const [descSize, setDescSize] = useState("");
  const [layout, setLayout] = useState("");
  const [slideNo, setSlideNo] = useState("");
  const [slideStyle, setSlideStyle] = useState("");
  const [roundVal, setRoundVal] = useState("");
  const [isSwipe, setIsSwipe] = useState("");
  const [isBookmark, setIsBookmark] = useState("");
  const [swipeText, setSwipeText] = useState("");
  const [swipeIcon, setSwipeIcon] = useState("");
  const [headShot, setHeadShot] = useState("");
  const [introOutro, setIntroOutro] = useState("");
  const [imgSize, setImgSize] = useState("");
  const [nameSize, setNameSize] = useState("");
  const [handleSize, setHandleSize] = useState("");
  const [headShotColor, setHeadShotColor] = useState("");
  const [bgOpacity, setBgOpacity] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [descColor, setDescColor] = useState("");
  const [subTitleColor, setSubTitleColor] = useState("");
  const [pageNoColor, setPageNoColor] = useState("");
  const [swipeColor, setSwipeColor] = useState("");
  const [bookmarkColor, setBookmarkColor] = useState("");
  const [boxColor, setBoxColor] = useState("");
  const [aspectRatio, setAspectRatio] = useState("");
  const [search, setSearch] = useState("");
  const [aiAssistOpen, setAiAssistOpen] = useState(false);
  const [AiTextValue, setAiTextValue] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [fileName, setFileName] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [description, setDescription] = useState("");
  const [previewPDF, setPreviewPDF] = useState(null);
  const [highQualityPDFBlob, setHighQualityPDFBlob] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const nextSlide = () => {
    if (currentSlide < upper.slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleAiTextValueChange = (e) => {
    setAiTextValue(e.target.value);
  };

  const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const [openContentOverlay, setOpenContentOverlay] = useState(false);

  const handleGenerate = () => {
    axiosInstance
      .post("ai-post/generate-description-from-topic", { topic: AiTextValue })
      .then((response) => {
        setDescription(response.data.description);
        console.log("API Response:", response.data);
      })
      .catch((error) => {
        console.error("Error making API request:", error);
      });
    setAiAssistOpen(false);
  };

  const linkedinId = user?.userData?.linkedinId;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    dispatch(userProfileData(userData.linkedinId));
  }, []);

  // Updated useEffect to set default font to "Philosopher"
  useEffect(() => {
    setTitleFont(textChange.titleFontType || "Philosopher"); // Changed default to "Philosopher"
    setBodyFont(textChange.bodyFontType || "Philosopher");  // Changed default to "Philosopher"
    setSubtitleSize(textChange.fontSizeSubtitle);
    setTitleSize(textChange.fontSizeTitle);
    setDescSize(textChange.fontSizeDesc);
    setTextAlignment(textChange.textAlign || "center");
    setVerticalAlignment(textChange.verticalAlign || "center");
    setTitleColor(textChange.titleColor);
    setDescColor(textChange.descColor);
    setSubTitleColor(textChange.subTitleColor);
    setPageNoColor(textChange.pageNoColor);
  }, [textChange]);

  // Preload the preview PDF when slides are available
  useEffect(() => {
    if (upper.slides && upper.slides.length > 0) {
      debouncedGeneratePDF(true); // Preload the preview PDF
    }
  }, [upper.slides]);

  const handleToneChange = (tone) => {
    setTone(tone);
  };

  const autoScheduleAndPost = async () => {
    try {
      const userTimeSlots = await axios.get(`${BASE_URL}schedule/get-auto-scheduler-slot/${user?.userData?.linkedinId}`);
      let utcTimeSlot = [];
      const currentDate = new Date().toISOString().split("T")[0];

      for (const slot of userTimeSlots.data.timeSlot) {
        const futureDate = new Date(`${currentDate}T${slot}`).toISOString();
        utcTimeSlot.push(futureDate?.split(".")[0]?.split("T")[1]);
      }

      toast.info("Preparing carousel for LinkedIn...");
      const pdf = await generatePDF(false); // Generate high-quality PDF for posting
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", user?.userData?.linkedinId);

      toast.info("Uploading PDF to LinkedIn...");
      const uploadResponse = await postApi("linkedin/document-upload", formData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const asset = uploadResponse.asset;

      const postData = {
        author: `urn:li:person:${user?.userData?.linkedinId}`,
        commentary: state.adjustReducer.slides[0].title || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      const bodyPost = {
        ...postData,
        utcTimeSlots: utcTimeSlot,
        userId: user?.userData?.linkedinId,
        originalUrl: null,
        media: asset,
        mediaTitle: "Carousel",
        mediaDis: "application/pdf",
        postType: "Carousel",
        connectionType: "LinkedIn",
        postContent: postData.commentary,
        postId: null,
        postTag: null,
        autoCommentId: null,
        platformPost: "LinkedIn",
      };

      toast.info("Scheduling LinkedIn post...");
      const scheduleResponse = await postApi("schedule/create-auto-schedule-post", bodyPost, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
        },
      });

      if (!scheduleResponse.success) {
        throw new Error("Failed to schedule LinkedIn post");
      }

      toast.success("Carousel PDF scheduled for posting on LinkedIn successfully!");
      dispatch(resetSlides());
      return scheduleResponse;
    } catch (error) {
      console.error("Error in auto-scheduling and posting:", error);
      toast.error(`Failed to auto-schedule and post: ${error.message}`);
      throw error;
    }
  };

  const schedulePostHander = () => {
    setSchedulePanelOpen(true);
  };

  const cancelSchedulePost = () => {
    setSchedulePanelOpen(false);
    setScheduleDate("");
    setScheduleTime("");
    setCheckCustomTime(false);
  };

  const onScheduleConfirmHandler = async () => {
    setSchedulePanelOpen(false);

    const futureDate = new Date(`${scheduleDate}T${scheduleTime}`);

    try {
      const pdf = await generatePDF(false); // Generate high-quality PDF for posting
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", user?.userData?.linkedinId);

      const uploadResponse = await postApi("linkedin/document-upload", formData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const asset = uploadResponse.asset;

      const postData = {
        author: `urn:li:person:${user?.userData?.linkedinId}`,
        commentary: state.adjustReducer.slides[0].title || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      const bodyPost = {
        ...postData,
        date_time: futureDate.toISOString().split(".")[0],
        userId: user?.userData?.linkedinId,
        originalUrl: null,
        media: asset,
        mediaTitle: "Carousel",
        mediaDis: "application/pdf",
        postType: "Carousel",
        connectionType: "LinkedIn",
        postContent: postData.commentary,
        postId: null,
        postTag: null,
        autoCommentId: null,
        platformPost: "LinkedIn",
      };

      const scheduleResponse = await postApi("schedule/create-schedule-post", bodyPost, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
        },
      });

      if (!scheduleResponse.success) {
        throw new Error("Failed to schedule LinkedIn post");
      }

      toast.success("Carousel PDF scheduled for posting on LinkedIn successfully!");
      dispatch(resetSlides());
      setisLoading(false);
    } catch (error) {
      console.error("Error in scheduling carousel post:", error);
      toast.error(`Failed to schedule carousel post: ${error.message}`);
    }
  };

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [ref, callback]);
  };

  const image = "/images/pp.jpeg";

  const [openAdjustDropdownIndex, setOpenAdjustDropdownIndex] = useState(null);
  useOutsideClick(dropdownRef, () => {
    setOpenAdjustDropdownIndex(null);
  });

  const [openBackgroundDropdownIndex, setOpenBackgroundDropdownIndex] = useState(null);
  useOutsideClick(dropdownRef2, () => {
    setOpenBackgroundDropdownIndex(null);
  });

  const handleToggleAdjustDropdown = (index) => {
    setOpenAdjustDropdownIndex(openAdjustDropdownIndex === index ? null : index);
  };

  const handleToggleBackgroundDropdown = (index) => {
    setOpenBackgroundDropdownIndex(openBackgroundDropdownIndex === index ? null : index);
  };

  const handleSubtitleAdjust = (index, setSubtitle) => {
    const data = {
      index: index,
      subtitle: setSubtitle,
    };
    dispatch(adjustSubtitle(data));
  };

  const handleTitleAdjust = (index, setTitle) => {
    const data = {
      index: index,
      title: setTitle,
    };
    dispatch(adjustTitle(data));
  };

  const handleDescAdjust = (index, setDesc) => {
    const data = {
      index: index,
      desc: setDesc,
    };
    dispatch(adjustDesc(data));
  };

  const handleImageAdjust = (index, setImage) => {
    const data = {
      index: index,
      image: setImage,
    };
    dispatch(adjustImage(data));
  };

  const settingsChange = useSelector((s) => s.settingsReducer);
  const headShotChange = useSelector((s) => s.headShotReducer);
  const imageUploadChange = useSelector((s) => s.imageUploadReducer);

  useEffect(() => {
    if (aspectRatio) {
      const [width, height] = aspectRatio.split(":").map(Number);
      setH((520 * height) / width);
    }
  }, [aspectRatio]);

  useEffect(() => {
    setLayout(canvaChange.layout);
    setPattern(canvaChange.pattern);
    setBgOpacity(canvaChange.bgOpacity);
    setBgColor(canvaChange.bgColor);
    setAspectRatio(canvaChange.aspectRatio);
    setBoxColor(canvaChange.boxDivColor);
  }, [canvaChange]);

  useEffect(() => {
    setSlideNo(settingsChange.slideNo);
    setSlideStyle(settingsChange.slideNoStyle);
    setRoundVal(settingsChange.round);
    setIsSwipe(settingsChange.swipe);
    setIsBookmark(settingsChange.bookmark);
    setSwipeText(settingsChange.swipeText);
    setSwipeIcon(settingsChange.swipeIcon);
    setSwipeColor(settingsChange.swipeColor);
    setBookmarkColor(settingsChange.bookmarkColor);
  }, [settingsChange]);

  useEffect(() => {
    setHeadShot(headShotChange.headShot);
    setIntroOutro(headShotChange.introOutro);
    setImgSize(headShotChange.imgSize);
    setNameSize(headShotChange.nameSize);
    setHandleSize(headShotChange.handleSize);
    setHeadShotColor(headShotChange.headShotColor);
  }, [headShotChange]);

  const postToLinkedIn = async () => {
    setIsPosting(true);
    toast.info("Preparing carousel for LinkedIn...");

    try {
      const pdf = await generatePDF(false); // Generate high-quality PDF for posting
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", linkedinId);

      toast.info("Uploading PDF to LinkedIn...");
      const uploadResponse = await axios.post(`${BASE_URL}linkedin/document-upload`, formData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const asset = uploadResponse.data.asset;

      const postData = {
        author: `urn:li:person:${linkedinId}`,
        commentary: description || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      toast.info("Creating LinkedIn post...");
      const postResponse = await axios.post(`${BASE_URL}linkedin/create-linkedin-post`, postData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!postResponse.data.success) {
        throw new Error("Failed to create LinkedIn post");
      }

      toast.success("Carousel PDF posted to LinkedIn successfully!");
      const dbPostData = {
        userId: linkedinId,
        postUrn: postResponse.data.postUrn,
        postContent: postData.commentary,
        connectionType: "LinkedIn",
        postType: "carousel",
        postTag: "LinkedIn Post",
        media: [URL.createObjectURL(pdfBlob)],
        status: "PUBLISH",
        date_time: new Date().toISOString().split(".")[0],
        platformPost: {
          linkedin: true,
        },
      };

      toast.info("Saving post to the database...");
      const savePostResponse = await axios.post(`${BASE_URL}published/post-create`, dbPostData);

      if (savePostResponse.status === 200) {
        dispatch(resetSlides());
      }
    } catch (err) {
      console.error("Error posting to LinkedIn:", err);
      toast.error(`Failed to post carousel to LinkedIn: ${err.message}`);
    } finally {
      setisLoading(false);
      setIsPosting(false);
      dispatch(isLoading(false));
    }
  };

  const handleTargetAudienceChange = (targetAudience) => {
    setTargetAudience(targetAudience);
  };

  const handleSlides = (e) => {
    const value = parseInt(e.target.value);
    setSlides(value);
    setSlidesError(value < 3 || value > 15);
  };

  // Optimized generatePDF function with two-step generation (preview and download)
  const generatePDF = async (isPreview = true) => {
    const grammarlyElements = document.querySelectorAll("grammarly-extension");
    grammarlyElements.forEach((element) => (element.style.display = "none"));

    dispatch(isLoading(true));

    try {
      const slides = document.querySelector(".carousel-scroll-container")?.querySelectorAll(".capture-container");

      // if (!slides || slides.length === 0) {
      //   dispatch(isLoading(false));
      //   toast.error("No slides found to generate PDF");
      //   return null;
      // }

      const firstSlide = slides[0];
      const slideWidth = firstSlide.offsetWidth;
      const slideHeight = firstSlide.offsetHeight;
      const aspectRatio = slideWidth / slideHeight;

      const pdfWidth = 297; // A3 width in mm for better quality
      const pdfHeight = pdfWidth / aspectRatio;

      const pdf = new jsPDF({
        orientation: pdfHeight > pdfWidth ? "portrait" : "landscape",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
        compress: true,
        precision: 16,
      });

      // Process slides in parallel
      const imagePromises = Array.from(slides).map(async (slide, i) => {
        const dataUrl = await toPng(slide, {
          quality: isPreview ? 0.5 : 0.95, // Lower quality for preview
          pixelRatio: isPreview ? 0.5 : 2, // Lower pixel ratio for preview
          skipAutoScale: true,
          cacheBust: true,
          backgroundColor: "#ffffff",
          style: {
            transform: "scale(1)",
            transformOrigin: "top left",
            width: `${slideWidth}px`,
            height: `${slideHeight}px`,
          },
        });
        return { image: dataUrl, index: i };
      });

      const images = await Promise.all(imagePromises);
      images.sort((a, b) => a.index - b.index);

      images.forEach(({ image }, i) => {
        if (i > 0) {
          pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? "portrait" : "landscape");
        }
        pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight, undefined, isPreview ? "FAST" : "MEDIUM");
      });

      const pdfBlob = pdf.output("blob");
      if (isPreview) {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPreviewPDF(pdfUrl);
      } else {
        setHighQualityPDFBlob(pdfBlob);
      }

      grammarlyElements.forEach((element) => (element.style.display = ""));
      dispatch(isLoading(false));
      setShowDialog2(false);

      return pdf;
    } catch (error) {
      console.error("Error generating PDF:", error);
      dispatch(isLoading(false));
      // toast.error("Error generating PDF. Please try again.");
      return null;
    }
  };

  // Debounced version of generatePDF to prevent redundant calls
  const debouncedGeneratePDF = debounce(async (isPreview) => {
    await generatePDF(isPreview);
  }, 300);

  // Handle showing the preview modal
  const handleShowPreview = async () => {
    if (!previewPDF) {
      await debouncedGeneratePDF(true);
    }
    setShowPreviewModal(true);
  };

  // Handle download after preview
  const handleDownloadAfterPreview = async () => {
    if (!highQualityPDFBlob) {
      await generatePDF(false); // Generate high-quality PDF for download
    }
    if (highQualityPDFBlob) {
      const url = URL.createObjectURL(highQualityPDFBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${fileName || "carousel"}.pdf`;
      link.click();
      URL.revokeObjectURL(url);
      setShowPreviewModal(false);
      toast.success("PDF downloaded successfully!");
    }
  };

  const updateCreditHistory = async (creditInfo) => {
    try {
      const topicCredit = await getApi("creditpoint/point-info/Carousel-Generation");
      const transactionData = await getApi(`transaction/get-subscription-by-id/${user.userData.teamId}`);

      const bodyPost = {
        teamId: user.userData.teamId,
        name: transactionData[0].plan_Name,
        subscriptionId: creditInfo.data.subscriptionId,
        userId: user.userData.linkedinId,
        crpoint: `${topicCredit.data.point}`,
        transaction: "Carousel-Generation",
      };
      await postApi("credit/create-credit-history", bodyPost);
    } catch (error) {
      console.warn("update Credit History", error);
    }
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    let creditInfo;

    try {
      creditInfo = await getApi(`credit/check-credit-point/${user.userData.teamId}`);

      if (creditInfo.status === false) {
        throw new Error("Error fetching credit information");
      }

      if (creditInfo.status === 403) {
        toast.error("Trial period has expired. Please subscribe to continue.");
        return;
      }
    } catch (error) {
      console.error("Error fetching credit information:", error);
      toast.error(error.response.data.message);
      return;
    }

    if (!tone) setToneError(true);
    if (tone && targetAudience && !slidesError) {
      if (active === "Text") {
        const data = {
          text: text,
          tone: tone,
          targetAudience: targetAudience,
          slides: slides,
        };
        setisLoading(true);
        dispatch(aiPostText(data, setisLoading));
      } else if (active === "Topic") {
        const data = {
          topic: topic,
          tone: tone,
          targetAudience: targetAudience,
          slides: slides,
        };
        setisLoading(true);
        dispatch(aiPostTopic(data, setisLoading));
      } else if (active === "Article") {
        const data = {
          articleUrl: articleLink,
          tone: tone,
          targetAudience: targetAudience,
          slides: slides,
        };
        setisLoading(true);
        dispatch(aiPostArticle(data, setisLoading));
      }
    }
    updateCreditHistory(creditInfo);
  };

  const [showDialog2, setShowDialog2] = useState(false);
  const closeDialog2 = () => {
    setShowDialog2(false);
    setActive("upload");
    setSearch("");
  };

  const handleTopicChange = (e) => {
    const newTopic = e.target.value;
    if (newTopic.length <= 50) {
      setTopic(newTopic);
      setTopicLimitExceeded(false);
    } else {
      toast.warning(`Max 50 characters allowed`);
    }
  };

  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
  const [showCarouselPreview, setShowCarouselPreview] = useState(false);

  const handleSaveAsPNG = async () => {
    dispatch(isLoading(true));
    try {
      const slides = document.querySelectorAll(".capture-container");
      if (slides.length === 0) return;

      const canvas = await html2canvas(slides[0], {
        scale: 8,
        useCORS: true,
        logging: false,
        backgroundColor: null,
        allowTaint: true,
        imageTimeout: 0,
        windowWidth: slides[0].offsetWidth * 2,
        windowHeight: slides[0].offsetHeight * 2,
        onclone: (clonedDoc) => {
          const images = clonedDoc.getElementsByTagName("img");
          return Promise.all(
            Array.from(images).map((img) => {
              if (img.complete) return Promise.resolve();
              return new Promise((resolve) => {
                img.onload = resolve;
                img.onerror = resolve;
              });
            })
          );
        },
      });

      const link = document.createElement("a");
      link.download = `${fileName || "carousel"}.png`;
      link.href = canvas.toDataURL("image/png", 1.0);
      link.click();
      toast.success("PNG downloaded successfully!");
    } catch (error) {
      console.error("Error saving as PNG:", error);
      toast.error("Error saving as PNG. Please try again.");
    } finally {
      dispatch(isLoading(false));
    }
  };

  const handleExportToZapier = () => {
    toast.info("Zapier export coming soon!");
  };

  const handleExportToMake = () => {
    toast.info("Make export coming soon!");
  };

  const [previewImages, setPreviewImages] = useState([]);
  const [isGeneratingPreviews, setIsGeneratingPreviews] = useState(false);

  const generatePreviewImages = async () => {
    setIsGeneratingPreviews(true);
    const images = [];

    try {
      const slides = document.querySelectorAll(".capture-container");
      for (let slide of slides) {
        const dataUrl = await toPng(slide, {
          quality: 0.95,
          backgroundColor: "white",
        });
        images.push(dataUrl);
      }
      setPreviewImages(images);
    } catch (error) {
      console.error("Error generating preview images:", error);
      toast.error("Error generating preview images");
    }

    setIsGeneratingPreviews(false);
  };

  const generateAndDownloadPDF = async () => {
    setIsGeneratingPreviews(true);
    dispatch(isLoading(true));

    try {
      const slides = document.querySelectorAll(".capture-container");
      if (!slides.length) {
        toast.error("No slides to download");
        return;
      }

      const firstSlide = slides[0];
      const slideWidth = firstSlide.offsetWidth;
      const slideHeight = firstSlide.offsetHeight;
      const aspectRatio = slideWidth / slideHeight;

      const pdfWidth = 210;
      const pdfHeight = pdfWidth / aspectRatio;

      const pdf = new jsPDF({
        orientation: pdfHeight > pdfWidth ? "portrait" : "landscape",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });

      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i];
        const dataUrl = await toPng(slide, {
          quality: 1.0,
          pixelRatio: 2,
          skipAutoScale: true,
          cacheBust: true,
          backgroundColor: "#ffffff",
          style: {
            transform: "scale(1)",
            transformOrigin: "top left",
            width: `${slideWidth}px`,
            height: `${slideHeight}px`,
          },
        });

        if (i > 0) {
          pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? "portrait" : "landscape");
        }

        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight, undefined, "FAST");
      }

      pdf.save(`${fileName || "carousel"}.pdf`);
      toast.success("PDF downloaded successfully!");
      setShowCarouselPreview(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF. Please try again.");
    } finally {
      setIsGeneratingPreviews(false);
      dispatch(isLoading(false));
    }
  };

  return (
    <div>
      <Modal
        show={schedulePanelOpen}
        onHide={() => setIsPopupOpen(true)}
        backdrop={false}
        keyboard={false}
        dialogClassName={`custom-width-modal-40 ${isDark ? "" : "isdark-model"}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Post Linkedin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows="10"
            className="form-control description-textarea w-100"
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Enter your description here..."
          ></textarea>
          <div className="d-flex justify-content-start mt-2">
            <button
              className={`ai-assist-button ${isDark ? "publish-post-light" : "publish-post-dark"} p-2 gradientBorderFinal`}
              onClick={() => setAiAssistOpen(true)}
            >
              AI Assist
            </button>
          </div>
          <div className="mt-3">
            <input
              type="text"
              className="form-control"
              value={fileName}
              onChange={handleFileNameChange}
              placeholder="Enter file name here..."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="">
            <button
              className={`${isDark ? "publish-post-light" : "publish-post-dark"} p-2 gradientBorderFinal text-[0.875rem]`}
              onClick={postToLinkedIn}
            >
              Post to LinkedIn
            </button>
            <div className="btn-group">
              <button
                className="gradient-button-new text-white p-2 px-3 text-[0.875rem]"
                style={{
                  borderRadius: "6px 0 0 6px",
                  border: "1px solid gray",
                  width: "300px",
                }}
                onClick={autoScheduleAndPost}
              >
                Auto-Schedule
              </button>
              <button
                className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                style={{ backgroundColor: "#39B2FF" }}
                id="dropdownMenuReference"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-reference="parent"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul
                className="dropdown-menu grediant-button w-full"
                aria-labelledby="dropdownMenuReference"
                style={{ cursor: "pointer", padding: 0, zIndex: 100000000 }}
              >
                <li
                  onClick={schedulePostHander}
                  className="flex justify-center items-center gradient-button-new p-3"
                  style={{
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    borderRadius: "6px",
                  }}
                >
                  Custom-Schedule
                </li>
              </ul>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={schedulePanelOpen}
        onHide={() => setIsPopupOpen(true)}
        backdrop={false}
        keyboard={false}
        dialogClassName={`custom-width-modal-30 ${!isDark ? "" : "isdark-model"} second-modal`}
      >
        <Modal.Header>
          <Modal.Title>Preview Post</Modal.Title>
        </Modal.Header>
        <Modal.Body className="padded-modal-body">
          <div className="post-preview-container p-3 border rounded">
            <div className="d-flex align-items-center mb-3">
              <img
                src={user?.userPhoto?.length > 0 ? user?.userPhoto : `${IMAGE_URL}/images/Icons/avatar.jpg`}
                alt="User"
                className="rounded-circle me-2"
                style={{ width: "50px", height: "50px" }}
              />
              <div>
                <h5 className="m-0">{user?.userData?.userName}</h5>
                <small className="text-muted">
                  {user?.userData?.localizedHeadline?.length > 50
                    ? `${user?.userData?.localizedHeadline.slice(0, 50)}...`
                    : user?.userData?.localizedHeadline}
                </small>
              </div>
            </div>

            <div className="mb-3">
              <p>{description}</p>
            </div>

            <div ref={scrollContainerRef} className="carousel-preview mb-3 border rounded position-relative">
              <div className="carousel-navbar bg-light text-center p-2">File Name | {upper.slides.length}</div>

              <div className="carousel-page d-flex justify-content-center"></div>

              {currentSlide > 0 && (
                <button className="carousel-btn-prev position-absolute arrow-circle" onClick={prevSlide}>
                  <i className="fa fa-chevron-left"></i>
                </button>
              )}
              {currentSlide < upper.slides.length - 1 && (
                <button className="carousel-btn-next position-absolute arrow-circle" onClick={nextSlide}>
                  <i className="fa fa-chevron-right"></i>
                </button>
              )}
            </div>

            <div className="page-info text-center mt-2">
              Page {currentSlide + 1} / {upper.slides.length}
            </div>

            <div className="d-flex justify-content-between mt-3 border-top pt-3">
              <div className="d-flex align-items-center">
                <i className="fa fa-thumbs-up me-2"></i>
                <span>Like</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fa fa-comment-dots me-2"></i>
                <span>Comment</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fa fa-retweet me-2"></i>
                <span>Repost</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fa fa-paper-plane me-2"></i>
                <span>Send</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={aiAssistOpen} onHide={() => setAiAssistOpen(false)} backdrop={false} keyboard={false} className="ai-assist-modal">
        <Modal.Body className="p-4">
          <div className="d-flex flex-column ai-assist-content">
            <h5>AI Assist</h5>
            <input
              type="text"
              onChange={handleAiTextValueChange}
              value={AiTextValue}
              className="form-control my-3"
              placeholder="Write your topic here..."
            />
            <button className="btn btn-primary align-self-end" onClick={handleGenerate}>
              Generate
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDialog2}
        onHide={closeDialog2}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <Modal.Header closeButton className={`${isDark ? "text-black" : "text-white"}`}>
          Carousel
        </Modal.Header>
        <Modal.Body>
          <div className="flex overflow-x-scroll"></div>
          <button className={`text-white mt-4 float-right gradient-button-new p-2 rounded-md`} onClick={handleDownloadAfterPreview}>
            Download
          </button>
        </Modal.Body>
      </Modal>

      {/* Updated Download Preview Modal with Lazy Loading and Placeholder */}
      <Modal
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        size="lg"
        centered
        className={isDark ? "" : "isdark-model"}
      >
        <Modal.Header closeButton>
          <Modal.Title>PDF Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {previewPDF ? (
            <div style={{ height: "70vh", overflow: "auto" }}>
              <iframe
                src={previewPDF}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  loading: "lazy", // Added lazy loading
                }}
                title="PDF Preview"
              />
            </div>
          ) : (
            <div style={{ height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <p>Loading PDF...</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="gradient-button-new text-white p-2 px-3 rounded-md" onClick={handleDownloadAfterPreview}>
            Download
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCarouselPreview}
        onHide={() => setShowCarouselPreview(false)}
        size="xl"
        centered
        className={isDark ? "custom-modal" : "custom-modal isdark-model"}
        dialogClassName="custom-modal-dialog"
      >
        <button
          className="custom-close-button"
          onClick={() => setShowCarouselPreview(false)}
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>

        <Modal.Header className="border-b-0 py-4 flex items-center">
          <Modal.Title className="carousel-preview-title pl-6">Carousel Preview</Modal.Title>
          <div className="flex gap-2 ml-auto pr-6">
            <button
              className={`carousel-action-button ${
                isDark ? "bg-white text-gray-900 hover:bg-gray-100 border-gray-300" : "bg-gray-800 text-white hover:bg-gray-700 border-gray-600"
              }`}
              onClick={generateAndDownloadPDF}
              disabled={isGeneratingPreviews}
            >
              {isGeneratingPreviews ? (
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              ) : (
                <i className="fas fa-file-pdf"></i>
              )}
              {isGeneratingPreviews ? "Please Wait..." : "Download"}
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-6">
          {isGeneratingPreviews ? (
            <div className="flex flex-col justify-center items-center h-64 gap-2">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Please Wait...</span>
              </div>
              <span className="text-gray-600 dark:text-gray-300 font-medium">Please Wait...</span>
            </div>
          ) : (
            <div className="preview-scroll-container flex overflow-x-auto p-4 hide-scrollbar snap-x snap-mandatory">
              {previewImages.length === 0 ? (
                <div className="flex justify-center items-center w-full h-64 text-gray-500 dark:text-gray-400">
                  No slides available to preview.
                </div>
              ) : (
                previewImages.map((imageUrl, index) => (
                  <div
                    key={index}
                    className="preview-slide flex-shrink-0 snap-center"
                    style={{
                      width: "400px",
                      height: "400px",
                      border: "1px solid var(--border-color)",
                      borderRadius: "12px",
                      overflow: "hidden",
                      position: "relative",
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt={`Slide ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ))
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>

        <div className="py-4 sm:py-6 px-2 sm:px-3 md:px-4 flex flex-col gap-2 sm:gap-3 overflow-y-auto max-h-[80vh] sm:max-h-[70vh] md:max-h-[100vh]">
          <p className="font-bold text-base sm:text-lg md:text-base">AI Generation</p>

          <div className="flex flex-col">
            <div className="flex flex-col sm:flex-row justify-between py-2 px-2 sm:px-3 md:px-4">
              <span
                className={`${active === "Topic" ? "border-b-2 gradient-border-bottom" : "border-b-2 border-white"} cursor-pointer ${
                  isDark ? "hover:bg-gray-100" : "hover:bg-gray-400"
                } hover:rounded-t-md p-1 sm:p-2 px-2 sm:px-3 md:px-4 text-sm sm:text-base`}
                onClick={() => setActive("Topic")}
              >
                Topic
              </span>
              <span
                className={`${active === "Text" ? "border-b-2 gradient-border-bottom" : "border-b-2 border-white"} cursor-pointer ${
                  isDark ? "hover:bg-gray-100" : "hover:bg-gray-400"
                } hover:rounded-t-md p-1 sm:p-2 px-2 sm:px-3 md:px-4 text-sm sm:text-base`}
                onClick={() => setActive("Text")}
              >
                Paragraph
              </span>
              <span
                className={`${active === "Article" ? "border-b-2 gradient-border-bottom" : "border-b-2 border-white"} cursor-pointer ${
                  isDark ? "hover:bg-gray-100" : "hover:bg-gray-400"
                } hover:rounded-t-md p-1 sm:p-2 px-2 sm:px-3 md:px-4 text-sm sm:text-base`}
                onClick={() => setActive("Article")}
              >
                Article
              </span>
            </div>
            <hr className="my-1 sm:my-2 border-gray-300 dark:border-gray-600" />
          </div>

          <form className="flex flex-col gap-4 sm:gap-3" onSubmit={(e) => handleFormSubmission(e)}>
            {active === "Topic" ? (
              <div className="flex flex-col w-full gap-1 sm:gap-2 text-xs sm:text-sm">
                <label className="text-xs sm:text-sm font-semibold" style={{ color: isDark ? "black" : "white" }}>
                  Enter a Topic (max 50 characters)
                </label>
                <Input
                  type="text"
                  value={topic}
                  onChange={handleTopicChange}
                  placeholder="e.g. Innovative Startup Ideas"
                  style={{
                    background: "transparent",
                    color: isDark ? "black" : "white",
                  }}
                  required={true}
                  className="w-full sm:w-auto"
                />
                {topicLimitExceeded && <p className="text-xs text-red-500">Topic is limited to 50 characters.</p>}
              </div>
            ) : active === "Text" ? (
              <div className="flex flex-col w-full gap-1 sm:gap-2 text-xs sm:text-sm">
                <label className="text-xs sm:text-sm font-semibold" style={{ color: isDark ? "black" : "white" }}>
                  Enter your text content
                </label>
                <Textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Your awesome content"
                  rows={3}
                  style={{
                    background: "transparent",
                    color: isDark ? "black" : "white",
                  }}
                  className="w-full"
                  required={true}
                />
              </div>
            ) : (
              <div className="flex flex-col w-full gap-1 sm:gap-2 text-xs sm:text-sm">
                <label className="text-xs sm:text-sm font-semibold" style={{ color: isDark ? "black" : "white" }}>
                  Enter an Article URL
                </label>
                <Input
                  type="text"
                  value={articleLink}
                  onChange={(e) => setArticleLink(e.target.value)}
                  placeholder="https://www.example.com/article"
                  style={{
                    background: "transparent",
                    color: isDark ? "black" : "white",
                  }}
                  className="w-full sm:w-auto"
                  required={true}
                />
              </div>
            )}
            <div className="flex flex-col gap-1 sm:gap-2 text-xs sm:text-sm">
              <p className="text-xs sm:text-sm font-semibold">Tone/Mood</p>
              <Select value={tone} onValueChange={handleToneChange} className="w-full sm:w-auto">
                <SelectTrigger
                  className={`${isDark ? "text-black bg-white" : "text-white bg-transparent"} border border-gray-300 dark:border-gray-600 rounded-md`}
                >
                  <SelectValue placeholder="Creative" />
                </SelectTrigger>
                <SelectContent
                  className={`${isDark ? "bg-white text-black" : "bg-black text-white"} border border-gray-300 dark:border-gray-600 rounded-md`}
                >
                  <SelectItem value="Creative">Creative</SelectItem>
                  <SelectItem value="Curious">Curious</SelectItem>
                  <SelectItem value="Empathetic">Empathetic</SelectItem>
                  <SelectItem value="Casual">Casual</SelectItem>
                  <SelectItem value="Formal">Formal</SelectItem>
                  <SelectItem value="Fun">Fun</SelectItem>
                  <SelectItem value="Happy">Happy</SelectItem>
                  <SelectItem value="Hopeful">Hopeful</SelectItem>
                  <SelectItem value="Informal">Informal</SelectItem>
                  <SelectItem value="Informative">Informative</SelectItem>
                  <SelectItem value="Joyful">Joyful</SelectItem>
                  <SelectItem value="Optimistic">Optimistic</SelectItem>
                  <SelectItem value="Persuasive">Persuasive</SelectItem>
                  <SelectItem value="Sarcastic">Sarcastic</SelectItem>
                  <SelectItem value="Sincere">Sincere</SelectItem>
                  <SelectItem value="Witty">Witty</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-1 sm:gap-2 text-xs sm:text-sm">
              <p className="text-xs sm:text-sm font-semibold">Target Audience</p>
              <Select value={targetAudience} onValueChange={handleTargetAudienceChange} className="w-full sm:w-auto">
                <SelectTrigger
                  className={`${isDark ? "text-black bg-white" : "text-white bg-transparent"} border border-gray-300 dark:border-gray-600 rounded-md`}
                >
                  <SelectValue placeholder="Beauty Enthusiasts" />
                </SelectTrigger>
                <SelectContent
                  className={`${isDark ? "bg-white text-black" : "bg-black text-white"} border border-gray-300 dark:border-gray-600 rounded-md`}
                >
                  <SelectItem value="Beauty Enthusiasts">Beauty Enthusiasts</SelectItem>
                  <SelectItem value="Entrepreneurs">Entrepreneurs</SelectItem>
                  <SelectItem value="Environmentalists">Environmentalists</SelectItem>
                  <SelectItem value="Fitness Enthusiasts">Fitness Enthusiasts</SelectItem>
                  <SelectItem value="Foodies">Foodies</SelectItem>
                  <SelectItem value="Gamers">Gamers</SelectItem>
                  <SelectItem value="General Public">General Public</SelectItem>
                  <SelectItem value="Gen Z">Gen Z</SelectItem>
                  <SelectItem value="Millennials">Millennials</SelectItem>
                  <SelectItem value="Professionals">Professionals</SelectItem>
                  <SelectItem value="Students">Students</SelectItem>
                  <SelectItem value="Tech Enthusiasts">Tech Enthusiasts</SelectItem>
                  <SelectItem value="Travelers">Travelers</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-1 sm:gap-2 text-xs sm:text-sm -mt-2 sm:-mt-3">
              <label className="text-xs sm:text-sm font-semibold" style={{ color: isDark ? "black" : "white" }}>
                Slides
              </label>
              <input
                type="number"
                className="w-full sm:w-[290px] text-xs sm:text-sm border-[1px] border-gray-200 rounded-md p-2 outline-none"
                placeholder=""
                value={slides}
                onChange={(e) => handleSlides(e)}
                style={{
                  background: "transparent",
                  color: isDark ? "black" : "white",
                }}
                required={true}
              />
              {slidesError && (
                <p className="text-xs text-red-500">Slides should be greater than 2 and less than 16</p>
              )}
            </div>

            <Button
              type="submit"
              className="gradient-button-new flex items-center justify-center gap-2 hover:bg-green-800 w-full sm:w-auto"
              disabled={isLoadingLocal ? true : false}
            >
              {isLoadingLocal ? (
                <div className="flex justify-center items-center gap-1">
                  <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                  <p>Generating...</p>
                </div>
              ) : (
                <>
                  {aiImg && <img src={aiImg} alt="AI Icon" className="w-4 h-4" />}
                  {`Generate from ${active === "Text" ? "Text" : active === "Topic" ? "Topic" : "Article"}`}
                </>
              )}
            </Button>
          </form>

          <div className="flex flex-col gap-3 sm:gap-3">
            <button
              className="gradient-button-new text-white p-2 mt-2 px-2 rounded-md text-xs sm:text-sm md:text-base w-full sm:w-auto"
              onClick={() => setIsEditOverlayOpen(true)}
            >
              <span className="text">Create Post</span>
            </button>

            <EditPostOverlay isOpen={isEditOverlayOpen} onClose={() => setIsEditOverlayOpen(false)} />

            <button
              className="gradient-button-new text-white p-2 px-2 rounded-md text-xs sm:text-sm md:text-base w-full sm:w-auto"
              onClick={async () => {
                setShowCarouselPreview(true);
                await generatePreviewImages();
              }}
              style={{ zIndex: 9000000 }}
            >
              Download Carousel
            </button>
            
          </div>
        </div>
      </div>
   
  );
}

export default AiBar;
       