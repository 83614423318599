import React, { useEffect, useState, useMemo } from "react";
import TimezoneSelect from "react-timezone-select";
import { patchApi, postApi } from "../../api/methods";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchUserInfo } from "../../store/features/user/userSlice";
import axios from "axios";
import { BASE_URL } from "../../api/API";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const getDayIcon = (day) => {
  return (
    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
    </svg>
  );
};

const WEEKDAYS = [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
];

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      const time = `${formattedHour}:${formattedMinute}`;
      times.push({ value: time, label: time });
    }
  }
  return times;
};

const General = (props) => {
  const users = useSelector((state) => state.user);
  const isDarkStyle = useSelector((state) => state.common.isDark);
  const { layoutShift } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const userInfo = users?.userData;
  const userTopics = users?.userChosenTopics;
  console.log("User Topics", userTopics);

  const initialTimezone = useMemo(() => {
    try {
      return userTopics?.timeZone ? JSON.parse(userTopics.timeZone) : Intl.DateTimeFormat().resolvedOptions();
    } catch (error) {
      console.error("Error parsing timezone:", error);
      return Intl.DateTimeFormat().resolvedOptions();
    }
  }, [userTopics?.timeZone]);

  const [selectedTimezone, setSelectedTimezone] = useState(initialTimezone);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [evergreenSlots, setEvergreenSlots] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [slotData, setSlotData] = useState([]);
  const [isEvergreenModalOpen, setIsEvergreenModalOpen] = useState(false);

  const [evergreenConfig, setEvergreenConfig] = useState({
    startDate: new Date(),
    repeatFrequency: 1,
    repeatUnit: "day",
    selectedDays: [],
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    times: {},
  });

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "400px",
      maxHeight: "70vh",
      padding: "20px",
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      border: "1px solid #e5e7eb",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  // Load initial data from userTopics
  useEffect(() => {
    if (userTopics) {
      setSelectedTimezone(initialTimezone);
      setIsDark(userTopics.isDarkmode || false);
      setEvergreenSlots(Array.isArray(userTopics.evergreenSlots) ? userTopics.evergreenSlots : []);
      setIsOn(userTopics.isEvergreen && Array.isArray(userTopics.evergreenSlots) && userTopics.evergreenSlots.length > 0);
      setSelectedTimes(
        Array.isArray(userTopics.timeSlots) ? userTopics.timeSlots.map((slot) => slot?.startTime).filter(Boolean) : []
      );
    }
  }, [userTopics, initialTimezone]);

  // Sync slotData with evergreenSlots
  useEffect(() => {
    setSlotData(evergreenSlots);
  }, [evergreenSlots]);

  const handleTimeChange = async (selectedOptions) => {
    try {
      const selectedValues = selectedOptions?.map((option) => option.value) || [];
      const timeSlotObjects = selectedValues.map((time) => ({
        startTime: time,
        endTime: time,
        daysOfWeek: [],
        isRecurring: false,
        frequency: "once",
        interval: 1,
      }));

      setSelectedTimes(selectedValues);

      const response = await postApi("user/create-user-choosen-topics", {
        loggeduserId: userInfo?.linkedinId,
        isDarkmode: isDark,
        isEvergreen: isOn,
        timeZone: JSON.stringify(selectedTimezone),
        timeSlots: timeSlotObjects,
        evergreenSlots,
      });

      if (response) {
        await dispatch(fetchUserInfo(userInfo?.linkedinId));
        toast.success("Time slots updated successfully!");
      }
    } catch (error) {
      console.error("Error updating time slots:", error);
      toast.error("Failed to update time slots");
      setSelectedTimes(
        Array.isArray(userTopics?.timeSlots) ? userTopics.timeSlots.map((slot) => slot?.startTime).filter(Boolean) : []
      );
    }
  };

  const handleUpdateUser = async () => {
    try {
      const response = await postApi("user/create-user-choosen-topics", {
        loggeduserId: userInfo?.linkedinId,
        isDarkmode: isDark,
        isEvergreen: isOn,
        timeZone: JSON.stringify(selectedTimezone),
        timeSlots: selectedTimes.map((time) => ({
          startTime: time,
          endTime: time,
          daysOfWeek: [],
          isRecurring: false,
          frequency: "once",
          interval: 1,
        })),
        evergreenSlots,
      });

      if (response) {
        await dispatch(fetchUserInfo(userInfo?.linkedinId));
        toast.success("Settings updated successfully!");
        setIsUpdate(false);
        setShowUpdateButton(false);
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Update failed!");
      setIsUpdate(false);
    }
  };

  const handleTimezoneChange = async (timezone) => {
    try {
      setSelectedTimezone(timezone);
      setShowUpdateButton(true);
      setIsUpdate(true);

      await postApi("user/create-user-choosen-topics", {
        loggeduserId: userInfo?.linkedinId,
        isDarkmode: isDark,
        isEvergreen: isOn,
        timeZone: JSON.stringify(timezone),
        timeSlots: selectedTimes.map((time) => ({
          startTime: time,
          endTime: time,
          daysOfWeek: [],
          isRecurring: false,
          frequency: "once",
          interval: 1,
        })),
        evergreenSlots,
      });

      toast.success("Timezone updated successfully!");
      dispatch(fetchUserInfo(userInfo?.linkedinId));
    } catch (error) {
      console.error("Error updating timezone:", error);
      toast.error("Failed to update timezone");
      setSelectedTimezone(initialTimezone);
    }
  };

  const handleSwichs = () => {
    if (!isOn && (!evergreenSlots || evergreenSlots.length === 0)) {
      setIsEvergreenModalOpen(true);
    } else {
      setIsOn(!isOn);
      setShowUpdateButton(true);
      setIsUpdate(true);
    }
  };

  const handleRemoveTimeSlot = async (timeToRemove) => {
    try {
      const newTimeSlots = selectedTimes.filter((time) => time !== timeToRemove);
      const timeSlotObjects = newTimeSlots.map((time) => ({
        startTime: time,
        endTime: time,
        daysOfWeek: [],
        isRecurring: false,
        frequency: "once",
        interval: 1,
      }));

      setSelectedTimes(newTimeSlots);

      const response = await postApi("user/create-user-choosen-topics", {
        loggeduserId: userInfo?.linkedinId,
        isDarkmode: isDark,
        isEvergreen: isOn,
        timeZone: JSON.stringify(selectedTimezone),
        timeSlots: timeSlotObjects,
        evergreenSlots,
      });

      if (response) {
        await dispatch(fetchUserInfo(userInfo?.linkedinId));
        toast.success("Time slot removed successfully!");
      }
    } catch (error) {
      console.error("Error removing time slot:", error);
      toast.error("Failed to remove time slot");
      setSelectedTimes(
        Array.isArray(userTopics?.timeSlots) ? userTopics.timeSlots.map((slot) => slot?.startTime).filter(Boolean) : []
      );
    }
  };

  const handleEvergreenConfigSave = async () => {
    if (evergreenConfig.repeatUnit === "week" && evergreenConfig.selectedDays.length === 0) {
      toast.error("Please select at least one day");
      return;
    }

    const missingTimes =
      evergreenConfig.repeatUnit === "week"
        ? evergreenConfig.selectedDays.some((dayIndex) => !evergreenConfig.times[dayIndex] || evergreenConfig.times[dayIndex].length === 0)
        : !evergreenConfig.times[0] || evergreenConfig.times[0].length === 0;

    if (missingTimes) {
      toast.error("Please select posting times");
      return;
    }

    try {
      const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
      const selectedDayNames = evergreenConfig.selectedDays.map((dayIndex) => dayNames[dayIndex]);
      const firstDayIndex = evergreenConfig.selectedDays[0] || 0;
      const firstTimeSlot = evergreenConfig.repeatUnit === "week" ? evergreenConfig.times[firstDayIndex][0].value : evergreenConfig.times[0][0].value;

      let formattedTimeSlots = {};
      if (evergreenConfig.repeatUnit === "week") {
        formattedTimeSlots = evergreenConfig.selectedDays.reduce((acc, dayIndex) => {
          const times = evergreenConfig.times[dayIndex] || [];
          return {
            ...acc,
            [dayNames[dayIndex].toLowerCase()]: times.map((time) => ({ time: time.value, enabled: true })),
          };
        }, {});
      } else {
        formattedTimeSlots = {
          default: (evergreenConfig.times[0] || []).map((time) => ({ time: time.value, enabled: true })),
        };
      }

      const frequency = evergreenConfig.repeatUnit === "day" ? "daily" : evergreenConfig.repeatUnit === "week" ? "weekly" : "monthly";
      const evergreenSlotData = {
        startDate: evergreenConfig.startDate,
        endDate: evergreenConfig.endDate,
        frequency,
        interval: evergreenConfig.repeatFrequency,
        timeSlots: formattedTimeSlots,
        startTime: firstTimeSlot,
        endTime: firstTimeSlot,
      };

      if (frequency === "weekly") {
        evergreenSlotData.daysOfWeek = selectedDayNames;
      } else if (frequency === "monthly") {
        evergreenSlotData.dayOfMonth = evergreenConfig.startDate.getDate() || 1;
      }

      const response = await postApi(`user/addEvergreenSlot/${userInfo?.linkedinId}`, evergreenSlotData);
      if (response.data) {
        const newSlot = { ...evergreenSlotData, id: response.data.id };
        const updatedSlots = [...evergreenSlots, newSlot];

        await postApi("user/create-user-choosen-topics", {
          loggeduserId: userInfo?.linkedinId,
          isDarkmode: isDark,
          isEvergreen: true,
          timeZone: JSON.stringify(selectedTimezone),
          timeSlots: selectedTimes.map((time) => ({
            startTime: time,
            endTime: time,
            daysOfWeek: [],
            isRecurring: false,
            frequency: "once",
            interval: 1,
          })),
          evergreenSlots: updatedSlots,
        });

        setEvergreenSlots(updatedSlots);
        setIsEvergreenModalOpen(false);
        setIsOn(true);
        setShowUpdateButton(true);
        setIsUpdate(true);

        setEvergreenConfig({
          startDate: new Date(),
          repeatFrequency: 1,
          repeatUnit: "day",
          selectedDays: [],
          endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
          times: {},
        });

        toast.success("Evergreen slot added successfully!");
        dispatch(fetchUserInfo(userInfo?.linkedinId));
      }
    } catch (error) {
      console.error("Error adding evergreen slot:", error);
      toast.error(error.response?.data?.message || "Failed to add evergreen slot");
    }
  };

  const handleRemoveEvergreenSlot = async (index) => {
    try {
      const newSlots = slotData.filter((_, i) => i !== index);
      setSlotData(newSlots);
      setEvergreenSlots(newSlots);

      await postApi("user/create-user-choosen-topics", {
        loggeduserId: userInfo?.linkedinId,
        isDarkmode: isDark,
        isEvergreen: newSlots.length > 0,
        timeZone: JSON.stringify(selectedTimezone),
        timeSlots: selectedTimes.map((time) => ({
          startTime: time,
          endTime: time,
          daysOfWeek: [],
          isRecurring: false,
          frequency: "once",
          interval: 1,
        })),
        evergreenSlots: newSlots,
      });

      if (newSlots.length === 0) setIsOn(false);
      toast.success("Evergreen slot removed successfully!");
      dispatch(fetchUserInfo(userInfo?.linkedinId));
    } catch (error) {
      console.error("Error removing evergreen slot:", error);
      toast.error(error.response?.data?.message || "Failed to remove evergreen slot");
      setSlotData([...evergreenSlots]);
      setEvergreenSlots([...evergreenSlots]);
    }
  };

  const timeSlotIteration = useMemo(() => {
    return selectedTimes.map((time) => ({ label: time, value: time }));
  }, [selectedTimes]);

  const getFrequencyText = (frequency, interval) => {
    switch (frequency) {
      case "daily": return `day${interval > 1 ? "s" : ""}`;
      case "weekly": return `week${interval > 1 ? "s" : ""}`;
      case "monthly": return `month${interval > 1 ? "s" : ""}`;
      default: return frequency.replace("ly", "");
    }
  };

  return (
    <>
      <div className="general-item py-2 mb-2 my-2">
        <h3 className="text-lg mb-1">Personal Information</h3>
        <p className="user-info-account border text-sm sm:text-base">{userInfo?.userName}</p>
      </div>
      <div className="general-item py-2 mb-2">
        <h3 className="text-lg mb-1">Email Address</h3>
        <p className="user-info-account border text-sm sm:text-base">{userInfo?.email}</p>
      </div>

      <div className="general-item row d-flex justify-content-between align-items-center py-2 mb-2">
        <div className="col-md-8">
          <h3 className="text-lg">Set Time Slot</h3>
        </div>
        <div className="col-md-4">
          <Select
            isMulti
            options={generateTimeOptions()}
            value={timeSlotIteration}
            onChange={handleTimeChange}
            placeholder="Select a time as 24 Hours"
            className="time-slote shadow"
          />
        </div>
      </div>

      <div className="general-item row d-flex justify-content-between align-items-center py-2 mb-2">
        <div className="col-md-8">
          <h3>Timezone</h3>
        </div>
        <div className="col-md-4">
          <TimezoneSelect value={selectedTimezone} onChange={handleTimezoneChange} className="mb-3 shadow time-zone" />
        </div>
      </div>

      <div className="general-item-switch py-2 mb-2">
        <h3 className="flex items-center gap-2">
          Evergreen Slot
          <i className="fa fa-info-circle" style={{ fontSize: "16px" }} title="Your selected post will be reported on your wall"></i>
        </h3>
        <div className="switch" onClick={handleSwichs}>
          <span className={isOn ? "black" : "white"}></span>
        </div>
      </div>

      {isOn && (
        <div className={`mb-6 border border-gray-300 rounded-lg p-3 ${!isDarkStyle ? "text-white" : "text-black"}`}>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">
              <p className={`text-xl ${!isDarkStyle ? "text-white" : "text-black"}`}>Evergreen Slots</p>
            </h3>
            <p className={`text-sm ${!isDarkStyle ? "text-white" : "text-black"}`}>Configure when you want your posts to be automatically reposted</p>
          </div>

          <div className="space-y-4 mb-6">
            {(slotData || []).map((slot, index) => (
              <div key={slot.id || index} className={`${!isDarkStyle ? "text-white" : "text-black"}`}>
                <div className="flex justify-between items-center mb-3">
                  <div>
                    <h4 className={`font-medium ${!isDarkStyle ? "text-white" : "text-black"}`}>
                      Every {slot.interval} {getFrequencyText(slot.frequency, slot.interval)}
                    </h4>
                    {slot.daysOfWeek?.length > 0 && (
                      <p className={`text-sm mt-1 ${!isDarkStyle ? "text-white" : "text-black"}`}>
                        On: {slot.daysOfWeek.join(", ")}
                      </p>
                    )}
                    {slot.startTime && (
                      <p className={`text-sm mt-1 ${!isDarkStyle ? "text-white" : "text-black"}`}>
                        Times it will be posted: {slot.startTime}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={() => handleRemoveEvergreenSlot(index)}
                    className="text-red-500 hover:text-red-700"
                    disabled={!slot.id}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={() => setIsEvergreenModalOpen(true)}
            className={`w-full py-3 border-2 border-dashed rounded-lg flex items-center justify-center gap-2 ${!isDarkStyle ? "text-white border-white" : "text-black border-black"}`}
          >
            <i className="fas fa-plus"></i>
            Add New Slot
          </button>
        </div>
      )}

      <div className="footer-general mb-4 mt-4">
        <div className="linkedin-branding flex flex-col sm:flex-row items-center w-full">
          <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-4">
            <div className="flex items-center">
              <div className="linkedin-image" style={{ marginRight: "10px" }}>
                <i className="fa-brands fa-linkedin fa-2x" style={{ color: "#2267dd" }}></i>
              </div>
              <h3 style={{ margin: "0 10px", fontSize: "16px" }}>
                Connected as <strong style={{ marginLeft: "5px", fontSize: "16px" }}>{userInfo?.userName}</strong>
              </h3>
            </div>
            <div className="footer-general-btn w-full sm:w-auto mt-2 sm:mt-0">
              <button
                className="btn btn-primary w-full sm:w-auto"
                style={{ minWidth: "150px", whiteSpace: "nowrap", fontSize: "14px" }}
                onClick={() => (window.location.href = `${BASE_URL}auth/linkedin?isFromAccount=true`)}
              >
                <i className="fa fa-refresh"></i>
                <span> Re-Authenticate</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showUpdateButton && (
        <div className="upDate-btn">
          <button onClick={handleUpdateUser} className="update-button">
            Update
          </button>
          <button onClick={() => setShowUpdateButton(false)} className="cancel-button">
            Cancel
          </button>
        </div>
      )}

      <div
        className="delete-user mb-1"
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: "40px" }}
      >
        <div>
          <h3 className="text-lg mb-1">Danger Zone</h3>
          <p className="mb-2">Careful, these actions are permanent</p>
        </div>
        <span
          className="border-2 border-red-600 p-2 rounded-md"
          style={{ backgroundColor: "red", fontWeight: 300, color: "white", cursor: "pointer" }}
          onClick={async () => {
            if (confirm("Are you sure you want to delete your account?")) {
              try {
                await patchApi(`user/user-session`, { ac_status: false, linkedinId: users.userData.linkedinId });
                await axios.post(`${BASE_URL}userprofile/logout`, {}, { withCredentials: true });
                window.location.href = "/login";
              } catch (err) {
                console.error("Error deleting account:", err);
              }
            }
          }}
        >
          Delete my Account
        </span>
      </div>

      <Modal isOpen={isEvergreenModalOpen} onRequestClose={() => setIsEvergreenModalOpen(false)} contentLabel="Evergreen Configuration" style={customModalStyles}>
        <div className="relative" style={{ maxHeight: "calc(90vh - 40px)" }}>
          <button onClick={() => setIsEvergreenModalOpen(false)} className="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
            ×
          </button>

          <h2 className="text-xl font-semibold mb-4">Repeat</h2>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Start</label>
            <DatePicker
              selected={evergreenConfig.startDate}
              onChange={(date) => setEvergreenConfig({ ...evergreenConfig, startDate: date })}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="mb-4 flex items-center gap-2">
            <label className="text-sm font-medium">Repeat every</label>
            <input
              type="number"
              min="1"
              value={evergreenConfig.repeatFrequency}
              onChange={(e) => setEvergreenConfig({ ...evergreenConfig, repeatFrequency: parseInt(e.target.value) || 1 })}
              className="w-16 p-1 border rounded"
            />
            <select
              value={evergreenConfig.repeatUnit}
              onChange={(e) => setEvergreenConfig({ ...evergreenConfig, repeatUnit: e.target.value })}
              className="p-1 border rounded"
            >
              <option value="day">day</option>
              <option value="week">week</option>
              <option value="month">month</option>
            </select>
          </div>

          {(evergreenConfig.repeatUnit === "week" || evergreenConfig.repeatUnit === "month") && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Select Days</label>
              <div className="flex flex-wrap gap-2">
                {["M", "T", "W", "T", "F", "S", "S"].map((day, index) => (
                  <button
                    key={index}
                    className={`w-8 h-8 rounded-full ${evergreenConfig.selectedDays.includes(index) ? "bg-blue-500 text-white" : "bg-gray-200"}`}
                    onClick={() => {
                      const days = evergreenConfig.selectedDays.includes(index)
                        ? evergreenConfig.selectedDays.filter((d) => d !== index)
                        : [...evergreenConfig.selectedDays, index];
                      setEvergreenConfig({ ...evergreenConfig, selectedDays: days });
                    }}
                  >
                    {day}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">End Date</label>
            <DatePicker
              selected={evergreenConfig.endDate}
              onChange={(date) => setEvergreenConfig({ ...evergreenConfig, endDate: date })}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Post Times</label>
            {evergreenConfig.repeatUnit === "day" ? (
              <Select
                isMulti
                options={generateTimeOptions()}
                value={evergreenConfig.times[0] || []}
                onChange={(selectedTimes) => setEvergreenConfig({ ...evergreenConfig, times: { 0: selectedTimes }, selectedDays: [0] })}
                placeholder="Select posting times"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            ) : (
              <div className="space-y-4">
                {evergreenConfig.selectedDays.map((dayIndex) => (
                  <div key={dayIndex} className="flex flex-col space-y-2">
                    <label className="text-sm text-gray-600">{["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][dayIndex]}</label>
                    <Select
                      isMulti
                      options={generateTimeOptions()}
                      value={evergreenConfig.times[dayIndex] || []}
                      onChange={(selectedTimes) =>
                        setEvergreenConfig({ ...evergreenConfig, times: { ...evergreenConfig.times, [dayIndex]: selectedTimes } })
                      }
                      placeholder="Select posting times"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex justify-end gap-2 mt-4">
            <button onClick={() => setIsEvergreenModalOpen(false)} className="px-4 py-2 border rounded hover:bg-gray-100">
              Cancel
            </button>
            <button onClick={handleEvergreenConfigSave} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default General;