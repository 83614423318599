import React, { useRef, useState } from "react";
import "./AIPostGen.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoading } from "../../../store/features/common/commonSlice";
import { toast } from "react-toastify";
import { postApi, getApi } from "../../../api/methods";
import { IMAGE_URL } from "../../../api/API";
import ai from "../../../assets/ai.svg";
import aiWhite from "../../../assets/AIwhite.svg";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { addAIPost, addDraftPostId, addMedia, addMediaDis, addMediaTitle, addOriginalUrl, changeConnectionType, changePostSelection, changePostType, clearPostState, setPostGenerationType } from "../../../store/features/post/postSlice";
import { POST_GENERATION_TYPES } from "../../../store/features/post/initialState";
import { white } from "tailwindcss/colors";

const validateURL = (url) => {
  // Regular expression for URL validation
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
};

const AIPostGen = () => {
  // This is the content type options for the post generation

  const contentTypeOptions = [
    { value: "thoughtLeadership", label: "General Public" },
    { value: "thoughtLeadership", label: "Entrepreneur" },
    { value: "thoughtLeadership", label: "Beauty Enthusiasts" },
    { value: "thoughtLeadership", label: "Environmentalists" },
    { value: "thoughtLeadership", label: "Fitness Enthusiasts" },
    { value: "thoughtLeadership", label: "Foodies" },
    { value: "thoughtLeadership", label: "Gamers" },
    { value: "thoughtLeadership", label: "Gen Z" },
    { value: "thoughtLeadership", label: "Millennials" },
    { value: "thoughtLeadership", label: "Professionals" },
    { value: "thoughtLeadership", label: "Students" },
    { value: "thoughtLeadership", label: "Tech Enthusiasts" },
    { value: "thoughtLeadership", label: "Travelers" },
  ];

  // This is the tone options for the post generation

  /* The above code is defining an array called `toneOptions` that contains objects with `value` and
`label` properties. These objects represent different mood options that can be selected. Each object
has a `value` of "mood" and a `label` representing a specific mood such as "Happy", "Sad",
"Creative", "Curious", and others. This array can be used to populate a dropdown or select input
with mood options for users to choose from. */

  const toneOptions = [
    { value: "mood", label: "Neutral" },
    { value: "mood", label: "Happy" },
    { value: "mood", label: "Sad" },
    { value: "mood", label: "Creative" },
    { value: "mood", label: "Curious" },
    { value: "mood", label: "Empathetic" },
    { value: "mood", label: "Casual" },
    { value: "mood", label: "Formal" },
    { value: "mood", label: "Fun" },
    { value: "mood", label: "Hopeful" },
    { value: "mood", label: "Informal" },
    { value: "mood", label: "Informative" },
    { value: "mood", label: "Joyful" },
    { value: "mood", label: "Optimistic" },
    { value: "mood", label: "Persuasive" },
    { value: "mood", label: "Sarcastic" },
    { value: "mood", label: "Sincere" },
    { value: "mood", label: "Witty" },
  ];

  // Now this will initialize the state variables
  const [isDisabledInput, setDisabledInput] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "select",
    label: "Select...",
  });
  const [AIstate, setAIstate] = useState({
    AItopic: "",
    AIcatagory: "",
    AICarouselTitle: "",
    AICarouselNumber: 1,
  });
  const [showGenerateOverlay, setShowGenerateOverlay] = useState(false);
  const [showModelPreviewOverlay, setShowModelPreviewOverlay] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState(contentTypeOptions[0].label);
  const [selectedTone, setSelectedTone] = useState(toneOptions[0].label);
  const [selectedModel, setSelectedModel] = useState("default");

  // This is the model previews for the post generation

  /* The above code is defining an array of model previews for different content creation frameworks or
  strategies. Each object in the array represents a specific model with properties such as id, name,
  and preview. These models include PAS (Problem-Agitation-Solution), AIDA
  (Attention-Interest-Desire-Action), SLAP (Stop-Look-Act-Purchase), BAB (Before-After-Bridge), 4Cs
  (Clear-Concise-Compelling-Credible), and FAB (Features-Advantages-Benefits). Each model preview
  provides a brief description of the strategy and its */

  const modelPreviews = [
    // {
    //   id: "default",
    //   name: "Default",
    //   preview: "Generate default post with standard format and structure.",
    // },
    {
      id: "pas",
      name: "PAS (Problem-Agitation-Solution)",
      preview: "First identify a problem your audience faces, then agitate that pain point by elaborating on its consequences, and finally present your solution. Perfect for problem-solving content.",
    },
    {
      id: "aida",
      name: "AIDA (Attention-Interest-Desire-Action)",
      preview: "Grab attention with a hook, build interest through value proposition, create desire by highlighting benefits, and end with a clear call to action. Ideal for persuasive content.",
    },
    {
      id: "slap",
      name: "SLAP (Stop-Look-Act-Purchase)",
      preview: "Make readers stop scrolling with an attention-grabbing opener, keep them looking with compelling content, inspire them to act, and guide them towards a purchase decision.",
    },
    {
      id: "bab",
      name: "BAB (Before-After-Bridge)",
      preview: "Paint a picture of the current situation (Before), show the potential future (After), and explain how to get there (Bridge). Great for transformation stories.",
    },
    {
      id: "4cs",
      name: "4Cs (Clear-Concise-Compelling-Credible)",
      preview: "Create content that's easy to understand, straight to the point, engaging to read, and backed by facts or experience. Perfect for educational content.",
    },
    {
      id: "fab",
      name: "FAB (Features-Advantages-Benefits)",
      preview: "Present the key features of your solution, explain their advantages, and highlight the specific benefits they bring to your audience. Excellent for product-focused content.",
    },
  ];

  // This is the handleGenerateWithOptions for the post generation

  const handleGenerateWithOptions = () => {
    if (!selectedContentType || !selectedTone) {
      toast.error("Please select both content type and tone");
      return;
    }

    if (selectedOption.label === "Long Content") {
      setShowGenerateOverlay(false);
      setShowModelPreviewOverlay(true);
      setSelectedModel("default");
    } else {
      setShowGenerateOverlay(false);
      generatePostHandler();
    }
  };

  // This is the handleFinalGenerate for the post generation

  const handleFinalGenerate = () => {
    // If no model is selected, use default model
    if (!selectedModel) {
      setSelectedModel("default");
    }
    setShowModelPreviewOverlay(false);
    generatePostHandler();
  };

  // Redux dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux selectors
  const { userData, userChosenTopics } = useSelector((state) => state.user);
  console.log(userData); // User data and chosen topics
  const { isDark } = useSelector((state) => state.common); // Dark mode status
  const post = useSelector((state) => state.post);

  // Go Now Button functionality

  const redirectToDiscover = () => {
    navigate("/discover"); // Redirects to the 'discover' page
  };
  // Generate PDF state
  const [generatePDF, setGeneratePDF] = useState(false);
  // console.log({ AIstate: AIstate.AIlength });

  // Function use to handle state
  const onChangeHandler = (e) => {
    setAIstate((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // When user can generate a AI post then the given credit amount deduct from credit balance and update in credit history
  const updateCreditHistory = async (creditInfo) => {
    try {
      const topicCredit = await getApi("creditpoint/point-info/AI-Generation");
      const transactionData = await getApi(`transaction/get-subscription-by-id/${userData.teamId}`);
      console.log("topicCredit");
      console.log(topicCredit);
      console.log("topicCredit");

      const bodyPost = {
        teamId: userData.teamId,
        name: transactionData[0].plan_Name,
        subscriptionId: creditInfo.data.subscriptionId,
        userId: userData.linkedinId,
        crpoint: `${topicCredit.data.point}`,
        transaction: "AI-Generation",
      };
      // console.log(bodyPost);
      await postApi("credit/create-credit-history", bodyPost);
    } catch (error) {
      console.warn("update Credit History", error);
    }
  };

  /*
    Here check if credit balance is not available then AI post will not generate
    otherwise AI post generate of short, long post type

    if user want to generate post of Disagree with a webpage, Agree with a webpage and Summarize a webpage then
    1st exrtact data from that given url and generate post respectively
  */
  const generatePostHandler = async () => {
    if (selectedOption.label === "Select..." || !AIstate.AItopic) {
      toast.error("Please select type of post!");
    } else {
      try {
        setDisabledInput(false);

        const ai_post_id = `ai_${uuidv4()}`;
        console.log(`ai_post_id : ${ai_post_id}`);

        let lengthOfPost;
        if (selectedOption.label === "LinkedIn Carousel") {
          lengthOfPost = {
            AICarouselTitle: AIstate.AItopic,
            AICarouselNumber: AIstate.AICarouselNumber,
            AIlength: 50,
          };
          // } else if (selectedOption.label === 'Disagree with a webpage') {
          //     lengthOfPost = 50
          // } else if (selectedOption.label === 'Agree with a webpage') {
          //     lengthOfPost = 50
          // } else if (selectedOption.label === 'Summarize a webpage') {
          //     lengthOfPost = 50
          // } else if (selectedOption.label === 'Long Content') {
          //     lengthOfPost = 200
        } else {
          lengthOfPost = 50;
        }

        let creditInfo;

        try {
          // Fetch credit information for the user's team
          creditInfo = await getApi(`credit/check-credit-point/${userData.teamId}`);

          if (creditInfo.status === false) {
            throw new Error("Error fetching credit information");
          }

          // Check if the response code is 403 (trail period expired)
          if (creditInfo.status === 403) {
            toast.error("Trial period has expired. Please subscribe to continue.");
            return;
          }
        } catch (error) {
          console.error("Error fetching credit information:", error);
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
          return;
        }
        // const creditInfo = await getApi(`credit/check-credit-point/${userData.teamId}`)
        console.log("creditInfo : ", creditInfo);

        if (selectedOption.label === "Disagree with a webpage" || selectedOption.label === "Agree with a webpage" || selectedOption.label === "Summarize a webpage") {
          setIsLoader(true);
          if (!validateURL(AIstate.AItopic)) {
            toast.warning("Please enter valid URL");
            setIsLoader(false);
            return;
          }
          dispatch(changePostType("ARTICLE"));
          dispatch(addOriginalUrl(AIstate.AItopic));

          let body = {
            category: AIstate.AIcatagory,
            topic: AIstate.AItopic,
            usreId: userData.linkedinId,
            id: ai_post_id,
            length: Number(lengthOfPost),
            postType: selectedOption.label,
            targetAudience: selectedContentType,
            tone: selectedTone,
          };

          if (Number(creditInfo.data.balance) > 0) {
            await postApi("web-scraping/url", body).then((res) => {
              updateCreditHistory(creditInfo);
              setIsLoader(false);
              setDisabledInput(false);
              toast.success(`AI Post Generated for ${selectedOption.label}.`);
              dispatch(addAIPost(res.aiResponse));
              // setGeneratePDF(true)
            });
          } else {
            console.log("hello");

            toast.error(`Insufficient Credits!`);
            setIsLoader(false);
            navigate("/buy-subscription");
          }
        } else if (selectedOption.label === "LinkedIn Carousel") {
          setIsLoader(true);
          let body = {
            category: AIstate.AIcatagory,
            topic: AIstate.AItopic,
            usreId: userData.linkedinId,
            id: ai_post_id,
            length: lengthOfPost,
            postType: selectedOption.label,
            targetAudience: selectedContentType,
            tone: selectedTone,
          };
          console.log("aipost1", body);
          // alert(JSON.stringify(body))
          if (Number(creditInfo.data.balance) > 0) {
            await postApi("ai-post/ai-post-create", body).then((res) => {
              updateCreditHistory(creditInfo);
              setIsLoader(false);
              setDisabledInput(false);
              toast.success("AI Post Generated for Carousel.");
              dispatch(addAIPost(res.aiResponse));
              dispatch(changePostType(selectedOption.label));
              dispatch(addMediaTitle(AIstate.AICarouselTitle));
            });
          } else {
            toast.error(`Insufficient Credits!`);
            setIsLoader(false);
            navigate("/buy-subscription");
          }
          setIsLoader(false);
        } else {
          setIsLoader(true);
          let body = {
            category: AIstate.AIcatagory,
            topic: AIstate.AItopic,
            usreId: userData.linkedinId,
            id: ai_post_id,
            length: Number(lengthOfPost),
            // Added the contentType and tone to the body

            postType: selectedOption.label,
            targetAudience: selectedContentType,
            tone: selectedTone,
          };

          // console.log('ai_post',{body})

          // Added the direct post api call where the body is passed

          console.log("creditInfo.data.balance");
          console.log(creditInfo.data.balance);
          if (creditInfo.data.balance == "unlimited" || Number(creditInfo.data.balance) > 0) {
            const response = await postApi("ai-post/ai-post-create", body);
            console.log("AI response:", response);

            updateCreditHistory(creditInfo);
            setIsLoader(false);
            setDisabledInput(false);
            toast.success("Post Generated!");
            dispatch(addAIPost(response.aiResponse));
            dispatch(setPostGenerationType(POST_GENERATION_TYPES.AI_CONTENT));

            // This will reset tone and content type to their default options
            setSelectedTone(toneOptions[0].label);
            setSelectedContentType(contentTypeOptions[0].label);
          } else {
            toast.error(`Insufficient Credits!`);
            setIsLoader(false);
            setDisabledInput(false);
            navigate("/buy-subscription");
          }
        }
      } catch (error) {
        setDisabledInput(false);
        setIsLoader(false);
        console.error("Error sending message:", error);
        toast.error("Post generation error!");
        // window.location.href = '/post-idea'
      }
    }
  };
  const options = [
    { value: "short", label: "Short Content" },
    { value: "long", label: "Long Content" },
    { value: "summarize", label: "Summarize a webpage" },
    { value: "agree", label: "Agree with a webpage" },
    { value: "disagree", label: "Disagree with a webpage" },
    // { value: 'carousel', label: 'LinkedIn Carousel' },
  ];

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setAIstate((prev) => {
      return {
        ...prev,
        AIlength: selectedOption.label,
      };
    });
  };

  // Custom style for type of post input field
  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "linear-gradient(126deg, #8A51FF -9.26%, #39B2FF 79.86%)", // Set your desired background color here
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Set text color to white
    }),
  };

  // console.log({selectedOption, AIstate});

  const formatOptionLabel = ({ label }) => <div>{label}</div>;

  // Handler for model selection/deselection
  const handleModelClick = (modelId) => {
    // If the model is already selected, deselect it
    if (selectedModel === modelId) {
      setSelectedModel(null);
    } else {
      setSelectedModel(modelId);
    }
  };

  // Handler for double click to deselect
  const handleModelDoubleClick = (e, modelId) => {
    e.preventDefault();
    if (selectedModel === modelId) {
      setSelectedModel(null);
    }
  };

  return (
    <>
      <div className={`w-[100%]`}>
        <h3 className="text-2xl -mb-3">Have a topic in Mind ?</h3>
        <form className="ai-form" onClick={(e) => e.preventDefault()}>
          <div className="form-group">
            <label htmlFor="exampleFormControlSelect1">Type of post</label>
            <Select
              // className={` xxxx ${isDark ? 'type-post-hr-light' : 'type-post-hr-dark'}`}
              value={selectedOption}
              onChange={handleChange}
              options={options}
              formatOptionLabel={formatOptionLabel}
              styles={customStyles}
              isDisabled={isDisabledInput}
            />
          </div>

          {AIstate.AIlength === "Disagree with a webpage" || AIstate.AIlength === "Agree with a webpage" || AIstate.AIlength === "Summarize a webpage" ? (
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Enter URL Link</label>
              <input
                disabled={isDisabledInput}
                type="text"
                className={`form-control ${isDark ? "" : "form-control-dark"}`}
                id="exampleFormControlInput1"
                placeholder="Enter URL"
                name="AItopic"
                style={{
                  backgroundColor: isDark ? "white" : "black",
                  color: isDark ? "black" : "white",
                }}
                onChange={(e) => onChangeHandler(e)}
                value={AIstate.AItopic}
              />
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Enter Topic or Phrase</label>
              <input
                disabled={isDisabledInput}
                type="text"
                className={`form-control ${isDark ? "" : "form-control-dark"}`}
                id="exampleFormControlInput1"
                placeholder="Enter topic for AI"
                name="AItopic"
                style={{
                  backgroundColor: isDark ? "white" : "black",
                  color: isDark ? "black" : "white",
                }}
                onChange={(e) => onChangeHandler(e)}
                value={AIstate.AItopic}
              />
            </div>
          )}
          {AIstate.AIlength === "LinkedIn Carousel" && (
            <>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Enter Title of Carousel</label>
                <input
                  disabled={isDisabledInput}
                  type="text"
                  className={`form-control ${isDark ? "" : "form-control-dark"}`}
                  id="exampleFormControlInput1"
                  placeholder="title"
                  name="AICarouselTitle"
                  style={{
                    backgroundColor: isDark ? "white" : "black",
                    color: isDark ? "black" : "white",
                  }}
                  onChange={(e) => onChangeHandler(e)}
                  value={AIstate.AICarouselTitle}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Number of thread</label>
                <select
                  disabled={isDisabledInput}
                  className={`form-control no-of-thread ${isDark ? "" : "form-control-dark"}`}
                  id="exampleFormControlSelect1"
                  name="AICarouselNumber"
                  style={{
                    backgroundColor: isDark ? "white" : "black",
                    color: isDark ? "black" : "white",
                  }}
                  onChange={(e) => onChangeHandler(e)}
                  value={AIstate.AICarouselNumber}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </div>
            </>
          )}

          <p className={`credit-box ${isDark ? "credit-box-light" : "credit-box-dark"}`}>
            Every Post or Rewrite generated consumes <span className={`${isDark ? "credit-text-light" : "credit-text-dark"}`}>1 Credit</span>
          </p>
          <div className="form-group ai-gen-btn" align="center">
            {isLoader ? (
              <button class={`p-2  ${isDark ? "text-white" : "text-white"} flex gap-2 items-center justify-center gradientBorderFinal`} type="button" disabled>
                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                Generating...
              </button>
            ) : AIstate.AIlength === "Disagree with a webpage" || AIstate.AIlength === "Agree with a webpage" || AIstate.AIlength === "Summarize a webpage" ? (
              <button className={`btn btn-primary ${isDark ? "grediant-button" : "grediant-border"}`} onClick={generatePostHandler}>
                Generate post using link
              </button>
            ) : (
              <button
                className={`gradientBorderFinal p-2`}
                onClick={() => {
                  if (selectedOption.label === "Long Content" || selectedOption.label === "Short Content") {
                    setShowGenerateOverlay(true);
                  } else {
                    generatePostHandler();
                  }
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  color: white,
                }}
              >
                <img src={aiWhite} alt="AI icon"></img>
                <p>Add Magic & Create Post</p>
              </button>
            )}
          </div>
        </form>
        <div className={`discover-box gradientBorderFinal`} style={{ width: "100%" }}>
          <h3 className="text-lg my-2">Can't think of a topic?</h3>
          <p className="my-2">
            <strong>Discover 1000s</strong> of high performing posts.
          </p>
          <p className=" my-2">
            Find inspiration and <strong>Re-write with AI in seconds</strong>
          </p>
          <button onClick={redirectToDiscover} className="btn grediant-button mt-3  gradient-btn1 text-white hover:text-black flex gap-1">
            Go Now{" "}
            <span>
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </span>{" "}
          </button>
        </div>

        {/* First Overlay Modal */}
        {showGenerateOverlay && (
          <Modal show={showGenerateOverlay} onHide={() => setShowGenerateOverlay(false)} centered backdrop={true} keyboard={true} className={`framework-modal ${isDark ? "" : "isdark-model"}`} size="lg">
            <Modal.Body className="p-6">
              {/* Progress Steps for generate post */}
              <div className="flex items-center justify-center mb-6">
                <div className="flex items-center">
                  <div className="gradient-button-new px-4 py-2 rounded-md font-semibold text-white">Personalize Post</div>
                  {/* Here the long content is selected then the choose framework is shown in blocks*/}
                  {selectedOption.label === "Long Content" && (
                    <>
                      <div className="h-[2px] w-12 bg-gray-300 mx-2"></div>
                      <div className="text-gray-400 px-4 py-2 rounded-md border border-gray-300 font-semibold">Choose Framework</div>
                    </>
                  )}
                </div>
              </div>

              <Form className="px-2">
                <Form.Group className="mb-5">
                  <Form.Label className={`text-lg font-semibold mb-2 ${isDark ? "text-dark" : "text-white"}`}>Which group of audience are you writing for?</Form.Label>
                  <Select
                    options={contentTypeOptions}
                    defaultValue={contentTypeOptions[0]}
                    value={contentTypeOptions.find((option) => option.label === selectedContentType)}
                    onChange={(selectedOption) => setSelectedContentType(selectedOption.label)}
                    className={`select-input ${isDark ? "bg-light text-dark" : "bg-dark text-white"}`}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "1px solid #4F46E5",
                        borderRadius: "6px",
                        minHeight: "40px",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        backgroundColor: isDark ? "white" : "black",
                        color: isDark ? "black" : "white",
                      }),
                      option: (base) => ({
                        ...base,
                        backgroundColor: isDark ? "white" : "black",
                        color: isDark ? "black" : "white",
                        "&:hover": {
                          backgroundColor: "#4F46E5",
                          color: "white",
                        },
                      }),
                      singleValue: (base) => ({
                        ...base,
                        color: isDark ? "black" : "white",
                      }),
                      menu: (base) => ({
                        ...base,
                        backgroundColor: isDark ? "white" : "black",
                        border: "1px solid #4F46E5",
                      }),
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label className={`text-lg font-semibold mb-2 ${isDark ? "text-dark" : "text-white"}`}>How are you feeling about the topic?</Form.Label>
                  <Select
                    options={toneOptions}
                    defaultValue={toneOptions[0]}
                    value={toneOptions.find((option) => option.label === selectedTone)}
                    onChange={(selectedOption) => setSelectedTone(selectedOption.label)}
                    className={`select-input ${isDark ? "bg-light text-dark" : "bg-dark text-white"}`}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "1px solid #4F46E5",
                        borderRadius: "6px",
                        minHeight: "40px",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        backgroundColor: isDark ? "white" : "black",
                        color: isDark ? "black" : "white",
                      }),
                      option: (base) => ({
                        ...base,
                        backgroundColor: isDark ? "white" : "black",
                        color: isDark ? "black" : "white",
                        "&:hover": {
                          backgroundColor: "#4F46E5",
                          color: "white",
                        },
                      }),
                      singleValue: (base) => ({
                        ...base,
                        color: isDark ? "black" : "white",
                      }),
                      menu: (base) => ({
                        ...base,
                        backgroundColor: isDark ? "white" : "black",
                        border: "1px solid #4F46E5",
                      }),
                    }}
                  />
                </Form.Group>
              </Form>

              <div className="flex justify-end gap-3 mt-6 px-2">
                <Button variant={isDark ? "light" : "secondary"} onClick={() => setShowGenerateOverlay(false)} className="px-4 py-2 rounded-md">
                  Back
                </Button>
                <Button
                  className="gradient-button-new px-4 py-2 rounded-md font-semibold"
                  onClick={() => {
                    if (selectedOption.label === "Long Content") {
                      handleGenerateWithOptions();
                    } else {
                      if (!selectedContentType || !selectedTone) {
                        toast.error("Please select both content type and tone");
                        return;
                      }
                      setShowGenerateOverlay(false);
                      generatePostHandler();
                    }
                  }}
                >
                  {selectedOption.label === "Long Content" ? "Next" : "Generate Post"}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {/* Second Overlay Modal for Framework Selection */}
        {showModelPreviewOverlay && (
          <Modal
            show={showModelPreviewOverlay}
            onHide={() => setShowModelPreviewOverlay(false)}
            centered
            backdrop={true}
            keyboard={true}
            className={`framework-modal ${isDark ? "" : "isdark-model"}`}
            size="lg"
            // current
          >
            <Modal.Body className="p-6">
              {/* Progress Steps */}
              <div className="flex items-center justify-center mb-3">
                <div className="flex items-center">
                  <div className="bg-gray-300 text-gray-600 px-4 py-2 rounded-md font-semibold">Personalize</div>
                  <div className="h-[2px] w-12 bg-gray-300 mx-2"></div>
                  <div className="gradient-button-new px-4 py-2 rounded-md font-semibold text-white">Choose Framework</div>
                </div>
              </div>

              <p className="text-sm text-gray-400 text-center pb-3">(Applicable Only For Long Content)</p>

              <div className="grid grid-cols-2 gap-3 px-2">
                {modelPreviews.map((model) => (
                  <div
                    key={model.id}
                    className={`p-4 rounded-xl cursor-pointer transition-all hover:shadow-md ${selectedModel === model.id ? "border-2 border-blue-500" : "border border-gray-200 hover:border-blue-300"}`}
                    onClick={() => handleModelClick(model.id)}
                    onDoubleClick={(e) => handleModelDoubleClick(e, model.id)}
                  >
                    <h4 className={`text-sm font-semibold mb-1 ${isDark ? "text-black" : "text-white"}`}>{model.name}</h4>
                    <p className={`text-xs leading-relaxed ${isDark ? "text-gray-600" : "text-gray-300"}`}>{model.preview}</p>
                  </div>
                ))}
              </div>

              <div className="flex justify-end gap-3 mt-6 px-2">
                <Button
                  variant={isDark ? "light" : "secondary"}
                  onClick={() => {
                    setShowModelPreviewOverlay(false);
                    setShowGenerateOverlay(true);
                  }}
                  className="px-4 py-2 rounded-md"
                >
                  Back
                </Button>
                <Button className="gradient-button-new px-4 py-2 rounded-md font-semibold" onClick={handleFinalGenerate}>
                  Generate Post
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>

      {generatePDF && <GenaratePDF />}
    </>
  );
};

/* The above code is a React component that defines a function called `GenaratePDF`. Inside this
function, it creates a reference to a DOM element using the `useRef` hook. The `handleGeneratePdf`
function is defined within `GenaratePDF`, which uses the `html2canvas` library to capture the
content of the referenced DOM element as an image. It then creates a PDF document using the `jsPDF`
library and saves the captured image as a PDF file named "download.pdf" when the function is called. */

export default AIPostGen;

const GenaratePDF = () => {
  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    html2canvas(reportTemplateRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
    });
  };

  return (
    <div className="pdf-container">
      <div className="pdf-box">
        <div className="btn-container d-flex justify-content-between">
          <button className="btn btn-primary" onClick={handleGeneratePdf}>
            Generate PDF
          </button>
          <button className="btn btn-primary">Add Image</button>
        </div>
        <hr />
        <div ref={reportTemplateRef}>
          <ReportTemplate />
        </div>
      </div>
    </div>
  );
};

const ReportTemplate = () => {
  const post = useSelector((state) => state.post);

  return (
    <>
      <div className="pdf-template">
        <h2 style={{ textAlign: "center", fontWeight: "600", color: "darkcyan" }}>AWS</h2>
        <img src="http://localhost:9090/uploads/pxfuel.jpg" style={{ height: "400px", width: "100%", margin: "2rem 0" }} />
        <p style={{ overflowWrap: "break-word" }}>
          hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu
          gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja
          dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu
          gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja
          dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu
          gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja
          dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu
          gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja
          dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu
          gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja
          dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu
          gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088hfh0gwgshg0sdigigvrjp=-q4refvj ughsdja
          dughpdhg[u]aji g8gohgopu gofg0ugghuhh9gheq9gv088
        </p>
      </div>
    </>
  );
};
