import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../api/API";
import "./InviteWithdrawn.css";

// If user sent the Team Invitation request but later on if they withdrawn the request then this will show there

const InviteWithdrawn = () => {
  return (
    <div className="invite-withdrawn-container">
      <div className="message-box">
        <img src={`${IMAGE_URL}/images/logo.svg`} alt="Logo" className="logo-center" />
        <h3>We’re Sorry!</h3>
        <p>Your invite has been withdrawn. Unfortunately, you are unable to join at this moment.</p>
        <p>But don't worry, you can still create a new account.</p>
        <Link to="/register" className="gradient-button register-button">
          Register Here
        </Link>
      </div>
    </div>
  );
};

export default InviteWithdrawn;
