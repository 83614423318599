import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPreviewPost, togglePreview } from "../../../../store/features/previewPost/previewPostSlice";
import { IMAGE_URL } from "../../../../api/API";
import DraftPostPreview from "../../myDraft/DraftPostPreview";

/* The `QueueItems` component is a functional component in a React application. It takes in props
`actualTime`, `data`, `editPost`, `previewPost`, and `deleteQueueConfirm`. */

const QueueItems = ({ actualTime, data, editPost, previewPost, deleteQueueConfirm }) => {
  const { isDark } = useSelector((state) => state.common);
  const previewPostState = useSelector((state) => state.previewPost);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`queue-post-items ${!data?.postTag ? (isDark ? "normal-bg-color-light" : "normal-bg-color-dark") : data?.postTag === "EVERGREEN" ? "evergreen-bg-color" : "schedule-bg-color"} ${
          isDark ? "" : data?.postTag !== "EVERGREEN" && "queue-text-color-dark"
        }`}
      >
        <div className="queue-post-left">
          {!data?.postTag ? (
            <i className="fa-regular fa-clock" style={{ color: "grey" }}></i>
          ) : data?.postTag === "EVERGREEN" ? (
            <i className="fa-solid fa-star" style={{ color: "#2b5eb6" }} title="Evergreen"></i>
          ) : (
            <i className="fa-regular fa-clock"></i>
          )}
          <div className="queue-time max-sm:text-[8px]">{actualTime}</div>
          <div className={`queue-content ${data.postContent === " You have not scheduled Any Post" && "queue-content-no-post"} `}>{data?.postContent?.length > 50 ? `${data?.postContent?.slice(0, 50)}...` : data?.postContent}</div>
        </div>

        {/* This block of code is conditionally rendering the content inside the `<div
        className="queue-post-right">` based on the value of `previewPost`. */}

        {previewPost && (
          <div className="queue-post-right">
            <div className="d-flex align-items-center">
              {data?.platformPost?.linkedin && <img src={`${IMAGE_URL}/images/socialIcon/linkedin.png`} style={{ height: "18px", width: "18px" }}></img>}
              {data?.platformPost?.twitter && <img src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`} style={{ height: "18px", width: "18px" }}></img>}
              {data?.platformPost?.facebook && <img src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} style={{ height: "18px", width: "18px" }}></img>}
              {data?.platformPost?.instagram && <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} style={{ height: "18px", width: "18px" }}></img>}
            </div>
            {data?.status &&
              (data.status === "PUBLISH" ? (
                <div className={`queue-status evergreen-status-color`}>
                  <i className="fa-solid fa-check-double fa-beat-fade"></i>
                  Published
                </div>
              ) : data.status === "FAILED" ? (
                <div className={`queue-status failed-status-color`}>
                  <i className="fa-solid fa-exclamation-triangle" style={{ color: "#ffffff" }}></i>
                  Failed
                </div>
              ) : (
                <div className={`queue-status schedule-status-color`}>
                  <i className="fa-regular fa-clock fa-spin" style={{ color: "#f7f7f8" }}></i>
                  Scheduled
                </div>
              ))}

            {data.status && (
              <>
                <i className="fa-regular fa-eye" onClick={() => previewPost(data)}></i>
                <i className="fa-solid fa-pencil" onClick={() => editPost(data)}></i>
                <i className="fa-regular fa-trash-can" style={{ color: "#b80a24" }} onClick={() => deleteQueueConfirm(data)}></i>
              </>
            )}
          </div>
        )}
      </div>

      {/* This code snippet is conditionally rendering the `DraftPostPreview` component based on the value of
      `previewPostState.showPreview`. */}
      {previewPostState.showPreview && <DraftPostPreview onClose={() => dispatch(togglePreview(false))} />}
    </>
  );
};

export default QueueItems;
