export const userState = {
    userData: {},
    userPhoto: '',
    userChosenTopics: {
        timeZone: "",
        isEvergreen: false,
        isDarkmode: true,
        topics: [],
        timeSlots: [],
        userPromtAIProfession: '',
        userAIPromtTopics: '',
        language: ''
    },
    counter: {},
    teamMembers: [],
    notifications: {},
    currentDate: Date(),
}
