import React, { useEffect, useState } from "react";
import FlagSelect from "react-flags-select";
import TimezoneSelect from "react-timezone-select";
import { IMAGE_URL } from "../../api/API";

// Here user can describe themselves that who are they, what they want to post about, etc.

const DescribeYourSelf = (props) => {
  const [getProfess, setGetProfess] = useState(props.getUserTopics.userPromtAIProfession);
  const [selectedOptions, setSelectedOptions] = useState(props.getUserTopics.userAIPromtTopics);
  const [selectedCountry, setSelectedCountry] = useState(props.getUserTopics.language || "US");
  const [selectedTimezone, setSelectedTimezone] = useState(props.getUserTopics.timeZone ? JSON.parse(props.getUserTopics.timeZone) : "");

  const handleSelectChange = (newValue) => {
    // Map the selected options to an array of string values
    const stringValues = newValue?.map((option) => option.value);
    setSelectedOptions(stringValues);
  };

  useEffect(() => {
    // Update setUserTopics with new user information
    props.setUserTpoics((prev) => {
      return {
        ...prev,
        language: selectedCountry,
        timeZone: JSON.stringify(selectedTimezone),
        userPromtAIProfession: getProfess,
        userAIPromtTopics: selectedOptions,
      };
    });
  }, [selectedCountry, selectedTimezone, getProfess, selectedOptions]);

  const handleTimezoneChange = (timezone) => {
    // Update the selected timezone
    setSelectedTimezone(timezone);
  };

  const handleSelect = (country) => {
    // Update the selected country
    setSelectedCountry(country);
  };

  const formatLabel = (country) => {
    // Format the country label with flag, label, and code
    return `${country.flag} ${country.label} (${country.code})`;
  };

  return (
    <div className="popup-container">
      <div className="setup-header">
        <img className="setup-logo" src={`${IMAGE_URL}/images/logo.svg`} alt="Growero Logo" />
        <div className="setup-progress">
          <div className="progress-step completed">
            <span className="step-number">✓</span>
            <span className="step-text">Topics</span>
          </div>
          <div className="progress-line" />
          <div className="progress-step active">
            <span className="step-number">2</span>
            <span className="step-text">Details</span>
          </div>
        </div>
      </div>

      <div className="container setup-content">
        <div className="row">
          <div className="col-12">
            <p className="setup-subtitle">Configure your personal AI</p>
            <h2 className="setup-title">How would you describe yourself?</h2>
            <p className="setup-description">Who are you? What is it you do and talk about?</p>
          </div>

          <div className="col-12 mb-5">
            <div className="topic-input-container">
              <div className="form-group">
                <p className="label mb-2">I am a...</p>
                <input type="text" className="topic-input" value={getProfess} onChange={(e) => setGetProfess(e.target.value)} placeholder="Who are you..." />
              </div>

              <div className="form-group">
                <p className="label mt-4 mb-2">I usually post about...</p>
                <textarea className="topic-input" placeholder="Description" value={selectedOptions} onChange={(e) => setSelectedOptions(e.target.value)} style={{ minHeight: "120px" }} />
              </div>

              <div className="form-group">
                <p className="label mt-4 mb-2">
                  I write in...
                  <span style={{ fontSize: "0.6rem" }}>(Optional)</span>
                </p>
                <FlagSelect
                  onSelect={(country) => setSelectedCountry(country)}
                  selected={selectedCountry}
                  showSelectedLabel={true}
                  countries={["US"]}
                  customLabels={{
                    US: "English (US)",
                  }}
                  placeholder="Select a Language"
                  className="flag-select-container"
                />
              </div>

              <div className="form-group">
                <p className="label mt-4 mb-2">
                  My timezone is...
                  <span style={{ fontSize: "0.6rem" }}>(Optional)</span>
                </p>
                <TimezoneSelect value={selectedTimezone} onChange={handleTimezoneChange} className="timezone-select-container" />
              </div>
            </div>
          </div>

          <div className="col-12 navigation-buttons">
            <button className="back-button" onClick={props.click}>
              <span className="back-icon">←</span>
              Back
            </button>
            <button className="next-button" onClick={props.handleSaveInfo}>
              Complete Setup
              <span className="next-icon">→</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescribeYourSelf;
