import React, { useEffect, useRef, useState } from "react";
import { postApi, getApi, deleteApi } from "../../api/methods";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_URL_BACKEND } from "../../api/API";
import {
  addMediaDis,
  addMediaTitle,
  addOriginalUrl,
  changePostType,
} from "../../store/features/post/postSlice";
import { isLoading } from "../../store/features/common/commonSlice";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {
  pexelImageData,
  pixabayImageData,
  unsplashImageData,
  clearImages,
} from "../../store/features/media/mediaAction";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Check } from "lucide-react";
import MediaDropdown from "../../components/DropDown/MediaDropdown";

const Media = (props) => {
  const userInfo = useSelector((state) => state.user.userData);
  const { loading, isDark } = useSelector((state) => state.common);
  const post = useSelector((state) => state.post);
  const fileInputRef = useRef(null);
  const [isImgSelect, setIsImgSelect] = useState(true);
  const [isPreview, setIsPreview] = useState("");
  const [inPanel, setInPanel] = useState(false);
  const [getMedia, setGetMedia] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [getUniqueMedia, setUniqueMedia] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchPlat, setSearchPlat] = useState("");
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [isSelected, setIsSelected] = useState([]);
  const [showDialog, setShowDialog] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [allImages, setAllImages] = useState([]);
  const closeDialog = () => setShowDialog(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedAIImages, setSelectedAIImages] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedMediaItems, setSelectedMediaItems] = useState([]);
  const [mediaFilter, setMediaFilter] = useState("all"); // 'all', 'images', 'videos', 'documents'
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const scrollContainerRef = useRef(null);

  const imagesList = useSelector((s) => s.media);
  const dispatch = useDispatch();

  const pathname = location.pathname === "/ai-content";

  // useEffect and useRef for handling outside clicks
  const filterDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log(window.location.pathname);
  });

  useEffect(() => {
    if (searchPlat && searchText) {
      handleSearch();
    }
  }, [searchPlat]);

  // Searching Imgaes with Unsplash, pixabay and pexels
  const handleSearchWithPage = (pageNum) => {
    setIsLoadingMore(true);
    if (searchPlat === "Pexels") {
      dispatch(pexelImageData(searchText, pageNum));
    } else if (searchPlat === "Pixabay") {
      dispatch(pixabayImageData(searchText, pageNum));
    } else {
      dispatch(unsplashImageData(searchText, pageNum));
    }
    setIsLoadingMore(false);
  };

  const handleSearch = () => {
    setPage(1); // Reset page when new search is initiated
    handleSearchWithPage(1);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedFiles([]);
    setSelectedImages([]);
    setIsImgSelect(true);
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleClose1 = () => {
    setShow1(false);
    setSearchText("");
  };

  const handleShow = () => {
    setShow(true);
  };

  const handlePreview = (path) => {
    setUniqueMedia(path);
    setIsPreview(path);

    // from extention this will define the media type
    const extension = path.split(".").pop().toLowerCase();

    // ye post type me media set krdega
    if (["mp4", "webm", "mov"].includes(extension)) {
      dispatch(changePostType("VIDEO"));
    } else if (extension === "pdf") {
      dispatch(changePostType("PDF"));
    } else {
      dispatch(changePostType("IMAGE"));
    }

    // some other details
    dispatch(addOriginalUrl(`${IMAGE_URL_BACKEND}/uploads/${path}`));
    dispatch(addMediaDis(path));
    dispatch(addMediaTitle(path));

    if (props.isPreview) {
      props.setMediaPanel(false);
    }
  };

  const toggleImageSelection = (imageUrl) => {
    setSelectedAIImages((prev) => {
      if (prev.includes(imageUrl)) {
        return prev.filter((url) => url !== imageUrl);
      } else {
        return [...prev, imageUrl];
      }
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files || e.dataTransfer.files);
    const mediaFiles = files.filter((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo =
        file.type.startsWith("video/") ||
        file.name.toLowerCase().endsWith(".mp4") ||
        file.name.toLowerCase().endsWith(".mov") ||
        file.name.toLowerCase().endsWith(".webm");
      const isPDF = file.type === "application/pdf";
      return isImage || isVideo || isPDF;
    });

    if (mediaFiles.length > 0) {
      setSelectedImages(mediaFiles);
      setSelectedFiles(mediaFiles);
      setIsImgSelect(false);
    }

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleImageChangemore = (e) => {
    const files = Array.from(e.target.files);
    const mediaFiles = files.filter((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo =
        file.type.startsWith("video/") ||
        file.name.toLowerCase().endsWith(".mp4") ||
        file.name.toLowerCase().endsWith(".mov") ||
        file.name.toLowerCase().endsWith(".webm");
      const isPDF = file.type === "application/pdf";
      return isImage || isVideo || isPDF;
    });

    setSelectedFiles((prev) => [...prev, ...mediaFiles]);
    setSelectedImages((prev) => [...prev, ...mediaFiles]);

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Remove visual feedback
    e.currentTarget.style.backgroundColor = "transparent";

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);
      const mediaFiles = files.filter((file) => {
        const isImage = file.type.startsWith("image/");
        const isVideo =
          file.type.startsWith("video/") ||
          file.name.toLowerCase().endsWith(".mp4") ||
          file.name.toLowerCase().endsWith(".mov") ||
          file.name.toLowerCase().endsWith(".webm");
        const isPDF = file.type === "application/pdf";
        return isImage || isVideo || isPDF;
      });

      if (selectedFiles.length === 0) {
        // First drop
        setSelectedImages(mediaFiles);
        setSelectedFiles(mediaFiles);
        setIsImgSelect(false);
      } else {
        // Adding more files
        setSelectedFiles((prev) => [...prev, ...mediaFiles]);
        setSelectedImages((prev) => [...prev, ...mediaFiles]);
      }
    }
  };

  const uploadArrayOfUrls = async () => {
    try {
      if (selectedImages1.length === 0) {
        toast.error("Please select at least one image");
        return;
      }

      setIsProgress(true);

      const res = await postApi(`media/upload-medias-url`, {
        userId: userInfo?.linkedinId,
        imageUrls: selectedImages1,
      });

      if (res?.status === true) {
        toast.success("Images uploaded successfully!");
        setShow1(false);
        getUserMedia();
        setSelectedImages1([]);
        dispatch(clearImages());
      } else {
        toast.error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Image upload failed");
    } finally {
      setIsProgress(false);
      setSelectedFiles([]);
      setIsImgSelect(true);
      handleClose1();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProgress(true);
    const formData = new FormData();

    try {
      // Generate thumbnails for videos
      for (const file of selectedFiles) {
        const isVideo =
          file.type.startsWith("video/") ||
          file.name.toLowerCase().endsWith(".mp4") ||
          file.name.toLowerCase().endsWith(".mov") ||
          file.name.toLowerCase().endsWith(".webm");

        if (isVideo) {
          try {
            const thumbnail = await generateVideoThumbnail(file);
            console.log("Video thumbnail generated:", thumbnail);
          } catch (error) {
            console.error("Error generating thumbnail:", error);
          }
        }
      }

      // Append each file with its original extension
      selectedFiles.forEach((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        const uniqueFileName = `${uuidv4()}.${extension}`;
        const newFile = new File([file], uniqueFileName, {
          type: file.type || `video/${extension}`,
        });
        formData.append("imageName", newFile);
      });

      formData.append("userId", userInfo.linkedinId);

      const response = await postApi("media/upload-medias", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.status === true) {
        if (selectedFiles.length === 1) {
          const file = selectedFiles[0];
          const isVideo =
            file.type.startsWith("video/") ||
            file.name.toLowerCase().endsWith(".mp4") ||
            file.name.toLowerCase().endsWith(".mov") ||
            file.name.toLowerCase().endsWith(".webm");

          if (isVideo) {
            toast.success("Video uploaded successfully!");
          } else if (file.type === "application/pdf") {
            toast.success("PDF uploaded successfully!");
          } else {
            toast.success("Image uploaded successfully!");
          }
        } else {
          toast.success("Files uploaded successfully!");
        }

        // Clear all states related to file upload
        setShow(false);
        setSelectedFiles([]);
        setSelectedImages([]);
        setIsImgSelect(true);
        getUserMedia();

        // Reset the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      console.error("Error uploading media:", error);
      toast.error("Upload failed!");
    } finally {
      dispatch(isLoading(false));
      setIsProgress(false);
      handleClose(); // This will handle all the cleanup
    }
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    console.log(selectedImages1);
    const formData = new FormData();
    selectedImages1.forEach((image) => {
      formData.append("imageName", image);
    });
    formData.append("userId", userInfo.linkedinId);
    console.log(formData);
    try {
      const response = await postApi("media/upload-medias", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.status === true) {
        toast.success("Image uploaded Successfully!");
        setShow1(!show1);
        getUserMedia();
      }
      dispatch(isLoading(false));
      setIsProgress(false);
      setSelectedImages1([]);
      setIsImgSelect(true);
    } catch (error) {
      console.error(error);
      toast.error("Image upload failed!");
      setIsProgress(false);
    }
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
    toast.info("Please upload the selected files.");
  };

  const getUserMedia = async () => {
    await getApi(`media/get-media-byuserid/${userInfo?.linkedinId}`)
      .then((res) => {
        setGetMedia(res.data.media);
        if (res.data.media.length === 0) {
          setMessage("Media not uploaded yet!");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    if (userInfo.linkedinId) {
      getUserMedia();
    }
    if (!loading) {
      setIsImgSelect(true);
    }
  }, [loading, userInfo]);

  // Add this console log to see the media data
  useEffect(() => {
    console.log("getMedia:", getMedia);
  }, [getMedia]);

  const deleteMedia = (url, index) => {
    console.log("Deleting media at index:", index);
    setShowDialog(true);
    setDeleteId(url);
  };

  const deleteMediaModalYes = async () => {
    console.log(deleteId);
    const body = {
      userId: userInfo?.linkedinId,
      id: deleteId,
    };
    try {
      dispatch(isLoading(true));
      await deleteApi("media/delete-media-byuserid", body);
      dispatch(isLoading(false));
      setGetMedia("");
      setIsPreview("");
      toast.success("Media is deleted.");
      getUserMedia();
    } catch (error) {
      dispatch(isLoading(false));
      console.warn("media not deleted:", error);
      toast.error("media not deleted!");
    }
    setShowDialog(false);
  };

  const handleCancelselected = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const deleteSelected = () => {
    const body = {
      userId: userInfo.linkedinId,
      id: isSelected,
    };
    console.log(body);
    try {
      dispatch(isLoading(true));
      deleteApi("media/delete-media-byuserid", body);
      dispatch(isLoading(false));
      setGetMedia("");
      setIsPreview("");
      toast.success("Media is deleted.");
    } catch (error) {
      dispatch(isLoading(false));
      console.warn("media not deleted:", error);
      toast.error("media not deleted!");
    }
  };

  const handleImages = (source) => {
    const check = allImages.includes(source);
    if (check) {
      const newArr = allImages.filter((image) => image !== source);
      setAllImages(newArr);
    } else {
      setAllImages([...allImages, source]);
    }
  };

  const uploadFiles = async () => {
    if (selectedAIImages.length === 0) {
      alert("Please select at least one image to upload.");
      return;
    }

    setIsUploading(true);

    try {
      selectedAIImages.forEach((imageUrl) => {
        const imageName = imageUrl.split("/").pop();
        setIsPreview(imageName);
        handlePreview(imageName);
      });
      setSelectedAIImages([]);
    } catch (error) {
      console.error("Error uploading images:", error);
      alert(`Failed to upload images. Error: ${error.message}`);
    } finally {
      toast.success("Image added successfully");
      setIsUploading(false);
    }
  };

  const getImageUrl = (imageNameOrUrl) => {
    if (imageNameOrUrl.startsWith("http")) {
      // It's already a full URL, so we need to extract the filename
      const filename = getFilenameFromUrl(imageNameOrUrl);
      return `${IMAGE_URL_BACKEND}/uploads/${filename}`;
    }
    // It's just the filename, so we can use it directly
    return `${IMAGE_URL_BACKEND}/uploads/${imageNameOrUrl}`;
  };

  // for drag and drop
  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default to allow drop
    e.stopPropagation();

    // Add visual feedback
    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Remove visual feedback
    e.currentTarget.style.backgroundColor = "transparent";
  };

  const handleImageSelection = (imageName) => {
    setSelectedMediaItems((prev) => {
      if (prev.includes(imageName)) {
        return prev.filter((item) => item !== imageName);
      } else {
        return [...prev, imageName];
      }
    });
    // preview of the last selected image
    setIsPreview(imageName);
    setUniqueMedia(imageName);
  };

  const handleAddSelectedMedia = () => {
    selectedMediaItems.forEach((imageName) => {
      handlePreview(imageName);
    });
    setSelectedMediaItems([]);
    if (props.setMediaPanel) {
      props.setMediaPanel(false);
    }
  };

  // for filter the added media by type
  const getFilteredMedia = () => {
    if (!getMedia) return [];

    switch (mediaFilter) {
      case "images":
        return getMedia.filter((item) => {
          const extension = item.imageName.split(".").pop().toLowerCase();
          return ["jpg", "jpeg", "png", "gif", "webp"].includes(extension);
        });
      case "videos":
        return getMedia.filter((item) => {
          const extension = item.imageName.split(".").pop().toLowerCase();
          return ["mp4", "webm", "mov"].includes(extension);
        });
      case "documents":
        return getMedia.filter((item) => {
          const extension = item.imageName.split(".").pop().toLowerCase();
          return extension === "pdf";
        });
      default:
        return getMedia;
    }
  };

  const renderMediaItem = (item) => {
    const extension = item.imageName.split(".").pop().toLowerCase();
    const mediaUrl = `${IMAGE_URL_BACKEND}/uploads/${item.imageName}`;

    if (extension === "pdf") {
      return (
        <div className="pdf-preview-container">
          <div className="pdf-icon-top">
            <i className="fas fa-file-pdf"></i>
            {/* <span>{item.imageName}</span> */}
          </div>
          <div className="pdf-preview-frame">
            <div className="pdf-preview-content">
              <iframe
                src={`${mediaUrl}#toolbar=0&view=FitH`}
                title="PDF Preview"
                className="pdf-iframe"
              />
              {/* <div className="pdf-overlay">
                <button
                  className="view-pdf-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(mediaUrl, "_blank");
                  }}
                >
                  View PDF
                </button>
              </div> */}
            </div>
          </div>
        </div>
      );
    } else if (["mp4", "webm", "mov"].includes(extension)) {
      return (
        <div className="video-preview">
          <video
            src={mediaUrl}
            className={`img-fluid ${
              isDark ? "img-video-bg-light" : "img-video-bg-dark"
            }`}
          />
          <div className="video-overlay">
            <i className="fas fa-play"></i>
          </div>
        </div>
      );
    } else {
      return (
        <img
          src={mediaUrl}
          className={`img-fluid ${
            isDark ? "img-video-bg-light" : "img-video-bg-dark"
          }`}
          alt=""
        />
      );
    }
  };

  const renderPreviewItem = () => {
    if (!getUniqueMedia) return null;

    const extension = getUniqueMedia.split(".").pop().toLowerCase();
    const mediaUrl = `${IMAGE_URL_BACKEND}/uploads/${getUniqueMedia}`;

    if (extension === "pdf") {
      return (
        <div className="preview-pdf-container">
          <div className="preview-pdf-header">
            <div className="preview-pdf-title">
              <i className="fas fa-file-pdf"></i>
              {/* <span>{getUniqueMedia}</span> */}
            </div>
          </div>
          <div className="preview-pdf-content">
            <iframe
              src={`${mediaUrl}#toolbar=0&view=FitH`}
              title="PDF Preview"
              className="preview-pdf-iframe"
            />
            <button
              className="preview-pdf-btn"
              onClick={() => window.open(mediaUrl, "_blank")}
            >
              View PDF
            </button>
          </div>
        </div>
      );
    } else if (["mp4", "webm", "mov"].includes(extension)) {
      return (
        <div className="video-preview-container">
          <div className="video-preview-header">
            {/* <span className="video-filename">
              {getUniqueMedia.split("-").pop()}
            </span> */}
          </div>
          <div className="video-preview-frame">
            <video
              src={mediaUrl}
              className={`video-player ${
                isDark ? "img-video-bg-light" : "img-video-bg-dark"
              }`}
              muted
              autoPlay
              loop
              playsInline
            />
          </div>
        </div>
      );
    } else {
      return (
        <img
          src={mediaUrl}
          className={`img-fluid w-100 ${
            isDark ? "img-video-bg-light" : "img-video-bg-dark"
          }`}
          alt=""
        />
      );
    }
  };

  const getSelectedMediaType = () => {
    if (selectedMediaItems.length === 0) return "";

    // this is the type of the last selected item
    const lastSelected = getUniqueMedia;
    const extension = lastSelected.split(".").pop().toLowerCase();

    if (["mp4", "webm", "mov"].includes(extension)) {
      return "Video";
    } else if (extension === "pdf") {
      return "PDF";
    } else {
      return "Image";
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const container = e.target;
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 100 &&
        !isLoadingMore &&
        imagesList.images?.length > 0
      ) {
        setPage((prevPage) => {
          const nextPage = prevPage + 1;
          handleSearchWithPage(nextPage);
          return nextPage;
        });
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoadingMore, imagesList.images]);

  // UseEffect to reset page when search platform changes
  useEffect(() => {
    setPage(1);
    if (searchPlat && searchText) {
      handleSearchWithPage(1);
    }
  }, [searchPlat]);

  return (
    <>
      <div className="media-container">
        <div className={isPreview ? "media-body" : "media-body-expand"}>
          <div className="d-flex justify-content-between items-center media-sub-header">
            <h3 className="max-md:text-sm text-xl font-semibold">
              We have safely saved it for you here
            </h3>
            <div className="flex items-center gap-3">
              <div className="relative">
                <div ref={filterDropdownRef}>
                  <button
                    className={`filter-btn max-sm:text-xs hover:opacity-90 transition-all text-xs flex items-center gap-2 ${
                      isDark
                        ? "bg-[#f3f4f6] text-gray-700"
                        : "bg-[#2d2d2d] text-white"
                    }`}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    <i className="fas fa-filter"></i>
                    {mediaFilter === "all"
                      ? "All Media"
                      : mediaFilter === "images"
                      ? "Images"
                      : mediaFilter === "videos"
                      ? "Videos"
                      : "Documents"}
                  </button>
                  {dropdownOpen && (
                    <div
                      className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg ${
                        isDark ? "bg-white" : "bg-[#1e1e1e]"
                      } ring-1 ring-black ring-opacity-5 z-10`}
                    >
                      <div className="py-1">
                        {["all", "images", "videos", "documents"].map(
                          (filter) => (
                            <button
                              key={filter}
                              className={`block px-4 py-2 text-sm w-full text-left transition-colors ${
                                isDark
                                  ? `hover:bg-gray-100 ${
                                      mediaFilter === filter
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    }`
                                  : `hover:bg-[#2d2d2d] ${
                                      mediaFilter === filter
                                        ? "bg-[#2d2d2d] text-white"
                                        : "text-gray-300"
                                    }`
                              }`}
                              onClick={() => {
                                setMediaFilter(filter);
                                setDropdownOpen(false);
                              }}
                            >
                              {filter.charAt(0).toUpperCase() + filter.slice(1)}
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <button
                className="upload-btn max-sm:text-[11px] hover:opacity-90 transition-all text-xs"
                onClick={handleShow}
              >
                <i className="fas fa-upload mr-2"></i>
                Upload Media
              </button>
              {props.isPreview && selectedMediaItems.length > 0 && (
                <button
                  className="gradient-button-new text-white rounded-md py-2 px-4 hover:opacity-90 transition-all flex items-center text-xs"
                  onClick={handleAddSelectedMedia}
                >
                  <i className="fas fa-plus mr-2"></i>
                  Add {selectedMediaItems.length} Selected{" "}
                  {selectedMediaItems.length === 1
                    ? getSelectedMediaType()
                    : `${getSelectedMediaType()}s`}
                </button>
              )}
            </div>
          </div>
          <div className="media-selector">
            <div className="media-body-img">
              {pathname ? (
                // Render for AI content page
                getFilteredMedia()?.length > 0 ? (
                  getFilteredMedia()?.map((item, index) => {
                    const imageUrl = getImageUrl(item.imageName);
                    return (
                      <div
                        key={index}
                        className={`media-body-item cursor-pointer ${
                          selectedMediaItems.includes(item.imageName)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => {
                          if (props.isPreview) {
                            handleImageSelection(item.imageName);
                          } else {
                            setIsPreview(item.imageName);
                            setUniqueMedia(item.imageName);
                          }
                        }}
                      >
                        {renderMediaItem(item)}
                        {selectedMediaItems.includes(item.imageName) && (
                          <div className="img-checked">
                            <img
                              src="/images/tickImg.svg"
                              alt="Tick"
                              style={{ width: "20px" }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="media-not">
                    <h3>{message}</h3>
                  </div>
                )
              ) : // Render for other pages
              getFilteredMedia()?.length > 0 ? (
                getFilteredMedia()?.map((item, index) => (
                  <div
                    key={index}
                    className={`media-body-item cursor-pointer ${
                      selectedMediaItems.includes(item.imageName)
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (props.isPreview) {
                        handleImageSelection(item.imageName);
                      } else {
                        setIsPreview(item.imageName);
                        setUniqueMedia(item.imageName);
                      }
                    }}
                  >
                    {renderMediaItem(item)}
                    {selectedMediaItems.includes(item.imageName) && (
                      <div className="img-checked">
                        <img
                          src="/images/tickImg.svg"
                          alt="Tick"
                          style={{ width: "20px" }}
                        />
                      </div>
                    )}
                    {!props.isPreview && (
                      <i
                        className="fa-regular fa-trash-can media-delete-contain"
                        title="Delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteMedia(item._id);
                        }}
                      ></i>
                    )}
                  </div>
                ))
              ) : (
                userInfo.linkedinId && (
                  <div className="media-not">
                    <h3>{message}</h3>
                  </div>
                )
              )}
            </div>
          </div>

          {/* {pathname && (
            <button
              className="flex gradient-button-new text-white rounded-md p-1 px-5 "
              style={{ marginLeft: "auto" }}
              onClick={uploadFiles}
              disabled={isUploading || selectedAIImages.length === 0}
            >
              {isUploading
                ? "Uploading..."
                : `Upload ${selectedAIImages.length} Files`}
            </button>
          )}
          {!pathname && <button className="hidden">Upload</button>} */}
        </div>

        {
          <div
            className={` ${
              isPreview ? "media-preview" : "media-preview-collapsed"
            } ${isDark ? "media-light-bg rounded-xl" : "darkMode"} `}
          >
            <div className="preview-text">
              <h3>{props.isPreview ? "Selected" : "Preview"}</h3>{" "}
              <span onClick={() => setIsPreview("")}>
                <i className="fa-solid fa-xmark cursor-pointer"></i>
              </span>
            </div>
            <div className={`'preview-item'`}>{renderPreviewItem()}</div>
          </div>
        }
      </div>
      <Modal
        show={show1}
        onHide={handleClose1}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <div
          style={{ padding: "20px" }}
          className="flex flex-row justify-start"
        >
          <form onSubmit={(e) => e.preventDefault()} style={{ width: "80%" }}>
            <div className="relative w-full">
              <input
                type="text"
                style={{
                  width: "100%",
                  margin: "5px 0 0 0",
                  padding: "10px 10px 10px 35px",
                  borderRadius: "5px",
                  border: "none",
                  outline: "none",
                  backgroundColor: "#f5f5f5",
                }}
                placeholder="Search Images"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <img
                src="/images/searchImg.svg"
                style={{
                  position: "absolute",
                  top: "15px",
                  left: "8px",
                  width: "24px",
                }}
              />
              <button
                onClick={() => handleSearch()}
                className="absolute right-2 top-1/2 -translate-y-1/2 px-3 py-1.5 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors text-xs m-1"
                style={{ fontSize: "14px" }}
              >
                Search
              </button>
            </div>
          </form>
          <MediaDropdown
            isDark={isDark}
            searchPlat={searchPlat}
            setSearchPlat={setSearchPlat}
            setShow1={setShow1}
            dispatch={dispatch}
            clearImages={clearImages}
            setSearchText={setSearchText}
          />
        </div>
        <div
          ref={scrollContainerRef}
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "7px",
            overflowY: "auto",
            margin: "20px 0",
            height: "500px",
            padding: "0 20px 20px 20px",
            breakInside: "avoid",
          }}
          id="hide-scrollbar"
        >
          {imagesList.images &&
            imagesList.images.map((i, ind) => {
              const imageUrl =
                imagesList.keyWord === "Pexels"
                  ? i.src.medium
                  : imagesList.keyWord === "Pixabay"
                  ? i.webformatURL
                  : i.urls.small;

              const isSelected = selectedImages1.includes(imageUrl);

              return (
                <div
                  key={ind}
                  style={{ position: "relative", display: "flex" }}
                >
                  <img
                    src={imageUrl}
                    style={{
                      maxWidth: "268px",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "10px",
                      border: isSelected ? "3px solid green" : "none",
                    }}
                    onClick={() =>
                      setSelectedImages1((prev) =>
                        isSelected
                          ? prev.filter((url) => url !== imageUrl)
                          : [...prev, imageUrl]
                      )
                    }
                  />
                  {isSelected && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        padding: "6px",
                      }}
                    >
                      <img
                        src="/images/tickImg.svg"
                        alt="Tick"
                        style={{ width: "20px" }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          {isLoadingMore && (
            <div className="w-full flex justify-center py-4">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <div
          style={{
            padding: "0 20px 20px 20px",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "4px",
          }}
        >
          <button className="upload-btn" onClick={uploadArrayOfUrls}>
            Upload Media
          </button>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <Modal.Header closeButton>
          <div
            className="media-model-header"
            style={{ padding: "5px 10px 0px 10px" }}
          >
            <h2 className={isDark ? "" : "text-white"}>Select Media</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            style={{
              padding: "0px 10px 0px 10px",
              color: "gray",
              fontSize: "18px",
              marginBottom: "10px",
            }}
          >
            Design with :
          </p>
          <div style={{ padding: "0px 10px 0px 10px" }}>
            <div className="imageDivOuter">
              <div
                className={`imageDivInner cursor-pointer ${
                  isDark
                    ? "hover:border-black hover:border-2"
                    : "hover:border-gray-400 hover:border-2"
                }`}
                onClick={() => {
                  setSearchPlat("Pixabay");
                  setShow1(true);
                  dispatch(clearImages());
                  setSearchText("");
                }}
              >
                <img src="/images/Pixabay_new.png"></img>
              </div>
              <div
                className={`imageDivInner cursor-pointer ${
                  isDark
                    ? "hover:border-black hover:border-2"
                    : "hover:border-gray-400 hover:border-2"
                }`}
                onClick={() => {
                  setSearchPlat("Unsplash");
                  setShow1(true);
                  dispatch(clearImages());
                  setSearchText("");
                }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={
                    isDark
                      ? "/images/UnsplashLogoLight.png"
                      : "/images/UnsplashLogoLight.png"
                  }
                  style={{ width: isDark ? "150px" : "220px" }}
                ></img>
              </div>
              <div
                className={`imageDivInner cursor-pointer ${
                  isDark
                    ? "hover:border-black hover:border-2"
                    : "hover:border-gray-400 hover:border-2"
                }`}
                onClick={() => {
                  setSearchPlat("Pexels");
                  setShow1(true);
                  dispatch(clearImages());
                  setSearchText("");
                }}
              >
                <img
                  src={
                    isDark
                      ? "/images/PexelsLogoLight.png"
                      : "/images/PexelsLogoLight.png"
                  }
                ></img>
              </div>
            </div>

            {/* Show drop zone only when no files are selected */}
            {selectedFiles.length === 0 && (
              <div
                className="media-model-body-btn"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "gray",
                }}
              >
                <p className="text-lg mb-2">Upload from Device : </p>
                <div
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  className="upload-drop-zone"
                  style={{
                    border: "2px dashed #ccc",
                    borderRadius: "4px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    marginBottom: "20px",
                  }}
                  onClick={() => {
                    handleFileSelect();
                  }}
                >
                  <button
                    className="upload-btn"
                    style={{ padding: "15px", width: "100%" }}
                  >
                    Select or Drag & Drop Media
                  </button>
                </div>
                <input
                  type="file"
                  accept="image/*, video/mp4, video/webm, video/mov, video/quicktime, application/pdf"
                  id="fileInput"
                  multiple
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </div>
            )}

            {/* Show selected files area with drag and drop capability */}
            {selectedFiles.length > 0 && (
              <>
                <div
                  className={`selected-media-model ${
                    isDark ? "" : "selected-media-model-dark"
                  }`}
                  style={{ margin: "20px 0", alignItems: "flex-start" }}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  {selectedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="selected-media-model-item d-flex justify-content-center"
                    >
                      {file.type === "application/pdf" ? (
                        // PDF Preview
                        <div className="pdf-preview-container-upload">
                          <div className="pdf-icon-header">
                            <i
                              className="fas fa-file-pdf"
                              style={{ fontSize: "24px", color: "#dc3545" }}
                            ></i>
                            <span className="pdf-name">{file.name}</span>
                          </div>
                          <div className="pdf-preview-frame-upload">
                            <iframe
                              src={`${URL.createObjectURL(file)}#toolbar=0`}
                              title="PDF Preview"
                              className="pdf-iframe-upload"
                            />
                            <div className="pdf-controls">
                              <button
                                className="view-pdf-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(
                                    URL.createObjectURL(file),
                                    "_blank"
                                  );
                                }}
                              >
                                View PDF
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : file?.name?.split(".").pop() === "mp4" ? (
                        // Video Preview
                        <video
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          height={160}
                          className="w-100"
                          controls
                        />
                      ) : (
                        // Image Preview
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          height={160}
                          className="w-100 cursor-pointer"
                        />
                      )}
                      <div className="cancel-model">
                        <i
                          className="fa-regular fa-trash-can media-delete-contain"
                          title="Delete"
                          onClick={() => handleCancelselected(index)}
                        ></i>
                      </div>
                    </div>
                  ))}
                  {/* <div
                    className="drag-drop-hint"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#666",
                      pointerEvents: "none",
                      opacity: 0.7,
                    }}
                  >
                    Drag and drop more files here
                  </div> */}
                </div>
                <div className="d-flex justify-content-end gap-2">
                  <button
                    onClick={() => fileInputRef.current.click()}
                    className="upload-btn m-1"
                  >
                    Add More
                  </button>
                  <button onClick={handleSubmit} className="upload-btn m-1">
                    Upload
                  </button>
                </div>

                <input
                  type="file"
                  accept="image/*, video/mp4, video/webm, video/mov, video/quicktime, application/pdf"
                  id="fileInput"
                  multiple
                  onChange={handleImageChangemore}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />

                {isProgress && (
                  <div className="progress mt-4">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDialog}
        onHide={closeDialog}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <Modal.Header
          closeButton
          className={`${isDark ? "text-black" : "text-white"}`}
        ></Modal.Header>
        <Modal.Body>
          <p className="-mt-8" style={{ color: isDark ? "black" : "white" }}>
            Are you sure you want to delete the media file?
          </p>
          <div className="flex mt-3 items-center gap-3">
            <button
              className="gradient-button-new p-2 px-4 text-white rounded-md"
              onClick={deleteMediaModalYes}
            >
              Yes
            </button>
            <button
              className="gradient-button-new p-2 px-4 text-white rounded-md"
              onClick={() => setShowDialog(false)}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Media;
