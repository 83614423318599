import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPreviewPost, togglePreview } from "../../../store/features/previewPost/previewPostSlice";
import PropTypes from "prop-types";
import DraftPostPreview from "./DraftPostPreview";

const MyDraftPost = ({ data, index, editPost, deleteDraftConfirm }) => {
  const { isDark } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  };

  /**
   * The `formatTime` function takes a date string as input and returns the time portion in 12-hour
   * format.
   * @returns The `formatTime` function takes a `dateString` as input, converts it to a Date object, and
   * then returns the time portion of that date in a formatted string with hours and minutes in 12-hour
   * format.
   */
  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  /**
   * The functions handlePreview and handleClosePreview are used to manage the preview of a post in a
   * React application.
   */
  const handlePreview = () => {
    const postData = {
      ...data,
      postContent: data.postContent || "",
      postType: data.postType || "NONE",
      mediaTitle: data.mediaTitle || [],
      originalUrl: data.originalUrl || "",
    };
    dispatch(addPreviewPost(postData));
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  return (
    <>
      <div className={`queue-post-items ${isDark ? "normal-bg-color" : "darkMode queue-text-color-dark"}`}>
        <div className="queue-post-left">
          <div>
            <b>{index + 1}.</b>
          </div>
          <div className="queue-time">{formatDate(data.updatedAt)}</div>
          <div className="queue-time">{formatTime(data.updatedAt)}</div>
          <div className="queue-content">{data.postContent?.length > 100 ? `${data.postContent.slice(0, 100)}...` : data.postContent}</div>
        </div>
        <div className="queue-post-right">
          <button className="icon-button" onClick={handlePreview} aria-label="Preview post">
            <i className="fa-regular fa-eye"></i>
          </button>
          <button className="icon-button" onClick={() => editPost(data)} aria-label="Edit post">
            <i className="fa-solid fa-pencil"></i>
          </button>
          <button className="icon-button" onClick={() => deleteDraftConfirm(data.postId)} aria-label="Delete post">
            <i className="fa-regular fa-trash-can" style={{ color: "#b80a24" }}></i>
          </button>
        </div>
      </div>

      {showPreview && <DraftPostPreview onClose={handleClosePreview} />}
    </>
  );
};

/* The code snippet `MyDraftPost.propTypes` is defining the PropTypes for the props that the
`MyDraftPost` component expects to receive. It is specifying the expected data types and whether
they are required or not. Here's a breakdown of what each PropTypes definition is doing: */

MyDraftPost.propTypes = {
  data: PropTypes.shape({
    postId: PropTypes.string.isRequired,
    postContent: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  editPost: PropTypes.func.isRequired,
  deleteDraftConfirm: PropTypes.func.isRequired,
};

export default MyDraftPost;
