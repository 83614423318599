import React, { useEffect, useState } from "react";
import { deleteApi, getApi, postApi } from "../../../api/methods";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
const Instagram = ({ setIsAccount, getConnData }) => {
  const userinfo = useSelector((state) => state.user.userData);
  const linkedinId = userinfo?.linkedinId;
  const queryParams = new URLSearchParams(window.location.search);
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  // const facebookId = queryParams.get("facebookuserId");
  const facebookId = cookies.get("facebookuserId");
  const [getFB, setGetFB] = useState([]);
  const [igConnectedAc, setIGConnectedAc] = useState([]);
  const [getSearch, setGetSearch] = useState("");
  const [pendingAccount, setPendingAccount] = useState("");
  const [outOf, setOutOf] = useState("");
  const [getFbId, setGetFbId] = useState(null);

  // unconnectedData is filtered to include only items that are not already connected.
  let unconnectedData = getFB?.filter((item) => !getConnData?.igConnectedAc?.some((chosenItem) => chosenItem.id === item.id));

  // getigUser function fetches Instagram user data.
  const getigUser = () => {
    getApi(`facebook/get-facebook-user?facebookId=${facebookId}&userId=${userinfo?.userId}`).then((res) => {
      if (res?.data) {
        setGetFbId(res?.data);
        handleGetFbpage(res?.data?.facebookId);
      }

      // alert(JSON.stringify(res?.data))
    });
  };
  const fetchPendingAccountsStatus = async () => {
    try {
      const response = await getApi(`credit/get-credit-left-account/${userinfo.teamId}`);
      setPendingAccount(response.accountLeft);
      setOutOf(response.outOf);
    } catch (error) {
      console.warn(`fetchPendingAccountsStatus ${error}`);
    }
  };

  // handleGetFbpage function fetches Instagram page list.
  const handleGetFbpage = (fbid) => {
    getApi(`get-fb-page-list?facebookId=${fbid}&instagram=instagram`).then((res) => {
      if (res?.data) {
        setGetFB(res?.data?.igAccount);
      }
    });
  };

  // handleConnectPage function connects the selected Instagram page.
  const handleConnectPage = () => {
    // console.log('fbConnectedAc',fbConnectedAc.length);
    if (igConnectedAc.length > pendingAccount) {
      alert("can not add more account");
      return;
    }
    postApi("credit/create-credit-history", {
      userId: userinfo.linkedinId,
      teamId: userinfo.teamId,
      numberOfAccount: igConnectedAc.length,
      accountType: `Instagram `,
    });
    postApi("connect-social-account", {
      facebookId: getFbId?.facebookId,
      igConnectedAc: igConnectedAc,
    }).then((res) => {
      setIsAccount(false);
      toast.success("Instagram Account Connected Sucessfully");
    });
  };
  // console.log({ RES: igConnectedAc });
  // useEffect is used to call getigUser and handleGetFbpage when the component mounts.
  useEffect(() => {
    getigUser();
    fetchPendingAccountsStatus();
    fetchPendingAccountsStatus();
    if (getFbId) {
      handleGetFbpage();
    }
  }, []);
  return (
    <>
      <div className="fblist">
        <div className="search">
          <span className="mt-2">Select the accounts that you want to add.</span>
          <input value={getSearch} onChange={(e) => setGetSearch(e.target.value)} type="search" style={{ border: "2px solid gray" }} placeholder="Search Account" />
          <i class="fa-solid fa-magnifying-glass pb-3"></i>
        </div>
        <> Your Plan allows you to conncect {pendingAccount} more account</>
        <ul style={{ height: "200px", overflowY: "auto", paddingTop: "1rem" }}>
          {unconnectedData?.length > 0
            ? unconnectedData
                ?.filter((item) => item.instagram_business_account.name.includes(getSearch.toLowerCase()))
                ?.map((item, index) => {
                  return (
                    <li key={index}>
                      {item?.instagram_business_account?.name}
                      <input type="checkbox" onClick={() => setIGConnectedAc((prevState) => [...prevState, item])} />
                    </li>
                  );
                })
            : "No Data Found"}
        </ul>
        {pendingAccount != 0 && <button onClick={() => handleConnectPage()}>Connect Facebook Page</button>}
      </div>
    </>
  );
};

export default Instagram;
