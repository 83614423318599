import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../api/API";

const PostAd = ({ icon, title, desc, path, button_name }) => {
  const { isDark } = useSelector((state) => state.common);

  return (
    <div className={`post-ad-item  ${isDark ? "post-ad grediant-border" : "post-ad-dark"}  `}>
      <div className="post-ad-img d-flex justify-content-center">
        <img src={`${IMAGE_URL}/images/Icons/dashboard/${icon}.svg`} />
      </div>
      <div className="post-box-text">
        <h3 className="d-flex justify-content-center w-100 mb-3 text-sm xl:text-xl">{title}</h3>
        <p className="d-flex justify-content-center w-100 text-xs xl:text-lg">{desc}</p>
      </div>
      <div className="btn-create-post">
        <Link to={path} className="grediant-button" style={{ background: " linear-gradient(126deg,#8A51FF -9.26%,#39B2FF 79.86%)" }} title={path}>
          {button_name}
        </Link>
      </div>
    </div>
  );
};

export default PostAd;
