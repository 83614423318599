import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Eye, EyeOff, UserPlus, CheckCircle, XCircle } from "lucide-react";
import { BASE_URL, IMAGE_URL } from "../../api/API";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    lengthCriteria: false,
    upperCaseCriteria: false,
    lowerCaseCriteria: false,
    numberCriteria: false,
    specialCharCriteria: false,
  });
  const [referralCode, setReferralCode] = useState(null);
  const [campaign, setCampaign] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Referral Rocket campaign
    const initializeReferralRocket = async () => {
      if (window.Rocket) {
        const campaignInstance = window.Rocket.getCampaign();
        setCampaign(campaignInstance);

        // Get referral code from URL or cookie
        const code = window.Rocket.getReferralCode();
        if (code) {
          const isValid = await window.Rocket.validateCode(code);
          if (isValid) {
            setReferralCode(code);
          }
        }
      }
    };

    initializeReferralRocket();
  }, []);

  const handleSSORegister = async (provider) => {
    setError("");
    setIsLoading(true);

    try {
      // Store a flag that SSO registration is in progress
      localStorage.setItem("ssoRegistrationInProgress", "true");
      localStorage.setItem("ssoProvider", provider);

      // Track referral info if needed
      if (referralCode) {
        localStorage.setItem("referralCode", referralCode);
      }

      // Then redirect to the SSO provider
      window.location.href = `${BASE_URL}auth/${provider}`;
    } catch (error) {
      setError("Registration failed. Please try again.");
      console.error("Registration error:", error);
      setIsLoading(false);
    }
  };


  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      // First register the user
      const response = await axios.post(`${BASE_URL}userprofile/register`, {
        email,
        password,
      });

      // If registration is successful, add participant to Referral Rocket
      if (campaign) {
        try {
          await campaign.addParticipant({
            email,
            referredByCode: referralCode, // This will be null if there's no referral
          });

          // If this is a referred signup, qualify the participant
          if (referralCode) {
            await campaign.qualifyParticipant({
              email,
              rewardReferee: true, // Give reward to both referrer and referee
            });
          }
        } catch (referralError) {
          console.error("Referral tracking error:", referralError);
        }
      }

      setSuccessMessage("Registration successful. Please verify your email before login.");
      setEmail("");
      setPassword("");
      setPasswordStrength("");
      setPasswordCriteria({
        lengthCriteria: false,
        upperCaseCriteria: false,
        lowerCaseCriteria: false,
        numberCriteria: false,
        specialCharCriteria: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("User already exists");
      } else {
        setError("Registration failed. Please try again.");
      }
      console.error("Registration error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const calculatePasswordStrength = (password) => {
    const lengthCriteria = password.length >= 8;
    const upperCaseCriteria = /[A-Z]/.test(password);
    const lowerCaseCriteria = /[a-z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[^A-Za-z0-9]/.test(password);

    setPasswordCriteria({
      lengthCriteria,
      upperCaseCriteria,
      lowerCaseCriteria,
      numberCriteria,
      specialCharCriteria,
    });

    let strength = "";
    if (
      lengthCriteria &&
      upperCaseCriteria &&
      lowerCaseCriteria &&
      numberCriteria &&
      specialCharCriteria
    ) {
      strength = "Strong";
    } else if (
      lengthCriteria &&
      (upperCaseCriteria || lowerCaseCriteria) &&
      (numberCriteria || specialCharCriteria)
    ) {
      strength = "Moderate";
    } else {
      strength = "Weak";
    }

    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    calculatePasswordStrength(newPassword);
  };

  const allCriteriaMet =
    passwordCriteria.lengthCriteria &&
    passwordCriteria.upperCaseCriteria &&
    passwordCriteria.lowerCaseCriteria &&
    passwordCriteria.numberCriteria &&
    passwordCriteria.specialCharCriteria;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-800 to-gray-300">
      <div className="w-full max-w-md p-8 space-y-6 bg-white bg-opacity-80 rounded-2xl shadow-2xl backdrop-blur-lg backdrop-filter">
        {referralCode && (
          <div className="text-sm text-green-600 bg-green-100 p-3 rounded-md">
            You were referred by a friend! 🎉
          </div>
        )}
        <div className="text-center">
          <div className="flex justify-center items-center">
            <img src={`${IMAGE_URL}/images/logo.svg`} alt="logo" className="w-36 h-auto p-2 mb-2" />
          </div>
          <h2 className="text-3xl font-extrabold text-gray-800 tracking-tight">
            Create an Account
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Sign up now to start your journey with Growero!
          </p>
        </div>

        {successMessage ? (
          <div className="text-center space-y-4">
            <div className="flex justify-center text-green-600">
              <CheckCircle size={64} strokeWidth={1.5} />
            </div>
            <p className="text-green-700 font-medium">{successMessage}</p>
            <button
              onClick={() => navigate("/login")}
              className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors duration-300"
            >
              Go to Login
            </button>
          </div>
        ) : (
          <form onSubmit={handleRegister} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                id="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                  placeholder="Create a strong password :)"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-blue-600"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>

              {password && (
                <div className="mt-2 p-3 bg-gray-100 rounded-md">
                  <p className="text-xs font-medium text-gray-700 mb-2">Password Requirements:</p>
                  <ul className="space-y-1 text-xs">
                    <li
                      className={`flex items-center ${passwordCriteria.lengthCriteria ? "text-green-600" : "text-gray-500"
                        }`}
                    >
                      {passwordCriteria.lengthCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      At least 8 characters
                    </li>
                    <li
                      className={`flex items-center ${passwordCriteria.upperCaseCriteria ? "text-green-600" : "text-gray-500"
                        }`}
                    >
                      {passwordCriteria.upperCaseCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Upper case letters (A-Z)
                    </li>
                    <li
                      className={`flex items-center ${passwordCriteria.lowerCaseCriteria ? "text-green-600" : "text-gray-500"
                        }`}
                    >
                      {passwordCriteria.lowerCaseCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Lower case letters (a-z)
                    </li>
                    <li
                      className={`flex items-center ${passwordCriteria.numberCriteria ? "text-green-600" : "text-gray-500"
                        }`}
                    >
                      {passwordCriteria.numberCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Numbers (0-9)
                    </li>
                    <li
                      className={`flex items-center ${passwordCriteria.specialCharCriteria ? "text-green-600" : "text-gray-500"
                        }`}
                    >
                      {passwordCriteria.specialCharCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Special characters (e.g. !@#$%^&*)
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {error && <div className="p-3 text-sm text-red-700 bg-red-100 rounded-md">{error}</div>}

            <button
              type="submit"
              disabled={!allCriteriaMet || isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300 disabled:opacity-50"
            >
              {isLoading ? (
                <span className="animate-pulse">Creating Account...</span>
              ) : (
                <span className="flex items-center">
                  <UserPlus className="mr-2" size={20} /> Sign Up
                </span>
              )}
            </button>
          </form>
        )}
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or sign up with</span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3 mt-6">
            {/* Google */}
            <button
              onClick={() => handleSSORegister('google')}
              className="flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
            >
              <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.7663 12.2764C23.7663 11.4607 23.6996 10.6406 23.5588 9.83807H12.2402V14.4591H18.722C18.453 15.9494 17.5888 17.2678 16.3233 18.1056V21.1039H20.1903C22.4611 19.0139 23.7663 15.9274 23.7663 12.2764Z" fill="#4285F4" />
                <path d="M12.2402 24.0008C15.4766 24.0008 18.2059 22.9382 20.1944 21.1039L16.3274 18.1055C15.2516 18.8375 13.8677 19.2519 12.2445 19.2519C9.11388 19.2519 6.45946 17.1399 5.50693 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2402 24.0008Z" fill="#34A853" />
                <path d="M5.50253 14.3003C5.00464 12.8099 5.00464 11.1961 5.50253 9.70575V6.61481H1.51221C-0.18326 10.0056 -0.18326 14.0004 1.51221 17.3912L5.50253 14.3003Z" fill="#FBBC04" />
                <path d="M12.2402 4.74966C13.9508 4.7232 15.607 5.36697 16.8406 6.54867L20.2695 3.12177C18.1001 1.0855 15.2208 -0.034466 12.2402 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50692 9.70575C6.45566 6.86173 9.11388 4.74966 12.2402 4.74966Z" fill="#EA4335" />
              </svg>
              Google
            </button>

            {/* LinkedIn */}
            <button
              onClick={() => handleSSORegister('linkedin')}
              className="flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
            >
              <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="#0A66C2" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.447 20.452H16.893V14.883C16.893 13.555 16.866 11.846 15.041 11.846C13.188 11.846 12.905 13.291 12.905 14.785V20.452H9.351V9H12.765V10.561H12.811C13.288 9.661 14.448 8.711 16.181 8.711C19.782 8.711 20.448 11.081 20.448 14.166V20.452H20.447Z" />
                <path d="M5.337 7.433C4.193 7.433 3.274 6.507 3.274 5.368C3.274 4.23 4.194 3.305 5.337 3.305C6.477 3.305 7.401 4.23 7.401 5.368C7.401 6.507 6.477 7.433 5.337 7.433Z" />
                <path d="M7.119 20.452H3.555V9H7.119V20.452Z" />
              </svg>
              LinkedIn
            </button>

            {/* Facebook */}
            <button
              onClick={() => handleSSORegister('facebook')}
              className="flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
            >
              <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="#1877F2" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 12.073C24 5.405 18.627 0 12 0C5.373 0 0 5.405 0 12.073C0 18.099 4.388 23.094 10.125 24V15.563H7.078V12.073H10.125V9.413C10.125 6.385 11.917 4.713 14.658 4.713C15.97 4.713 17.344 4.949 17.344 4.949V7.925H15.83C14.339 7.925 13.875 8.855 13.875 9.807V12.073H17.203L16.673 15.563H13.875V24C19.612 23.094 24 18.099 24 12.073Z" />
              </svg>
              Facebook
            </button>

            {/* Twitter */}
            <button
              onClick={() => handleSSORegister('twitter')}
              className="flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
            >
              <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="#1DA1F2" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723 10.054 10.054 0 01-3.127 1.184 4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
              </svg>
              Twitter
            </button>
          </div>
        </div>
        {!successMessage && (
          <div className="text-center">
            <p className="text-sm text-gray-600">
              Already have an account?{" "}
              <a
                href="/login"
                className="text-blue-600 hover:text-blue-800 hover:underline font-semibold transition-colors"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/login");
                }}
              >
                Sign In
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
