import React, { useEffect, useState } from "react";
import { BASE_URL, IMAGE_URL } from "../../api/API";
import { useSelector } from "react-redux";
import { deleteApi, getApi, patchApi, postApi } from "../../api/methods";
import { toast } from "react-toastify";
import AccountList from "./AccountList";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";

const Integration = (props) => {
  const cookies = new Cookies();
  const queryParams = new URLSearchParams(window.location.search);
  console.log(queryParams);
  const userinfo = useSelector((state) => state.user.userData);
  const { isDark } = useSelector((state) => state.common);
  const [fbId, setFbId] = useState("");
  const [getFacebookUser, setFacebookUser] = useState(null);
  const [getTwitterUser, setTwitterUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [isAccount, setIsAccount] = useState(false);
  const [getConnData, setGetConnData] = useState(null);
  const [totalAccount, setTotalAccount] = useState(null);
  const [totalConAccount, setTotalConAccount] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [getLinkedInUser, setLinkedInUser] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [planName, setPlanName] = useState("Free-tier");

  // pass userid or linkedin id of user from props
  const userId = props.userId;
  const facebookId = props.facebookId;
  const connectionLimitReached = queryParams.get("connectionLimitReached") || "";

  const getPlanName = async () => {
    let transactionData;
    try {
      transactionData = await getApi(`transaction/get-subscription-by-id/${userinfo.teamId}`);
      setPlanName(transactionData[0].plan_Name);
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    }
  };

  console.log({ connectionLimitReached });
  useEffect(() => {
    console.log(connectionLimitReached);
    if (connectionLimitReached == "") {
      console.log("hello");
      // return;
    } else {
      if (connectionLimitReached != "notRiched" || connectionLimitReached == "") {
        toast.warning(`you can add only ${connectionLimitReached}`);
      }
    }
  }, []); // The empty dependency array [] ensures that this effect runs only once, on component mount

  // Check subscription status
  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const transactionData = await getApi(`transaction/get-subscription-by-id/${userinfo.teamId}`);
        if (transactionData.length > 0 && transactionData[0].plan_Name === "Free-Tier") {
          setIsFree(true);
        }
      } catch (error) {
        console.error("Error checking subscription:", error);
      }
    };

    checkSubscription();
    getPlanName();
  }, [userinfo.teamId]);

  // When user linkedin id is available then this api is hit otherwise not
  useEffect(() => {
    if (userId) {
      setTimeout(() => {
        patchApi(`twitter/get-twiterid`, { twitterId: userId, linkedinId: userinfo?.linkedinId })
          .then((res) => {
            console.log({ res });
            getTwitterbyId();
          })
          .catch((err) => console.warn({ err }));
      }, 500);
    }
    if (facebookId) {
      setTimeout(() => {
        patchApi(`facebook/get-facebookid`, { facebookId: facebookId, userId: userinfo?.userId })
          .then((res) => {
            console.log({ res });
            getFacebookbyId();
          })
          .catch((err) => console.warn({ err }));
      }, 500);
    }
  }, [userinfo?.linkedinId]);

  // Fetch user twitter data by hit this API
  const getTwitterbyId = async () => {
    getApi(`twitter/get-twitter-user/${userinfo?.linkedinId}`).then((res) => {
      setTwitterUser(res.data);
    });
  };

  // Get facebook information by hit this API
  const getFacebookbyId = async () => {
    // const fbid=facebookId  || userinfo?.linkedinId
    if (facebookId) {
      setFacebookUser(true);
    }
  };

  // This function is not use currently
  const getInstagrambyId = async () => {};

  useEffect(() => {
    getInstagrambyId();
    getFacebookbyId();
    getTwitterbyId();
  }, [userinfo?.linkedinId]);

  useEffect(() => {
    getInstagrambyId();
    getFacebookbyId();
    getTwitterbyId();
  }, [loading]);

  // Redirect to respective authentication page
  const handleTwitter = () => {
    window.location.href = `${BASE_URL}auth/twitter`;
  };
  const handleFacebook = () => {
    var redirectURLfb = `${BASE_URL}auth/facebook`;
    console.log(redirectURLfb);
    window.location.href = redirectURLfb;
  };
  const handleInstagram = () => {
    window.location.href = `${BASE_URL}auth/instagram?userId=${cookies.get("userId")}`;
    getfbUser();
  };

  // Remove twitter account of this user
  const removeTWAccountHandler = async () => {
    const confirmRM = confirm("Are you sure?");
    if (confirmRM) {
      setLoading(true);
      try {
        await deleteApi(`twitter/twitter-delete/${userinfo?.linkedinId}`);
        toast.success("Deleted!");
      } catch (err) {
        // toast.error('not deleted!')
      }
      setLoading(false);
    }
  };

  // Remove facebook account of this user
  const removeFBAccountHandler = async () => {
    const confirmRM = confirm("Are you sure?");
    if (confirmRM) {
      setLoading(true);
      try {
        await deleteApi(`facebook/delete-fb-user/${facebookId}`);
        toast.success("Deleted!");
        getFacebookbyId();
        getfbUser();
      } catch (err) {
        // toast.error('not deleted!')
      }
      setLoading(false);
    }
  };

  // Remove instagram account of this user
  const removeIGAccountHandler = async () => {
    const confirmRM = confirm("Are you sure?");
    if (confirmRM) {
      setLoading(true);
      try {
        await deleteApi(`facebook/delete-ig-user/${fbId}`);
        toast.success("Deleted!");
        getFacebookbyId();
        getfbUser();
      } catch (err) {
        // toast.error('not deleted!')
      }
      setLoading(false);
    }
  };
  const getfbUser = () => {
    if (userinfo?.linkedinId) {
      getApi(`facebook/get-facebook-user?facebookId=${facebookId}&userId=${userinfo?.userId}`).then((res) => {
        if (res?.data) {
          setFbId(res?.data?.facebookId);
          getConnectedAc(res?.data?.facebookId);
        }
      });
    }
  };

  // Get all the connected facebook pages of the user
  const getConnectedAc = (fbid) => {
    getApi(`get-connected-ac?facebookId=${fbid}`).then((res) => {
      // console.log(res.data);
      if (res?.data) {
        setGetConnData(res?.data);
      }
      // console.log('GetConnData',GetConnData);
      setTotalConAccount(res.data.igConnectedAc.length + res.data.fbConnectedAc.length);
      setTotalAccount(res.data.igAccount.length + res.data.pageList.length);
    });
  };

  // Remove facebook page of the user facebook connected pages of this website
  const handleRemoveFacebook = (pageId) => {
    const shouldDelete = window.confirm("Are you sure you want to delete facebook account?");
    if (fbId) {
      if (shouldDelete) {
        postApi("remove-connected-ac", {
          facebookId: fbId,
          pageId: pageId,
          acType: "facebook",
        }).then((res) => {
          toast.success("Facebook Account Removed Sucessfully");
          getfbUser();
        });
      }
    }
  };

  // Remove instagram page of the user instagram connected pages of this website
  const handleRemoveInstagram = (pageId) => {
    const shouldDelete = window.confirm("Are you sure you want to delete Instagram account?");
    if (fbId) {
      if (shouldDelete) {
        postApi("remove-connected-ac", {
          facebookId: fbId,
          pageId: pageId,
        }).then((res) => {
          toast.success("Instagram Account Removed Sucessfully");
          getfbUser();
        });
      }
    }
  };
  // console.log({ getFacebookUser });
  useEffect(() => {
    console.log("everytime");
    getfbUser();
    getConnectedAc();
    // console.log('GetConnData',GetConnData);
  }, [userinfo?.linkedinId, isAccount, facebookId]);
  //linkedin
  const handleLinkedIn = () => {
    window.location.href = `${BASE_URL}auth/linkedin`;
  };

  const removeLinkedInAccountHandler = async () => {
    const confirmRM = confirm("Are you sure?");
    if (confirmRM) {
      setLoading(true);
      try {
        await deleteApi(`linkedin/delete-linkedin-user/${userinfo?.linkedinId}`);
        toast.success("Deleted!");
        setLinkedInUser(false);
      } catch (err) {
        toast.error("Could not delete account!");
      }
      setLoading(false);
    }
  };

  return (
    <div className="relative w-full" style={{ height: "100vh" }}>
      {isFree && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-3">
          <div className={` ${isDark ? "post-ad grediant-border" : "post-ad-dark"} `} style={{ width: "50%" }}>
            <div className="post-box-text">
              <p className="d-flex justify-content-center w-100 text-xs xl:text-lg">
                You have discovered a premium feature. To access all our features, you need to subscribe to one of our plans by clicking the button below.
              </p>
            </div>
            <div className="btn-create-post">
              <Link
                to="/buy-subscription"
                className="grediant-button"
                style={{
                  background: "linear-gradient(126deg,#8A51FF -9.26%,#39B2FF 79.86%)",
                }}
                title="Upgrade"
              >
                Upgrade
              </Link>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          filter: isFree ? "grayscale(100%)" : "none",
          pointerEvents: isFree ? "none" : "auto",
        }}
      >
        <div className="my-2 mb-5">
          <h3 className="text-xl">Social Media Accounts</h3>
        </div>
        <div className="social-item">
          <div className="social-info">
            <i className="fa-brands fa-linkedin fa-2x" style={{ color: "blue" }}></i>
            <p className="mb-0 ms-3">
              <strong>LinkedIn</strong>
            </p>
          </div>
          <div className="social-action">
            {getLinkedInUser ? (
              <div className="after-connection">
                <button onClick={handleLinkedIn}>Re-Authenticate</button>
                <button onClick={removeLinkedInAccountHandler}>Remove</button>
              </div>
            ) : (
              <button className="connect-btn2 flex justify-center items-center" onClick={handleLinkedIn}>
                <i className="fa-brands fa-linkedin fa-2x" style={{ color: "white", marginRight: "0.5rem" }}></i>
                Continue With LinkedIn
              </button>
            )}
          </div>
        </div>
        <div className="social-item">
          <div className="social-info ">
            <img src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`} alt="" />
            <p className="mb-0 ms-3">
              <strong>X (Former Twitter)</strong> <br />{" "}
            </p>
          </div>
          <div className="social-action">
            {getTwitterUser !== null ? (
              <>
                <div className="after-connection">
                  <button onClick={handleTwitter}>Re-Authentication</button>
                  <button onClick={removeTWAccountHandler}>Remove</button>
                </div>
              </>
            ) : (
              <button className="connect-btn1 flex justify-center items-center" onClick={handleTwitter}>
                <img src={`${IMAGE_URL}/images/twitterx.svg`} height="30px" width="30px" style={{ marginRight: "0.5rem" }} alt="" />
                Continue With Twitter{" "}
              </button>
            )}
          </div>
        </div>
        <div className="social-item">
          <div className="social-info">
            <img src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} alt="" style={{ zIndex: "2" }} />
            <p className="mb-0 ms-3">
              <strong>Facebook</strong> <br />{" "}
            </p>
          </div>
          <div className="social-action">
            {getFacebookUser === true ? (
              <>
                <div className="after-connection">
                  <button onClick={handleFacebook}>Re-Authentication</button>
                  <button onClick={removeFBAccountHandler}>Remove</button>
                </div>
              </>
            ) : (
              <button className="connect-btn2 flex justify-center items-center" onClick={handleFacebook}>
                <img src={`${IMAGE_URL}/images/facebook.svg`} height="30px" width="30px" style={{ marginRight: "0.5rem" }} alt="" /> Continue With Facebook{" "}
              </button>
            )}
          </div>
        </div>
        <div className="social-item">
          <div className="social-info">
            <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} alt="" style={{ marginLeft: "0px", zIndex: "1" }} />
            <p className="mb-0 ms-3">
              <strong>Instagram</strong> <br />{" "}
            </p>
          </div>
          <div className="social-action">
            {getFacebookUser?.isInstagram === true ? (
              <>
                <div className="after-connection">
                  <button onClick={handleInstagram}>Re-Authentication</button>
                  <button onClick={removeIGAccountHandler}>Remove</button>
                </div>
              </>
            ) : (
              <button className="connect-btn2 flex justify-center items-center" onClick={handleInstagram}>
                <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} height="30px" width="30px" style={{ marginRight: "0.5rem" }} alt="" /> Continue With Instagram{" "}
              </button>
            )}
          </div>
        </div>
        <div className="conntectedAccount">
          {/* The above code is a snippet of JSX code written in JavaScript React. It represents a
          component that displays connected accounts information. Here is a breakdown of what the code
          is doing: */}
          <div className="conntectedAccount-header">
            <h2>Connected Accounts{totalConAccount && totalAccount && { totalConAccount } / { totalAccount }}</h2>
            <span title="Connect More Account" className="PLUS" onClick={() => setShowMore(!showMore)}>
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            {showMore && (
              <div className="add-more">
                <div className="add-more-item">
                  <input
                    type="radio"
                    name="rd"
                    onClick={() => {
                      setIsAccount(true);
                      setAccountType("fb"), facebookId(facebookId);
                    }}
                  />
                  <label>Facebook</label>
                </div>
                <div className="add-more-item">
                  <input
                    type="radio"
                    name="rd"
                    onClick={() => {
                      setIsAccount(true);
                      setAccountType("ig");
                    }}
                  />
                  <label>Instagram</label>
                </div>
              </div>
            )}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="conn-container">
                  {getConnData !== undefined ? (
                    <>
                      {getConnData?.fbConnectedAc?.map((item, index) => {
                        return (
                          <div key={index} className="conn-item" onClick={() => handleRemoveFacebook(item?.id)}>
                            <h3>
                              <img src={item?.picture?.data?.url} height="40" width="40" alt="" />
                              <span>{item?.name?.substring(0, 18)}</span>
                            </h3>
                            <img className="iglogo" src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} alt="" style={{ zIndex: "2" }} />
                          </div>
                        );
                      })}
                      {getConnData?.igConnectedAc?.map((item, index) => {
                        return (
                          <div key={index} className="conn-item" onClick={() => handleRemoveInstagram(item?.id)}>
                            <h3>
                              <img height="40" width="40" src={item?.instagram_business_account?.profile_picture_url} />
                              <span>{`${item?.instagram_business_account?.name?.substring(0, 18)} ...`}</span>
                            </h3>
                            <img className="iglogo" src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} alt="" style={{ marginLeft: "-10px", zIndex: "1" }} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    "No Connected Account Found"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* The above code is conditionally rendering an `AccountList` component based on the value of the
        `isAccount` variable. If `isAccount` is true, the `AccountList` component is rendered with the
        specified props (`accountType`, `setIsAccount`, `isAccount`, `setAccountType`, `getConnData`).
        If `isAccount` is false, the `AccountList` component is not rendered. */}
        {isAccount && <AccountList accountType={accountType} setIsAccount={setIsAccount} isAccount={isAccount} setAccountType={setAccountType} getConnData={getConnData} />}
      </div>
    </div>
  );
};

export default Integration;