import React, { useEffect, useRef, useState } from "react";
import "./CreatePost.css";
import { useDispatch, useSelector } from "react-redux";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import {
  addAIPost,
  addDraftPostId,
  addMedia,
  addMediaDis,
  addOriginalUrl,
  changeConnectionType,
  changePostSelection,
  changePostType,
  clearPostState,
  setPostGenerationType,
  setActiveComponent,
  addMediaTitle,
} from "../../../store/features/post/postSlice";
import { toast } from "react-toastify";
import { deleteApi, getApi, postApi } from "../../../api/methods";
import { isLoading } from "../../../store/features/common/commonSlice";
import { POSTTAG_CUSTOM } from "../../../utility/enums.constant";
import Media from "../../../pages/Media/Media";
import Modal from "react-bootstrap/esm/Modal";
import { Form, Button } from "react-bootstrap";
import { Button as CustomButton } from "../../../components/ui/button";
import addImg from "../../../assets/addImg.svg";
import addImgWhite from "../../../assets/addImgWhite.svg";
import uploadImg from "../../../assets/uploadImg.svg";
import recentImg from "../../../assets/recentImage.svg";
import searchImg from "../../../assets/searchImg.svg";
import uploadImgWhite from "../../../assets/uploadImgWhite.svg";
import recentImgWhite from "../../../assets/recentImgWhite.svg";
import searchImgWhite from "../../../assets/searchImgWhite.svg";
import { BASE_URL, IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../../api/API";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Picker from "@emoji-mart/react";
import emojiData from "@emoji-mart/data";
import { v4 as uuidv4 } from "uuid";
import { POST_GENERATION_TYPES } from "../../../store/features/post/initialState";
import { addGenerateType, clearGeneratePostState } from "../../../store/features/generatePost/generatePostSlice";

/*
  business logic use here:
    Firstly, composer editor has take input from user type post idea or generated from Open AI

    Now user wants to 
    - copy that post to clipboard
    - add any emoji to any position of that post
    - add uploaded images/gifs/videos etc to tha post
    - add external link to that post
    - add post to evergreen 
    - add post to bookmark
    - rewrite the post using open AI
    - reset all the state to intitial post structure
    - when user can write anything in the editor then that text is save automatically user can see in under my quere > draft tab

    - Publish post: when user click this button that post immediately post in linkedin with their post type like none/image/video/article format as long as posting to selected social media in auto distibution
    - schedule post: It has two types 
      -> auto schedule
        - In auto schedule when user avaliable time slot is present then asiign this post to this slot and user can see in my queue section
      -> custom schedule
        - In this user can input custom date and time for schedule posting
    
    Auto Plug: Now add auto plug feture means automatically add comment of that post in given time
    
    Auto Distribution: this is for convert post format to selected social media format and publish or schedule for respective social media platform
*/

/**
 * The `PostContent` component in JavaScript React displays a limited number of lines of content with
 * an option to expand and view more.
 * @returns The `PostContent` component is returning JSX elements that conditionally display content
 * based on whether it is expanded or not. The content is initially displayed with a maximum of 2
 * lines, and if the content exceeds 5 lines, an option to "View more" or "View less" is provided. The
 * displayed content is toggled between full content and truncated content based on the `isExpanded`
 */

const PostContent = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const lines = content.split("\n");
  const shouldShowViewMore = lines.length > 5;

  const displayedContent = isExpanded ? content : lines.slice(0, 2).join("\n");

  return (
    <div>
      <p className="text-gray-800 text-[14px] whitespace-pre-wrap">
        {displayedContent}
        {!isExpanded && shouldShowViewMore && "..."}
      </p>
      {shouldShowViewMore && (
        <button onClick={() => setIsExpanded(!isExpanded)} className="text-blue-600 hover:text-blue-700 text-[14px] font-medium mt-2">
          {isExpanded ? "View less" : "View more"}
        </button>
      )}
    </div>
  );
};

const CreatePost = (props) => {
  const [showPollPreviewModal, setShowPollPreviewModal] = useState(false);
  const [hidden, setHidden] = useState({
    date: "block",
    time: "block",
  });
  const user = useSelector((state) => state.user);
  const { isDark } = useSelector((state) => state.common);

  const [active, setActive] = useState("upload");

  const post = useSelector((state) => state.post);
  const common = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const linkedinId = user?.userData?.linkedinId;
  const linkedinAccessToken = user.userData.accessToken;
  const [schedulePanelOpen, setSchedulePanelOpen] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [checkCustomTime, setCheckCustomTime] = useState(false);

  const [isOnPlug, setIsOnPlug] = useState(false);
  const [autoPlugOpen, setAutoPlugOpen] = useState(false);
  const [autoPlugComment, setAutoPlugComment] = useState("");
  const [autoPlugDelay, setAutoPlugDelay] = useState(null);
  const [autoPlugTags, setAutoPlugTags] = useState(null);
  const [prevComments, setPrevComments] = useState([]);

  const [isOnDist, setIsOnDist] = useState(false);
  const [isOnTweet, setIsOnTweet] = useState(false);
  const [isOnFacebook, setIsOnFacebook] = useState(false);
  const [isOnInsta, setIsOnInsta] = useState(false);
  const [urlPanel, setUrlPanel] = useState(false);
  const [mediaPanel, setMediaPanel] = useState(false);
  const [btnCaroselPdf, setBtnCaroselPdf] = useState(false);
  const [btnCaroselPdfName, setBtnCaroselPdfName] =
    // useState("Convert Carousel");
    useState("PDF");

  // Emoji picker
  const [isPickerVisible, setPickerVisible] = useState(false);
  const buttonRef = useRef(null);
  const [planName, setPlanName] = useState("Free-tier");
  const [isStarFilled, setIsStarFilled] = useState(false);
  const [isDraftFilled, setIsDraftFilled] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  // This is to restore the latest state
  const [undoStack, setUndoStack] = useState([]);

  const handlePixabayApi = (e) => {
    if (e) e.preventDefault();
    {
      searchPlat == "Pexels" ? dispatch(pexelImageData(search)) : searchPlat == "Pixabay" ? dispatch(pixabayImageData(search)) : dispatch(unsplashImageData(search));
    }
  };
  const handleDeleteRecent = (imageUrl) => {
    dispatch(deleteRecentImage(imageUrl));
  };

  const image = "/images/pp.jpeg";

  const handleRemoveImg = (id) => {
    dispatch(imageSet({ id: id, img: "" }));
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(imageUploadAction(reader.result));
      };
      reader.readAsDataURL(file);
    }
    setActive("recents");
  };

  const [showDialog2, setShowDialog2] = useState(false);
  const closeDialog2 = () => {
    setShowDialog2(false);
    setActive("upload");
    setSearch("");
  };
  const handleDialog = (id) => {
    setShowDialog(true);
    setDialogId(id);
  };
  const handleDialog1 = (id) => {
    setShowDialog1(true);
    setDialogId1(id);
  };
  const handleRecentImage = (id, img) => {
    const data = {
      id: id,
      backgroundImage: img,
    };
    dispatch(backgroundImageSet(data));
    setShowDialog(false);
  };

  const handleImageinImage = (id, img) => {
    const data = {
      id: id,
      img: img,
    };
    dispatch(imageSet(data));
    setShowDialog1(false);
    console.log(data);
  };

  // Show the convert carosel to pdf button instead of publish post because post type is linkedin Carousel, Directly crosel post is not send to linikedin posting
  useEffect(() => {
    if (post.postType === "PDF") {
      setBtnCaroselPdf(true);
    }
  }, [post.postType]);

  const [getThread, setGetThread] = useState(JSON.parse(localStorage.getItem("threadData")) || []);
  const [getFB, setGetFB] = useState(null);
  // console.log({ getThread });
  const [fbPageList, setFbPageList] = useState([]);
  const [isFb, setIsFb] = useState(false);
  const [isIG, setIsIG] = useState(false);
  const [isTweet, setIsTweet] = useState(false);
  const [isInsta, setIsInsta] = useState(false);

  const [getIgAc, setGetIgAc] = useState(null);

  const navigate = useNavigate();
  const getfbUser = () => {
    // Check if the LinkedIn ID is available
    if (linkedinId) {
      // Initialize Facebook ID
      let facebookId = "";

      // Make API call to get Facebook user information
      getApi(`facebook/get-facebook-user?facebookId=${facebookId}&userId=${user?.userData?.userId}`).then((res) => {
        // Check if data is available in the response
        if (res?.data) {
          // Call function to get connected account
          getConnectedAc(res?.data?.facebookId);
        }

        // Alert with the response data (for debugging)
        // alert(JSON.stringify(res?.data))
      });
    }
  };
  const getConnectedAc = (fbid) => {
    // Make API call to get connected accounts using the provided Facebook ID
    getApi(`get-connected-ac?facebookId=${fbid}`).then((res) => {
      // Check if data is available in the response
      if (res?.data) {
        // Set the Facebook page list state with the response data
        setFbPageList(res?.data);
        // Log the response data (for debugging)
        // console.log({ res: res?.data });
      }
    });
  };

  const getPlanName = async () => {
    console.log("user.userData");
    console.log(user.userData.teamId);
    const transactionData = await getApi(`transaction/get-subscription-by-id/${user.userData.teamId}`);
    console.log("transactionData");
    console.log(transactionData[0].plan_Name);
    setPlanName(transactionData[0].plan_Name);
  };

  const updatedContent = post.postContent.split(/#\s*/).map((part, index) => (
    <React.Fragment key={index}>
      {index != 0 ? "#" : ""}
      {part} {index === 0 && part.trim().length > 0 && post.postContent.includes("#") ? "\n\n" : ""}{" "}
    </React.Fragment>
  ));
  useEffect(() => {
    console.log("user", user.userData);
    getPlanName();
    console.log("common", common);
    getfbUser();
  }, []);
  const getFacebookuseer = async () => {
    // Set the Facebook ID to an empty string for now
    let facebookId = "";

    // Make an API call to get Facebook user data using the provided Facebook ID and user ID
    await getApi(`facebook/get-facebook-user?facebookId=${facebookId}&userId=${user?.userData?.userId}`).then((res) => {
      // Set the 'getFB' state with the response data
      setGetFB(res.data);
    });
  };

  // facebook post start

  const [getFbPageId, setGetFbPageId] = useState([]);
  // console.log({getFbPageId});

  /*
  This function loops through the getFbPageId array and posts the message to each Facebook page. If an image is included in the post, it constructs the image URL and includes it in the post data.
  */

  const createFacebookPost = () => {
    // Construct the image URL using the backend URL and the post's original URL
    const url = `${IMAGE_URL_BACKEND}/uploads/${post.originalUrl}`;
    // Set the image path to the constructed URL if the original URL is not empty
    const imgPath = post.originalUrl !== "" ? url : undefined;

    // Iterate over the Facebook page IDs and post to each page
    for (let fbd of getFbPageId) {
      // Make an API call to post to the Facebook page
      postApi("post-to-page", {
        pagesId: fbd.id,
        message: post.postContent,
        facebookId: getFB?.facebookId,
        urls: [imgPath],
      }).then((res) => {
        // Clear the 'getFbPageId' state after posting
        setGetFbPageId([]);
        // Show a success toast message
        toast.success("Facebook Post Published Successfully");
      });
    }
  };

  // facebook post end

  // Background Style of instagram preview post
  const styles = [
    {
      name: "Navi Blue",
      style: {
        background: "#304b63",
        width: "100%",
        // height: '200px',
        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Green",
      style: {
        background: "#718a66",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Sky Blue",
      style: {
        background: "#009cc6",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Brown",
      style: {
        background: "#98521a",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Pink",
      style: {
        background: "#e73877",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
  ];
  const [selectedStyle, setSelectedStyle] = useState(styles[0].name);
  const [igBgcolor, setigBgColor] = useState(styles[0]);

  // change style of instagram preview backgroud, because user can select which theme shoud use for post
  const handleChangeStyle = (event) => {
    setSelectedStyle(event.target.value);
  };

  const [getIg, setGetIG] = useState([]);
  // console.log({ getIg });

  /* 
    This function loops through the getIg array, prepares the Instagram data for each account, and then makes an API call to post to Instagram with the thread data, Instagram data, Facebook ID, and selected style.
  */
  const handlePublishIg = async () => {
    // Retrieve thread data from local storage or use the post content if not available
    const threadData = JSON.parse(localStorage.getItem("threadData")) || [post.postContent];

    // Iterate over the Instagram accounts
    for (let data of getIg) {
      // Prepare the Instagram data for the API call
      const igDatas = {
        pageId: data.id,
        instagramId: data.instagram_business_account.id,
      };
      await postApi("instagram-post", {
        textArray: threadData,
        igData: igDatas,
        facebookId: getFB?.facebookId,
        bgcolor: selectedStyle,
      }).then((res) => {
        // Clear the 'getIG' and 'getThread' states after posting
        setGetIG([]);
        setGetThread([]);
        toast.success("Instagram Post Published Sucessfully");
      });
    }
  };

  const twitteruser = props.getTwitterUser;
  // console.log({ twitteruser });

  // this function generate threads of 250 to 280 words for twitter posting
  const handletwitterthreads = async () => {
    if (getThread?.length > 0) {
      return;
    } else {
      dispatch(isLoading(true));
      await axios
        .post(`${apibase}/ai-post-into-threads`, {
          content: post.postContent,
        })
        .then((res) => {
          const threadData = res.data.data;
          localStorage.setItem("threadData", JSON.stringify(threadData));
          setGetThread(threadData);
          setTimeout(() => {
            // handleImageConvert()
          }, 1000);
          dispatch(isLoading(false));
        });
    }
  };

  // fetch previous auto comments of the user for auto plug funcationality
  const fetchPreviousComment = async () => {
    const response = await getApi(`auto-comment/get-auto-comment/${user.userData.linkedinId}`);
    setPrevComments(response.data);
  };

  // Here publish twitter post to their connected twitter account
  const handlePublishTwitterpost = async () => {
    try {
      // Check if there is only one item in the thread (single tweet)
      if (getThread.length === 1) {
        // Post a single tweet
        await axios
          .post(`${apibase}/api/twitter/create`, {
            arrayOfText: getThread,
            userId: user.userData.linkedinId,
          })
          .then((res) => {
            setGetThread([]);
            toast.success("Tweet published successfully!");
          })
          .catch((err) => {
            toast.warn(`Failed to Tweet`);
          });
      } else {
        // If there are multiple items in the thread (tweet thread)
        if (twitteruser.twitterId !== undefined) {
          // Publish a tweet thread
          await axios
            .post(`${apibase}/api/publish-twitter-thread`, {
              twitterId: twitteruser.twitterId,
              text: getThread,
            })
            .then((res) => {
              setGetThread([]);
              toast.success("Tweet published successfully!");
            })
            .catch((err) => {
              toast.warn(`Failed to Tweet`);
            });
        } else {
          // If twitterId is not found, show a warning
          toast.warn("twitterId not Found Try again");
        }
      }
    } catch (err) {
      // If tweet publish is unsuccessful, show an error toast
      toast.error("Tweet publish unsuccessful!");
    }
  };

  /*
    This function futureDateTimeHandler takes a date and a time as input, combines them into a single future date and time string, and then splits them into separate date and time components.
  */

  const futureDateTimeHandler = (date, time) => {
    // Combine the date and time into a single string
    const future = new Date(`${date}T${time}`).toISOString();

    // Split the combined string into date and time components
    const futureDate = future?.split("T")[0]; // Extract the date part
    const futureTime = future?.split("T")[1].split(".")[0]; // Extract the time part

    // Return the future date and time components
    return { futureDate, futureTime };
  };

  // handle schedule twitter post
  const handleScheduleTwitterpost = async () => {
    const { futureDate, futureTime } = futureDateTimeHandler(scheduleDate, scheduleTime);

    await axios
      .post(`${apibase}/api/twitter/create-schedule-data`, {
        userId: user.userData.linkedinId,
        text: getThread,
        date: futureDate,
        time: futureTime,
        twitterId: twitteruser.twitterId,
      })
      .then((res) => {
        setGetThread([]);
        // console.log({ res });
        toast.success("Tweet scheduled!");
      })
      .catch((err) => {
        console.warn({ err });
        toast.error("Tweet schedule failed, retry!");
      });
  };

  // handle scheduleing facebook post
  const handleSchedulefbpost = async () => {
    const url = `${IMAGE_URL_BACKEND}/uploads/${post.originalUrl}`;
    const imgPath = post.originalUrl !== "" ? url : undefined;
    const { futureDate, futureTime } = futureDateTimeHandler(scheduleDate, scheduleTime);
    for (let fbp of getFbPageId) {
      await axios
        .post(`${apibase}/api/facebook/create-facebook-schedule-post`, {
          userId: getFB?.linkedinId,
          facebookId: getFB?.facebookId,
          pageId: fbp.id,
          message: post.postContent,
          date: futureDate,
          time: futureTime,
          permanentAcessToken: getFB?.acessToken,
          url: imgPath,
        })
        .then((res) => {
          // console.log({ res });
          toast.success("Facebook Post Scheduled!");
        })
        .catch((err) => {
          console.warn({ err });
          toast.error("Facebook post schedule failed, retry!");
        });
    }
  };

  // handle scheduleing instagram post
  const handleIGschedule = async () => {
    const { futureDate, futureTime } = futureDateTimeHandler(scheduleDate, scheduleTime);
    const threadData = JSON.parse(localStorage.getItem("threadData")) || [post.postContent];
    for (let data of getIg) {
      const igDatas = {
        pageId: data.id,
        instagramId: data.instagram_business_account.id,
      };

      await axios
        .post(`${apibase}/api/facebook/ig-schedule`, {
          textArray: threadData,
          igData: igDatas,
          facebookId: getFB?.facebookId,
          bgcolor: selectedStyle,
          date: futureDate,
          time: futureTime,
        })
        .then((res) => {
          setGetThread([]);
          setGetIG([]);
          toast.success("Instagram Post Published Sucessfully");
        });
    }
  };

  // when auto distribution toggle is manupulate then get feacebook info of user
  useEffect(() => {
    getFacebookuseer();
  }, [isOnDist]);

  // when auto plug toggle is manupulate then get all previous comments of user
  useEffect(() => {
    fetchPreviousComment();
  }, [autoPlugOpen]);

  // here maintain structure of request post format with which social media is selected that is also maintain here according to post type
  const bodyPostHandler = () => {
    const getPostIdPrefix = () => {
      switch (post.postGenerationType) {
        case POST_GENERATION_TYPES.AI_CONTENT:
          return "ai";
        case POST_GENERATION_TYPES.DISCOVER:
          return "discover";
        case POST_GENERATION_TYPES.ON_THE_GO:
          return "otg";
        case POST_GENERATION_TYPES.CUSTOM:
        default:
          return "custom";
      }
    };

    const postId = `${getPostIdPrefix()}_${uuidv4()}`;
    if (post.postType === "NONE") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else if (post.postType === "IMAGE") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        media: post.media,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else if (post.postType === "VIDEO") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        media: post.media,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else if (post.postType === "PDF") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        media: post.media,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    }
  };

  // here construct the linkedin request post format/structure
  const genPostFormatLinkedin = () => {
    if (post.postType === "NONE") {
      return {
        author: `urn:li:person:${linkedinId}`,
        lifecycleState: "PUBLISHED",
        specificContent: {
          "com.linkedin.ugc.ShareContent": {
            shareCommentary: {
              text: post.postContent
                .split(/#\s*/)
                .map((part, index) => `${index !== 0 ? "#" : ""}${part} ${index === 0 && part.trim().length > 0 && post.postContent.includes("#") ? "\n\n" : ""}`)
                .join(""),
            },
            shareMediaCategory: "NONE",
          },
        },
        visibility: {
          "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
        },
      };
    } else if (post.postType === "IMAGE") {
      // Ensure that media properties are arrays and have equal length
      if (Array.isArray(post.mediaTitle)) {
        console.log("postData0");
        console.log(post);
        console.log("postData0");
        const mediaArray = post.mediaTitle.map((mediaItem, index) => {
          return {
            status: "READY",
            description: {
              text: post.mediaTitle[index],
            },
            media: "",
            title: {
              text: post.mediaTitle[index],
            },
          };
        });

        const postData = {
          author: `urn:li:person:${linkedinId}`,
          lifecycleState: "PUBLISHED",
          specificContent: {
            "com.linkedin.ugc.ShareContent": {
              shareCommentary: {
                text: post.postContent,
              },
              shareMediaCategory: "IMAGE",
              media: mediaArray,
            },
          },
          visibility: {
            "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
          },
        };
        return postData;
      } else {
        throw new Error("Media and mediaTitleAndDescription arrays must be of equal length and not empty");
      }
    } else if (post.postType === "VIDEO") {
      return {
        author: `urn:li:person:${linkedinId}`,
        lifecycleState: "PUBLISHED",
        specificContent: {
          "com.linkedin.ugc.ShareContent": {
            shareCommentary: {
              text: post.postContent,
            },
            shareMediaCategory: "VIDEO",
            media: [
              {
                status: "READY",
                description: {
                  text: post.mediaDis,
                },
                media: post.media,
                title: {
                  text: post.mediaTitle,
                },
              },
            ],
          },
        },
        visibility: {
          "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
        },
      };
    } else if (post.postType === "PDF") {
      return {
        author: `urn:li:person:${linkedinId}`,
        commentary: post.postContent,
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: post.mediaTitle || post.originalUrl.split("/").pop(),
            id: [post.media],
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };
    } else if (post.postType === "POLL") {
      const pollData = JSON.parse(post.postContent);
      return {
        author: `urn:li:person:${linkedinId}`,
        lifecycleState: "PUBLISHED",
        specificContent: {
          "com.linkedin.ugc.ShareContent": {
            shareCommentary: {
              text: pollData.question,
            },
            shareMediaCategory: "POLL",
            poll: {
              question: pollData.question,
              options: pollData.options.map((option) => ({ text: option })),
              duration: parseInt(pollData.duration) * 24 * 60 * 60 * 1000, // Convert days to milliseconds
            },
          },
        },
        visibility: {
          "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
        },
      };
    } else {
      if (!post.postContent && !post.originalUrl) {
        return false;
      } else {
        return {
          author: `urn:li:person:${linkedinId}`,
          lifecycleState: "PUBLISHED",
          specificContent: {
            "com.linkedin.ugc.ShareContent": {
              shareCommentary: {
                text: post.postContent,
              },
              shareMediaCategory: "ARTICLE",
              media: [
                {
                  status: "READY",
                  originalUrl: post.originalUrl,
                },
              ],
            },
          },
          visibility: {
            "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
          },
        };
      }
    }
  };

  // Function to initialize the document
  const initializeDocument = async (s3Key) => {
    try {
      const initResponse = await axios.post(
        `${BASE_URL}linkedin/initialise-post-pdf`,
        {
          userId: linkedinId,
          s3Key: s3Key,
        },
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!initResponse.data?.data?.asset) {
        throw new Error("Failed to initialize document");
      }

      return {
        documentUploadUrl: initResponse.data.data.documentUploadUrl,
        asset: initResponse.data.data.asset,
      };
    } catch (err) {
      console.error("Document initialization error:", err);
      throw new Error("Failed to initialize document: " + (err.message || "Unknown error"));
    }
  };

  // LinkedinMediaUpload function
  const linkedinMediaUpload = async (fileName) => {
    try {
      dispatch(isLoading(true));

      const fileToUpload = post.originalUrl || fileName;
      if (!fileToUpload) {
        throw new Error("No file selected for upload");
      }

      // Create FormData
      const formData = new FormData();
      formData.append("file", fileToUpload);
      formData.append("userId", linkedinId);

      // First upload to S3
      const uploadResponse = await axios.post(`${BASE_URL}linkedin/document-upload`, formData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (!uploadResponse.data?.s3FileUrl) {
        throw new Error("Invalid upload response");
      }

      // Get the S3 key from the URL
      const s3Key = uploadResponse.data.s3FileUrl.split("/").pop();

      // Initialize the document to get the URN and upload URL
      const initResponse = await axios.post(
        `${BASE_URL}linkedin/initialise-post-pdf`,
        {
          userId: linkedinId,
          s3Key: s3Key,
        },
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!initResponse.data?.data?.asset) {
        throw new Error("Failed to initialize document");
      }

      const { documentUploadUrl, asset } = initResponse.data.data;

      // Store the LinkedIn URN and S3 URL
      dispatch(addMedia(asset));
      dispatch(addOriginalUrl(uploadResponse.data.s3FileUrl));
      dispatch(addMediaTitle(fileToUpload instanceof File ? fileToUpload.name : s3Key));
      dispatch(changePostType("PDF"));

      setBtnCaroselPdfName("PDF");
      setBtnCaroselPdf(false);

      dispatch(isLoading(false));
      toast.success("Document uploaded successfully");
      setMediaPanel(false);

      return {
        ...uploadResponse.data,
        asset,
        documentUploadUrl,
      };
    } catch (err) {
      dispatch(isLoading(false));
      console.warn("Document upload error:", err);
      const errorMessage = err.response?.data?.message || err.message || "Failed to upload document";
      toast.error(errorMessage);
      throw err;
    }
  };

  // This is simply manage the state of post that is currently publish that post
  const publishPostHandler = () => {
    dispatch(changePostSelection("create-post"));
    dispatch(clearGeneratePostState(""));

    // Check if this is a poll post
    if (post.postType === "POLL") {
      handlePollPost()
        .then(() => {
          // Additional cleanup after successful poll post
          setPollData(null);
          dispatch(changePostType("NONE"));
          dispatch(clearPostState());
        })
        .catch((error) => {
          console.error("Error in poll post:", error);
        });
    } else {
      postNowHandler();
    }

    // Handle other social media posts
    if (isOnTweet) {
      handlePublishTwitterpost();
      setIsOnTweet(false);
    }
    if (isOnFacebook) {
      createFacebookPost();
      setIsOnFacebook(false);
    }
    if (isOnInsta) {
      setTimeout(() => {
        handlePublishIg();
        setIsOnInsta(false);
      }, 1000);
    }
  };
  // This is simply manage the state of post that is schedule that post
  const schedulePostHander = () => {
    // setSchedulePanelOpen(true)
    // dispatch(changePostSelection('schedule-post'))

    if (post.postContent) {
      setSchedulePanelOpen(true);
      dispatch(changePostSelection("schedule-post"));
      console.log(post.postContent);
    } else {
      toast.error("Please write your idea!");
    }
  };

  // This function handles different scenarios for posting content: immediate posting, scheduling for later, and handling auto-plug logic. It interacts with the backend to publish posts and manage scheduling.

  // Here check user wnats to publish post immediatly or scheduled it
  const postNowHandler = async () => {
    if (!post.postContent) {
      toast.error("Please write your idea!");
      return;
    }

    try {
      dispatch(isLoading(true));

      let postData; // Declare postData at the top level of the try block

      if (post.postType === "VIDEO") {
        await handleVideoPost();
      } else {
        // Handle PDF and other post types
        if (post.postType === "PDF") {
          // First initialize the PDF post since we already have the S3 URL
          const s3Key = `uploads/${post.originalUrl.split("/").pop()}`;
          const initResponse = await postApi(
            "linkedin/initialise-post-pdf",
            {
              userId: linkedinId,
              s3Key: s3Key,
            },
            {
              headers: {
                Authorization: `Bearer ${linkedinAccessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!initResponse?.data?.asset) {
            throw new Error("Failed to initialize document");
          }

          // Create the post with the correct format using the asset URN
          postData = {
            author: `urn:li:person:${linkedinId}`,
            commentary: post.postContent,
            visibility: "PUBLIC",
            content: {
              media: {
                title: "Untitled PDF",
                id: initResponse.data.asset,
              },
            },
            distribution: {
              feedDistribution: "MAIN_FEED",
              targetEntities: [],
              thirdPartyDistributionChannels: [],
            },
            lifecycleState: "PUBLISHED",
            isReshareDisabledByAuthor: false,
          };
        } else {
          // Handle other post types
          postData = genPostFormatLinkedin();
        }

        // Create LinkedIn post
        const postResponse = await postApi("linkedin/create-linkedin-post", postData, {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!postResponse?.data?.id) {
          throw new Error("Failed to create LinkedIn post");
        }

        // Handle successful post creation
        const postBody = bodyPostHandler();
        postBody.status = "PUBLISH";
        postBody.postUrn = postResponse.data.id;

        const res = await postApi("published/post-create", postBody);
        if (!res) {
          throw new Error("Error saving post in database");
        }

        toast.success("Post Published Successfully!", { autoClose: 1000 });
      }

      // Common cleanup code
      localStorage.removeItem("threadData");
      dispatch(clearPostState());
      setIsOnDist(false);
      setIsOnPlug(false);

      if (post.draftPostId) {
        await deleteApi("draft/draft-post-delete", {
          postId: post.draftPostId,
        });
      }

      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.error("Post creation error:", error);

      try {
        const postBody = bodyPostHandler();
        postBody.status = "FAILED";
        await postApi("published/post-create", postBody);
      } catch (err) {
        console.error("Error saving failed post:", err);
      }

      toast.error(error.message || "Failed to create post");
    }
  };

  // Clear custom schedule post states
  const cancelSchedulePost = () => {
    setSchedulePanelOpen(false);
    setScheduleDate("");
    setScheduleTime("");
    setCheckCustomTime(false);
  };

  // Clear auto plug states
  const cancelAutoPlugPost = () => {
    setIsOnPlug(false);
    setAutoPlugOpen(false);
    setAutoPlugDelay("");
    setAutoPlugComment("");
  };

  // When user add custom schedule post
  const onScheduleConfirmHandler = () => {
    if (!scheduleDate || !scheduleTime) {
      toast.info("Please select Date and time");
    } else {
      const futureDate = new Date(`${scheduleDate}T${scheduleTime}`);
      // console.log('futureDate',futureDate, futureDate.toISOString());
      setSchedulePanelOpen(false);
      postNowHandler();
    }
  };

  // When user add auto plug
  const onAutoPlugHandler = () => {
    console.log("Auto Plug Data:", {
      delay: autoPlugDelay,
      comment: autoPlugComment,
      tags: autoPlugTags,
    });
    if (!autoPlugDelay || !autoPlugComment || !autoPlugTags) {
      toast.info("Please select Delay in time and your comment");
    } else {
      setAutoPlugOpen(false);
    }
  };

  // Type in post editor changes
  const onPostDescriptionHandler = (e) => {
    const newContent = e.target.value;
    dispatch(addAIPost(newContent));
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isPickerVisible &&
        !event.target.closest(".emoji-picker") && // Changed this line
        event.target !== buttonRef.current
      ) {
        setPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPickerVisible]);

  const EmojiTrayToggle = () => {
    setPickerVisible(!isPickerVisible);
  };

  // Save post into draft
  const saveDraft = async () => {
    console.log("Saving draft...");
    try {
      // Generate a draft post ID if it doesn't exist
      const draftPostId = post.draftPostId || `draft_${uuidv4()}`;

      // Update the draft post ID in the state
      dispatch(addDraftPostId(draftPostId));

      // Create the body for the API request
      const bodyPost = bodyPostHandler();
      bodyPost.postId = draftPostId;

      // Check if there is content in the post
      if (post.postContent) {
        // Send a request to the backend to save the draft post
        const response = await postApi("draft/draft-post-create", bodyPost);
        console.log(response);
        // Display a success message
        toast.success("Draft saved", { autoClose: 1000 });
        return true; // Indicate success
      } else {
        toast.warning("Cannot save an empty draft");
        return false; // Indicate failure
      }
    } catch (error) {
      // Display an error message if the draft couldn't be saved
      toast.error("Error saving draft: " + error.message);
      return false; // Indicate failure
    }
  };

  // Add external link/url to article type post when user click URL icon
  const addURLHandler = () => {
    if (!post.postContent) {
      toast.error("Please write your idea for Share URL Post!");
    } else {
      dispatch(addOriginalUrl(""));
      dispatch(changePostType("ARTICLE"));
      setUrlPanel(true);
    }
  };

  // Cancel/Remove the external link to post, when that happen then post type change to none for linkedin request body format format
  const cancelURLPost = () => {
    setUrlPanel(false);
    dispatch(changePostType("NONE"));
  };

  useEffect(() => {
    if (!post.postContent) {
      dispatch(addDraftPostId());
    } else {
    }
  }, [post.postContent, post.originalUrl]);

  useEffect(() => {
    const saveDraftMount = async () => {
      postApi("draft/draft-post-create", bodyPost)
        .then((response) => {
          console.log(response);
          // Display a success message
          // toast.success(response.data.message);
        })
        .catch((error) => {
          // Display an error message if the draft couldn't be saved
          // toast.error(error);
        });
      console.log("saveDraft check");
      // Generate a draft post ID if it doesn't exist
      const draftPostId = post.draftPostId || `draft_${uuidv4()}`;
      // Update the draft post ID in the state
      dispatch(addDraftPostId(draftPostId));

      // Create the body for the API request
      const bodyPost = bodyPostHandler();
      bodyPost.postId = post.draftPostId;

      // Check if there is content in the post and a draft post ID
      if (post.postContent && post.draftPostId) {
        console.log("this is working");
        // Send a request to the backend to save the draft post
        postApi("draft/draft-post-create", bodyPost)
          .then((response) => {
            console.log(response);
            // Display a success message
            toast.success(response.data.message, { autoClose: 1000 });
          })
          .catch((error) => {
            // Display an error message if the draft couldn't be saved
            toast.error(error);
          });
      }
    };

    const handleBeforeUnload = async (e) => {
      // Call your function or perform any action before the page unloads
      console.log("hello");
      saveDraftMount();

      // Make a POST request
      await postApi("draft/draft-post-create", bodyPost)
        .then((response) => {
          console.log(response);
          // Display a success message
          toast.success(response.data.message, { autoClose: 1000 });
        })
        .catch((error) => {
          // Display an error message if the draft couldn't be saved
          toast.error(error);
        });

      const confirmationMessage = "Are you sure you want to leave?";
      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    };

    // Event listener for beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    // Event listener for beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures that this effect runs only once (on mount)

  // Add images to that post when user click image/Media button
  const mediaPanelHander = () => {
    if (!post.postContent) {
      toast.error("Please write your idea for Image Post!");
    } else {
      dispatch(changePostType("IMAGE"));
      setUrlPanel(false);
      setMediaPanel(true);
    }
  };

  // Handle auto plug toggle bar like on/off
  const handleSwitchOfPlug = () => {
    if (isOnPlug === false) {
      // If auto plug is off and there is content in the post
      if (post.postContent) {
        setIsOnPlug(true);
        // Open the auto plug panel
        setAutoPlugOpen(true);
      } else {
        // If there is no content in the post, display an error message
        toast.error("Please write your idea!");
      }
    } else {
      // If auto plug is already on, cancel the auto plug
      cancelAutoPlugPost();
    }
  };
  const [seeOptions, setSeeOptions] = useState(false);
  // Handle auto plug toggle bar like on/off
  const handleSwitchOfDistribution = () => {
    if (isOnDist === false) {
      // If distribution is off and there is content in the post
      if (post.postContent) {
        setIsOnDist(true);
        setSeeOptions(true);
        // If the post content is less than 250 characters, convert to image (handleImageConvert function)
        if (post.postContent?.length < 250) {
          // handleImageConvert()
        }
      } else {
        // If there is no content in the post, display an error message
        toast.error("Please write your idea!");
      }
    } else {
      // If distribution is already on, turn it off and reset all distribution-related states
      setSeeOptions(false);
      setIsOnDist(false);
      setIsOnTweet(false);
      setIsOnFacebook(false);
      setIsOnInsta(false);
      setGetThread([]);
    }
  };

  // If twitter button is on then check if this user hase already connect their twitter account or not
  // if yes then post the twitter data or convert it to thread of multyple
  const handleSwichsOfTweet = () => {
    if (!props.getTwitterUser) {
      const confirmTW = confirm("Twitter is not connected! Try to connect.");
      if (confirmTW) {
        navigate("/my-accounts?invite=2");
      }
    } else {
      if (isOnTweet === false) {
        if (post.postContent) {
          setIsOnTweet(true);
          if (post.postContent?.length > 270) {
            handletwitterthreads();
          } else {
            setGetThread([post.postContent]);
          }
        } else {
          toast.error("Please write you idea!");
        }
      } else {
        setIsOnTweet(false);
        setGetThread([]);
      }
    }
  };

  // If facebook toggle is on then 1st check facebook account is connected and all the connected facebook pages are fetched here
  const handleSwichsOfFacebook = () => {
    // if (fbPageList?.fbConnectedAc?.length === 0) {
    //   const confirmFB = confirm("Facebook is not connected! Try to connect.");
    //   if (confirmFB) {
    //     navigate("/my-accounts?invite=2");
    //   }
    // } else {
    //   if (isOnFacebook === false) {
    //     if (post.postContent) {
    //       setIsOnFacebook(true);

    //       setIsFb(!isFb);
    //     } else {
    //       toast.error("Please write you idea!");
    //     }
    //   } else {
    //     setIsOnFacebook(false);
    //   }
    // }
    if (!props.getFacebookUser) {
      const confirmTW = confirm("Facebook is not connected! Try to connect.");
      if (confirmTW) {
        navigate("/my-accounts?invite=2");
      }
    } else {
      if (isOnFacebook === false) {
        if (post.postContent) {
          setIsOnFacebook(true);

          setIsFb(!isFb);
        } else {
          toast.error("Please write you idea!");
        }
      } else {
        setIsOnFacebook(false);
      }
    }
  };

  // If facebook toggle is on then 1st check instagram account is connected and all the connected instagram pages are fetched here
  // And if their is long post then it convert to multiple parts to post in instagram
  const handleSwichsOfInsta = () => {
    if (!props.getInstaUser) {
      const confirmTW = confirm("Insta is not connected! Try to connect.");
      if (confirmTW) {
        navigate("/my-accounts?invite=2");
      }
    } else {
      if (isOnInsta === false) {
        if (post.postContent) {
          setIsOnInsta(true);
          setIsIG(!isIG);
          // handleCreateigaccount()
          if (post.postContent?.length > 270) {
            handletwitterthreads();
          } else {
            setGetThread([post.postContent]);
          }
        } else {
          toast.error("Please write you idea!");
        }
      } else {
        setIsOnInsta(false);
      }
    }
  };

  // Copy to clipboard
  const copyPasteHandler = () => {
    if (!post.postContent) {
      toast.error("Please write your idea for Copy!");
    } else {
      /* Copy selected text into clipboard */
      navigator.clipboard.writeText(post.postContent);
      toast.success("Text Copied", { autoClose: 1000 });
    }
  };

  // Save the post in evergreen
  /*
  - Checks if there is content in the post. If not, it displays an error message and returns.
  - Creates a body object containing the necessary information for the evergreen post.
  - Sends a request to the API to create the evergreen post.
  - Displays a success message if the creation is successful.
  - Displays an error message if the creation fails, with special handling for the case where the post is already an evergreen post (HTTP status code 409).
  */
  const onEvergreenHnadler = async () => {
    if (!post.postContent) {
      toast.error(`Please write you idea!`);
      return;
    }
    // setEvergreenStatus(true);
    const getPostIdPrefix = () => {
      switch (post.postGenerationType) {
        case POST_GENERATION_TYPES.AI_CONTENT:
          return "ai";
        case POST_GENERATION_TYPES.DISCOVER:
          return "discover";
        case POST_GENERATION_TYPES.ON_THE_GO:
          return "otg";
        case POST_GENERATION_TYPES.CUSTOM:
        default:
          return "custom";
      }
    };

    const bodyPost = {
      userId: user.userData.linkedinId,
      postId: post.postId || `${getPostIdPrefix()}_${uuidv4()}`,
      postContent: post.postContent,
      connectionType: post.connectionType,
      postType: post.postType,
      mediaDis: post.mediaDis,
      mediaTitle: post.mediaTitle,
      media: post.media,
      originalUrl: post.originalUrl,
    };
    console.log(post.mediaTitle);

    try {
      dispatch(isLoading(true));
      // console.log(props.data);
      const response = await postApi("evergreen/create", bodyPost);
      toast.success("Saved as evergreen!", { autoClose: 1000 });
      // dispatch(clearPostState())
      dispatch(isLoading(false));
      setIsStarFilled(true);
    } catch (error) {
      dispatch(isLoading(false));
      console.warn(error);
      // here this 409 code is comes because of already added
      if (error.response.status === 409) {
        const message = error.response.data.message;
        toast.error(message);
      } else {
        const message = "Something went wrong!";
        toast.error(message);
      }
    }
  };

  // Save the post in bookmark
  /*
    - Checks if there is content in the post. If not, it displays an error message and returns.
    - Creates a body object containing the necessary information for the bookmarked post.
    - Sends a request to the API to create a bookmark for the post.
    - Displays a success message if the bookmarking is successful.
    - Displays an error message if the bookmarking fails, with special handling for the case where the post is already bookmarked (HTTP status code 409).
  */
  const onBookmarkHnadler = async () => {
    if (!post.postContent) {
      toast.error(`Please write you idea!`);
      return;
    }

    const bodyPost = {
      userId: user.userData.linkedinId,
      postId: post.postId || `bookmark_${uuidv4()}`,
      postContent: post.postContent,
      connectionType: post.connectionType,
      postType: post.postType,
      mediaDis: post.mediaDis,
      mediaTitle: post.mediaTitle,
      media: post.media,
      originalUrl: post.originalUrl,
    };

    try {
      dispatch(isLoading(true));
      const response = await postApi("book-marks/bookmark-post-create", bodyPost);
      toast.success("Saved in Bookmark!", { autoClose: 1000 });
      setIsFilled(true);
      // dispatch(clearPostState())
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.warn(error);
      // here this 409 code is comes because of already added
      if (error.response.status === 409) {
        const message = error.response.data.message;
        toast.error(message);
      } else {
        const message = "Something went wrong!";
        toast.error(message);
      }
    }
  };

  // Consumes of credit point by rewrite the post by using open AI
  const updateCreditHistory = async (creditInfo) => {
    try {
      const topicCredit = await getApi("creditpoint/point-info/AI-Generation");

      const bodyPost = {
        teamId: user.userData.teamId,
        name: "Re-Write Content",
        subscriptionId: creditInfo.data.subscriptionId,
        userId: user.userData.linkedinId,
        crpoint: `${topicCredit.data.point}`,
      };

      await postApi("credit/create-credit-history", bodyPost);
    } catch (error) {
      console.warn("update Credit History", error);
    }
  };

  // Rewrite the post by user, make sure user have sufficient credit balance
  /*
  - Checks if there is content in the post. If not, it displays an error message and returns.
  - Retrieves the team's credit balance from the API.
  - If the team has sufficient credits, it sends a request to the AI service to rewrite the post content.
  - If the rewrite is successful, it updates the credit history, adds the rewritten content to the post, and displays a success message.
  - If the team has insufficient credits, it displays an error message.
  */
  const onRewriteHandler = async () => {
    if (!post.postContent) {
      toast.error(`Please write you idea!`);
      return;
    }

    try {
      dispatch(isLoading(true));
      const creditInfo = await getApi(`credit/check-credit-point/${user.userData.teamId}`);

      if (Number(creditInfo.data.balance) > 0) {
        let body = {
          topic: post.postContent,
        };

        await postApi("ai-post/ai-post-rewrite", body).then((res) => {
          updateCreditHistory(creditInfo);
          dispatch(addAIPost(res.aiResponse));
          dispatch(isLoading(false));
          toast.success("Re-write Successful!", { autoClose: 1000 });
        });
      } else {
        toast.error(`Insufficient Credits!`);
        dispatch(isLoading(false));
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.warn("error of re-write content: " + err);
    }
  };

  // here this function check users available time slot for the day or next day upto 10 days to add schedule post to perticular time
  // with the respective auto plug feature

  /*
    This function seems to handle the scheduling of posts with an auto-comment feature. It first checks if the post content is present. If it is, it proceeds with the auto-plug logic, where it prepares a comment body and sends a request to create an auto-comment. Then, it prepares the post body for scheduling, including setting the auto-comment ID if it was created. Finally, it uploads media if the post type is an image or video, checks for available time slots, and schedules the post.
  */

  const autoSchedulerHandler = async () => {
    if (!post.postContent) {
      toast.error("Please write your idea!");
    } else {
      // auto plug logic
      dispatch(isLoading(true));
      let commentResponse;
      // Check if auto-plug settings are available
      if (autoPlugComment && autoPlugDelay && autoPlugTags) {
        // Prepare the comment body for auto-plug
        const currentDate = new Date();
        // currentDate.setHours(currentDate.getHours() + Number(autoPlugDelay))
        let futureDate = new Date(`${currentDate.toISOString().split("T")[0]}T${autoPlugDelay}`);
        // futureDate = futureDate.split(':')

        futureDate = futureDate.toISOString().split(".")[0];

        const bodyCommentPost = {
          comment_text: autoPlugComment,
          shareURN: "",
          userId: user.userData.linkedinId,
          date: futureDate,
          tags: autoPlugTags,
        };
        // console.log({bodyPost});
        // Create auto-comment
        commentResponse = await postApi("auto-comment/create-auto-comment", bodyCommentPost);
        // console.log({commentResponse});
        fetchPreviousComment();
      }

      // const futureDate = new Date(`${scheduleDate}T${scheduleTime}`).toISOString();
      // console.log({futureDate});
      const getPostIdPrefix = () => {
        switch (post.postGenerationType) {
          case POST_GENERATION_TYPES.AI_CONTENT:
            return "ai";
          case POST_GENERATION_TYPES.DISCOVER:
            return "discover";
          case POST_GENERATION_TYPES.ON_THE_GO:
            return "otg";
          case POST_GENERATION_TYPES.CUSTOM:
          default:
            return "custom";
        }
      };

      // Prepare the post body
      const bodyPost = bodyPostHandler();
      bodyPost.postId = post.draftPostId?.split("_")[0] === "schedule" ? post.draftPostId : `${getPostIdPrefix()}_${uuidv4()}`;
      //  bodyPost.date_time = futureDate
      bodyPost.postTag = POSTTAG_CUSTOM;

      if (autoPlugComment && autoPlugDelay) {
        bodyPost.autoCommentId = commentResponse.data._id;
      }

      // console.log({schedulePost: bodyPost});
      try {
        // Upload media if necessary of image or video
        if (post.postType === "IMAGE" || post.postType === "VIDEO") {
          linkedinMediaUpload("");
        }

        if ((post.postType === "IMAGE" || post.postType === "VIDEO") && !post.media) {
          toast.warning("Please wait, Image is uploading...");
          dispatch(isLoading(true));
          //  return
        }

        // Get user's available time slots
        let utcTimeSlot = [];
        getApi(`schedule/get-auto-scheduler-slot/${user?.userData?.linkedinId}`).then(async (userTimeSlots) => {
          const currentDate = new Date().toISOString().split("T")[0];

          for (const slot of userTimeSlots.timeSlot) {
            const futureDate = new Date(`${currentDate}T${slot}`).toISOString();
            utcTimeSlot.push(futureDate?.split(".")[0]?.split("T")[1]);
          }

          bodyPost.utcTimeSlots = utcTimeSlot;

          // Schedule the post
          try {
            const response = await postApi("schedule/create-auto-schedule-post", bodyPost, {
              headers: {
                Authorization: `Bearer ${linkedinAccessToken}`,
                "Content-Type": "application/json",
              },
            });
            toast.success(response.message, { autoClose: 1000 });
            dispatch(clearPostState());
            localStorage.removeItem("threadData");
            cancelSchedulePost();
            dispatch(isLoading(false));
            deleteApi("draft/draft-post-delete", { postId: post.draftPostId })
              .then((res) => console.log("draft delete success"))
              .catch((err) => console.warn(`err in draft :${err}`));
          } catch (error) {
            dispatch(isLoading(false));
            dispatch(clearPostState());
            console.error("Error sending message:", error);
            toast.error(error.response.data.message);
          }
        });
      } catch (error) {
        dispatch(isLoading(false));
        dispatch(clearPostState());
        console.error("Error sending message:", error);
        toast.error(error.response.data.message);
      }
    }
  };

  const scheduleOnSelectedDate = async () => {
    if (post.date_time) {
      try {
        const bodyPost = bodyPostHandler();
        bodyPost.date_time = post.date_time;
        const getPostIdPrefix = () => {
          switch (post.postGenerationType) {
            case POST_GENERATION_TYPES.AI_CONTENT:
              return "ai";
            case POST_GENERATION_TYPES.DISCOVER:
              return "discover";
            case POST_GENERATION_TYPES.ON_THE_GO:
              return "otg";
            case POST_GENERATION_TYPES.CUSTOM:
            default:
              return "custom";
          }
        };
        bodyPost.postId = post.draftPostId?.split("_")[0] === "schedule" ? post.draftPostId : `${getPostIdPrefix()}_${uuidv4()}`;
        bodyPost.postTag = POSTTAG_CUSTOM;
        console.log(bodyPost);
        const response = await postApi("schedule/create-schedule-post", bodyPost, {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        });
        toast.success(response.message, { autoClose: 1000 });
        dispatch(clearPostState());
        localStorage.removeItem("threadData");
        cancelSchedulePost();
        dispatch(isLoading(false));
        deleteApi("draft/draft-post-delete", { postId: post.draftPostId })
          .then((res) => console.log("draft delete success"))
          .catch((err) => console.warn(`err in draft :${err}`));
        return;
      } catch (error) {
        dispatch(isLoading(false));
        dispatch(clearPostState());
        console.error("Error sending message:", error);
        toast.error(error.response.data.message);
        return;
      }
    }
  };
  const handleSaveDraft = async () => {
    await saveDraft();
    setIsDraftFilled(true);
  };

  // Select the any previour comment through tags of user
  const onCommentTagHandler = (tag) => {
    // Set the selected tag in state
    setAutoPlugTags(tag);

    // Filter the previous comments to find the comment with the selected tag
    const filterComment = prevComments.filter((item) => item.tags === tag);

    // Set the auto-plugged comment text to the found comment's text
    setAutoPlugComment(filterComment[0].comment_text);
  };

  // Emoji manupulate start

  // Here we have to find position to where the emoji is use so that our post is looks emoji enable post
  const textareaRef = useRef(null);
  const [emoji, setEmoji] = useState(false);
  const [text, setText] = useState(post.postContent);
  const [prevCursorPos, setPrevCorsorPos] = useState(null);
  // const [prevCursorPosCount, setPrevCorsorPosCount] = useState(0)
  const handleEmojiSelect = (emoji) => {
    let cursorPos;

    // If the previous cursor position is not set, get the current cursor position
    if (prevCursorPos === null) {
      cursorPos = textareaRef.current.selectionStart;
      setPrevCorsorPos(cursorPos); // Store the current cursor position
    } else {
      cursorPos = prevCursorPos + 2; // Move the cursor position to the end of the inserted emoji
    }

    // Get the text before and after the cursor position
    const textBeforeCursor = text.substring(0, cursorPos);
    const textAfterCursor = text.substring(cursorPos);

    // Insert the emoji into the text at the cursor position
    const updatedText = textBeforeCursor + emoji.native + textAfterCursor;

    // Update the state with the updated text
    setText(updatedText);

    // Dispatch an action to update the AI post in the store
    dispatch(addAIPost(updatedText));

    // Hide the emoji picker
    // setPickerVisible(false);
  };

  // This function handle to clear all the states or reset it
  const handlerClearAllState = () => {
    dispatch(clearPostState());
    setGetThread([]);
    setIsOnDist(false);
    setIsOnTweet(false);
    setIsOnFacebook(false);
    setIsOnInsta(false);
    setText("");
    setBtnCaroselPdf(false);
    localStorage.removeItem("threadData");
    setIsOnPlug(false);
    setAutoPlugComment("");
    setAutoPlugDelay(null);
    setPrevComments([]);
    setAutoPlugTags(null);
    setPickerVisible(false);
    setPrevCorsorPos(null);
  };
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = () => setShowDialog(false);
  const [showDialog1, setShowDialog1] = useState(false);
  const closeDialog1 = () => {
    setShowDialog1(false);
    setActive("upload");
    setSearch("");
  };

  const [selectedArray, setSelectedArray] = useState([]);

  const addToArray = (newItem) => {
    setSelectedArray([...selectedArray, newItem]);
  };

  const handleUploadSuccess = () => {
    console.log("Upload was successful");
    // Perform any necessary actions after successful upload
  };

  useEffect(() => {
    dispatch(setActiveComponent("main"));
  }, [dispatch]);

  const pushToUndoStack = (content) => {
    setUndoStack((prev) => [...prev, content]);
  };

  // For handling video posts
  const handleVideoPost = async () => {
    try {
      // Step 1: Initialize video upload
      const s3Key = `uploads/${post.originalUrl.split("/").pop()}`;
      const initResponse = await postApi(
        "linkedin/video-upload",
        {
          userId: linkedinId,
          s3Key: s3Key,
        },
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
            "LinkedIn-Version": "202307",
            "X-Restli-Protocol-Version": "2.0.0",
          },
        }
      );

      console.log("Init response:", initResponse.asset);

      // Step 2: Create LinkedIn post - simplified payload to match backend expectations
      const videoPostData = {
        userId: linkedinId,
        asset: initResponse.asset,
        description: post.postContent,
      };

      console.log("Sending to create post:", videoPostData);

      const postResponse = await postApi("linkedin/create-linkedin-post-video", videoPostData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "application/json",
          "LinkedIn-Version": "202307",
          "X-Restli-Protocol-Version": "2.0.0",
        },
      });

      console.log("Post response:", postResponse?.id);

      if (!postResponse?.data?.id) {
        throw new Error("Failed to create LinkedIn video post");
      }

      // Step 3: Save to database
      const postBody = bodyPostHandler();
      postBody.status = "PUBLISH";
      postBody.postUrn = postResponse.data.id;

      const res = await postApi("published/post-create", postBody);
      if (!res) {
        throw new Error("Error saving video post in database");
      }

      toast.success("Video Post Published Successfully!", { autoClose: 1000 });
      return postResponse.data.id;
    } catch (error) {
      console.error("Video post creation error:", error);
      toast.error(error.message || "Failed to publish video post");
      throw error;
    }
  };

  const [showPollModal, setShowPollModal] = useState(false);
  const [pollQuestion, setPollQuestion] = useState("");
  const [pollOptions, setPollOptions] = useState(["", ""]);
  const [pollDuration, setPollDuration] = useState("1");

  const handleSwitchOfPoll = () => {
    if (!post.postContent.trim()) {
      toast.warning("Please write some content before creating a poll");
      return;
    }
    setShowPollModal(true);
  };

  const [pollData, setPollData] = useState(null);

  const handlePollPost = async () => {
    try {
      if (!pollData) {
        throw new Error("Poll data not found");
      }

      const durationMap = {
        1: "ONE_DAY",
        3: "THREE_DAYS",
        7: "SEVEN_DAYS",
        14: "FOURTEEN_DAYS",
      };

      const pollRequestData = {
        userId: linkedinId,
        question: pollData.question,
        options: pollData.options,
        commentary: post.postContent,
        duration: durationMap[pollData.duration] || "ONE_DAY",
      };

      // Create poll post
      const response = await postApi("linkedin/create-poll", pollRequestData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.success) {
        // Save to database
        const postBody = bodyPostHandler();
        postBody.status = "PUBLISH";
        postBody.postUrn = response.pollId;
        postBody.pollData = pollData;

        const res = await postApi("published/post-create", postBody);
        if (!res) {
          throw new Error("Error saving poll post in database");
        }

        // Clear everything only after successful post
        setPollData(null);
        setPollQuestion("");
        setPollOptions(["", "", "", ""]);
        setPollDuration("1");
        dispatch(changePostType("NONE"));
        dispatch(clearPostState());
        setShowPollPreviewModal(false);
        setShowPollModal(false);

        // Clear the post content
        dispatch({
          type: "post/setPostContent",
          payload: "",
        });

        toast.success("Poll Published Successfully!", { autoClose: 1000 });
        return response.pollId;
      } else {
        throw new Error(response?.message || "Failed to create LinkedIn poll");
      }
    } catch (error) {
      console.error("Poll post creation error:", error);
      toast.error(error.message || "Failed to publish poll");
      throw error;
    }
  };

  return (
    <>
      <div className="w-[100%] p-2">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold m-0">Create Posts</h3>
          <div>
            <select
              className={`${common.isDark ? "auto-plug-hr-light" : "auto-plug-hr-dark"} p-2 rounded-lg`}
              style={{ color: "white", width: "10rem", fontSize: "13px" }}
              id="exampleFormControlSelect1"
              value={post.connectionType}
              onChange={(e) => dispatch(changeConnectionType(e.target.value))}
            >
              <option value={"PUBLIC"}>Public</option>
              <option value={"CONNECTIONS"}>Connections</option>
            </select>
          </div>
        </div>

        <div className="form-group mb-2">
          <div className="post-icon-btn flex flex-wrap gap-1 mb-2">
            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.postContent;

                // saving current state to undo stack before making changes
                pushToUndoStack(text);

                const selectedText = text.substring(start, end);
                // Map for bold characters
                const boldMap = {
                  a: "𝗮",
                  b: "𝗯",
                  c: "𝗰",
                  d: "𝗱",
                  e: "𝗲",
                  f: "𝗳",
                  g: "𝗴",
                  h: "𝗵",
                  i: "𝗶",
                  j: "𝗷",
                  k: "𝗸",
                  l: "𝗹",
                  m: "𝗺",
                  n: "𝗻",
                  o: "𝗼",
                  p: "𝗽",
                  q: "𝗾",
                  r: "𝗿",
                  s: "𝘀",
                  t: "𝘁",
                  u: "𝘂",
                  v: "𝘃",
                  w: "𝘄",
                  x: "𝘅",
                  y: "𝘆",
                  z: "𝘇",
                  A: "𝗔",
                  B: "𝗕",
                  C: "𝗖",
                  D: "𝗗",
                  E: "𝗘",
                  F: "𝗙",
                  G: "𝗚",
                  H: "𝗛",
                  I: "𝗜",
                  J: "𝗝",
                  K: "𝗞",
                  L: "𝗟",
                  M: "𝗠",
                  N: "𝗡",
                  O: "𝗢",
                  P: "𝗣",
                  Q: "𝗤",
                  R: "𝗥",
                  S: "𝗦",
                  T: "𝗧",
                  U: "𝗨",
                  V: "𝗩",
                  W: "𝗪",
                  X: "𝗫",
                  Y: "𝗬",
                  Z: "𝗭",
                  0: "𝟬",
                  1: "𝟭",
                  2: "𝟮",
                  3: "𝟯",
                  4: "𝟰",
                  5: "𝟱",
                  6: "𝟲",
                  7: "𝟳",
                  8: "𝟴",
                  9: "𝟵",
                };

                const newText =
                  text.substring(0, start) +
                  selectedText
                    .split("")
                    .map((char) => boldMap[char] || char)
                    .join("") +
                  text.substring(end);
                dispatch(addAIPost(newText));
              }}
              title="Bold"
            >
              <i className="fa-solid fa-bold text-xl text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.postContent;

                // saving current state to undo stack before making any changes
                pushToUndoStack(text);

                const selectedText = text.substring(start, end);
                // Map for italic characters
                const italicMap = {
                  a: "𝘢",
                  b: "𝘣",
                  c: "𝘤",
                  d: "𝘥",
                  e: "𝘦",
                  f: "𝘧",
                  g: "𝘨",
                  h: "𝘩",
                  i: "𝘪",
                  j: "𝘫",
                  k: "𝘬",
                  l: "𝘭",
                  m: "𝘮",
                  n: "𝘯",
                  o: "𝘰",
                  p: "𝘱",
                  q: "𝘲",
                  r: "𝘳",
                  s: "𝘴",
                  t: "𝘵",
                  u: "𝘶",
                  v: "𝘷",
                  w: "𝘸",
                  x: "𝘹",
                  y: "𝘺",
                  z: "𝘻",
                  A: "𝘈",
                  B: "𝘉",
                  C: "𝘊",
                  D: "𝘋",
                  E: "𝘌",
                  F: "𝘍",
                  G: "𝘎",
                  H: "𝘏",
                  I: "𝘐",
                  J: "𝘑",
                  K: "𝘒",
                  L: "𝘓",
                  M: "𝘔",
                  N: "𝘕",
                  O: "𝘖",
                  P: "𝘗",
                  Q: "𝘘",
                  R: "𝘙",
                  S: "𝘚",
                  T: "𝘛",
                  U: "𝘜",
                  V: "𝘝",
                  W: "𝘞",
                  X: "𝘟",
                  Y: "𝘠",
                  Z: "𝘡",
                };

                const newText =
                  text.substring(0, start) +
                  selectedText
                    .split("")
                    .map((char) => italicMap[char] || char)
                    .join("") +
                  text.substring(end);
                dispatch(addAIPost(newText));
              }}
              title="Italic"
            >
              <i className="fa-solid fa-italic text-xl text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.postContent;

                // Saving current state to undo stack before making any changes
                pushToUndoStack(text);

                const selectedText = text.substring(start, end);

                // Include the space at the end if it exists
                const adjustedEnd = selectedText.endsWith(" ") ? end : end + 1;
                const adjustedSelectedText = text.substring(start, adjustedEnd);

                // Check if text already has underline
                const hasUnderline = adjustedSelectedText.split("").some((char) => char.includes("\u0332"));

                const newText =
                  text.substring(0, start) +
                  adjustedSelectedText
                    .split("")
                    .map((char) => {
                      if (hasUnderline) {
                        // Remove underline if present
                        return char.replace("\u0332", "");
                      } else {
                        // Add underline if not present
                        return char + "\u0332";
                      }
                    })
                    .join("") +
                  text.substring(adjustedEnd);
                dispatch(addAIPost(newText));
              }}
              title="Underline"
            >
              <i className="fa-solid fa-underline text-xl text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.postContent;

                // saving current state to undo stack before making any changes
                pushToUndoStack(text);

                const selectedText = text.substring(start, end);

                // Include the space at the end if it exists
                const adjustedEnd = selectedText.endsWith(" ") ? end : end + 1;
                const adjustedSelectedText = text.substring(start, adjustedEnd);

                // Check if text already has strikethrough
                const hasStrikethrough = adjustedSelectedText.split("").every((char) => char.includes("\u0336"));

                const newText =
                  text.substring(0, start) +
                  adjustedSelectedText
                    .split("")
                    .map((char) => {
                      if (hasStrikethrough) {
                        // Remove strikethrough if present
                        return char.replace(/̷/g, "");
                      } else {
                        // Add strikethrough if not present
                        return char + "̷";
                      }
                    })
                    .join("") +
                  text.substring(adjustedEnd);
                dispatch(addAIPost(newText));
              }}
              title="Strikethrough"
            >
              <i className="fa-solid fa-strikethrough text-xl text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.postContent;

                // Saving current state to undo stack before making any changes
                pushToUndoStack(text);

                const selectedText = text.substring(start, end);

                // Process text to remove all formatting
                let normalText = selectedText;

                // Remove bold characters
                const boldChars = {
                  "𝗮": "a",
                  "𝗯": "b",
                  "𝗰": "c",
                  "𝗱": "d",
                  "𝗲": "e",
                  "𝗳": "f",
                  "𝗴": "g",
                  "𝗵": "h",
                  "𝗶": "i",
                  "𝗷": "j",
                  "𝗸": "k",
                  "𝗹": "l",
                  "𝗺": "m",
                  "𝗻": "n",
                  "𝗼": "o",
                  "𝗽": "p",
                  "𝗾": "q",
                  "𝗿": "r",
                  "𝘀": "s",
                  "𝘁": "t",
                  "𝘂": "u",
                  "𝘃": "v",
                  "𝘄": "w",
                  "𝘅": "x",
                  "𝘆": "y",
                  "𝘇": "z",
                  "𝗔": "A",
                  "𝗕": "B",
                  "𝗖": "C",
                  "𝗗": "D",
                  "𝗘": "E",
                  "𝗙": "F",
                  "𝗚": "G",
                  "𝗛": "H",
                  "𝗜": "I",
                  "𝗝": "J",
                  "𝗞": "K",
                  "𝗟": "L",
                  "𝗠": "M",
                  "𝗡": "N",
                  "𝗢": "O",
                  "𝗣": "P",
                  "𝗤": "Q",
                  "𝗥": "R",
                  "𝗦": "S",
                  "𝗧": "T",
                  "𝗨": "U",
                  "𝗩": "V",
                  "𝗪": "W",
                  "𝗫": "X",
                  "𝗬": "Y",
                  "𝗭": "Z",
                };

                // Remove italic characters
                const italicChars = {
                  "𝘢": "a",
                  "𝘣": "b",
                  "𝘤": "c",
                  "𝘥": "d",
                  "𝘦": "e",
                  "𝘧": "f",
                  "𝘨": "g",
                  "𝘩": "h",
                  "𝘪": "i",
                  "𝘫": "j",
                  "𝘬": "k",
                  "𝘭": "l",
                  "𝘮": "m",
                  "𝘯": "n",
                  "𝘰": "o",
                  "𝘱": "p",
                  "𝘲": "q",
                  "𝘳": "r",
                  "𝘴": "s",
                  "𝘵": "t",
                  "𝘶": "u",
                  "𝘷": "v",
                  "𝘸": "w",
                  "𝘹": "x",
                  "𝘺": "y",
                  "𝘻": "z",
                  "𝘈": "A",
                  "𝘉": "B",
                  "𝘊": "C",
                  "𝘋": "D",
                  "𝘌": "E",
                  "𝘍": "F",
                  "𝘎": "G",
                  "𝘏": "H",
                  "𝘐": "I",
                  "𝘑": "J",
                  "𝘒": "K",
                  "𝘓": "L",
                  "𝘔": "M",
                  "𝘕": "N",
                  "𝘖": "O",
                  "𝘗": "P",
                  "𝘘": "Q",
                  "𝘙": "R",
                  "𝘚": "S",
                  "𝘛": "T",
                  "𝘜": "U",
                  "𝘝": "V",
                  "𝘞": "W",
                  "𝘟": "X",
                  "𝘠": "Y",
                  "𝘡": "Z",
                };

                // Replace each character
                for (const [formatted, normal] of Object.entries(boldChars)) {
                  normalText = normalText.split(formatted).join(normal);
                }
                for (const [formatted, normal] of Object.entries(italicChars)) {
                  normalText = normalText.split(formatted).join(normal);
                }

                // Remove underline and strikethrough
                normalText = normalText.replace(/[\u0332\u0336\u0337\u0338̷]/g, "");

                const newText = text.substring(0, start) + normalText + text.substring(end);
                dispatch(addAIPost(newText));
              }}
              title="Clear Formatting"
            >
              <i className="fa-solid fa-remove-format text-xl text-gray-600"></i>
            </button>

            <button
              className="p-1 rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.postContent;

                // Saving current state to undo stack before making any changes
                pushToUndoStack(text);

                const selectedText = text.substring(start, end);

                // Check if the selected text ends with a space
                if (selectedText.endsWith(" ")) {
                  return;
                }

                // Get the content after the selected text
                const contentAfter = text.substring(end);
                const paragraphs = contentAfter.split("\n\n").filter(Boolean);

                // Check if text already has bullet points
                const hasBulletPoints = paragraphs.some((line) => line.trim().startsWith("●"));

                if (hasBulletPoints) {
                  // Remove bullet points
                  const cleanContent = paragraphs
                    .map((line) => {
                      if (line.trim().startsWith("#")) {
                        return line;
                      }
                      return line.trim().replace(/^●\s*/, "");
                    })
                    .filter(Boolean)
                    .join("\n\n");

                  const newText = text.substring(0, start) + selectedText + (cleanContent.length > 0 ? "\n\n" + cleanContent : "");
                  dispatch(addAIPost(newText.replace(/^\n+/, "")));
                } else {
                  // Add bullet points
                  const bulletPointContent = paragraphs
                    .map((line) => {
                      if (line.trim().startsWith("#")) {
                        return line;
                      }
                      return line.trim() ? "●  " + line.trim() : "";
                    })
                    .filter(Boolean)
                    .join("\n\n");

                  const newText = text.substring(0, start) + selectedText + (bulletPointContent.length > 0 ? "\n\n" + bulletPointContent : "");
                  dispatch(addAIPost(newText.replace(/^\n+/, "")));
                }
              }}
              title="Bullet Points"
            >
              <i className="fa-solid fa-list-ul text-xl text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={(e) => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.postContent;

                // Saving current state to undo stack before making any changes
                pushToUndoStack(text);

                const selectedText = text.substring(start, end);

                // Check if it's a double click
                if (e.detail === 2) {
                  // Remove numbering from selected text
                  const unnumberedContent = selectedText
                    .split("\n\n")
                    .map((line) => line.replace(/^\d+\)\s+/, ""))
                    .join("\n\n");

                  const newText = text.substring(0, start) + unnumberedContent + text.substring(end);
                  dispatch(addAIPost(newText));
                  return;
                }

                // Single click - add numbering to selected text
                // Check if the selected text ends with a space
                if (selectedText.endsWith(" ")) {
                  return; // Do not add numbering if selected text ends with a space
                }

                // Split selected text into paragraphs
                const paragraphs = selectedText.split("\n\n").filter(Boolean);

                // Add numbering to paragraphs, skipping hashtags
                const numberedContent = paragraphs
                  .map((line, index) => {
                    // Check if the line contains a hashtag
                    if (line.trim().startsWith("#")) {
                      return line; // Return the line unchanged if it starts with a hashtag
                    }
                    return `${index + 1})  ${line.trim()}`; // Add numbering otherwise
                  })
                  .filter(Boolean)
                  .join("\n\n");

                // Combine the text with numbered selected content
                const newText = text.substring(0, start) + numberedContent + text.substring(end);
                dispatch(addAIPost(newText));
              }}
              title="Numbering (Double-click to remove)"
            >
              <i className="fa-solid fa-list-ol text-xl text-gray-600"></i>
            </button>
          </div>

          <textarea
            ref={textareaRef}
            className={`form-control w-full min-h-[120px] text-[1rem] resize-y outline-none p-3 rounded-lg mb-2 ${isDark ? "post-textarea-light" : "post-textarea-dark"}`}
            style={{
              border: "1px solid #e5e7eb",
              backgroundColor: isDark ? "#ffffff" : "#1a1a1a",
              color: isDark ? "#000" : "#fff",
              height: "auto",
              minHeight: "300px",
              maxHeight: "300px",
              lineHeight: "1.5",
              overflowY: "auto",
              fontSize: "13px",
            }}
            placeholder="Write your post here..."
            value={post.postContent}
            onClick={() => {
              setPickerVisible(false);
              setPrevCorsorPos(null);
            }}
            onChange={(e) => {
              let currentText = e.target.value;
              currentText = currentText.replace(/^\n+/, "");
              setText(currentText);
              onPostDescriptionHandler({
                ...e,
                target: { ...e.target, value: currentText },
              });
            }}
            onKeyDown={(e) => {
              // This will handle the Shift+Enter
              if (e.key === "Enter" && e.shiftKey) {
                e.preventDefault();
                const { selectionStart, selectionEnd } = e.target;
                const newValue = post.postContent.substring(0, selectionStart) + "\n" + post.postContent.substring(selectionEnd);
                dispatch(addAIPost(newValue));
                // Setting cursor position after update
                setTimeout(() => {
                  e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
                }, 0);
              }

              // This will handle the Ctrl+Z or Cmd+Z
              if ((e.ctrlKey || e.metaKey) && e.key === "z") {
                e.preventDefault();
                if (undoStack.length > 0) {
                  const prevContent = undoStack[undoStack.length - 1];
                  dispatch(addAIPost(prevContent));
                  setUndoStack((prev) => prev.slice(0, -1));
                }
              }
            }}
          />
        </div>

        <div className="flex flex-wrap justify-between items-center gap-4 mb-2">
          <div className="post-icon-btn flex flex-wrap">
            <button className="rounded-lg hover:bg-gray-100 transition-colors" onClick={() => setPickerVisible(!isPickerVisible)} title="Emoji">
              <i className="fa-regular fa-face-smile text-lg text-gray-600"></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={mediaPanelHander} title="Media">
              <i className="fa-regular fa-images text-lg text-gray-600"></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={addURLHandler} title="URL">
              <i className="fa-solid fa-link text-lg text-gray-600"></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={copyPasteHandler} title="Copy">
              <i className="fa-regular fa-copy text-lg text-gray-600"></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={handleSaveDraft} title={isDraftFilled ? "Draft Saved" : "Save Draft"}>
              <i className={`fa-${isDraftFilled ? "solid" : "regular"} fa-file-lines text-lg text-gray-600`}></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={onEvergreenHnadler} title="Evergreen">
              <i className={`fa-${isStarFilled ? "solid" : "regular"} fa-star text-lg text-gray-600`}></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={onBookmarkHnadler} title="Bookmark">
              <i className={`fa-${isFilled ? "solid" : "regular"} fa-bookmark text-lg text-gray-600`}></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={onRewriteHandler} title="Re-write">
              <i className="fa-solid fa-rotate text-lg text-gray-600"></i>
            </button>
            <button className="p-1 rounded-lg hover:bg-gray-100 transition-colors" onClick={handlerClearAllState} title="Clear">
              <i className="fa-solid fa-trash text-lg text-red-500"></i>
            </button>
          </div>
        </div>

        {isPickerVisible && (
          <div className="emoji-picker absolute z-50">
            <Picker data={emojiData} previewPosition={"none"} onEmojiSelect={handleEmojiSelect} className="emojiTray" />
          </div>
        )}

        {mediaPanel && (
          <div className={`autoPlug-Panel ${common.isDark ? "schedule-panel-light" : "schedule-panel-dark"}`}>
            <div className={`media-date-picker ${common.isDark ? "" : "schedule-dark"}`}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <i className={`fa-solid fa-xmark cursor-pointer ${common.isDark ? "" : "fa-xmark-dark"}`} onClick={() => setMediaPanel(false)}></i>
              </div>
              <Media isPreview={true} setMediaPanel={setMediaPanel} onUploadSuccess={handleUploadSuccess} />
            </div>
          </div>
        )}

        {urlPanel && (
          <div className="form-group url-link mb-2">
            <input className="form-control" typeof="text" placeholder="Article Link / URL" value={post.originalUrl} onChange={(e) => dispatch(addOriginalUrl(e.target.value))} />
            <i className="fa-solid fa-xmark" onClick={cancelURLPost} style={common.isDark ? { color: "#000" } : { color: "#fff" }}></i>
          </div>
        )}

        <div className="button-box">
          <button className={`${common.isDark ? "publish-post-light" : "publish-post-dark"} p-2 gradientBorderFinal`} onClick={publishPostHandler}>
            Publish Post
          </button>

          {/* <button className="btn grediant-button schedule-post" onClick={schedulePostHander}>Schedule Post</button> */}

          <div className="btn-group">
            <button className="gradient-button-new text-white p-2 px-3" style={{ borderRadius: "6px 0 0 6px", border: "1px solid gray" }} onClick={autoSchedulerHandler}>
              Auto-Schedule
            </button>
            <button className="btn btn-secondary dropdown-toggle dropdown-toggle-split" style={{ backgroundColor: "#39B2FF" }} id="dropdownMenuReference" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu create-post-page grediant-button" aria-labelledby="dropdownMenuReference" style={{ cursor: "pointer", padding: 0, marginTop: "-1rem" }}>
              <li
                onClick={schedulePostHander}
                className="flex justify-center items-center gradient-button-new p-2"
                style={{
                  fontSize: "0.8rem",
                  cursor: "pointer",
                  borderRadius: "6px",
                  color: "white",
                }}
              >
                Custom-Schedule
              </li>
              {/* {post.date_time && (
                <li
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    marginTop: "0.5rem",
                  }}
                  onClick={scheduleOnSelectedDate}
                >
                  Save/Edit
                </li>
              )} */}
            </ul>
          </div>
        </div>

        {/* {(planName!='Free-tier' && planName !=='Pro')&&( */}
        <div className="automation-box">
          <h3 className="text-lg">Automation</h3>
          <div className="row">
            <div className="col-lg-12">
              <div className="general-item-switch">
                <h3 className="text-lg">Create Poll Post</h3>
                <div className="switch" onClick={handleSwitchOfPoll} style={{ marginRight: "1.3rem" }}>
                  <span className={showPollModal ? "black" : "white"}></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="general-item-switch">
                <h3 className="text-lg">Auto Plug</h3>
                <div className="switch" onClick={handleSwitchOfPlug} style={{ marginRight: "1.3rem" }}>
                  <span className={isOnPlug ? "black" : "white"}></span>
                </div>
              </div>
            </div>
          </div>
          {post.postType !== "PDF" && (
            <div className="row">
              {/* <div className="col-lg-12">
                <div className="general-item-switch">
                  <h3 className="text-lg">Auto Distribute</h3>
                  <div
                    className="switch"
                    onClick={handleSwitchOfDistribution}
                    style={{ marginRight: "1.3rem" }}
                  >
                    <span className={isOnDist ? "black" : "white"}></span>
                  </div>
                </div>
              </div> */}
            </div>
          )}
          {seeOptions && (
            <div className={`row ${isOnFacebook && "igcontainer"}`}>
              {post.postType === "NONE" && (
                <div className="col-lg-12 mb-3">
                  <div className="general-item-switch">
                    {common.isDark ? <img src={`${IMAGE_URL}/images/socialIcon/tweeter-light.png`} alt="" /> : <img src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`} alt="" />}
                    <div className="switch" onClick={handleSwichsOfTweet} style={{ marginRight: "1.3rem" }}>
                      <span className={isOnTweet ? "black" : "white"}></span>
                    </div>
                  </div>
                </div>
              )}
              {post.postType === "NONE" && (
                <div className="col-lg-12 mb-3">
                  <div className="general-item-switch">
                    <img src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} alt="" />
                    <div className="switch" onClick={handleSwichsOfFacebook} style={{ marginRight: "1.3rem" }}>
                      <span className={isOnFacebook ? "black" : "white"}></span>
                    </div>
                  </div>
                  {/* {isOnFacebook ? (
                  fbPageList?.fbConnectedAc?.length > 0 ? (
                    fbPageList?.fbConnectedAc?.map((item, index) => {
                      return (
                        <div key={index} className="fb-list">
                          <p
                            className={`mb-0 ${
                              common.isDark ? "" : "text-white"
                            }`}
                          >
                            {index + 1}. {item.name}
                          </p>
                          <input
                            type="checkbox"
                            name="fb_page"
                            value={index}
                            // onClick={() => setGetFbPageId(prev => [...prev, item?.id])}
                            onChange={(e) => handleCheckboxChangefb(e, item)}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <p>You don't have facebook Business Pages</p>
                    </>
                  )
                ) : (
                  ""
                )} */}
                </div>
              )}
              {post.postType === "NONE" && (
                <div className={`col-lg-12 mb-5 pb-4  `}>
                  <div className="general-item-switch">
                    <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} alt="" />
                    <div className="switch" onClick={handleSwichsOfInsta} style={{ marginRight: "1.3rem" }}>
                      <span className={isOnInsta ? "black" : "white"}></span>
                    </div>
                  </div>
                  {/* {isOnInsta
                    ? fbPageList?.igConnectedAc?.length > 0
                      ? fbPageList?.igConnectedAc?.map((item, index) => {
                          return (
                            <div key={index} className="fb-list">
                              <p
                                className={`mb-0 ${
                                  common.isDark ? "" : "text-white"
                                }`}
                              >
                                {index + 1}.{" "}
                                {item?.instagram_business_account?.name}
                              </p>
                              <input
                                type="checkbox"
                                name="ig_page"
                                value={index}
                                // onClick={() => setGetIG(prev => [...prev, item])}
                                onChange={(e) =>
                                  handleCheckboxChangeig(e, item)
                                }
                              />
                            </div>
                          );
                        })
                      : "Account Not Connected"
                    : ""} */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {schedulePanelOpen && (
        <Modal show={schedulePanelOpen} onHide={() => setSchedulePanelOpen(false)} centered backdrop="static" keyboard={false} className={isDark ? " " : "isdark-model"}>
          <Modal.Header closeButton>
            <Modal.Title className={`${isDark ? "text-black" : "text-white"}`}>Schedule Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={`schedule-text ${isDark ? "text-dark" : " text-white"}`}>Schedule your post at the optimal times to engage with more of your customers or manually select a date and time in the future to publish your post.</p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Select date for Schedule</Form.Label>
                <Form.Control
                  type="date"
                  value={scheduleDate}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const currentDate = new Date().toISOString().split("T")[0];
                    if (e.target.value >= currentDate) {
                      setScheduleDate(e.target.value);
                    } else {
                      toast.error("Please select current or future date");
                    }
                  }}
                  className={isDark ? "bg-light text-dark" : "bg-dark text-white"}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Select time for Schedule</Form.Label>
                <Form.Control type="time" value={scheduleTime} onClick={(e) => e.stopPropagation()} onChange={(e) => setScheduleTime(e.target.value)} className={isDark ? "bg-light text-dark" : "bg-dark text-white"} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={isDark ? "light" : "secondary"} onClick={() => setSchedulePanelOpen(false)}>
              Cancel
            </Button>
            <Button className="gradient-button-new" onClick={onScheduleConfirmHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {autoPlugOpen && (
        <Modal show={autoPlugOpen} onHide={() => setAutoPlugOpen(false)} centered backdrop="static" keyboard={false} className={isDark ? "" : "isdark-model"}>
          <Modal.Header closeButton>
            <Modal.Title className={`${isDark ? "text-black" : "text-white"}`}>Auto Plug</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={`schedule-text ${isDark ? "text-dark" : "text-white"}`}>Configure when and how your content will be automatically plugged</p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Select delay time</Form.Label>
                <Form.Control
                  type="time"
                  value={autoPlugDelay}
                  onClick={(e) => {
                    e.target.showPicker();
                    setHidden({
                      ...hidden,
                      date: "none",
                    });
                  }}
                  onBlur={(e) => {
                    setHidden({
                      ...hidden,
                      date: "block",
                    });
                  }}
                  onChange={(e) => setAutoPlugDelay(e.target.value)}
                  className={isDark ? "bg-light text-dark" : "bg-dark text-white"}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Previous Plugs</Form.Label>
                <Form.Select onChange={(e) => onCommentTagHandler(e.target.value)} className={isDark ? "bg-light text-dark" : "bg-dark text-white"}>
                  <option>Select Previous Plugs</option>
                  {prevComments?.map((comment, i) => (
                    <option key={i}>{comment.tags}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Tags</Form.Label>
                <Form.Control type="text" placeholder="Type your tags" value={autoPlugTags} onChange={(e) => setAutoPlugTags(e.target.value)} className={isDark ? "bg-light text-dark" : "bg-dark text-white"} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Comment</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Type your comment to your post" value={autoPlugComment} onChange={(e) => setAutoPlugComment(e.target.value)} className={isDark ? "bg-light text-dark" : "bg-dark text-white"} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={isDark ? "light" : "secondary"} onClick={() => setAutoPlugOpen(false)}>
              Cancel
            </Button>
            <Button className="gradient-button-new" onClick={onAutoPlugHandler}>
              Create Plug
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* The above code is a snippet of JavaScript React code that is responsible for rendering a modal
      for creating a poll. */}

      {showPollModal && (
        <Modal show={showPollModal} onHide={() => setShowPollModal(false)} centered backdrop="static" keyboard={false} className={isDark ? "" : "isdark-model"}>
          <Modal.Header closeButton>
            <Modal.Title className={`${isDark ? "text-black" : "text-white"}`}>Create Poll</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-4">
                <Form.Label className={`${isDark ? "text-dark" : "text-white"} font-semibold`}>Poll Question</Form.Label>
                <Form.Control type="text" placeholder="What would you like to ask?" value={pollQuestion} onChange={(e) => setPollQuestion(e.target.value)} className={`${isDark ? "bg-light text-dark" : "bg-dark text-white"} p-2.5`} maxLength={100} />
                <small className={`${isDark ? "text-muted" : "text-gray-400"} mt-1 block`}>{pollQuestion.length}/100 characters</small>
              </Form.Group>

              <div className="mb-4">
                <Form.Label className={`${isDark ? "text-dark" : "text-white"} font-semibold`}>Poll Options</Form.Label>
                {pollOptions.map((option, index) => (
                  <Form.Group key={index} className="mb-2">
                    <div className="d-flex gap-2 align-items-center">
                      <div className="flex-grow-1">
                        <Form.Control
                          type="text"
                          placeholder={`Option ${index + 1}`}
                          value={option}
                          onChange={(e) => {
                            const newOptions = [...pollOptions];
                            newOptions[index] = e.target.value;
                            setPollOptions(newOptions);
                          }}
                          className={`${isDark ? "bg-light text-dark" : "bg-dark text-white"} p-2`}
                          maxLength={30}
                        />
                        <small className={`${isDark ? "text-muted" : "text-gray-400"} mt-1 block`}>{option.length}/30 characters</small>
                      </div>
                      {index > 1 && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="d-flex align-items-center justify-content-center"
                          style={{ minWidth: "40px", height: "40px", marginBottom: "35px" }}
                          onClick={() => {
                            const newOptions = pollOptions.filter((_, i) => i !== index);
                            setPollOptions(newOptions);
                          }}
                        >
                          <i className="fa-solid fa-trash-alt"></i>
                        </Button>
                      )}
                    </div>
                  </Form.Group>
                ))}
              </div>

              {pollOptions.length < 4 && (
                <Button variant={isDark ? "outline-dark" : "outline-light"} className="mb-4 w-100" onClick={() => setPollOptions([...pollOptions, ""])}>
                  <i className="fa-solid fa-plus me-2"></i>
                  Add Option ({pollOptions.length}/4)
                </Button>
              )}

              <Form.Group className="mb-4">
                <Form.Label className={`${isDark ? "text-dark" : "text-white"} font-semibold`}>Poll Duration</Form.Label>
                <Form.Select value={pollDuration} onChange={(e) => setPollDuration(e.target.value)} className={`${isDark ? "bg-light text-dark" : "bg-dark text-white"} p-2`}>
                  <option value="1">1 day</option>
                  <option value="3">3 days</option>
                  <option value="7">1 week</option>
                  <option value="14">2 weeks</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowPollModal(false)}>
              Edit Post
            </Button>
            <Button
              className="gradient-button-new"
              onClick={() => {
                // Enhanced validation
                if (!pollQuestion.trim()) {
                  toast.error("Please enter a poll question");
                  return;
                }

                const validOptions = pollOptions.filter((opt) => opt.trim());
                if (validOptions.length < 2) {
                  toast.error("Please enter at least 2 options");
                  return;
                }

                if (validOptions.length !== new Set(validOptions).size) {
                  toast.error("Poll options must be unique");
                  return;
                }

                // Store poll data
                const newPollData = {
                  question: pollQuestion.trim(),
                  options: validOptions,
                  duration: pollDuration,
                };

                // Set post type to POLL and store poll data
                dispatch(changePostType("POLL"));
                setPollData(newPollData);

                // Close first modal and show preview modal
                setShowPollModal(false);
                setShowPollPreviewModal(true);
              }}
            >
              Create Poll
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showPollPreviewModal && pollData && (
        <Modal
          show={showPollPreviewModal}
          onHide={() => {
            setShowPollPreviewModal(false);
            setShowPollModal(true);
          }}
          centered
          backdrop="static"
          keyboard={false}
          className={`poll-preview-modal ${isDark ? "dark-mode" : ""}`}
          size="base"
        >
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-xl font-semibold">
              Poll Preview
              <p className="text-sm text-gray-500 font-normal mt-1">Preview how your poll will appear on LinkedIn</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="px-4">
            <div className={`poll-preview-container rounded-lg ${isDark ? "bg-white" : "bg-white"} shadow-sm border`}>
              {/* Profile Section */}
              <div className="p-4 border-b border-gray-200">
                <div className="flex items-start justify-between">
                  <div className="flex gap-3">
                    <div className="relative">
                      <img src={user?.userPhoto || `${IMAGE_URL}/images/Icons/avatar.jpg`} className="w-12 h-12 rounded-full border object-cover" alt="Profile" />
                      {/* <div className="absolute -bottom-1 -right-1 w-5 h-5 bg-blue-600 rounded-full flex items-center justify-center">
                        <i className="fas fa-check text-white text-xs"></i>
                      </div> */}
                    </div>

                    <div className="flex flex-col">
                      <span className="font-semibold text-[15px] hover:text-blue-600 cursor-pointer transition-colors">{user?.userData?.userName?.length > 30 ? `${user?.userData?.userName.slice(0, 30)}...` : user?.userData?.userName}</span>
                      <span className="text-[13px] text-gray-600 leading-snug">{user?.userData?.localizedHeadline?.length > 30 ? `${user?.userData?.localizedHeadline.slice(0, 30)}...` : user?.userData?.localizedHeadline}</span>
                      <div className="flex items-center gap-1.5 mt-0.5 text-gray-500">
                        <span className="text-[12px]">Just now</span>
                        <span className="text-[3px]">●</span>
                        <i className="fa-solid fa-earth-americas text-[11px]"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <i className="fa-brands fa-linkedin text-[#0a66c2] text-xl"></i>
                  </div>
                </div>
              </div>

              {/* Post Content */}
              {post.postContent && (
                <div className="px-4 py-3">
                  <PostContent content={post.postContent} />
                </div>
              )}

              {/* Poll Container */}
              <div className="p-4">
                <div className={`rounded-lg p-4 ${isDark ? "bg-white" : "bg-white"} border border-gray-200`}>
                  <h5 className={`font-semibold text-[16px] mb-4 text-black`}>{pollData.question}</h5>

                  <div className="space-y-2.5">
                    {pollData.options.map((option, index) => (
                      <div
                        key={index}
                        className={`poll-option relative 
                          ${isDark ? "bg-gray-100 hover:bg-gray-200" : "bg-white hover:bg-blue-50"}
                          p-3 rounded-lg cursor-pointer transition-all duration-200
                          border border-gray-200 hover:border-blue-500 group`}
                        onClick={() => {
                          const elements = document.querySelectorAll(".poll-option");
                          elements.forEach((el) => el.classList.remove("selected"));
                          elements[index].classList.add("selected");
                        }}
                      >
                        <div className="flex items-center gap-3">
                          <div
                            className={`w-5 h-5 rounded-full border-2 
                            ${isDark ? "border-gray-500" : "border-gray-300"}
                            group-hover:border-blue-500 flex items-center justify-center
                            group-[.selected]:border-blue-500 transition-colors`}
                          >
                            <div
                              className="w-2.5 h-2.5 rounded-full bg-blue-500 opacity-0 
                              group-[.selected]:opacity-100 transition-opacity"
                            ></div>
                          </div>
                          <span
                            className={`text-[14px] leading-tight 
                            ${isDark ? "text-gray-800" : "text-gray-800"}`}
                          >
                            {option}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="mt-4 flex items-center gap-2">
                    <i className="far fa-clock text-gray-500"></i>
                    <span className={`text-[13px] ${isDark ? "text-gray-400" : "text-gray-500"}`}>
                      Poll duration: {pollData.duration} {pollData.duration === "1" ? "day" : "days"}
                    </span>
                  </div>
                </div>
              </div>

              {/* Social Actions */}
              <div className="grid grid-cols-4 border-t border-gray-200 mt-2">
                {[
                  { icon: "fa-regular fa-thumbs-up", text: "Like" },
                  { icon: "fa-regular fa-comment", text: "Comment" },
                  { icon: "fa-solid fa-retweet", text: "Repost" },
                  { icon: "fa-regular fa-paper-plane", text: "Send" },
                ].map((action, index) => (
                  <button
                    key={index}
                    className={`flex items-center justify-center gap-2 py-3
                      
                      rounded-lg transition-colors group`}
                  >
                    <i
                      className={`${action.icon} ${isDark ? "text-gray-400" : "text-gray-600"} 
                      group-hover:text-blue-600 transition-colors`}
                    ></i>
                    <span
                      className={`text-[13px] font-medium 
                      ${isDark ? "text-gray-400" : "text-gray-600"}
                      group-hover:text-blue-600 transition-colors`}
                    >
                      {action.text}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-0 pt-2">
            <div className="flex justify-end gap-3 w-full">
              <Button
                variant="outline-secondary"
                className={`px-4 ${isDark ? "text-gray-300 border-gray-600" : ""}`}
                onClick={() => {
                  setShowPollPreviewModal(false);
                  setShowPollModal(true);
                }}
              >
                <i className="fas fa-pen-to-square mr-2"></i>
                Edit Poll
              </Button>
              <Button
                className="gradient-button-new px-4 flex items-center gap-2"
                onClick={async () => {
                  try {
                    setShowPollPreviewModal(false);
                    await publishPostHandler();
                  } catch (error) {
                    console.error("Error publishing poll:", error);
                  }
                }}
              >
                <i className="fas fa-paper-plane"></i>
                Publish Poll
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      <div className={isTweet ? "tweet-thread" : "tweet-thread-hide"}>
        <div className="tweet-thread-header pt-5">
          <h2 className="text-white">Twitter</h2>
          <i className="fa-regular fa-circle-xmark text-white fa-2x" onClick={() => setIsTweet(!isTweet)}></i>
        </div>
        <div className="tweet-thread-container mt-3">
          {getThread?.map((item, index) => {
            return (
              <div className="tweet-thread-item" key={index}>
                <div className="tweet-card-container">
                  <div className="tweet-card-header">
                    <img src={user?.userPhoto} alt="" />
                    <h4> {twitteruser?.name}</h4>
                  </div>
                  <div className="tweet-card-body">
                    <p>{item}</p>
                  </div>
                  <div className="tweet-card-footer">
                    <i className="fa-regular fa-comment"></i>
                    <i className="fa-solid fa-repeat"></i>
                    <i className="fa-regular fa-heart"></i>
                    <i className="fa-solid fa-chart-simple"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={isInsta ? "tweet-thread" : "tweet-thread-hide"}>
        <div className="tweet-thread-header pt-5">
          <h2 className="text-white">Instagram</h2>

          <select value={selectedStyle} onChange={handleChangeStyle}>
            {styles?.map((style) => (
              <option key={style.name} value={style.name}>
                {style.name}
              </option>
            ))}
          </select>
          <i className="fa-regular fa-circle-xmark text-white fa-2x" onClick={() => setIsInsta(!isInsta)}></i>
        </div>
        <div className="tweet-thread-container mt-3">
          {getThread?.map((item, index) => {
            return (
              <div className="tweet-thread-item" key={index}>
                <div className="tweet-card-container">
                  <div className="tweet-card-header">
                    <img src={user?.userPhoto} alt="" />
                    <h4> {twitteruser?.name}</h4>
                  </div>
                  <div className="tweet-card-body" style={styles.find((style) => style.name === selectedStyle)?.style || {}}>
                    <p style={{ color: "white" }}>{item}</p>
                  </div>
                  <div className="tweet-card-footer">
                    <i className="fa-regular fa-comment"></i>
                    <i className="fa-solid fa-repeat"></i>
                    <i className="fa-regular fa-heart"></i>
                    <i className="fa-solid fa-chart-simple"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CreatePost;
