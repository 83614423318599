import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteApi, postApi } from "../../api/methods";
import { isLoading } from "../../store/features/common/commonSlice";
import { addPostForPreview } from "../../store/features/post/postSlice";
import { useLocation, useNavigate } from "react-router-dom";
import "./PostCard.css";
import { IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../api/API";
import { addEvergreenData, fetchUserEverGreen } from "../../store/features/evergreen/evergreenSlice";
import { addBookmarkData, fetchUserBookmark } from "../../store/features/bookmark/bookmarkSlice";
import { addPreviewPost, togglePreview } from "../../store/features/previewPost/previewPostSlice";
import convertCodeToText from "../../utility/convertCodeToText";
import axios from "axios";
import { fetchUserOTGById } from "../../store/features/otg/otgSlice";
import { addDiscoverData } from "../../store/features/discover/discoverSlice";
import { v4 as uuidv4 } from "uuid";
import { POST_GENERATION_TYPES } from "../../store/features/post/initialState";
import { setPostGenerationType } from "../../store/features/post/postSlice";

// This is the post card component, like the LinkedIn preview

function convertToUserTimezone(date, timezone) {
  if (!timezone) return date;
  try {
    const parsedTimezone = typeof timezone === "string" ? JSON.parse(timezone) : timezone;

    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: parsedTimezone.value,
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });

    const parts = formatter.formatToParts(date);
    const dateObj = {};
    parts.forEach((part) => {
      if (["year", "month", "day", "hour", "minute", "second"].includes(part.type)) {
        dateObj[part.type] = parseInt(part.value, 10);
      }
    });

    return new Date(dateObj.year, dateObj.month - 1, dateObj.day, dateObj.hour, dateObj.minute, dateObj.second);
  } catch (error) {
    console.error("Error converting timezone:", error);
    return date;
  }
}

const PostCard = (props) => {
  const [discoverImage, setDiscoverImage] = useState(props.data?.discoverData?.profileImage ? props.data?.discoverData?.profileImage : props.data?.profileImage ? props.data.profileImage : `${IMAGE_URL}/images/Icons/avatar.jpg`);

  const location = useLocation();
  let pathName = location.pathname;
  // console.log({props});
  const user = useSelector((state) => state.user);
  const previewPost = useSelector((state) => state.previewPost);
  const { isDark } = useSelector((state) => state.common);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const post = useSelector((state) => state.post);
  const [modalData, setModalData] = useState(null);
  const [url, setUrl] = useState("");
  const userInfo = user?.userData;
  const [imageError, setImageError] = useState(false);
  const [evergreenStatus, setEvergreenStatus] = useState(props.data?.Evergreen ? true : pathName === "/evergreen" || pathName === "/evergreen/" ? true : false);
  const [bookmarkStatus, setBookmarkStatus] = useState(props.data?.Bookmark ? true : pathName === "/bookmarks" || pathName === "/bookmarks/" ? true : false);

  // Get user timezone from Redux store
  const userTimezone = user?.userChosenTopics?.timeZone;

  // Function to handle evergreen status toggle
  // here create a new evergreen post and evergreen empty symbol to fill with color symbol
  useEffect(() => {
    console.log("props:", props);
    if (props.postype === "ARTICLE") {
      console.log("Setting URL:", props.data.image);
      setUrl(props.image);
    }
    // Add logging for PDF/Carousel posts
    if (props.postype === "CAROUSEL" || props.postype === "PDF") {
      console.log("PDF/Carousel data:", {
        mediaDis: props.data.mediaDis,
        originalUrl: props.data.originalUrl,
        image: props.image,
        postType: props.data.postType,
        postype: props.postype,
      });
    }
  }, [props.postType, props.image, props.postype]);

  useEffect(() => {
    if (url !== "") {
      fetchMetaData();
    }
    console.log("This is useEffect, url:", url);
  }, [url]);

  const fetchMetaData = async () => {
    try {
      const response = await axios.post(`${apibase}/api/get-meta-data`, {
        url: url,
      });
      setModalData(response.data);
      console.log("This is fetchMetaData, response.data:", response.data[0]);
      console.log(url);
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setModalData({
        data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
      });
    }
  };

  const onEvergreenHnadler = async () => {
    // Toggle the evergreen status
    setEvergreenStatus(!evergreenStatus);

    // Check if the post is already marked as evergreen
    if (evergreenStatus) {
      // If marked as evergreen, delete the evergreen mark
      onDeleteHnadler({ evergreenMark: true });
      dispatch(fetchUserEverGreen({ userId: user?.userData?.linkedinId, page: 1 }));
    } else {
      try {
        dispatch(isLoading(true));

        // Create a new evergreen post
        const postBody = {
          userId: user.userData.linkedinId,
          postId: props.data.userName ? `discover_${uuidv4()}` : props.data.postId,
          postContent: props.data.postContent,
          connectionType: props.data.connectionType || "PUBLIC",
          postType: props.data.postType || "NONE",
          mediaDis: props.data.mediaDis || "",
          mediaTitle: props.data.mediaTitle || "",
          media: props.data.media || "",
          originalUrl: props.data.originalUrl || "",
        };

        // Make a request to create the evergreen post
        const response = await postApi("evergreen/create", postBody);
        toast.success("Saved as evergreen!");
        setEvergreenStatus(true);
        dispatch(fetchUserEverGreen({ userId: user?.userData?.linkedinId, page: 1 }));
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        console.warn(error);
        if (error.response.status === 409) {
          const message = error.response.data.message;
          toast.error(message);
          setEvergreenStatus(true);
        } else {
          const message = "Something went wrong!";
          toast.error(message);
        }
      }
    }
  };

  // Function to handle bookmark status toggle
  // here create a new bookmark post and bookmark empty symbol to fill with color symbol
  const onBookmarkHnadler = async () => {
    // Toggle the bookmark status
    setBookmarkStatus(!bookmarkStatus);

    // Check if the post is already bookmarked
    if (bookmarkStatus) {
      // If bookmarked, delete the bookmark
      onDeleteHnadler({ bookmarkMark: true });
      dispatch(fetchUserBookmark({ userId: user?.userData?.linkedinId, page: 1 }));
    } else {
      try {
        dispatch(isLoading(true));

        // Create a new bookmark
        const postBody = {
          userId: user.userData.linkedinId,
          postId: props.data.userName ? `discover_${uuidv4()}` : props.data.postId,
          postContent: props.data.postContent,
          connectionType: props.data.connectionType || "PUBLIC",
          postType: props.data.postType || "NONE",
          mediaDis: props.data.mediaDis || "",
          mediaTitle: props.data.mediaTitle || "",
          media: props.data.media || "",
          originalUrl: props.data.originalUrl || "",
          discoverData: props.data.userName ? props.data : null,
        };

        // Make a request to create the bookmark
        const response = await postApi("book-marks/bookmark-post-create", postBody);
        toast.success("Saved in Bookmark!");
        setBookmarkStatus(true);
        dispatch(fetchUserBookmark({ userId: user?.userData?.linkedinId, page: 1 }));

        if (postBody.postId.split("_")[0] === "discover") {
          // dispatch(addDiscoverData({ data:[], isNew:true }))
        }
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        console.warn(error);
        if (error?.response?.status === 409) {
          const message = error.response.data.message;
          toast.error(message);
          setBookmarkStatus(true);
        } else {
          const message = "Something went wrong!";
          toast.error(message);
        }
      }
    }
  };

  const onComposeHandler = () => {
    console.log("props.data.postId");
    console.log(props.data.postId);
    console.log("props.data.postId");
    if (!props.data.postId.startsWith("otg_")) {
      console.log("discover post");
      dispatch(setPostGenerationType(POST_GENERATION_TYPES.DISCOVER));
    } else {
      dispatch(setPostGenerationType(POST_GENERATION_TYPES.ON_THE_GO));
    }
    dispatch(addPostForPreview(props.data));
    // deleteApi(`schedule/delete-schedule-post/${props.data._id}`);
    navigate("/ai-content");
  };

  // here if user click evergreen hightlight symbol then evergreen post will be deleted
  // here if user click bookmark hightlight symbol then bookmark post will be deleted
  const onDeleteHnadler = async (mark = {}) => {
    // console.log(props.data, props.isEvergreen, props.isBookmark );
    // if(props?.data?.postId?.split('_')[0] === 'otg' || props?.data?.postId?.split('_')[0] === 'discover'){
    //     toast.info('Please unbookmark from Bookmarks section!')
    //     if( bookmarkStatus ){
    //         setBookmarkStatus(true)
    //     }
    //     if( evergreenStatus ){
    //         setEvergreenStatus(true)
    //     }
    //     return
    // }

    try {
      if (bookmarkStatus && (pathName === "/bookmarks" || pathName === "/bookmarks/")) {
        const confirmDelete = confirm("Are you sure?");

        if (bookmarkStatus && confirmDelete) {
          dispatch(isLoading(true));
          const response = await deleteApi("book-marks/bookmark-post-delete", {
            id: props.data.postId,
          });
          toast.success("Post deleted in Bookmark.");
          props?.setData(response.data);
          setBookmarkStatus(true);
          dispatch(isLoading(false));
          if (props.data.postId.split("_")[0] === "otg") {
            dispatch(fetchUserOTGById({ userId: user.userData?.linkedinId }));
          }
          if (props.data.postId.split("_")[0] === "discover") {
            // dispatch(addDiscoverData({ data:[], isNew:true }))
          }
        }
      } else {
        if (bookmarkStatus && mark.bookmarkMark) {
          toast.info("Please unbookmark from Bookmarks section");
          setBookmarkStatus(true);
        }
      }

      if (evergreenStatus && (pathName === "/evergreen" || pathName === "/evergreen/")) {
        const confirmDelete = confirm("Are you sure?");

        if (evergreenStatus && confirmDelete) {
          dispatch(isLoading(true));
          const response = await deleteApi("evergreen/evergreen-post-delete", {
            id: props.data.postId,
          });
          toast.success("Post deleted from Evergreen!");
          props?.setData(response.data);
          setEvergreenStatus(true);
          dispatch(isLoading(false));
          if (props.data.postId.split("_")[0] === "otg") {
            dispatch(fetchUserOTGById({ userId: user.userData?.linkedinId }));
          }
        }
      } else {
        if (evergreenStatus && mark.evergreenMark) {
          toast.info("Please remove from Evergreens section");
          setEvergreenStatus(true);
        }
      }
    } catch (error) {
      toast.info("Something went wrong!");
      dispatch(isLoading(false));
      console.warn(error);
    }
  };

  // preview post in global panel
  const previewPostCardHandler = () => {
    // console.log(props.data);
    dispatch(addPreviewPost(props.data));
    dispatch(togglePreview(true));
  };

  // view profile of discover posts only
  const viewProfileHandler = () => {
    let url;
    if (props.data.platform) {
      url = "http://www.twitter.com";
    }

    const urlLink = `${url}/${props.data.userName}`;

    if (props.data?.userName) {
      window.open(urlLink, "_blank").focus();
    }
  };

  useEffect(() => {}, [bookmarkStatus, evergreenStatus]);
  // console.log(props.data);

  // Format date and time with timezone
  let date;
  let time;

  if (props?.data?.date_time) {
    const currentDate = convertToUserTimezone(new Date(`${props?.data?.date_time}.000Z`), userTimezone);
    date = currentDate.toLocaleDateString();
    time = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: userTimezone?.value,
    });
  } else if (props?.data?.createdAt) {
    const currentDate = convertToUserTimezone(new Date(`${props?.data?.createdAt}`), userTimezone);
    date = currentDate.toLocaleDateString();
    time = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: userTimezone?.value,
    });
  }

  // Ensure consistent date format
  if (date) {
    const [month, day, year] = date.split("/");
    date = `${month.padStart(2, "0")}-${day.padStart(2, "0")}-${year}`;
  }

  //   useEffect(()=>{

  //     if(props.data?.profileImage){
  //         axios.get(props.data?.profileImage)
  //             .then(response=>{
  //                 if (response.status === 200) {
  //                     setDiscoverImage(props.data?.profileImage)
  //                   }
  //             })
  //             .catch(error=>{

  //             })
  //       }

  //   },[props.data?.profileImage])
  // console.log(props.data.originalUrl);

  const renderMediaGallery = () => {
    if (props.data?.media || props.image) {
      // Handle array of media or convert single image to array
      const mediaArray = Array.isArray(props.data?.media) ? props.data.media : props.data?.media ? [props.data.media] : [props.image];

      return (
        <div className="mx-4 mt-4">
          <div className="border border-gray-200 rounded-lg overflow-hidden">
            <div className="p-2 bg-gray-50">
              <div className="flex flex-wrap gap-2 justify-end">
                {mediaArray.map((media, index) => {
                  const mediaUrl = `${IMAGE_URL_BACKEND}/uploads/${media}`;
                  const isQRCode = media?.includes("qr") || mediaUrl?.includes("qr");

                  return (
                    <div
                      key={index}
                      className={`relative ${isQRCode ? "w-24 h-24" : "w-32 h-32"}`}
                      style={{
                        minWidth: isQRCode ? "96px" : "128px",
                        minHeight: isQRCode ? "96px" : "128px",
                      }}
                    >
                      <img
                        src={mediaUrl}
                        alt={`Post media ${index + 1}`}
                        className={`w-full h-full ${isQRCode ? "object-contain" : "object-cover"} rounded-md`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${IMAGE_URL}/images/Icons/placeholder.png`;
                        }}
                      />
                      <div className="absolute top-1 right-1">
                        <i
                          className="fa-regular fa-trash-can cursor-pointer p-1 bg-white rounded-full text-xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (props.onDeleteMedia) {
                              props.onDeleteMedia(media);
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`${isDark ? "bg-white" : "bg-[#1E1E1E]"} m-2 rounded-xl w-full`} key={props.i}>
      <div className="w-full">
        <div className="flex max-sm:flex-col justify-between w-full">
          <div className="w-full">
            {/* <div className="w-full flex justify-between items-center mb-8">
              <h3 className="text-[15px] font-semibold text-gray-600">
                Preview
              </h3>
            </div> */}

            <div className="bg-white rounded-xl shadow-sm border border-gray-200">
              {/* Header */}
              <div className="p-4 pb-2">
                <div className="flex items-start justify-between">
                  <div className="flex gap-3">
                    <img src={user?.userPhoto || `${IMAGE_URL}/images/Icons/avatar.jpg`} className="w-12 h-12 rounded-full border object-cover" alt="Profile" />

                    <div className="flex flex-col">
                      <span className="font-semibold text-[14px] text-black hover:text-blue-600 cursor-pointer">{user?.userData?.userName?.length > 30 ? `${user?.userData?.userName.slice(0, 30)}...` : user?.userData?.userName}</span>

                      <span className="text-[10px] text-black">{user?.userData?.localizedHeadline?.length > 30 ? `${user?.userData?.localizedHeadline.slice(0, 30)}...` : user?.userData?.localizedHeadline}</span>

                      <span className="text-[10px] text-black flex items-center gap-1">
                        {date ? `${date} ${time}` : "Now"} • <i className="fa-solid fa-earth-americas text-[10px]"></i>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    {
                      <span className="gradient-button-new p-1 px-2 text-xs rounded-md text-white" style={{}}>
                        {/* this is the code to get he post type in blue bg */}
                        {props?.data?.platform ||
                          (props?.postype === "CAROUSEL"
                            ? "CAROUSEL"
                            : props?.data?.postId?.split("_")[0] === "otg"
                            ? "OTG"
                            : props?.data?.postId?.split("_")[0] === "schedule"
                            ? "SCHEDULED"
                            : props?.data?.postId?.split("_")[0] === "ai"
                            ? "AI POST"
                            : props?.data?.postId?.split("_")[0] === "discover"
                            ? "DISCOVER"
                            : props?.data?.postId?.split("_")[0] === "custom"
                            ? "CUSTOM"
                            : props?.data?.postId?.split("_")[0]
                            ? props?.data?.postId?.split("_")[0].toUpperCase()
                            : props?.postype?.toUpperCase() || "UNKNOWN")}
                      </span>
                    }
                    {props?.postURL && (
                      <a className="discover-url" href={props?.postURL} target="_blank">
                        <i className="fa-solid fa-link" title="URL" style={{ color: isDark ? "#121212" : "#FFFFFF" }}></i>
                      </a>
                    )}

                    {props.isDelete && <i className="fa-regular fa-trash-can cursor-pointer" onClick={onDeleteHnadler} title="Delete"></i>}
                    {props.isEvergreen &&
                      (evergreenStatus ? (
                        <i className="fa-solid fa-star cursor-pointer" style={{ color: isDark ? "#121212" : "#000000" }} onClick={onEvergreenHnadler} title="Evergreen"></i>
                      ) : (
                        <i className="fa-regular fa-star cursor-pointer" onClick={onEvergreenHnadler} style={{ color: isDark ? "#121212" : "#000000" }} title="Evergreen"></i>
                      ))}
                    {props.isBookmark &&
                      (bookmarkStatus ? (
                        <i className="fa-solid fa-bookmark cursor-pointer" style={{ color: isDark ? "#121212" : "#000000" }} onClick={onBookmarkHnadler} title="Bookmark"></i>
                      ) : (
                        <i className="fa-regular fa-bookmark cursor-pointer" style={{ color: isDark ? "#121212" : "#000000" }} onClick={onBookmarkHnadler} title="Bookmark"></i>
                      ))}
                    <i className="fa-regular fa-pen-to-square cursor-pointer" title="Repurpose" style={{ color: isDark ? "#121212" : "#000000" }} onClick={onComposeHandler}></i>

                    <i className="fa-brands fa-linkedin text-[#0a66c2] text-xl"></i>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="px-4 pt-1">
                {(() => {
                  const content = props.des || "";

                  const parts = content.split(/(#[^\s#]+)/g);

                  const mainContent = parts
                    .filter((part) => !part.startsWith("#"))
                    .join(" ")
                    .trim();

                  const hashtags = parts.filter((part) => part.startsWith("#")).join(" ");

                  return (
                    <div className="text-[13px] text-black whitespace-pre-wrap">
                      {mainContent}

                      {hashtags && (
                        <>
                          {"\n\n"}

                          <span className="text-black font-bold">{hashtags}</span>
                        </>
                      )}
                    </div>
                  );
                })()}
              </div>

              {/* Media */}
              {renderMediaGallery()}

              {/* Article Preview */}
              {/* {post.originalUrl &&
                post.postType === "ARTICLE" &&
                modalData?.data && (
                  <div className="mx-4 mt-4 border border-gray-200 rounded-lg overflow-hidden hover:bg-gray-50 transition-colors cursor-pointer">
                    <a
                      href={post.originalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block"
                    >
                      {modalData.data.ogImage?.[0]?.url && (
                        <img
                          src={modalData.data.ogImage[0].url}
                          className="w-full h-52 object-cover"
                          alt="Article preview"
                        />
                      )}

                      <div className="p-3">
                        <h4 className="font-semibold text-[14px] text-gray-900">
                          {modalData.data.ogTitle}
                        </h4>

                        <p className="text-[12px] text-gray-600 mt-1 line-clamp-2">
                          {modalData.data.ogDescription}
                        </p>
                      </div>
                    </a>
                  </div>
                )} */}

              {/* PDF/Carousel Preview */}
              {/* {(props.postype === "CAROUSEL" || props.postype === "PDF") && (
                <div className="mx-4 mt-4 border border-gray-200 rounded-lg overflow-hidden">
                  <div className="flex items-center gap-2 p-3 border-b border-gray-200">
                    <i className="far fa-file-pdf text-red-500"></i>
                    <div className="flex-1">
                      <h4 className="text-sm font-medium text-gray-900">
                        PDF Document
                      </h4> */}
              {/* Debug info
                      <div className="text-xs text-gray-500">
                        {props.data.mediaDis && (
                          <div>Using mediaDis: {props.data.mediaDis}</div>
                        )}
                        {props.data.originalUrl && (
                          <div>Using originalUrl: {props.data.originalUrl}</div>
                        )}
                        {props.image && <div>Using image: {props.image}</div>}
                        <div>Post type: {props.postype}</div>
                      </div>
                    </div>
                  </div>
                  <div className="aspect-[4/3] bg-gray-50">
                    {props.data.mediaDis ? (
                      <object
                        data={`${IMAGE_URL_BACKEND}/uploads/${props.data.mediaDis}`}
                        type="application/pdf"
                        className="w-full h-full"
                      >
                        <iframe
                          src={`${IMAGE_URL_BACKEND}/uploads/${props.data.mediaDis}`}
                          className="w-full h-full"
                          title="PDF Preview"
                        />
                      </object>
                    ) : props.data.originalUrl ? (
                      <object
                        data={`${IMAGE_URL_BACKEND}/uploads/${props.data.originalUrl}`}
                        type="application/pdf"
                        className="w-full h-full"
                      >
                        <iframe
                          src={`${IMAGE_URL_BACKEND}/uploads/${props.data.originalUrl}`}
                          className="w-full h-full"
                          title="PDF Preview"
                        />
                      </object>
                    ) : props.image ? (
                      <object
                        data={`${IMAGE_URL_BACKEND}/uploads/${props.image}`}
                        type="application/pdf"
                        className="w-full h-full"
                      >
                        <iframe
                          src={`${IMAGE_URL_BACKEND}/uploads/${props.image}`}
                          className="w-full h-full"
                          title="PDF Preview"
                        />
                      </object>
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <p className="text-gray-500">
                          No PDF preview available
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )} */}

              {/* Video */}
              {/* {post.mediaTitle && post.postType === "VIDEO" && (
                <div className="mx-4 mt-4 relative">
                  <video
                    src={`${IMAGE_URL_BACKEND}/uploads/${post.mediaTitle}`}
                    className="w-full rounded-lg"
                    controls
                  />

                  {!props.isDeleteBtnHide && (
                    <button
                      onClick={() => deleteMedia()}
                      className="absolute top-2 right-2 p-2 bg-white/80 rounded-full hover:bg-white transition-colors group"
                      title="Delete"
                    >
                      <i className="fa-regular fa-trash-can text-gray-700 group-hover:text-gray-900" />
                    </button>
                  )}
                </div>
              )} */}

              {/* Engagement Section */}
              <div className="mt-4 px-4 pb-2">
                <hr className="border-gray-200" />

                <div className="grid grid-cols-4 -mx-1 mt-1">
                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-regular fa-thumbs-up text-black"></i>

                    <span className="text-[13px] font-medium text-black">Like</span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-regular fa-comment text-black"></i>

                    <span className="text-[13px] font-medium text-black">Comment</span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-solid fa-retweet text-black"></i>

                    <span className="text-[13px] font-medium text-black">Repost</span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-regular fa-paper-plane text-black"></i>

                    <span className="text-[13px] font-medium text-black">Send</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* this is the code to show the 3 buttons */}
          <div className="flex max-sm:pl-14 items-start"></div>
        </div>

        {/* Engagement Section */}
        {/* <div className="dark:bg-gray-800">
          <hr className="border-gray-400 dark:border-gray-600" />
          <div className="grid grid-cols-4 -mx-1 mt-1">
            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors group">
              <i className="fa-regular fa-thumbs-up text-gray-600 dark:text-white"></i>
              <span className="text-[13px] font-medium text-gray-600 dark:text-white">
                Like
              </span>
            </button>
            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors group">
              <i className="fa-regular fa-comment text-gray-600 dark:text-white"></i>
              <span className="text-[13px] font-medium text-gray-600 dark:text-white">
                Comment
              </span>
            </button>
            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors group">
              <i className="fa-solid fa-retweet text-gray-600 dark:text-white"></i>
              <span className="text-[13px] font-medium text-gray-600 dark:text-white">
                Repost
              </span>
            </button>
            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors group">
              <i className="fa-regular fa-paper-plane text-gray-600 dark:text-white"></i>
              <span className="text-[13px] font-medium text-gray-600 dark:text-white">
                Send
              </span>
            </button>
          </div>
        </div> */}

        {/* <div className="flex justify-between pt-2">
          {props.data.discoverData ? (
            <>
              <span className="">
                <i
                  className="fa fa-thumbs-up"
                  aria-hidden="true"
                  style={{ color: "#4267B2" }}
                ></i>{" "}
                {props.data.discoverData.likeCount || 0}
              </span>
              <span className="flex gap-2 items-center justify-center">
                <p className="flex gap-1 items-center justify-center">
                  {props.data.discoverData.commentCount || 0}{" "}
                  <i className="fa-regular fa-comment"></i>{" "}
                </p>
                <p className="flex gap-1 items-center justify-center">
                  {props.data.discoverData.repostCount || 0}{" "}
                  <i className="fa-solid fa-retweet"></i>
                </p>
              </span>
            </>
          ) : (
            <>
              <span className="">
                <i
                  className="fa fa-thumbs-up p-2"
                  aria-hidden="true"
                  style={{ color: "#4267B2" }}
                ></i>{" "}
                {props.data.likeCount || 0}
              </span>
              <span className="flex gap-2 items-center justify-center">
                <p className="flex gap-1 items-center justify-center">
                  {props.data.commentCount || 0}{" "}
                  <i className="fa-regular fa-comment"></i>{" "}
                </p>
                <p className="flex gap-1 items-center justify-center">
                  {props.data.repostCount || 0}{" "}
                  <i className="fa-solid fa-retweet"></i>
                </p>
              </span>

              
            </>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default PostCard;
