import {
  colorPickerSet,
  headShotColorSet,
  headShotSize,
  introOutroHeadShot,
  putHeadShot,
} from "../../../store/features/carousel/action/headShotAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontSizeImg from "../../../../src/assets/fontSize.svg";
import fontSizeWhiteImg from "../../../../src/assets/fontSizeWhite.svg";
import { Switch } from "../../../components/ui/switch";
import { Slider } from "@mui/material";

function HeadShotBar() {
  const {
    headShot,
    introOutro,
    imgSize,
    nameSize,
    handleSize,
    headShotColor,
    colorPicker,
  } = useSelector((s) => s.headShotReducer);
  const { isDark } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  // New state for additional features
  const [addBorder, setAddBorder] = useState(false);
  const [addShadow, setAddShadow] = useState(false);
  const [roundness, setRoundness] = useState(5);
  const [customBrandingSize, setCustomBrandingSize] = useState(false);

  useEffect(() => {
    const introHead = document.getElementById("introOutro");
    if (introHead) {
      introHead.disabled = !headShot;
    }
    if (!headShot) dispatch(introOutroHeadShot(false));
  }, [headShot, dispatch]);

  useEffect(() => {
    const colorInp = document.getElementById("colorInp");
    const colorPick = document.getElementById("colorPicker");
    if (colorInp && colorPick) {
      colorInp.disabled = !colorPicker;
      colorPick.disabled = !colorPicker;
    }
    // Set default color to black when colorPicker is off
    if (!colorPicker) {
      dispatch(headShotColorSet("#000000"));
    }
  }, [colorPicker, dispatch]);

  const handleHeadShotSize = (imageSize, namesSize, handlesSize) => {
    const data = {
      imgSize: imageSize,
      nameSize: namesSize,
      handleSize: handlesSize,
    };
    dispatch(headShotSize(data));
  };

  const handleHeadShot = (data) => {
    dispatch(putHeadShot(data));
  };

  const handleIntroOutro = (data) => {
    dispatch(introOutroHeadShot(data));
  };

  const handleHeadShotColor = (data) => {
    dispatch(headShotColorSet(data));
  };

  const handleColorPicker = (data) => {
    dispatch(colorPickerSet(data));
  };

  const handleRoundnessChange = (event, newValue) => {
    setRoundness(newValue);
    // You can dispatch an action here if you want to store roundness in Redux
  };

  return (
    <div className="py-6 px-3 flex flex-col gap-4">
      <p className="font-bold">Branding</p>
      <div className="flex flex-col w-full gap-3">
        <label
          className="text-sm font-semibold"
          style={{ color: isDark ? "black" : "white" }}
        >
          CONFIGURATIONS
        </label>
        <div className="flex flex-col gap-4 text-sm font-semibold">
          <div className="flex gap-2 items-center">
            <Switch
              checked={headShot}
              onCheckedChange={() => handleHeadShot(!headShot)}
              className="bg-blue-500 data-[state=checked]:bg-blue-500"
            />
            <p style={{ color: isDark ? "black" : "white" }}>Show branding</p>
          </div>
          <div className="flex gap-2 items-center">
            <Switch
              checked={introOutro}
              onCheckedChange={() => handleIntroOutro(!introOutro)}
              className="bg-blue-500 data-[state=checked]:bg-blue-500"
              id="introOutro"
            />
            <p style={{ color: isDark ? "black" : "white" }}>
              Only Intro and Outro slides
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 border-[1px] border-gray-200 rounded-lg w-[290px] px-2 pt-3 h-[128px]">
        <div className="flex items-center justify-between rounded-lg px-1">
          <p className="text-sm font-semibold">Custom Color</p>
          <Switch
            checked={colorPicker}
            onCheckedChange={() => handleColorPicker(!colorPicker)}
            className="bg-blue-500 data-[state=checked]:bg-blue-500"
            id="introOutro"
          />
        </div>
        <div className="relative -mb-3 mt-2">
          <input
            type="text"
            className={`w-full border rounded-md px-3 py-2 text-sm`}
            value={headShotColor}
            onChange={(e) => handleHeadShotColor(e.target.value)}
            id="colorInp"
            placeholder="Enter Hex Code"
            style={{
              background: "transparent",
              color: isDark ? "black" : "white",
            }}
          />
          <div className="relative -top-[32px] left-[200px]">
            <input
              type="color"
              id="colorPicker"
              className="h-[20px] mt-1 cursor-pointer"
              value={headShotColor}
              onChange={(e) => handleHeadShotColor(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 ">
        <p className="text-sm font-semibold mb-2">Branding Size</p>
        <div className="flex gap-2 items-center">
          <Switch
            checked={customBrandingSize}
            onCheckedChange={() => setCustomBrandingSize(!customBrandingSize)}
            className="bg-blue-500 data-[state=checked]:bg-blue-500"
          />
          <p style={{ color: isDark ? "black" : "white" }}>
            Custom Branding Size
          </p>
        </div>
        <div className="flex gap-3 mt-3">
          <div
            className={`border-[1px] tooltipClass rounded-md ${
              imgSize === "36px" && nameSize === "sm" && handleSize === "xs"
                ? isDark
                  ? "border-black"
                  : "border-gray-200"
                : isDark
                ? "border-gray-200"
                : "border-black"
            } p-2 flex items-center justify-center cursor-pointer ${
              addBorder ? "border-2 border-blue-500" : ""
            }`} // Add border dynamically
            title="small"
            onClick={() => handleHeadShotSize("36px", "sm", "xs")}
          >
            <img
              src={isDark ? fontSizeImg : fontSizeWhiteImg}
              width="16px"
              alt="small"
            />
          </div>
          <div
            className={`border-[1px] tooltipClass rounded-md ${
              imgSize === "39px" && nameSize === "base" && handleSize === "sm"
                ? isDark
                  ? "border-black"
                  : "border-gray-200"
                : isDark
                ? "border-gray-200"
                : "border-black"
            } p-2 flex items-center justify-center cursor-pointer ${
              addBorder ? "border-2 border-blue-500" : ""
            }`} // Add border dynamically
            title="medium"
            onClick={() => handleHeadShotSize("39px", "base", "sm")}
          >
            <img
              src={isDark ? fontSizeImg : fontSizeWhiteImg}
              width="18px"
              alt="medium"
            />
          </div>
          <div
            className={`border-[1px] tooltipClass rounded-md ${
              imgSize === "41px" && nameSize === "lg" && handleSize === "base"
                ? isDark
                  ? "border-black"
                  : "border-gray-200"
                : isDark
                ? "border-gray-200"
                : "border-black"
            } p-2 flex items-center justify-center cursor-pointer ${
              addBorder ? "border-2 border-blue-500" : ""
            }`} 
            title="large"
            onClick={() => handleHeadShotSize("41px", "lg", "base")}
          >
            <img
              src={isDark ? fontSizeImg : fontSizeWhiteImg}
              width="20px"
              alt="large"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeadShotBar;