import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useSelector } from "react-redux";
import Loader from "./components/Loader/Loader";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUserInfo } from "./store/features/user/userSlice";
import { setToken } from "./store/features/user/authSlice";
import Dashboard from "./pages/Dashboard/Dashboard";
import OTG from "./pages/OnTheGo/OTG";
import Discover from "./pages/Discover/Discover";
import Analytics from "./pages/Analytics/Analytics";
import Calender from "./pages/Calender/Calender";
import BookMarks from "./pages/Bookmarks/Bookmarks";
import Evergreen from "./pages/Evergreen/Evergreen";
import History from "./pages/History/History";
import Media from "./pages/Media/Media";
import Login from "./pages/Login/Login";
import AIContent from "./pages/PostIdea/PostIdea";
import OrderReview from "./pages/OrderReview/OrderReview";
import MyQueue from "./pages/MyQueue/MyQueue";
import MyAccount from "./pages/MyAccount/MyAccount";
import CreditHistory from "./pages/CreditHistory/CreditHistory";
import HelpDesk from "./pages/HelpDesk/HelpDesk";
import BySubscription from "./pages/BySubscription/BySubscription";
import VerifyMember from "./pages/verifyMember/VerifyMember";
import PostAnalytics from "./pages/Analytics/PostAnalytics";
import Carousel from "./pages/Carousel/Carousel";
import axios from "axios";
import { Testing } from "./pages/Testing";
import { postApi } from "./api/methods";
import Register from "./pages/Register/Register";
import AuthenticateWithLinkedIn from "./components/AfterLogin/AuthenticateWithLinkedIn";
import ResetPassword from "./components/AfterLogin/ResetPassword";
import ForgetPassword from "./components/AfterLogin/ForgetPassword";
import InvitationRegister from "./pages/Register/InvitationRegister";
import { BASE_URL } from "./api/API";
import { clarity } from 'react-microsoft-clarity';
import InviteWithdrawn from "./components/AfterLogin/InviteWithdrawn";
import AnalyticsComingSoon from "./pages/Analytics/AnalyticsComingSoon";

clarity.init('nnqj7m7u52');
clarity.identify('USER_ID', { userProperty: 'value' });
// import MobileNotification from "./components/Mobile/MobileNotification";

const captureUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  
  // Check if current URL has UTM parameters
  const utmParams = {
    utm_source: urlParams.get('utm_source'),
    utm_medium: urlParams.get('utm_medium'),
    utm_campaign: urlParams.get('utm_campaign'),
    utm_content: urlParams.get('utm_content'),
    utm_term: urlParams.get('utm_term'),
    utm_id: urlParams.get('utm_id'), // Added utm_id parameter
    affiliate_id: urlParams.get('affiliate_id')
  };
  
  // Check if at least one UTM parameter exists in the current URL
  const hasUtmParams = Object.values(utmParams).some(value => value !== null);
  
  if (hasUtmParams) {
    // Add timestamp to track when these UTM parameters were captured
    utmParams.captured_at = new Date().toISOString();
    
    // Save to localStorage, overwriting any previous values
    localStorage.setItem('utm_data', JSON.stringify(utmParams));
    console.log('UTM parameters captured:', utmParams);
    return utmParams;
  }
  
  // If no UTM params in URL, return existing ones from localStorage if available
  const storedUtmData = localStorage.getItem('utm_data');
  return storedUtmData ? JSON.parse(storedUtmData) : null;
};

const verifyCookieAndRedirect = async () => {
  try {
    const response = await axios.get(`${BASE_URL}verify-cookie`, {
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error("Cookie verification failed:", error);
    return false;
  }
};

// Component to handle UTM in URL and create UTM query string for redirects
const UTMHandler = ({ children }) => {
  const location = useLocation();
  
  useEffect(() => {
    captureUTMParameters();
  }, [location.search]); // Re-run whenever URL search params change
  
  return children;
};

// Helper function to create login path with UTM parameters
const createLoginPathWithUTM = () => {
  const utmData = localStorage.getItem('utm_data');
  if (!utmData) return "/login";
  
  const params = JSON.parse(utmData);
  const queryParams = new URLSearchParams();
  
  // Add each UTM parameter to query string if it exists
  Object.entries(params).forEach(([key, value]) => {
    if (value && key !== 'captured_at') {
      queryParams.append(key, value);
    }
  });
  
  const queryString = queryParams.toString();
  return queryString ? `/login?${queryString}` : "/login";
};

function App() {
  const [isAuth, setIsAuth] = useState(null);
  
  // fetch loading status from store
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  
  useEffect(() => {
    // Capture UTM parameters on initial load
    captureUTMParameters();
  
    const verifyCookie = async () => {
      try {
        const authenticatedData = await verifyCookieAndRedirect();
        console.log(authenticatedData);
        
        if (authenticatedData && authenticatedData.token) {
          dispatch(setToken(authenticatedData.token));
          dispatch(fetchUserInfo(authenticatedData.token));
          setIsAuth(authenticatedData.authenticated);
        } else {
          setIsAuth(false);
        }
      } catch (error) {
        console.error("Cookie verification failed:", error);
        setIsAuth(false);
      }
    };

    verifyCookie();
  }, []);

  // Generate login path with UTM parameters
  const loginPath = createLoginPathWithUTM();

  return (
    <>
      <BrowserRouter>
        <UTMHandler>
          {loading && <Loader />}
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route
              path="/invitationRegister"
              element={<InvitationRegister />}
            />
            <Route
              path="/InviteWithdrawn"
              element={<InviteWithdrawn />}
            />
            <Route
              path="authenticatewithlinkedin"
              element={<AuthenticateWithLinkedIn />}
            />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route
              path="/"
              element={
                isAuth === null ? (
                  <Loader />
                ) : isAuth ? (
                  <Home />
                ) : (
                  <Navigate to={loginPath} replace />
                )
              }
            >
              {/* <MobileNotification/> */}
              <Route index element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="ai-content" element={<AIContent />} />
              <Route path="on-the-go" element={<OTG />} />
              <Route path="discover" element={<Discover />} />
              <Route path="analytics" element={<Analytics />} />
              {/* <Route path="analytics" element={<AnalyticsComingSoon />} /> */}
              <Route path="postAnalytics" element={<PostAnalytics />} />
              <Route path="calendar" element={<Calender />} />
              <Route path="carousel" element={<Carousel />} />
              <Route path="bookmarks" element={<BookMarks />} />
              <Route path="evergreen" element={<Evergreen />} />
              <Route path="history" element={<History />} />
              <Route path="media" element={<Media />} />
              <Route path="my-queue" element={<MyQueue />} />
              <Route path="order-review" element={<OrderReview />} />
              <Route path="my-accounts" element={<MyAccount />} />
              <Route path="credit-history" element={<CreditHistory />} />
              <Route path="help-desk" element={<HelpDesk />} />
              <Route path="buy-subscription" element={<BySubscription />} />
              <Route path="testing" element={<Testing />} />
            </Route>
            <Route
              path="/login"
              element={!isAuth ? <Login /> : <Navigate to={"/"} />}
            />
            {/* No page found */}
            <Route path="*" element={<Navigate to={"/dashboard"} />} />
            <Route path="/VerifyMember" element={<VerifyMember />} />
            <Route path="/loader" element={<Loader />} />
          </Routes>
        </UTMHandler>
      </BrowserRouter>
    </>
  );
}

export default App;