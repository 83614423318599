import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const MediaDropdown = ({ isDark, searchPlat, setSearchPlat, setShow1, dispatch, clearImages, setSearchText }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const options = [{ name: "Pixabay" }, { name: "Unsplash" }, { name: "Pexels" }];

  const handleOptionSelect = (option) => {
    setSearchPlat(option);
    setShow1(true);
    dispatch(clearImages());
    setDropdownOpen(false);
  };

  return (
    <div className="relative top-1 ml-2 max-xl:h-[40px] h-[46px]" style={{ width: "20%" }}>
      <button className={`w-full h-full p-2 rounded-md ${isDark ? "bg-gray-200 text-black" : "bg-gray-700 text-white"} flex justify-between items-center`} onClick={() => setDropdownOpen(!dropdownOpen)}>
        {searchPlat || "Select Platform"}
        {dropdownOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {dropdownOpen && (
        <div className={`absolute top-full left-0 w-full mt-1 rounded-md shadow-lg ${isDark ? "bg-white" : "bg-gray-800"} z-10`}>
          {options.map((option) => (
            <div key={option.name} className={`p-2 cursor-pointer flex items-center ${isDark ? "hover:bg-gray-100" : "hover:bg-gray-700"}`} onClick={() => handleOptionSelect(option.name)}>
              <span className={`${isDark ? "text-black" : "text-white"} max-xl:hidden`}>{option.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MediaDropdown;
