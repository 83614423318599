import React from "react";
import { useSelector } from "react-redux";
import { IMAGE_URL } from "../../api/API";

/* The `PostSummeryBox` component is a functional React component that takes in props `icon`, `name`,
`count`, and `dataKey`. Within the component, it uses the `useSelector` hook from `react-redux` to
access the Redux store state. */
const PostSummeryBox = ({ icon, name, count, dataKey }) => {
  const { isDark } = useSelector((state) => state.common);
  const { summary } = useSelector((state) => state.analytics);

  let displayCount;
  if (count !== undefined) {
    displayCount = count;
  } else if (dataKey && summary && summary[dataKey] !== undefined) {
    displayCount = summary[dataKey];
  } else {
    displayCount = 0;
  }

  return (
    <div className={`post-box ${isDark ? "grediant-border" : "darkMode"} text-sm xl:text-base`} style={{ margin: "0 0 10px 0" }}>
      <div className={`${isDark ? "post-box-container" : "post-box-container-dark"} flex items-center p-[10px] gap-2`}>
        <img src={`${IMAGE_URL}/images/Icons/dashboard/${icon}.svg`} className="ml-1 xl:ml-3" style={{ width: "36px", height: "36px" }} />
        <div className="post-box-text" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <p className="head-text" style={{ margin: 0 }}>
            {name}
          </p>
          <h3>{displayCount > 1000 ? `${(displayCount / 1000).toFixed(1)}K` : displayCount}</h3>
        </div>
      </div>
    </div>
  );
};

export default PostSummeryBox;
