import React from "react";
import QueueContainer from "./queueContainer/QueueContainer";
import { useDispatch, useSelector } from "react-redux";
import PreviewPost from "../../PostIdea/PreviewPost/PreviewPost";
import { useEffect } from "react";
import { clearPostState } from "../../../store/features/post/postSlice";

/* This code snippet defines a functional React component named `MyQueueContainer`. 
It takes a prop `weeks` and uses the `useSelector` hook to access the `myQueueData` state from the Redux store. */

const MyQueueContainer = ({ weeks }) => {
  const { myQueueData } = useSelector((state) => state.scheduleData);
  const post = useSelector((state) => state.post);
  const dispatch = useDispatch();

  useEffect(() => {}, []);
  return (
    <>
      <div className="row">
        <div className={`queue-post-container ${"col-md-12"} `} style={{ overflowY: "scroll", maxHeight: "70vh" }}>
          {weeks?.map((perticularDate, i) => {
            if (i === weeks?.length - 1) {
              return;
            }
            return <QueueContainer key={i} index={i} date={perticularDate} data={myQueueData[perticularDate]} />;
          })}
        </div>
        {/* {
            post.postContent && <div className="col-xxl-4 col-lg-5 col-md-6 mt-4">
                <PreviewPost isPreview={true} />
              </div>
          } */}
      </div>
    </>
  );
};

export default MyQueueContainer;
