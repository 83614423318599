import React, { useEffect, useState } from "react";
import "./MyQueue.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMyQueueDate,
  fetchQueueData,
} from "../../store/features/scheduleData/scheduleDataSlice";
import MyDraftContainer from "../../components/MyQueue/myDraft/MyDraftContainer";
import MyQueueContainer from "../../components/MyQueue/myQueueContainer/MyQueueContainer";
import { Link, useNavigate } from "react-router-dom";
import { clearPostState } from "../../store/features/post/postSlice";

const MyQueue = () => {
  const [hidden, setHidden] = useState({
    date: "block",
    time: "block",
  });
  const { userData } = useSelector((state) => state.user);
  const { myQueueData, myQueueDate } = useSelector(
    (state) => state.scheduleData
  );
  const { draftData } = useSelector((state) => state.drafts);
  const { isDark, layoutShift } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [dateMap, setDateMap] = useState(Object.keys(myQueueData));
  const [weeks, setWeeks] = useState(getWeekDatesForDate(myQueueDate));
  const filterTabs = ["Post Queue", "Draft"];
  const [selectedTab, setSelectedTab] = useState(filterTabs[0]);
  const navigate = useNavigate();

  // tabs are manupulate here
  let componentToRender;
  switch (selectedTab) {
    case "Draft":
      componentToRender = <MyDraftContainer />;
      break;
    default:
      componentToRender = <MyQueueContainer weeks={weeks} />;
      break;
  }

  // date change from date input field
  const dateChangeFn = (e) => {
    setWeeks(getWeekDatesForDate(e.target.value));
    dispatch(changeMyQueueDate(e.target.value));
  };

  // get week dates of that given date
  function getWeekDatesForDate(dateQueue) {
    const weekDates = [];
    const date = new Date(dateQueue);
    date.setDate(date.getDate() - 1);
    for (let i = 0; i < 8; i++) {
      const day = new Date(date);
      day.setDate(date.getDate() + i);
      weekDates.push(day.toISOString().split("T")[0]);
    }
    return weekDates;
  }

  useEffect(() => {
    // fetch perticular week of data from current/given date
    setWeeks(getWeekDatesForDate(myQueueDate));
    let queueStartDate = weeks[0];
    let queueEndDate = weeks[weeks?.length - 1];

    dispatch(
      fetchQueueData({
        userId: userData.linkedinId,
        startDate: queueStartDate,
        endDate: queueEndDate,
      })
    );
    setDateMap(Object.keys(myQueueData));

    // dispatch(clearPostState())
  }, []);

  useEffect(() => {
    let queueStartDate = weeks[0];
    let queueEndDate = weeks[weeks?.length - 1];

    dispatch(
      fetchQueueData({
        userId: userData.linkedinId,
        startDate: queueStartDate,
        endDate: queueEndDate,
      })
    );
    setDateMap(Object.keys(myQueueData));
  }, [myQueueDate]);

  return (
    <div
      className={`flex flex-col py-12 ${layoutShift ? "pl-24 pr-20" : "px-6"}`}
      style={{ marginBottom: "0px" }}
    >
      <div className="flex flex-col">
        <h3 className="text-sm sm:text-xl">
          Being consistent never been easier
        </h3>
        <p className="mt-3 text-base">
          Manage{" "}
          <u>
            <Link to="/my-accounts?aiConfig=0" className="text-blue-600">
              Auto-Schedule
            </Link>
          </u>
        </p>
      </div>
      <div className="queue-header flex justify-between items-center mt-4">
        <div className="">
          {filterTabs?.map((tab, i) => {
            if (selectedTab === tab) {
              if (tab === "Draft") {
                return (
                  <span
                    key={i}
                    className={` ${
                      isDark ? "isActive-tab-light" : "isActive-tab-dark"
                    } `}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {tab}
                  </span>
                );
              } else {
                return (
                  <span
                    key={i}
                    className={` ${
                      isDark ? "isActive-tab-light" : "isActive-tab-dark"
                    } `}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {tab}
                  </span>
                );
              }
            } else {
              return (
                <span key={i} onClick={() => setSelectedTab(tab)}>
                  {tab}
                </span>
              );
            }
          })}
        </div>
        <div className=" d-flex justify-content-between align-items-center">
          <div className="queue-date">
            <input
              type="date"
              onClick={(e) => {
                e.target.type = "date";
                e.target.showPicker();

                setHidden({
                  ...hidden,
                  date: "none",
                });
              }}
              onBlur={(e) => {
                e.target.type = "date";
                setHidden({
                  ...hidden,
                  date: "block",
                });
              }}
              className={`form-control ${
                selectedTab !== "Post Queue" && "invisible"
              } `}
              value={myQueueDate}
              onChange={(e) => dateChangeFn(e)}
            />
          </div>
          <i
            className={`m-2 pb-3 fa-solid fa-gear ${
              selectedTab !== "Post Queue" && "invisible"
            }`}
            style={isDark ? { color: "#000" } : { color: "#fff" }}
            title="Change your Time Slot"
            onClick={() => navigate("/my-accounts")}
          ></i>
        </div>
      </div>

      <div className="queue-body">
        <div className="queue-body-post">{componentToRender}</div>
      </div>
    </div>
  );
};

export default MyQueue;
