import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { removeMedia, removeMediaDis, removeMediaTitle, changePostType } from "../../../store/features/post/postSlice";
import { IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../../api/API";

// This is the preview post component, it is used to preview the post before posting it, Its using in AIPostGen page

const PreviewPost = (props) => {
  const user = useSelector((state) => state.user);
  const post = useSelector((state) => state.post);
  const { isDark } = useSelector((state) => state.common);
  const [modalData, setModalData] = useState(null);
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();

  // This is the useEffect hook, it is used to fetch the metadata of the post
  useEffect(() => {
    if (post.postType === "ARTICLE") {
      setUrl(post.originalUrl);
    }
  }, [post.postType, post.originalUrl]);

  useEffect(() => {
    if (url !== "") {
      fetchMetaData();
    }
  }, [url]);

  const fetchMetaData = async () => {
    try {
      const response = await axios.post(`${apibase}/api/get-meta-data`, {
        url,
      });

      setModalData(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);

      setModalData({
        data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
      });
    }
  };

  const deleteMedia = (url, index) => {
    dispatch(removeMedia(index));

    dispatch(removeMediaDis(index));

    dispatch(removeMediaTitle(index));

    if (post.mediaTitle.filter((_, i) => i !== index).length === 0) {
      dispatch(changePostType("NONE"));
    }
  };

  /* The above code is a JavaScript function called `renderMedia` that determines the type of media
 (video, PDF, or image) based on the `mediaType` and then renders the corresponding media content
 using JSX. The `mediaType` and `mediaUrl` are assumed to be obtained from a Redux store. */

  const renderMedia = () => {
    const mediaType = post.postType; // Get from Redux store
    const mediaUrl = post.originalUrl; // Get from Redux store

    switch (mediaType) {
      case "VIDEO":
        return <video src={mediaUrl} controls controlsList="nodownload" playsInline className="preview-video" />;
      case "PDF":
        return (
          <div className="pdf-preview">
            <iframe src={`${mediaUrl}#toolbar=0`} title="PDF Preview" className="pdf-iframe" />
          </div>
        );
      case "IMAGE":
      default:
        return <img src={mediaUrl} alt="" className="preview-image" />;
    }
  };

  const renderMediaGallery = () => {
    if (!post.mediaTitle.length || post.postType !== "IMAGE") return null;

    const renderImage = (url, index, showOverlay = false, overlayCount = 0) => (
      <div key={index} className="relative h-full">
        <img src={`${IMAGE_URL_BACKEND}/uploads/${url}`} className="w-full h-full object-cover rounded-lg" alt={`Media ${index}`} style={{ maxHeight: post.mediaTitle.length > 2 ? "200px" : "400px" }} />

        {!props.isDeleteBtnHide && (
          <button onClick={() => deleteMedia(url, index)} className="absolute top-2 right-2 p-2 bg-white/80 rounded-full hover:bg-white transition-colors group" title="Delete">
            <i className="fa-regular fa-trash-can text-gray-700 group-hover:text-gray-900" />
          </button>
        )}

        {showOverlay && overlayCount > 0 && (
          <div className="absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg cursor-pointer">
            <span className="text-2xl font-bold text-white">+{overlayCount}</span>
          </div>
        )}
      </div>
    );

    return (
      <div className="px-4 mt-4">
        {post.mediaTitle.length === 1 ? (
          // Single image layout
          <div className="max-h-[400px] overflow-hidden rounded-lg">{renderImage(post.mediaTitle[0], 0)}</div>
        ) : post.mediaTitle.length === 2 ? (
          // Two images layout
          <div className="grid grid-cols-2 gap-4 max-h-[400px]">{post.mediaTitle.map((url, index) => renderImage(url, index))}</div>
        ) : post.mediaTitle.length === 3 ? (
          // Three images layout
          <div className="grid grid-cols-2 gap-4 max-h-[400px]">
            <div className="h-full">{renderImage(post.mediaTitle[0], 0)}</div>
            <div className="grid grid-rows-2 gap-4">{post.mediaTitle.slice(1, 3).map((url, index) => renderImage(url, index + 1))}</div>
          </div>
        ) : (
          // Four or more images layout
          <div className="grid grid-cols-2 gap-2 max-h-[400px]">
            <div className="grid grid-rows-2 gap-2">{post.mediaTitle.slice(0, 2).map((url, index) => renderImage(url, index))}</div>
            <div className="grid grid-rows-2 gap-2">{post.mediaTitle.slice(2, 4).map((url, index) => renderImage(url, index + 2, index === 1 && post.mediaTitle.length > 4, post.mediaTitle.length - 4))}</div>
          </div>
        )}
      </div>
    );
  };

  const renderMediaContent = () => {
    if (!post.mediaTitle.length) return null;

    // For PDFs
    if (post.postType === "PDF") {
      const pdfUrl = `${IMAGE_URL_BACKEND}/uploads/${post.mediaTitle}`;
      return (
        <div className="px-4 mt-4">
          <div className="pdf-preview rounded-lg overflow-hidden w-full h-[400px]">
            <iframe src={`${pdfUrl}#page=1&view=FitH&toolbar=0&navpanes=0&scrollbar=0`} title="PDF Preview" className="pdf-iframe w-full h-[400px]" />
          </div>
          {!props.isDeleteBtnHide && (
            <button onClick={() => deleteMedia(post.mediaTitle, 0)} className="absolute top-2 right-6 p-2 bg-white/80 rounded-full hover:bg-white transition-colors group" title="Delete">
              <i className="fa-regular fa-trash-can text-gray-700 group-hover:text-gray-900" />
            </button>
          )}
        </div>
      );
    }

    // For Videos
    if (post.postType === "VIDEO") {
      const videoUrl = `${IMAGE_URL_BACKEND}/uploads/${post.mediaTitle}`;
      return (
        <div className="px-4 mt-4 relative">
          <div className="rounded-lg overflow-hidden">
            <video src={videoUrl} className="w-full rounded-lg preview-video" controls controlsList="nodownload" playsInline />
          </div>
          {!props.isDeleteBtnHide && (
            <button onClick={() => deleteMedia(post.mediaTitle, 0)} className="absolute top-2 right-6 p-2 bg-white/80 rounded-full hover:bg-white transition-colors group" title="Delete">
              <i className="fa-regular fa-trash-can text-gray-700 group-hover:text-gray-900" />
            </button>
          )}
        </div>
      );
    }

    // For Images
    if (post.postType === "IMAGE") {
      return renderMediaGallery();
    }
  };

  return (
    <div className="w-full mt-3">
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-[20px] font-semibold text-gray-600">Preview Post</h3>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-200">
        {/* Header */}

        <div className="p-4 pb-2">
          <div className="flex items-start justify-between">
            <div className="flex gap-3">
              <img src={user?.userPhoto || `${IMAGE_URL}/images/Icons/avatar.jpg`} className="w-12 h-12 rounded-full border object-cover" alt="Profile" />

              <div className="flex flex-col">
                <span className="font-semibold text-[14px] text-black hover:text-blue-600 cursor-pointer">{user?.userData?.userName?.length > 30 ? `${user?.userData?.userName.slice(0, 30)}...` : user?.userData?.userName}</span>

                <span className="text-[10px] text-black">{user?.userData?.localizedHeadline?.length > 30 ? `${user?.userData?.localizedHeadline.slice(0, 30)}...` : user?.userData?.localizedHeadline}</span>

                <span className="text-[10px] text-black flex items-center gap-1">
                  Now • <i className="fa-solid fa-earth-americas text-[10px]"></i>
                </span>
              </div>
            </div>

            <i className="fa-brands fa-linkedin text-[#0a66c2] text-xl"></i>
          </div>
        </div>

        {/* Content of post which will publish on linkedin */}

        <div className="px-4 pt-1">
          {(() => {
            const content = post.postContent || "";
            const parts = content.split(/(#[^\s#]+)/g);
            const mainContent = parts
              .filter((part) => !part.startsWith("#"))
              .join(" ")
              .trim();

            const hashtags = parts.filter((part) => part.startsWith("#")).join(" ");
            return (
              <div className="text-[13px] text-black whitespace-pre-wrap">
                {mainContent}

                {hashtags && (
                  <>
                    {"\n\n"}
                    <span className="text-black font-bold">{hashtags}</span>
                  </>
                )}
              </div>
            );
          })()}
        </div>

        {/* Media */}

        <div className="preview-media-container">{renderMediaContent()}</div>

        {/* Article Preview */}

        {post.originalUrl && post.postType === "ARTICLE" && modalData?.data && (
          <div className="mx-4 mt-4 border border-gray-200 rounded-lg overflow-hidden hover:bg-gray-50 transition-colors cursor-pointer">
            <a href={post.originalUrl} target="_blank" rel="noopener noreferrer" className="block">
              {modalData.data.ogImage?.[0]?.url && <img src={modalData.data.ogImage[0].url} className="w-full h-52 object-cover" alt="Article preview" />}

              <div className="p-3">
                <h4 className="font-semibold text-[14px] text-gray-900">{modalData.data.ogTitle}</h4>

                <p className="text-[12px] text-gray-600 mt-1 line-clamp-2">{modalData.data.ogDescription}</p>
              </div>
            </a>
          </div>
        )}

        {/* Engagement Section */}

        <div className="mt-4 px-4 pb-2">
          {/* <div className="flex items-center gap-1 mb-2">
            <img src="/images/linkedInLikes.png" className="w-4 h-4" alt="Like" />
            <span className="text-[12px] text-black">0</span>
          </div> */}

          <hr className="border-gray-200" />
          <div className="grid grid-cols-4 -mx-1 mt-1">
            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
              <i className="fa-regular fa-thumbs-up text-black"></i>
              <span className="text-[13px] font-medium text-black">Like</span>
            </button>

            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
              <i className="fa-regular fa-comment text-black"></i>
              <span className="text-[13px] font-medium text-black">Comment</span>
            </button>

            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
              <i className="fa-solid fa-retweet text-black"></i>
              <span className="text-[13px] font-medium text-black">Repost</span>
            </button>

            <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
              <i className="fa-regular fa-paper-plane text-black"></i>
              <span className="text-[13px] font-medium text-black">Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPost;
