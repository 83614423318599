import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IMAGE_URL } from "../../api/API";
import { postApi, getApi, deleteApi, putApi } from "../../api/methods";
import { toast } from "react-toastify";
import axios from "axios";
import "./TeamPermissions.css";
import PostAd from "../Dashboard/PostAd";
import { Link } from "react-router-dom";
import Modal from "react-modal";

const TeamPermissions = () => {
  const userInfo = useSelector((state) => state.user.userData);
  const userPhoto = useSelector((state) => state.user.userPhoto);
  const userData = useSelector((state) => state.user.userData);
  const teamMembers = useSelector((state) => state.user.teamMembers);
  const [teamMembersLocal, setTeamMembersLocal] = useState(teamMembers);
  const { isDark } = useSelector((state) => state.common);
  const filterActiveTeamMembers = teamMembers.filter((member) => member.ac_status === true);
  const [teamMembersCount, setTeamMembersCount] = useState(filterActiveTeamMembers?.length || 0);
  const [inviteTextBox, setInviteTextBox] = useState(false);
  const [inviteEmailText, setInviteEmailText] = useState("");
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [isFree, setIsFree] = useState(false); // State to manage the free tier
  const [planName, setPlanName] = useState("");
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [companyAccounts, setCompanyAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);


  /**
   * The function `getPlanName` asynchronously fetches subscription data for a specific team and sets
   * the plan name based on the retrieved data.
   * @returns The `getPlanName` function is an asynchronous function that fetches subscription data for
   * a specific team ID and sets the plan name based on the retrieved data. The plan name is then
   * logged to the console. If an error occurs during the data fetching process, the function will log
   * an error message.
   */
  const getPlanName = async () => {
    let transactionData;
    try {
      transactionData = await getApi(`transaction/get-subscription-by-id/${userData.teamId}`);
      setPlanName(transactionData[0].plan_Name);
      console.log(planName);
    } catch (error) {
      console.error("Error fetching transaction data:", error);
      return;
    }
  };

  /**
   * The useEffect hook fetches invited members for a team and the handleMemberDelete function removes
   * a member from the team after confirming with a user prompt.
   */
  useEffect(() => {
    const fetchInvitedMembers = async () => {
      try {
        const response = await getApi(`invite/get-team-members-by-teamid/${userInfo.teamId}`, { withCredentials: true });
        setInvitedMembers(response.data.invitedMembers);
      } catch (error) {
        console.error("Error fetching invited members:", error);
      }
    };
    fetchInvitedMembers();
    getPlanName();
  }, [userInfo.teamId]);
  const handleMemberDelete = async (userId) => {
    const confirmed = window.confirm("Are you sure you want to remove this member?");
    if (confirmed) {
      const url = `/invite/change-team/${userId}`;
      try {
        const updatedUser = await putApi(url, null, { withCredentials: true });

        // Update state by filtering out the deleted member based on userId
        setTeamMembersLocal((prevMembers) => prevMembers.filter((member) => member.id !== userId));

        // Success toast notification
        toast.success("Member removed successfully!");
        console.log("User updated:", updatedUser);
      } catch (error) {
        console.error("Error updating user:", error);
        // Error toast notification
        toast.error(error.response?.data?.message || "Failed to remove member.");
      }
    }
  };

  /**
   * The function `handleDelete` is used to confirm and remove an invitation for a member, updating the
   * state and displaying success or error messages accordingly.
   */
  const handleDelete = async (memberEmail) => {
    const confirmed = window.confirm("Are you sure you want to remove this invite?");
    if (confirmed) {
      try {
        const response = await deleteApi(`/invite/removeInvitation/${memberEmail}`, null, { withCredentials: true });

        // Check the response for success
        if (response.success) {
          // Update state by filtering out the deleted member
          setInvitedMembers(invitedMembers.filter((member) => member.emailTo !== memberEmail));
          toast.success("Invite removed successfully!");
        } else {
          toast.error(response.message || "Failed to remove the invite.");
        }
      } catch (error) {
        console.error("Error removing invite:", error);
        toast.error(error.response?.data?.message || "Failed to remove invite.");
      }
    }
  };

  /* The above code is using the `useEffect` hook in a React component to check the subscription status
 of a user's team. It makes an asynchronous API call to fetch transaction data for the user's team
 ID and then checks if the first transaction has a plan name of "Free-Tier". If the condition is
 met, it sets the state variable `isFree` to true. If there is an error during the API call, it logs
 the error to the console. This effect runs whenever the `userData.teamId` changes. */
  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const transactionData = await getApi(`transaction/get-subscription-by-id/${userData.teamId}`);
        if (transactionData.length > 0 && transactionData[0].plan_Name === "Free-Tier" || "Pro") {
          setIsFree(true);
        }
      } catch (error) {
        console.error("Error checking subscription:", error);
      }
    };

    checkSubscription();
  }, [userData.teamId]);

  /**
   * The function `sendInviteLink` checks if the user can invite team members based on their
   * subscription plan, validates the email address, and sends an invite if all conditions are met.
   * @returns The `sendInviteLink` function returns different messages based on the conditions met
   * during its execution. Here are the possible return statements:
   */
  const sendInviteLink = async () => {
    if (isFree) {
      toast.error("Upgrade your plan to invite more team members.");
      return;
    }

    let transactionData;
    try {
      transactionData = await getApi(`transaction/get-subscription-by-id/${userData.teamId}`);
    } catch (error) {
      console.error("Error fetching transaction data:", error);
      toast.error("Failed to fetch transaction data.");
      return;
    }

    if (transactionData && transactionData.length > 0) {
      const planName = transactionData[0].plan_Name;

      if (planName === "Free-Tier") {
        toast.error("You are not allowed to invite team members on a free plan.");
        toast.info("Upgrade your plan to invite more team members.");
        return;
      }
    }

    if (!inviteEmailText || inviteEmailText.length === 0) {
      toast.error("Email field needs to be filled.");
      return;
    }

    if (!inviteEmailText.includes("@") || !inviteEmailText.includes(".")) {
      toast.error("Invalid email address.");
      return;
    }

    const [emailUsername, emailHosting] = inviteEmailText.split("@");
    const [emailHost, emailDomain] = emailHosting.split(".");

    if (emailUsername?.length > 3 && emailHost?.length > 3 && emailDomain?.length > 1) {
      try {
        const response = await postApi(
          `invite/invite-member`,
          {
            email: inviteEmailText,
            teamId: userInfo.teamId,
            userId: userInfo.userId,
            invitingPerson: emailUsername,
          },
          { withCredentials: true }
        );

        const data = response;

        if (data.success === true) {
          setInviteEmailText("");
          setInviteTextBox(false);
          toast.success("Invite sent successfully!");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error inviting team member:", error);
        toast.error(error.response?.data?.message || "Failed to send invite.");
      }
    } else {
      toast.error("Invalid email address.");
    }
  };

  /**
   * The function fetches LinkedIn company accounts data using the user's LinkedIn ID and handles
   * loading, error, and success states accordingly.
   */
  const fetchLinkedInCompanyAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await getApi(`linkedin/company-accounts/${userInfo.linkedinId}`);
      setCompanyAccounts(response.data);
    } catch (error) {
      toast.error("Failed to fetch company accounts");
      console.error("Error fetching company accounts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * The function `handleConnectCompany` connects a company account using LinkedIn API and displays
   * success or error messages accordingly.
   */
  const handleConnectCompany = async (companyId) => {
    try {
      const response = await postApi("linkedin/connect-company", {
        userId: userInfo.userId,
        teamId: userInfo.teamId,
        companyId: companyId,
      });

      if (response.success) {
        toast.success("Company account connected successfully!");
        setIsCompanyModalOpen(false);
        setSelectedCompany(null);
      }
    } catch (error) {
      toast.error("Failed to connect company account");
      console.error("Error connecting company:", error);
    }
  };

  const CompanyAccountsModal = () => (
    
    <Modal isOpen={isCompanyModalOpen} onRequestClose={() => setIsCompanyModalOpen(false)} className={`company-modal ${isDark ? "light-theme" : "dark-theme"}`} overlayClassName="company-modal-overlay">
      <div className="p-6 max-w-2xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-xl">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Connect LinkedIn Company Account</h3>
          <button onClick={() => setIsCompanyModalOpen(false)} className="text-gray-400 hover:text-gray-500">
            <i className="fas fa-times"></i>
          </button>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : companyAccounts.length > 0 ? (
          <div className="space-y-4">
            {companyAccounts.map((company) => (
              <div
                key={company.id}
                className={`p-4 border rounded-lg cursor-pointer transition-all duration-200 ${selectedCompany?.id === company.id ? "border-blue-500 bg-blue-50 dark:bg-blue-900" : "border-gray-200 hover:border-blue-300 dark:border-gray-700"}`}
                onClick={() => setSelectedCompany(company)}
              >
                <div className="flex items-center space-x-4">
                  {company.logoUrl && <img src={company.logoUrl} alt={company.name} className="w-12 h-12 rounded-full" />}
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">{company.name}</h4>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{company.industry}</p>
                  </div>
                </div>
              </div>
            ))}

            <div className="flex justify-end mt-6 space-x-3">
              <button onClick={() => setIsCompanyModalOpen(false)} className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600">
                Cancel
              </button>
              <button
                onClick={() => selectedCompany && handleConnectCompany(selectedCompany.id)}
                disabled={!selectedCompany}
                className={`px-4 py-2 text-white rounded-lg ${selectedCompany ? "bg-blue-500 hover:bg-blue-600" : "bg-blue-300 cursor-not-allowed"}`}
              >
                Connect Account
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-500 dark:text-gray-400">No company accounts found. Make sure you have admin access to LinkedIn company pages.</p>
          </div>
        )}
      </div>
    </Modal>
  );

  return (
    <div className="relative w-full " style={{ height: "100vh" }}>
      {isFree && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-3">
          <div className={`  ${isDark ? "post-ad grediant-border" : "post-ad-dark"}  `} style={{ width: "50%" }}>
            {/* <div className="post-ad-img d-flex justify-content-center"> */}
            {/* <img src={`${IMAGE_URL}/images/Icons/dashboard/${icon}.svg`} /> */}
            {/* </div> */}
            <div className="post-box-text">
              {/* <h3 className="d-flex justify-content-center w-100 mb-3 text-sm xl:text-xl">
                  Content
                </h3> */}
              <p className="d-flex justify-content-center w-100 text-xs xl:text-lg">You have discovered a premium feature. To access all our features, you need to subscribe to one of our plans by clicking the button below.</p>
            </div>
            <div className="btn-create-post">
              <Link
                to="/buy-subscription"
                className="grediant-button"
                style={{
                  background: " linear-gradient(126deg,#8A51FF -9.26%,#39B2FF 79.86%)",
                }}
                title="Upgrade"
              >
                Upgrade
              </Link>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          filter: isFree ? "grayscale(100%)" : "none",
          pointerEvents: isFree ? "none" : "auto",
        }}
      >
        <div>
          <h3 className="mb-2">
            <strong style={{ fontSize: "1.3rem" }}>Manage your team</strong>
          </h3>
          <p className="text-sm">Manage your team members and their permissions here.</p>
        </div>
        <div>
          {teamMembersLocal &&
            teamMembersLocal?.map((member, i) => {
              if (member.userName?.length > 0) {
                return (
                  <div className="userinfo-list mt-2" key={i}>
                    <div className="d-flex align-items-center">
                      {member.linkedinProfile?.length > 0 ? (
                        <img src={member.linkedinProfile} className="avatar-img w-10 rounded-full" style={{ borderRadius: "50%" }} />
                      ) : (
                        <img src={`${IMAGE_URL}/images/Icons/avatar.jpg`} className="avatar-img w-10 rounded-full" style={{ borderRadius: "50%" }} />
                      )}
                      <p className="mb-0 ms-2">
                        <strong>{member.userName}</strong>
                      </p>
                      {member.teamPrimary == 1 ? (
                        <span className="badge bg-primary m-2">Admin</span>
                      ) : (
                        <>
                          <span className="badge bg-secondary m-2">Member</span>
                          {userInfo.teamPrimary && (
                            <i
                              className="fas fa-trash-alt text-danger ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleMemberDelete(member.userId)} // Assume there's a handleDelete function
                            ></i>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <p className="mb-0 me-3">
                        {" "}
                        <i className="fa fa-user-plus" aria-hidden="true"></i> {[member.createdAt?.split("T")[0].split("-")[2], member.createdAt?.split("T")[0].split("-")[1], member.createdAt?.split("T")[0].split("-")[0]].join("-")}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>

        <div>
          {invitedMembers.length > 0 ? (
            invitedMembers.map((member, i) => (
              <div className="userinfo-list mt-2" key={i}>
                <div className="d-flex align-items-center">
                  <img src={`${IMAGE_URL}/images/Icons/avatar.jpg`} className="avatar-img w-10 rounded-full" style={{ borderRadius: "50%" }} />
                  <p className="mb-0 ms-2">
                    <strong>{member.emailTo}</strong>
                  </p>
                  <span className="badge bg-warning m-2">Pending Invite</span>
                  <i className="fa fa-trash text-danger ms-3 cursor-pointer" onClick={() => handleDelete(member.emailTo)}></i>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="my-3">
          <p className="text-lg">
            {planName === "Pro"
              ? "You cannot invite team members on a Pro plan"
              : planName === "Creator"
              ? "Your plan allows up to 5 team members"
              : planName === "Growth"
              ? "Your plan allows up to 7 team members"
              : "You cannot invite team members on a free plan"}
          </p>
        </div>
        {/* //Your plan allows up to 4 team members */}
        {inviteTextBox && (
          <div className="my-2">
            <input className="form-control" type="email" value={inviteEmailText} onChange={(e) => setInviteEmailText(e.target.value)} placeholder="Email for invitation" />
          </div>
        )}

        {inviteEmailText?.length > 0 ? (
          <div className="team-btn">
            <button onClick={sendInviteLink}>
              {" "}
              <i className="fa-regular fa-paper-plane"></i> SEND
            </button>
          </div>
        ) : (
          <div className="team-btn">{userInfo.teamPrimary && <button onClick={() => setInviteTextBox(!inviteTextBox)}> + INVITE NOW</button>}</div>
        )}

        {/* <div className="my-4">
          <div className="flex items-center justify-between mb-3">
            <h3 className="text-xl font-semibold">LinkedIn Company Accounts</h3>
            {userInfo.teamPrimary && (
              <button
                onClick={() => {
                  setIsCompanyModalOpen(true);
                  fetchLinkedInCompanyAccounts();
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center space-x-2"
              >
                <i className="fab fa-linkedin"></i>
                <span>Add Company Account</span>
              </button>
            )}
          </div>
        </div> */}

        <CompanyAccountsModal />
      </div>
    </div>
  );
};

export default TeamPermissions;
