import React from "react";
import Twitter from "./AccountLists/Twitter";
import Facebook from "./AccountLists/Facebook";
import Instagram from "./AccountLists/Instagram";
import { BASE_URL, IMAGE_URL } from "../../api/API";

const AccountList = ({ setIsAccount, getConnData, accountType, isAccount }) => {
  let renderComponets;
  switch (true) {
    case accountType === "tw":
      renderComponets = <Twitter />;
      break;
    case accountType === "fb":
      renderComponets = <Facebook setIsAccount={setIsAccount} isAccount={isAccount} getConnData={getConnData} />;
      break;
    case accountType === "ig":
      renderComponets = <Instagram setIsAccount={setIsAccount} isAccount={isAccount} getConnData={getConnData} />;
      break;

    default:
      break;
  }
  return (
    <>
      <div className="AccountList">
        <div className="AccountListContainer">
          <div className="fbpage-icon" onClick={() => setIsAccount(false)}>
            <i className="fa-regular fa-circle-xmark  fa-2x"></i>
          </div>
          <h2 className="text-dark flex gap-2 items-center mb-3 text-xl">
            {" "}
            {accountType === "tw" ? (
              "Twitter"
            ) : accountType === "fb" ? (
              <>
                <img src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} height={30} width={30} alt="" />
                Facebook
              </>
            ) : (
              <>
                <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} height={30} width={30} alt="" />
                Instagram
              </>
            )}
          </h2>
          <hr />
          {renderComponets}
        </div>
      </div>
    </>
  );
};

export default AccountList;
