import React, { useEffect, useRef, useState } from "react";
import "../components/OverlayCreatePost.css";
import { useDispatch, useSelector } from "react-redux";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import * as htmlToImage from "html-to-image";
import { aiPostTopic, aiPostArticle, aiPostText, resetSlides } from "../../src/store/features/carousel/action/adjustAction";
import jsPDF from "jspdf";
import {
  addAIPost,
  addDraftPostId,
  addMedia,
  addMediaDis,
  addOriginalUrl,
  changeConnectionType,
  changePostSelection,
  changePostType,
  clearPostState,
  setPostGenerationType,
  setActiveComponent,
  updateOverlayContent,
} from "../store/features/post/postSlice";
import { toast } from "react-toastify";
import { deleteApi, getApi, postApi } from "../api/methods";
import { isLoading } from "../store/features/common/commonSlice";
import { POSTTAG_CUSTOM } from "../utility/enums.constant";
import Media from "../pages/Media/Media";
import Modal from "react-bootstrap/esm/Modal";
import { Form } from "react-bootstrap";
import { Button } from "./ui/button";
import addImg from "../assets/addImg.svg";
import addImgWhite from "../assets/addImgWhite.svg";
import uploadImg from "../assets/uploadImg.svg";
import recentImg from "../assets/recentImage.svg";
import searchImg from "../assets/searchImg.svg";
import uploadImgWhite from "../assets/uploadImgWhite.svg";
import recentImgWhite from "../assets/recentImgWhite.svg";
import searchImgWhite from "../assets/searchImgWhite.svg";
import { imageUploadAction, clearImages, deleteRecentImage, pexelImageData, pixabayImageData, unsplashImageData } from "../store/features/carousel/action/imageUploadAction";
import { BASE_URL, IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../api/API";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Picker from "@emoji-mart/react";
import emojiData from "@emoji-mart/data";
import { v4 as uuidv4 } from "uuid";
import { POST_GENERATION_TYPES } from "../store/features/post/initialState";
import { addGenerateType, clearGeneratePostState } from "../store/features/generatePost/generatePostSlice";
import PropTypes from "prop-types";

/*
  business logic use here:
    Firstly, composer editor has take input from user type post idea or generated from Open AI

    Now user wants to 
    - copy that post to clipboard
    - add any emoji to any position of that post
    - add uploaded images/gifs/videos etc to tha post
    - add external link to that post
    - add post to evergreen 
    - add post to bookmark
    - rewrite the post using open AI
    - reset all the state to intitial post structure
    - when user can write anything in the editor then that text is save automatically user can see in under my quere > draft tab

    - Publish post: when user click this button that post immediately post in linkedin with their post type like none/image/video/article format as long as posting to selected social media in auto distibution
    - schedule post: It has two types 
      -> auto schedule
        - In auto schedule when user avaliable time slot is present then asiign this post to this slot and user can see in my queue section
      -> custom schedule
        - In this user can input custom date and time for schedule posting
    
    Auto Plug: Now add auto plug feture means automatically add comment of that post in given time
    
    Auto Distribution: this is for convert post format to selected social media format and publish or schedule for respective social media platform
*/

const OverlayCreatePost = ({ title, onClose, getTwitterUser, getFacebookUser, getInstaUser }) => {
  const dispatch = useDispatch();
  const post = useSelector((state) => state.post);
  const common = useSelector((state) => state.common);
  const { isDark } = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(setActiveComponent("overlay"));
    return () => {
      dispatch(setActiveComponent("main"));
    };
  }, [dispatch]);

  const [hidden, setHidden] = useState({
    date: "block",
    time: "block",
  });

  const [active, setActive] = useState("upload");
  const linkedinId = user?.userData?.linkedinId;
  const linkedinAccessToken = user.userData.accessToken;
  const [schedulePanelOpen, setSchedulePanelOpen] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [checkCustomTime, setCheckCustomTime] = useState(false);
  const [isOnPlug, setIsOnPlug] = useState(false);
  const [autoPlugOpen, setAutoPlugOpen] = useState(false);
  const [autoPlugComment, setAutoPlugComment] = useState("");
  const [autoPlugDelay, setAutoPlugDelay] = useState(null);
  const [autoPlugTags, setAutoPlugTags] = useState(null);
  const [prevComments, setPrevComments] = useState([]);
  const [isOnDist, setIsOnDist] = useState(false);
  const [isOnTweet, setIsOnTweet] = useState(false);
  const [isOnFacebook, setIsOnFacebook] = useState(false);
  const [isOnInsta, setIsOnInsta] = useState(false);
  const [urlPanel, setUrlPanel] = useState(false);
  const [mediaPanel, setMediaPanel] = useState(false);
  const [btnCaroselPdf, setBtnCaroselPdf] = useState(false);
  const [btnCaroselPdfName, setBtnCaroselPdfName] = useState("Convert Carousel");
  const [isPickerVisible, setPickerVisible] = useState(false);
  const buttonRef = useRef(null);
  const [planName, setPlanName] = useState("Free-tier");
  const [isStarFilled, setIsStarFilled] = useState(false);
  const [isDraftFilled, setIsDraftFilled] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handlePixabayApi = (e) => {
    if (e) e.preventDefault();
    {
      searchPlat == "Pexels" ? dispatch(pexelImageData(search)) : searchPlat == "Pixabay" ? dispatch(pixabayImageData(search)) : dispatch(unsplashImageData(search));
    }
  };
  const handleDeleteRecent = (imageUrl) => {
    dispatch(deleteRecentImage(imageUrl));
  };

  const image = "/images/pp.jpeg";

  const handleRemoveImg = (id) => {
    dispatch(imageSet({ id: id, img: "" }));
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(imageUploadAction(reader.result));
      };
      reader.readAsDataURL(file);
    }
    setActive("recents");
  };

  const [showDialog2, setShowDialog2] = useState(false);
  const closeDialog2 = () => {
    setShowDialog2(false);
    setActive("upload");
    setSearch("");
  };
  const handleDialog = (id) => {
    setShowDialog(true);
    setDialogId(id);
  };
  const handleDialog1 = (id) => {
    setShowDialog1(true);
    setDialogId1(id);
  };
  const handleRecentImage = (id, img) => {
    const data = {
      id: id,
      backgroundImage: img,
    };
    dispatch(backgroundImageSet(data));
    setShowDialog(false);
  };

  const handleImageinImage = (id, img) => {
    const data = {
      id: id,
      img: img,
    };
    dispatch(imageSet(data));
    setShowDialog1(false);
    console.log(data);
  };

  // Here convert carosel to pdf button show instead of publish post because post type is linkedin Carousel, Directly crosel post is not send to linikedin posting
  useEffect(() => {
    if (post.postType === "LinkedIn Carousel") {
      setBtnCaroselPdf(true);
    }
  }, [post.postType]);

  const [getThread, setGetThread] = useState(JSON.parse(localStorage.getItem("threadData")) || []);
  const [getFB, setGetFB] = useState(null);
  // console.log({ getThread });
  const [fbPageList, setFbPageList] = useState([]);
  const [isFb, setIsFb] = useState(false);
  const [isIG, setIsIG] = useState(false);
  const [isTweet, setIsTweet] = useState(false);
  const [isInsta, setIsInsta] = useState(false);

  const [getIgAc, setGetIgAc] = useState(null);

  const navigate = useNavigate();
  const getfbUser = () => {
    // Check if the LinkedIn ID is available
    if (linkedinId) {
      // Initialize Facebook ID
      let facebookId = "";

      // Make API call to get Facebook user information
      getApi(`facebook/get-facebook-user?facebookId=${facebookId}&userId=${user?.userData?.userId}`).then((res) => {
        // Check if data is available in the response
        if (res?.data) {
          // Call function to get connected account
          getConnectedAc(res?.data?.facebookId);
        }

        // Alert with the response data (for debugging)
        // alert(JSON.stringify(res?.data))
      });
    }
  };
  const getConnectedAc = (fbid) => {
    // Make API call to get connected accounts using the provided Facebook ID
    getApi(`get-connected-ac?facebookId=${fbid}`).then((res) => {
      // Check if data is available in the response
      if (res?.data) {
        // Set the Facebook page list state with the response data
        setFbPageList(res?.data);
        // Log the response data (for debugging)
        // console.log({ res: res?.data });
      }
    });
  };

  const getPlanName = async () => {
    console.log("user.userData");
    console.log(user.userData.teamId);
    const transactionData = await getApi(`transaction/get-subscription-by-id/${user.userData.teamId}`);
    console.log("transactionData");
    console.log(transactionData[0].plan_Name);
    setPlanName(transactionData[0].plan_Name);
  };

  const updatedContent = post.postContent.split(/#\s*/).map((part, index) => (
    <React.Fragment key={index}>
      {index != 0 ? "#" : ""}
      {part} {index === 0 && part.trim().length > 0 && post.postContent.includes("#") ? "\n\n" : ""}{" "}
    </React.Fragment>
  ));
  useEffect(() => {
    console.log("user", user.userData);
    getPlanName();
    console.log("common", common);
    getfbUser();
  }, []);
  const getFacebookuseer = async () => {
    // Set the Facebook ID to an empty string for now
    let facebookId = "";

    // Make an API call to get Facebook user data using the provided Facebook ID and user ID
    await getApi(`facebook/get-facebook-user?facebookId=${facebookId}&userId=${user?.userData?.userId}`).then((res) => {
      // Set the 'getFB' state with the response data
      setGetFB(res.data);
    });
  };

  // facebook post start

  const [getFbPageId, setGetFbPageId] = useState([]);
  // console.log({getFbPageId});

  /*
  This function loops through the getFbPageId array and posts the message to each Facebook page. If an image is included in the post, it constructs the image URL and includes it in the post data.
  */
  const createFacebookPost = () => {
    // Construct the image URL using the backend URL and the post's original URL
    const url = `${IMAGE_URL_BACKEND}/uploads/${post.originalUrl}`;
    // Set the image path to the constructed URL if the original URL is not empty
    const imgPath = post.originalUrl !== "" ? url : undefined;

    // Iterate over the Facebook page IDs and post to each page
    for (let fbd of getFbPageId) {
      // Make an API call to post to the Facebook page
      postApi("post-to-page", {
        pagesId: fbd.id,
        message: post.postContent,
        facebookId: getFB?.facebookId,
        urls: [imgPath],
      }).then((res) => {
        // Clear the 'getFbPageId' state after posting
        setGetFbPageId([]);
        // Show a success toast message
        toast.success("Facebook Post Published Successfully", {
          autoClose: 1000,
        });
      });
    }
  };

  // Background Style of instagram preview post
  const styles = [
    {
      name: "Navi Blue",
      style: {
        background: "#304b63",
        width: "100%",
        // height: '200px',
        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Green",
      style: {
        background: "#718a66",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Sky Blue",
      style: {
        background: "#009cc6",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Brown",
      style: {
        background: "#98521a",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
    {
      name: "Pink",
      style: {
        background: "#e73877",
        width: "100%",
        // height: '200px',

        padding: "20px",
        fontSize: "16px",
      },
    },
  ];
  const [selectedStyle, setSelectedStyle] = useState(styles[0].name);
  const [igBgcolor, setigBgColor] = useState(styles[0]);

  // Change style of instagram preview backgroud, because user can select which theme shoud use for post
  const handleChangeStyle = (event) => {
    setSelectedStyle(event.target.value);
  };

  // How are you feeling ?

  const [getIg, setGetIG] = useState([]);
  // console.log({ getIg });

  /* 
    This function loops through the getIg array, prepares the Instagram data for each account, and then makes an API call to post to Instagram with the thread data, Instagram data, Facebook ID, and selected style.
  */
  const handlePublishIg = async () => {
    // Retrieve thread data from local storage or use the post content if not available
    const threadData = JSON.parse(localStorage.getItem("threadData")) || [post.postContent];

    // Iterate over the Instagram accounts
    for (let data of getIg) {
      // Prepare the Instagram data for the API call
      const igDatas = {
        pageId: data.id,
        instagramId: data.instagram_business_account.id,
      };
      await postApi("instagram-post", {
        textArray: threadData,
        igData: igDatas,
        facebookId: getFB?.facebookId,
        bgcolor: selectedStyle,
      }).then((res) => {
        // Clear the 'getIG' and 'getThread' states after posting
        setGetIG([]);
        setGetThread([]);
        toast.success("Instagram Post Published Sucessfully", {
          autoClose: 1000,
        });
      });
    }
  };

  const twitteruser = getTwitterUser;
  // console.log({ twitteruser });

  // This function generate threads of 250 to 280 words for twitter posting
  const handletwitterthreads = async () => {
    if (getThread?.length > 0) {
      return;
    } else {
      dispatch(isLoading(true));
      await axios
        .post(`${apibase}/ai-post-into-threads`, {
          content: post.postContent,
        })
        .then((res) => {
          const threadData = res.data.data;
          localStorage.setItem("threadData", JSON.stringify(threadData));
          setGetThread(threadData);
          setTimeout(() => {
            // handleImageConvert()
          }, 1000);
          dispatch(isLoading(false));
        });
    }
  };

  // Fetch previous auto comments of the user for auto plug funcationality
  const fetchPreviousComment = async () => {
    const response = await getApi(`auto-comment/get-auto-comment/${user.userData.linkedinId}`);
    setPrevComments(response.data);
  };

  // Here publish twitter post to their connected twitter account
  const handlePublishTwitterpost = async () => {
    try {
      // Check if there is only one item in the thread (single tweet)
      if (getThread.length === 1) {
        // Post a single tweet
        await axios
          .post(`${apibase}/api/twitter/create`, {
            arrayOfText: getThread,
            userId: user.userData.linkedinId,
          })
          .then((res) => {
            setGetThread([]);
            toast.success("Tweet published successfully!", { autoClose: 1000 });
          })
          .catch((err) => {
            toast.warn(`Failed to Tweet`);
          });
      } else {
        // If there are multiple items in the thread (tweet thread)
        if (twitteruser.twitterId !== undefined) {
          // Publish a tweet thread
          await axios
            .post(`${apibase}/api/publish-twitter-thread`, {
              twitterId: twitteruser.twitterId,
              text: getThread,
            })
            .then((res) => {
              setGetThread([]);
              toast.success("Tweet published successfully!", {
                autoClose: 1000,
              });
            })
            .catch((err) => {
              toast.warn(`Failed to Tweet`);
            });
        } else {
          // If twitterId is not found, show a warning
          toast.warn("twitterId not Found Try again");
        }
      }
    } catch (err) {
      // If tweet publish is unsuccessful, show an error toast
      toast.error("Tweet publish unsuccessful!");
    }
  };

  /*
    This function futureDateTimeHandler takes a date and a time as input, combines them into a single future date and time string, and then splits them into separate date and time components.
  */

  const futureDateTimeHandler = (date, time) => {
    // Combine the date and time into a single string
    const future = new Date(`${date}T${time}`).toISOString();

    // Split the combined string into date and time components
    const futureDate = future?.split("T")[0]; // Extract the date part
    const futureTime = future?.split("T")[1].split(".")[0]; // Extract the time part

    // Return the future date and time components
    return { futureDate, futureTime };
  };

  // Handle schedule twitter post
  const handleScheduleTwitterpost = async () => {
    const { futureDate, futureTime } = futureDateTimeHandler(scheduleDate, scheduleTime);

    await axios
      .post(`${apibase}/api/twitter/create-schedule-data`, {
        userId: user.userData.linkedinId,
        text: getThread,
        date: futureDate,
        time: futureTime,
        twitterId: twitteruser.twitterId,
      })
      .then((res) => {
        setGetThread([]);
        // console.log({ res });
        toast.success("Tweet scheduled!", { autoClose: 1000 });
      })
      .catch((err) => {
        console.warn({ err });
        toast.error("Tweet schedule failed, retry!");
      });
  };

  // Handle scheduleing facebook post
  const handleSchedulefbpost = async () => {
    const url = `${IMAGE_URL_BACKEND}/uploads/${post.originalUrl}`;
    const imgPath = post.originalUrl !== "" ? url : undefined;
    const { futureDate, futureTime } = futureDateTimeHandler(scheduleDate, scheduleTime);
    for (let fbp of getFbPageId) {
      await axios
        .post(`${apibase}/api/facebook/create-facebook-schedule-post`, {
          userId: getFB?.linkedinId,
          facebookId: getFB?.facebookId,
          pageId: fbp.id,
          message: post.postContent,
          date: futureDate,
          time: futureTime,
          permanentAcessToken: getFB?.acessToken,
          url: imgPath,
        })
        .then((res) => {
          // console.log({ res });
          toast.success("Facebook Post Scheduled!", { autoClose: 1000 });
        })
        .catch((err) => {
          console.warn({ err });
          toast.error("Facebook post schedule failed, retry!");
        });
    }
  };

  // Handle scheduleing instagram post
  const handleIGschedule = async () => {
    const { futureDate, futureTime } = futureDateTimeHandler(scheduleDate, scheduleTime);
    const threadData = JSON.parse(localStorage.getItem("threadData")) || [post.postContent];
    for (let data of getIg) {
      const igDatas = {
        pageId: data.id,
        instagramId: data.instagram_business_account.id,
      };

      await axios
        .post(`${apibase}/api/facebook/ig-schedule`, {
          textArray: threadData,
          igData: igDatas,
          facebookId: getFB?.facebookId,
          bgcolor: selectedStyle,
          date: futureDate,
          time: futureTime,
        })
        .then((res) => {
          setGetThread([]);
          setGetIG([]);
          toast.success("Instagram Post Published Sucessfully", {
            autoClose: 1000,
          });
        });
    }
  };

  // When auto distribution toggle is manupulate then get feacebook info of user
  useEffect(() => {
    getFacebookuseer();
  }, [isOnDist]);

  // When auto plug toggle is manupulate then get all previous comments of user
  useEffect(() => {
    fetchPreviousComment();
  }, [autoPlugOpen]);

  // Here maintain structure of request post format with which social media is selected that is also maintain here according to post type
  const bodyPostHandler = () => {
    const getPostIdPrefix = () => {
      switch (post.postGenerationType) {
        case POST_GENERATION_TYPES.AI_CONTENT:
          return "ai";
        case POST_GENERATION_TYPES.DISCOVER:
          return "discover";
        case POST_GENERATION_TYPES.ON_THE_GO:
          return "otg";
        case POST_GENERATION_TYPES.CUSTOM:
        default:
          return "custom";
      }
    };

    const postId = `${getPostIdPrefix()}_${uuidv4()}`;
    if (post.postType === "NONE") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else if (post.postType === "IMAGE") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        media: post.media,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else if (post.postType === "VIDEO") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        media: post.media,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else if (post.postType === "LinkedIn Carousel") {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        media: post.media,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    } else {
      return {
        userId: linkedinId,
        postId: postId,
        postContent: post.postContent,
        connectionType: post.connectionType,
        postType: post.postType,
        mediaDis: post.mediaDis,
        mediaTitle: post.mediaTitle,
        originalUrl: post.originalUrl,
        platformPost: {
          twitter: isOnTweet,
          facebook: isOnFacebook,
          instagram: isOnInsta,
        },
      };
    }
  };

  // Here construct the linkedin request post format/structure
  const genPostFormatLinkedin = () => {
    if (post.postType === "NONE") {
      return {
        author: `urn:li:person:${linkedinId}`,
        lifecycleState: "PUBLISHED",
        specificContent: {
          "com.linkedin.ugc.ShareContent": {
            shareCommentary: {
              text: post.postContent
                .split(/#\s*/)
                .map((part, index) => `${index !== 0 ? "#" : ""}${part} ${index === 0 && part.trim().length > 0 && post.postContent.includes("#") ? "\n\n" : ""}`)
                .join(""),
            },
            shareMediaCategory: "NONE",
          },
        },
        visibility: {
          "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
        },
      };
    } else if (post.postType === "IMAGE") {
      // Ensure that media properties are arrays and have equal length
      if (Array.isArray(post.mediaTitle)) {
        console.log("postData0");
        console.log(post);
        console.log("postData0");
        const mediaArray = post.mediaTitle.map((mediaItem, index) => {
          return {
            status: "READY",
            description: {
              text: post.mediaTitle[index],
            },
            media: "",
            title: {
              text: post.mediaTitle[index],
            },
          };
        });

        const postData = {
          author: `urn:li:person:${linkedinId}`,
          lifecycleState: "PUBLISHED",
          specificContent: {
            "com.linkedin.ugc.ShareContent": {
              shareCommentary: {
                text: post.postContent,
              },
              shareMediaCategory: "IMAGE",
              media: mediaArray,
            },
          },
          visibility: {
            "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
          },
        };
        return postData;
      } else {
        throw new Error("Media and mediaTitleAndDescription arrays must be of equal length and not empty");
      }
    } else if (post.postType === "VIDEO") {
      return {
        author: `urn:li:person:${linkedinId}`,
        lifecycleState: "PUBLISHED",
        specificContent: {
          "com.linkedin.ugc.ShareContent": {
            shareCommentary: {
              text: post.postContent,
            },
            shareMediaCategory: "VIDEO",
            media: [
              {
                status: "READY",
                description: {
                  text: post.mediaDis,
                },
                media: post.media,
                title: {
                  text: post.mediaTitle,
                },
              },
            ],
          },
        },
        visibility: {
          "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
        },
      };
    } else if (post.postType === "LinkedIn Carousel") {
      return {
        author: `urn:li:person:${linkedinId}`,
        commentary: post.mediaTitle,
        visibility: post.connectionType,
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: post.mediaTitle,
            id: post.media,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };
    } else {
      if (!post.postContent && !post.originalUrl) {
        return false;
      } else {
        return {
          author: `urn:li:person:${linkedinId}`,
          lifecycleState: "PUBLISHED",
          specificContent: {
            "com.linkedin.ugc.ShareContent": {
              shareCommentary: {
                text: post.postContent,
              },
              shareMediaCategory: "ARTICLE",
              media: [
                {
                  status: "READY",
                  originalUrl: post.originalUrl,
                },
              ],
            },
          },
          visibility: {
            "com.linkedin.ugc.MemberNetworkVisibility": post.connectionType,
          },
        };
      }
    }
  };

  let s3fileURL;
  // here any media is upload to the linkedin server
  const linkedinMediaUpload = async (fileName) => {
    const postContentMedia = {
      imageFile: post.originalUrl || fileName,
      userId: linkedinId,
    };

    try {
      dispatch(isLoading(true));
      if (postContentMedia.imageFile.split(".").pop() === "mp4") {
        const response = await postApi("linkedin/video-upload", postContentMedia, {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        });

        const data = response;
        // console.log("image",{data});
        dispatch(addMedia(data.asset));
      } else if (postContentMedia.imageFile.split(".").pop() === "pdf") {
        const response = await postApi("linkedin/document-upload", postContentMedia, {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        });
        console.log("response", response);

        const data = response;
        (s3fileURL = JSON.stringfy(response.data.s3FileUrl)),
          // console.log("pdf", { data });
          dispatch(addMedia(data.asset));
      } else {
        const response = await postApi("linkedin/image-upload", postContentMedia, {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        });

        const data = response;
        // console.log("image",{data});
        dispatch(addMedia(data.asset));
        dispatch(isLoading(false));
        setBtnCaroselPdfName("Convert Carousel");
        setBtnCaroselPdf(false);
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.warn("media not uploaded error: " + err);
      toast.error("media is not uploaded in linkedin!");
      if (postContentMedia.imageFile.split(".").pop() === "pdf") {
        setBtnCaroselPdf(true);
        setBtnCaroselPdfName("Convert Carousel");
      }
    }
  };

  // Convert carousel post to pdf by backend
  const convertPDF = async () => {
    const slides = document.querySelectorAll(".capture-container");
    if (slides.length === 0) return;

    const firstSlide = slides[0];
    const slideWidth = firstSlide.offsetWidth;
    const slideHeight = firstSlide.offsetHeight;
    const aspectRatio = slideWidth / slideHeight;

    const pdfWidth = 210;
    const pdfHeight = pdfWidth / aspectRatio;

    const pdf = new jsPDF({
      orientation: pdfHeight > pdfWidth ? "portrait" : "landscape",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    try {
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i];

        const image = await htmlToImage.toPng(slide, {
          quality: 1.0,
          pixelRatio: 2,
          skipAutoScale: true,
          style: {
            transform: "scale(1)",
            transformOrigin: "top left",
            width: `${slideWidth}px`,
            height: `${slideHeight}px`,
          },
        });

        if (i > 0) {
          pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? "portrait" : "landscape");
        }

        pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight, undefined, "MEDIUM");
      }
      return pdf;
    } catch (error) {
      console.error("Error converting to PDF:", error);
      return null;
    }
  };

  const generatePDF = async (saveFile = true) => {
    dispatch(isLoading(true));

    const slides = document.querySelectorAll(".capture-container");
    if (slides.length === 0) return null;

    const firstSlide = slides[0];
    const slideWidth = firstSlide.offsetWidth;
    const slideHeight = firstSlide.offsetHeight;
    const aspectRatio = slideWidth / slideHeight;

    const pdfWidth = 210;
    const pdfHeight = pdfWidth / aspectRatio;

    const pdf = new jsPDF({
      orientation: pdfHeight > pdfWidth ? "portrait" : "landscape",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    try {
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i];

        const image = await htmlToImage.toPng(slide, {
          quality: 1.0,
          pixelRatio: 2,
          skipAutoScale: true,
          style: {
            transform: "scale(1)",
            transformOrigin: "top left",
            width: `${slideWidth}px`,
            height: `${slideHeight}px`,
          },
        });

        if (i > 0) {
          pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? "portrait" : "landscape");
        }

        pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight, undefined, "MEDIUM");
      }

      if (saveFile) {
        pdf.save(title + ".pdf");
      }

      return pdf;
    } catch (error) {
      console.error("Error generating PDF:", error);
      return null;
    } finally {
      if (saveFile) {
        dispatch(isLoading(false));
      }
    }
  };

  const postToLinkedIn = async () => {
    dispatch(isLoading(true));

    try {
      // Generate PDF without saving to file
      const pdf = await generatePDF(false);
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      // Convert PDF to blob
      const pdfBlob = pdf.output("blob");

      // Create FormData and append PDF
      const formData = new FormData();
      formData.append("file", pdfBlob, `${title || "carousel"}.pdf`);
      formData.append("userId", linkedinId);

      // Upload PDF to LinkedIn
      const uploadResponse = await axios.post(`${BASE_URL}linkedin/document-upload`, formData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (!uploadResponse.data || !uploadResponse.data.asset) {
        throw new Error("Failed to upload PDF to LinkedIn");
      }

      const asset = uploadResponse.data.asset;

      // Prepare post data
      const postData = {
        author: `urn:li:person:${linkedinId}`,
        commentary: post.overlayPostContent || "",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: title || "Carousel Post",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      // Create LinkedIn post
      const postResponse = await axios.post(`${BASE_URL}linkedin/create-linkedin-post`, postData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!postResponse.data.success) {
        throw new Error("Failed to create LinkedIn post");
      }

      console.log("postResponse.data.id", postResponse.data.id);
      console.log("postResponse.date", postResponse.data);
      console.log("postResponse", postResponse);

      // Save to database
      const dbPostData = {
        userId: linkedinId,
        postUrn: String(postResponse.data.data.id), //carousels return ugcURN
        postContent: postData.commentary,
        connectionType: "LinkedIn",
        postType: "carousel",
        postTag: "LinkedIn Post",
        media: uploadResponse.data.s3FileUrl,
        status: "PUBLISH",
        date_time: new Date().toISOString().split(".")[0],
        platformPost: {
          linkedin: true,
        },
      };

      // Save post to database
      await axios.post(`${BASE_URL}published/post-create`, dbPostData);

      // Clear all states and content
      dispatch(updateOverlayContent(post.overlayPostContent));
      dispatch(clearPostState());
      dispatch(resetSlides());
      setGetThread([]);
      setIsOnDist(false);
      setIsOnTweet(false);
      setIsOnFacebook(false);
      setIsOnInsta(false);
      setText("");
      setBtnCaroselPdf(false);
      localStorage.removeItem("threadData");
      setIsOnPlug(false);
      setAutoPlugComment("");
      setAutoPlugDelay(null);
      setPrevComments([]);
      setAutoPlugTags(null);
      setPickerVisible(false);
      setPrevCorsorPos(null);

      toast.success("Successfully posted to LinkedIn!");

      // Close the overlay
      onClose();

      return postResponse.data;
    } catch (error) {
      console.error("Error posting to LinkedIn:", error);
      toast.error(error.message || "Failed to post to LinkedIn");
      throw error;
    } finally {
      dispatch(isLoading(false));
    }
  };

  const downloadPDF = async () => {
    // if (!activeCarousel) {
    //   console.error("No active carousel selected");
    //   return;
    // }
    // setLoading(true);
    dispatch(isLoading(true));
    const grammarlyElements = document.querySelectorAll("grammarly-extension");
    grammarlyElements.forEach((element) => (element.style.display = "none"));

    const slides = document.querySelectorAll(".capture-container");
    if (slides.length === 0) return;

    // Get dimensions of the first slide to set PDF size
    const firstSlide = slides[0];
    const slideWidth = firstSlide.offsetWidth;
    const slideHeight = firstSlide.offsetHeight;
    const aspectRatio = slideWidth / slideHeight;

    // Set PDF size to match slide aspect ratio
    const pdfWidth = 210; // A4 width in mm
    const pdfHeight = pdfWidth / aspectRatio;

    const pdf = new jsPDF({
      orientation: pdfHeight > pdfWidth ? "portrait" : "landscape",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];

      // Create a canvas from the slide
      const canvas = await html2canvas(slide, {
        scale: 6, // Increase scale for better quality
        useCORS: true,
        logging: false, // Disable logging for better performance
        backgroundColor: null, // Transparent background
      });

      // Add the slide to the PDF
      if (i > 0) {
        pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? "portrait" : "landscape");
      }
      pdf.addImage(
        canvas.toDataURL("image/jpeg", 0.95), // Use JPEG with 95% quality for smaller file size
        "JPEG",
        0,
        0,
        pdfWidth,
        pdfHeight,
        undefined,
        "FAST" // Use fast compression
      );
    }
    pdf.save("carousel.pdf");
    grammarlyElements.forEach((element) => (element.style.display = ""));

    // Return pdf;
    dispatch(isLoading(false));
    setShowDialog2(false);
  };

  // This is simply manage the state of post that is currently publish that post
  const publishPostHandler = () => {
    dispatch(changePostSelection("create-post"));
    dispatch(changePostType("NONE"));
    dispatch(clearGeneratePostState(""));

    // This function is responsible for publish or schedule that post
    postNowHandler();
    if (isOnTweet) {
      handlePublishTwitterpost();
      setIsOnTweet(false);
    }
    if (isOnFacebook) {
      createFacebookPost();
      setIsOnFacebook(false);
    }

    if (isOnInsta) {
      setTimeout(() => {
        handlePublishIg();
        setIsOnInsta(false);
      }, 1000);
    }
  };
  // This is simply manage the state of post that is schedule that post
  const schedulePostHander = () => {
    // setSchedulePanelOpen(true)
    // dispatch(changePostSelection('schedule-post'))

    if (post.postContent) {
      setSchedulePanelOpen(true);
      dispatch(changePostSelection("schedule-post"));
      console.log(post.postContent);
    } else {
      toast.error("Please write your idea!");
    }
  };

  /*
    This function handles different scenarios for posting content: immediate posting, scheduling for later, and handling auto-plug logic. It interacts with the backend to publish posts and manage scheduling.
  */
  // Here check user wnats to publish post immediatly or scheduled it
  const postNowHandler = async () => {
    // Check if there is content in the post
    if (!post.postContent) {
      toast.error("Please write your idea!", { autoClose: 1000 });
    } else if (post.postSelection === "create-post" && post.postContent) {
      // Reset the schedule date and time if creating a new post
      setScheduleDate("");
      setScheduleTime("");
      const postContent = genPostFormatLinkedin();

      try {
        dispatch(isLoading(true));

        // if (post.postType === 'IMAGE' || post.postType === 'VIDEO' || post.postType === 'Carousel') {
        //   linkedinMediaUpload()
        // }

        // if ((post.postType === 'IMAGE' || post.postType === 'VIDEO' || post.postType === 'Carousel') && !post.media) {
        //   toast.warning('Please wait, File is uploading...')
        //   dispatch(isLoading(true))
        //   return
        // }

        // Publish linkedin post to linkedin
        console.log("postContent");
        console.log(postContent);
        console.log("postContent");
        const response = await postApi("linkedin/create-linkedin-post", postContent, {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        });
        const data = response.data;
        console.log("data");
        console.log(data);
        console.log("data");
        dispatch(isLoading(false));
        if (data.id) {
          // If the post was successfully published, handle auto-plug if configured
          try {
            // Auto plug is configure then auto plug is set to desire time
            if (autoPlugComment && autoPlugDelay && autoPlugTags) {
              const currentDate = new Date();
              // currentDate.setHours(currentDate.getHours() + Number(autoPlugDelay))
              let futureDate = new Date(`${currentDate.toISOString().split("T")[0]}T${autoPlugDelay}`);
              // futureDate = futureDate.split(':')

              futureDate = futureDate.toISOString().split(".")[0];

              const bodyPost = {
                comment_text: autoPlugComment,
                shareURN: data.id,
                userId: user.userData.linkedinId,
                date: futureDate,
                tags: autoPlugTags,
              };
              // console.log({bodyPost});
              await postApi("auto-comment/create-auto-comment", bodyPost);
              fetchPreviousComment();
            }

            const postBody = bodyPostHandler();
            console.log("postBody");
            console.log(postBody);
            console.log("postBod");
            // post status should be PUBLISH
            postBody.status = "PUBLISH";
            postBody.postUrn = data.id;

            console.log("after");
            console.log(postBody);
            console.log("after");
            const res = await postApi("published/post-create", postBody);
            // console.log({res});
            // console.log('ttttttttt');
            // handletwitterthreadpublish()
            if (!res) toast.error("Error in Post saved in database!");

            toast.success("Post Published Successfully!", { autoClose: 1000 });

            localStorage.removeItem("threadData");
            dispatch(clearPostState());
            setIsOnDist(false);
            setIsOnPlug(false);
            deleteApi("draft/draft-post-delete", { postId: post.draftPostId })
              .then((res) => console.log("draft delete success"))
              .catch((err) => console.warn(`err in draft :${err}`));
          } catch (error) {
            console.log(error);
            toast.error("Error in Publishing Post!");
          }
        } else {
          throw Error("Internal Server Error!");
        }
      } catch (error) {
        // In case any problem fetch to publish post in linkedin
        dispatch(isLoading(false));
        console.error(error);
        try {
          const postBody = bodyPostHandler();
          postBody.status = "FAILED";
          console.log({ postBody });
          toast.error("Failed to create post!");
          await postApi("published/post-create", postBody);
        } catch (err) {
          dispatch(isLoading(false));
          console.error(err);
          toast.error("Internal Server Error!");
        }
      }
    } else {
      // Handle scheduling a post for later
      // Here schedule post handle here with auto plug configuration check
      if (!scheduleDate || !scheduleTime) {
        setSchedulePanelOpen(true);
        toast.info("Please select Date and time");
      } else {
        dispatch(isLoading(true));

        // auto plug logic
        let commentResponse;
        if (autoPlugComment && autoPlugDelay && autoPlugTags) {
          const currentDate = new Date();
          // currentDate.setHours(currentDate.getHours() + Number(autoPlugDelay))
          let futureDate = new Date(`${currentDate.toISOString().split("T")[0]}T${autoPlugDelay}`);
          // futureDate = futureDate.split(':')

          futureDate = futureDate.toISOString().split(".")[0];

          const bodyCommentPost = {
            comment_text: autoPlugComment,
            shareURN: "",
            userId: user.userData.linkedinId,
            date: futureDate,
            tags: autoPlugTags,
          };
          // console.log({bodyPost});
          commentResponse = await postApi("auto-comment/create-auto-comment", bodyCommentPost);
          // console.log({commentResponse});
          fetchPreviousComment();
        }

        const futureDate = new Date(`${scheduleDate}T${scheduleTime}`).toISOString();
        // console.log({futureDate});

        const bodyPost = bodyPostHandler();
        const getPostIdPrefix = () => {
          switch (post.postGenerationType) {
            case POST_GENERATION_TYPES.AI_CONTENT:
              return "ai";
            case POST_GENERATION_TYPES.DISCOVER:
              return "discover";
            case POST_GENERATION_TYPES.ON_THE_GO:
              return "otg";
            case POST_GENERATION_TYPES.CUSTOM:
            default:
              return "custom";
          }
        };
        bodyPost.postId = `${getPostIdPrefix()}_${uuidv4()}`;
        bodyPost.date_time = futureDate;
        bodyPost.postTag = POSTTAG_CUSTOM;

        if (autoPlugComment && autoPlugDelay) {
          bodyPost.autoCommentId = commentResponse.data._id;
        }

        // console.log({schedulePost: bodyPost});
        try {
          // if (post.postType === 'IMAGE' || post.postType === 'VIDEO') {
          //   linkedinMediaUpload()
          // }

          // if ((post.postType === 'IMAGE' || post.postType === 'VIDEO') && !post.media) {
          //   toast.warning('Please wait, Image is uploading...')
          //   dispatch(isLoading(true))
          //   return
          // }
          console.log("bodyPost");
          console.log("bodyPost");
          console.log(bodyPost);
          console.log("bodyPost");
          console.log("bodyPost");

          const response = await postApi("schedule/create-schedule-post", bodyPost, {
            headers: {
              Authorization: `Bearer ${linkedinAccessToken}`,
              "Content-Type": "application/json",
            },
          });
          if (isOnTweet) {
            // console.log('call on schedule')
            handleScheduleTwitterpost();
          }
          if (isOnFacebook) {
            handleSchedulefbpost();
          }
          if (isOnInsta) {
            handleIGschedule();
          }
          toast.success(response.message, { autoClose: 1000 });
          dispatch(isLoading(false));
          localStorage.removeItem("threadData");
          dispatch(clearPostState());
          setIsOnDist(false);
          cancelSchedulePost();
          setIsOnPlug(false);
          deleteApi("draft/draft-post-delete", { postId: post.draftPostId })
            .then((res) => console.log("draft delete success"))
            .catch((err) => console.warn(`err in draft :${err}`));
        } catch (error) {
          dispatch(isLoading(false));
          console.error("Error sending message:", error);
          toast.error(error.message);
        }
      }
    }
  };

  // Clear custom schedule post states
  const cancelSchedulePost = () => {
    setSchedulePanelOpen(false);
    setScheduleDate("");
    setScheduleTime("");
    setCheckCustomTime(false);
  };

  // Clear auto plug states
  const cancelAutoPlugPost = () => {
    setIsOnPlug(false);
    setAutoPlugOpen(false);
    setAutoPlugDelay("");
    setAutoPlugComment("");
  };

  const onScheduleConfirmHandler = async () => {
    // Close the modal first
    setSchedulePanelOpen(false);

    const futureDate = new Date(`${scheduleDate}T${scheduleTime}`);

    try {
      // Step 1: Generate PDF after the modal is closed
      // toast.info("Preparing carousel for LinkedIn...");
      const pdf = await generatePDF();
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", user?.userData?.linkedinId);

      // Step 2: Upload PDF to LinkedIn
      // toast.info("Uploading PDF to LinkedIn...");
      const uploadResponse = await postApi("linkedin/document-upload", formData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("uploadResponse:", uploadResponse);

      const asset = uploadResponse.asset;
      console.log("asset:", asset);

      // Step 3: Prepare post data
      const postData = {
        author: `urn:li:person:${user?.userData?.linkedinId}`,
        commentary: state.adjustReducer.slides[0].title || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      // Step 4: Schedule the post
      const bodyPost = {
        ...postData,
        date_time: futureDate.toISOString().split(".")[0],
        userId: user?.userData?.linkedinId,
        originalUrl: null, // Add other necessary fields based on your backend schema
        media: asset, // Asset ID from the upload response
        mediaTitle: "Carousel", // Title for the media
        mediaDis: "application/pdf", // Media type or description
        postType: "Carousel", // Type of the post
        connectionType: "LinkedIn",
        postContent: postData.commentary,
        postId: null, // Post ID if required
        postTag: null, // Post tags if any
        autoCommentId: null, // Auto comment ID if any
        platformPost: "LinkedIn",
      };

      // toast.info("Scheduling LinkedIn post...");
      const scheduleResponse = await postApi("schedule/create-schedule-post", bodyPost, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
        },
      });
      console.log("scheduleResponse");
      console.log(scheduleResponse);
      console.log("scheduleResponse");

      if (!scheduleResponse.success) {
        throw new Error("Failed to schedule LinkedIn post");
      }

      toast.success("Carousel PDF scheduled for posting on LinkedIn successfully!", {
        autoClose: 1000,
      });
      dispatch(resetSlides());
    } catch (error) {
      console.error("Error in scheduling carousel post:", error);
      toast.error(`Failed to schedule carousel post: ${error.message}`);
    }
  };

  // When user add auto plug
  const onAutoPlugHandler = () => {
    if (!autoPlugDelay || !autoPlugComment || !autoPlugTags) {
      toast.info("Please select Delay in time and your comment");
    } else {
      setAutoPlugOpen(false);
    }
  };

  // Type in post editor changes
  const onPostDescriptionHandler = (e) => {
    const newContent = e.target.value;
    dispatch(addAIPost(newContent));
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isPickerVisible &&
        !event.target.closest(".emoji-picker") && // Changed this line
        event.target !== buttonRef.current
      ) {
        setPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPickerVisible]);

  const EmojiTrayToggle = () => {
    setPickerVisible(!isPickerVisible);
  };

  // Save post into draft
  const saveDraft = async () => {
    console.log("Saving draft...");
    try {
      // Generate a draft post ID if it doesn't exist
      const draftPostId = post.draftPostId || `draft_${uuidv4()}`;

      // Update the draft post ID in the state
      dispatch(addDraftPostId(draftPostId));

      // Create the body for the API request
      const bodyPost = bodyPostHandler();
      bodyPost.postId = draftPostId;

      // Check if there is content in the post
      if (post.postContent) {
        // Send a request to the backend to save the draft post
        const response = await postApi("draft/draft-post-create", bodyPost);
        console.log(response);
        // Display a success message
        toast.success("Draft saved", { autoClose: 1000 });
        return true; // Indicate success
      } else {
        toast.warning("Cannot save an empty draft");
        return false; // Indicate failure
      }
    } catch (error) {
      // Display an error message if the draft couldn't be saved
      toast.error("Error saving draft: " + error.message);
      return false; // Indicate failure
    }
  };

  // Add external link/url to article type post when user click URL icon
  const addURLHandler = () => {
    if (!post.postContent) {
      toast.error("Please write your idea for Share URL Post!");
    } else {
      // if (post.postType != "IMAGE" && post.postType != "VIDEO") {
      dispatch(addOriginalUrl(""));
      dispatch(changePostType("ARTICLE"));
      setUrlPanel(true);
      // }
    }
  };

  // Cancel/Remove the external link to post , when that happen then post type change to none for linkedin request body format format
  const cancelURLPost = () => {
    setUrlPanel(false);
    dispatch(changePostType("NONE"));
  };

  useEffect(() => {
    if (!post.postContent) {
      dispatch(addDraftPostId());
    } else {
    }
  }, [post.postContent, post.originalUrl]);

  useEffect(() => {
    const saveDraftMount = async () => {
      postApi("draft/draft-post-create", bodyPost)
        .then((response) => {
          console.log(response);
          // Display a success message
          // toast.success(response.data.message);
        })
        .catch((error) => {
          // Display an error message if the draft couldn't be saved
          // toast.error(error);
        });
      console.log("saveDraft check");
      // Generate a draft post ID if it doesn't exist
      const draftPostId = post.draftPostId || `draft_${uuidv4()}`;
      // Update the draft post ID in the state
      dispatch(addDraftPostId(draftPostId));

      // Create the body for the API request
      const bodyPost = bodyPostHandler();
      bodyPost.postId = post.draftPostId;

      // Check if there is content in the post and a draft post ID
      if (post.postContent && post.draftPostId) {
        console.log("this is working");
        // Send a request to the backend to save the draft post
        postApi("draft/draft-post-create", bodyPost)
          .then((response) => {
            console.log(response);
            // Display a success message
            toast.success(response.data.message, { autoClose: 1000 });
          })
          .catch((error) => {
            // Display an error message if the draft couldn't be saved
            toast.error(error);
          });
      }
    };

    const handleBeforeUnload = async (e) => {
      // Call your function or perform any action before the page unloads
      console.log("hello");
      saveDraftMount();

      // Make a POST request
      await postApi("draft/draft-post-create", bodyPost)
        .then((response) => {
          console.log(response);
          // Display a success message
          toast.success(response.data.message, { autoClose: 1000 });
        })
        .catch((error) => {
          // Display an error message if the draft couldn't be saved
          toast.error(error);
        });

      const confirmationMessage = "Are you sure you want to leave?";
      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    };

    // Add event listener for beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    // Add event listener for beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures that this effect runs only once (on mount)

  // Add images to that post when user click image/Media button
  const mediaPanelHander = () => {
    if (!post.postContent) {
      toast.error("Please write your idea for Image Post!");
    } else {
      dispatch(changePostType("IMAGE"));
      setUrlPanel(false);
      setMediaPanel(true);
    }
  };

  // Handle auto plug toggle bar like on/off
  const handleSwitchOfPlug = () => {
    if (isOnPlug === false) {
      // If auto plug is off and there is content in the post
      if (post.postContent) {
        setIsOnPlug(true);
        // Open the auto plug panel
        setAutoPlugOpen(true);
      } else {
        // If there is no content in the post, display an error message
        toast.error("Please write your idea!");
      }
    } else {
      // If auto plug is already on, cancel the auto plug
      cancelAutoPlugPost();
    }
  };
  const [seeOptions, setSeeOptions] = useState(false);
  // Handle auto plug toggle bar like on/off
  const handleSwitchOfDistribution = () => {
    if (isOnDist === false) {
      // If distribution is off and there is content in the post
      if (post.postContent) {
        setIsOnDist(true);
        setSeeOptions(true);
        // If the post content is less than 250 characters, convert to image (handleImageConvert function)
        if (post.postContent?.length < 250) {
          // handleImageConvert()
        }
      } else {
        // If there is no content in the post, display an error message
        toast.error("Please write your idea!");
      }
    } else {
      // If distribution is already on, turn it off and reset all distribution-related states
      setSeeOptions(false);
      setIsOnDist(false);
      setIsOnTweet(false);
      setIsOnFacebook(false);
      setIsOnInsta(false);
      setGetThread([]);
    }
  };

  // If twitter buton is on then check if this user hase already connect their twitter account or not
  // If yes then post the twitter data or convert it to thread of multyple
  const handleSwichsOfTweet = () => {
    if (!getTwitterUser) {
      const confirmTW = confirm("Twitter is not connected! Try to connect.");
      if (confirmTW) {
        navigate("/my-accounts?invite=2");
      }
    } else {
      if (isOnTweet === false) {
        if (post.postContent) {
          setIsOnTweet(true);
          if (post.postContent?.length > 270) {
            handletwitterthreads();
          } else {
            setGetThread([post.postContent]);
          }
        } else {
          toast.error("Please write you idea!");
        }
      } else {
        setIsOnTweet(false);
        setGetThread([]);
      }
    }
  };

  // If facebook toggle is on then 1st check facebook account is connected and all the connected facebook pages are fetched here

  const handleSwichsOfFacebook = () => {
    // if (fbPageList?.fbConnectedAc?.length === 0) {
    //   const confirmFB = confirm("Facebook is not connected! Try to connect.");
    //   if (confirmFB) {
    //     navigate("/my-accounts?invite=2");
    //   }
    // } else {
    //   if (isOnFacebook === false) {
    //     if (post.postContent) {
    //       setIsOnFacebook(true);

    //       setIsFb(!isFb);
    //     } else {
    //       toast.error("Please write you idea!");
    //     }
    //   } else {
    //     setIsOnFacebook(false);
    //   }
    // }
    if (!getFacebookUser) {
      const confirmTW = confirm("Facebook is not connected! Try to connect.");
      if (confirmTW) {
        navigate("/my-accounts?invite=2");
      }
    } else {
      if (isOnFacebook === false) {
        if (post.postContent) {
          setIsOnFacebook(true);

          setIsFb(!isFb);
        } else {
          toast.error("Please write you idea!");
        }
      } else {
        setIsOnFacebook(false);
      }
    }
  };

  // If facebook toggle is on then 1st check instagram account is connected and all the connected instagram pages are fetched here
  // And if their is long post then it convert to multiple parts to post in instagram
  const handleSwichsOfInsta = () => {
    if (!getInstaUser) {
      const confirmTW = confirm("Insta is not connected! Try to connect.");
      if (confirmTW) {
        navigate("/my-accounts?invite=2");
      }
    } else {
      if (isOnInsta === false) {
        if (post.postContent) {
          setIsOnInsta(true);
          setIsIG(!isIG);
          // handleCreateigaccount()
          if (post.postContent?.length > 270) {
            handletwitterthreads();
          } else {
            setGetThread([post.postContent]);
          }
        } else {
          toast.error("Please write you idea!");
        }
      } else {
        setIsOnInsta(false);
      }
    }
  };

  // Copy to clipboard
  const copyPasteHandler = () => {
    if (!post.postContent) {
      toast.error("Please write your idea for Copy!");
    } else {
      /* Copy selected text into clipboard */
      navigator.clipboard.writeText(post.postContent);
      toast.success("Text Copied", { autoClose: 1000 });
    }
  };

  // Save the post in evergreen
  /*
  - Checks if there is content in the post. If not, it displays an error message and returns.
  - Creates a body object containing the necessary information for the evergreen post.
  - Sends a request to the API to create the evergreen post.
  - Displays a success message if the creation is successful.
  - Displays an error message if the creation fails, with special handling for the case where the post is already an evergreen post (HTTP status code 409).
  */
  const onEvergreenHnadler = async () => {
    if (!post.postContent) {
      toast.error(`Please write you idea!`);
      return;
    }
    // setEvergreenStatus(true);
    const getPostIdPrefix = () => {
      switch (post.postGenerationType) {
        case POST_GENERATION_TYPES.AI_CONTENT:
          return "ai";
        case POST_GENERATION_TYPES.DISCOVER:
          return "discover";
        case POST_GENERATION_TYPES.ON_THE_GO:
          return "otg";
        case POST_GENERATION_TYPES.CUSTOM:
        default:
          return "custom";
      }
    };

    const bodyPost = {
      userId: user.userData.linkedinId,
      postId: post.postId || `${getPostIdPrefix()}_${uuidv4()}`,
      postContent: post.postContent,
      connectionType: post.connectionType,
      postType: post.postType,
      mediaDis: post.mediaDis,
      mediaTitle: post.mediaTitle,
      media: post.media,
      originalUrl: post.originalUrl,
    };
    console.log(post.mediaTitle);

    try {
      dispatch(isLoading(true));
      // console.log(props.data);
      const response = await postApi("evergreen/create", bodyPost);
      toast.success("Saved as evergreen!", { autoClose: 1000 });
      // dispatch(clearPostState())
      dispatch(isLoading(false));
      setIsStarFilled(true);
    } catch (error) {
      dispatch(isLoading(false));
      console.warn(error);
      // Here this 409 code is comes because of already added
      if (error.response.status === 409) {
        const message = error.response.data.message;
        toast.error(message);
      } else {
        const message = "Something went wrong!";
        toast.error(message);
      }
    }
  };

  // Save the post in bookmark
  /*
    - Checks if there is content in the post. If not, it displays an error message and returns.
    - Creates a body object containing the necessary information for the bookmarked post.
    - Sends a request to the API to create a bookmark for the post.
    - Displays a success message if the bookmarking is successful.
    - Displays an error message if the bookmarking fails, with special handling for the case where the post is already bookmarked (HTTP status code 409).
  */
  const onBookmarkHnadler = async () => {
    if (!post.postContent) {
      toast.error(`Please write you idea!`);
      return;
    }

    const bodyPost = {
      userId: user.userData.linkedinId,
      postId: post.postId || `bookmark_${uuidv4()}`,
      postContent: post.postContent,
      connectionType: post.connectionType,
      postType: post.postType,
      mediaDis: post.mediaDis,
      mediaTitle: post.mediaTitle,
      media: post.media,
      originalUrl: post.originalUrl,
    };

    try {
      dispatch(isLoading(true));
      const response = await postApi("book-marks/bookmark-post-create", bodyPost);
      toast.success("Saved in Bookmark!", { autoClose: 1000 });
      setIsFilled(true);
      // dispatch(clearPostState())
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.warn(error);
      // Here this 409 code is comes because of already added
      if (error.response.status === 409) {
        const message = error.response.data.message;
        toast.error(message);
      } else {
        const message = "Something went wrong!";
        toast.error(message);
      }
    }
  };

  // Here consumes of credit point by rewrite the post by using open AI
  const updateCreditHistory = async (creditInfo) => {
    try {
      const topicCredit = await getApi("creditpoint/point-info/AI-Generation");

      const bodyPost = {
        teamId: user.userData.teamId,
        name: "Re-Write Content",
        subscriptionId: creditInfo.data.subscriptionId,
        userId: user.userData.linkedinId,
        crpoint: `${topicCredit.data.point}`,
      };

      await postApi("credit/create-credit-history", bodyPost);
    } catch (error) {
      console.warn("update Credit History", error);
    }
  };

  // Rewrite the post by user, make sure user have sufficient credit balance
  /*
  - Checks if there is content in the post. If not, it displays an error message and returns.
  - Retrieves the team's credit balance from the API.
  - If the team has sufficient credits, it sends a request to the AI service to rewrite the post content.
  - If the rewrite is successful, it updates the credit history, adds the rewritten content to the post, and displays a success message.
  - If the team has insufficient credits, it displays an error message.
  */
  const onRewriteHandler = async () => {
    if (!post.postContent) {
      toast.error(`Please write you idea!`);
      return;
    }

    try {
      dispatch(isLoading(true));
      const creditInfo = await getApi(`credit/check-credit-point/${user.userData.teamId}`);

      if (Number(creditInfo.data.balance) > 0) {
        let body = {
          topic: post.postContent,
        };

        await postApi("ai-post/ai-post-rewrite", body).then((res) => {
          updateCreditHistory(creditInfo);
          dispatch(addAIPost(res.aiResponse));
          dispatch(isLoading(false));
          toast.success("Re-write Successful!", { autoClose: 1000 });
        });
      } else {
        toast.error(`Insufficient Credits!`);
        dispatch(isLoading(false));
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.warn("error of re-write content: " + err);
    }
  };

  // Here this function check users available time slot for the day or next day upto 10 days to add schedule post to perticular time with the respective auto plug feature

  /*
    This function seems to handle the scheduling of posts with an auto-comment feature. It first checks if the post content is present. If it is, it proceeds with the auto-plug logic, where it prepares a comment body and sends a request to create an auto-comment. Then, it prepares the post body for scheduling, including setting the auto-comment ID if it was created. Finally, it uploads media if the post type is an image or video, checks for available time slots, and schedules the post.
  */

  const autoScheduleAndPost = async () => {
    try {
      // Step 1: Get auto-scheduler slots
      const userTimeSlots = await axios.get(`${BASE_URL}schedule/get-auto-scheduler-slot/${user?.userData?.linkedinId}`);
      console.log("userTimeSlots:", userTimeSlots);

      let utcTimeSlot = [];
      const currentDate = new Date().toISOString().split("T")[0];

      for (const slot of userTimeSlots.data.timeSlot) {
        const futureDate = new Date(`${currentDate}T${slot}`).toISOString();
        utcTimeSlot.push(futureDate?.split(".")[0]?.split("T")[1]);
      }

      // Step 2: Generate and upload PDF
      toast.info("Preparing carousel for LinkedIn...");
      const pdf = await generatePDF();
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", user?.userData?.linkedinId);

      toast.info("Uploading PDF to LinkedIn...");
      const uploadResponse = await postApi("linkedin/document-upload", formData, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("uploadResponse:", uploadResponse);

      const asset = uploadResponse.asset;
      console.log("asset:", asset);

      // Step 3: Prepare post data
      const postData = {
        author: `urn:li:person:${user?.userData?.linkedinId}`,
        commentary: state.adjustReducer.slides[0].title || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      // Step 4: Schedule the post
      const bodyPost = {
        ...postData,
        utcTimeSlots: utcTimeSlot,
        userId: user?.userData?.linkedinId,
        originalUrl: null, // Add other necessary fields based on your backend schema
        media: [uploadResponse.s3FileUrl], // Assuming you want to store the PDF URL
        mediaTitle: "Carousel", // Title for the media
        mediaDis: "application/pdf", // Media type or description
        postType: "Carousel", // Type of the post
        connectionType: "LinkedIn",
        postContent: postData.commentary,
        postId: null, // Post ID if required
        postTag: null, // Post tags if any
        autoCommentId: null, // Auto comment ID if any
        platformPost: "LinkedIn",
      };

      console.log("bodyPost for scheduling:", bodyPost);

      toast.info("Scheduling LinkedIn post...");
      const scheduleResponse = await postApi("schedule/create-auto-schedule-post", bodyPost, {
        headers: {
          Authorization: `Bearer ${linkedinAccessToken}`,
        },
      });
      console.log("scheduleResponse:", scheduleResponse);

      if (!scheduleResponse.success) {
        throw new Error("Failed to schedule LinkedIn post");
      }

      toast.success("Carousel PDF scheduled for posting on LinkedIn successfully!", {
        autoClose: 1000,
      });
      dispatch(resetSlides());
      return scheduleResponse;
    } catch (error) {
      console.error("Error in auto-scheduling and posting:", error);
      toast.error(`Failed to auto-schedule and post: ${error.message}`);
      throw error;
    }
  };

  const autoSchedulerHandler = async () => {
    if (!post.postContent) {
      toast.error("Please write your idea!");
    } else {
      // Auto plug logic
      dispatch(isLoading(true));
      let commentResponse;
      // Check if auto-plug settings are available
      if (autoPlugComment && autoPlugDelay && autoPlugTags) {
        // Prepare the comment body for auto-plug
        const currentDate = new Date();
        // currentDate.setHours(currentDate.getHours() + Number(autoPlugDelay))
        let futureDate = new Date(`${currentDate.toISOString().split("T")[0]}T${autoPlugDelay}`);
        // futureDate = futureDate.split(':')

        futureDate = futureDate.toISOString().split(".")[0];

        const bodyCommentPost = {
          comment_text: autoPlugComment,
          shareURN: "",
          userId: user.userData.linkedinId,
          date: futureDate,
          tags: autoPlugTags,
        };
        // console.log({bodyPost});
        // Create auto-comment
        commentResponse = await postApi("auto-comment/create-auto-comment", bodyCommentPost);
        // console.log({commentResponse});
        fetchPreviousComment();
      }

      //  const futureDate = new Date(`${scheduleDate}T${scheduleTime}`).toISOString();
      // console.log({futureDate});
      const getPostIdPrefix = () => {
        switch (post.postGenerationType) {
          case POST_GENERATION_TYPES.AI_CONTENT:
            return "ai";
          case POST_GENERATION_TYPES.DISCOVER:
            return "discover";
          case POST_GENERATION_TYPES.ON_THE_GO:
            return "otg";
          case POST_GENERATION_TYPES.CUSTOM:
          default:
            return "custom";
        }
      };

      // Prepare the post body
      const bodyPost = bodyPostHandler();
      bodyPost.postId = post.draftPostId?.split("_")[0] === "schedule" ? post.draftPostId : `${getPostIdPrefix()}_${uuidv4()}`;
      //  bodyPost.date_time = futureDate
      bodyPost.postTag = POSTTAG_CUSTOM;

      if (autoPlugComment && autoPlugDelay) {
        bodyPost.autoCommentId = commentResponse.data._id;
      }

      // console.log({schedulePost: bodyPost});
      try {
        // Upload media if necessary of image or video
        if (post.postType === "IMAGE" || post.postType === "VIDEO") {
          linkedinMediaUpload("");
        }

        if ((post.postType === "IMAGE" || post.postType === "VIDEO") && !post.media) {
          toast.warning("Please wait, Image is uploading...");
          dispatch(isLoading(true));
          //  return
        }

        // Get user's available time slots
        let utcTimeSlot = [];
        getApi(`schedule/get-auto-scheduler-slot/${user?.userData?.linkedinId}`).then(async (userTimeSlots) => {
          const currentDate = new Date().toISOString().split("T")[0];

          for (const slot of userTimeSlots.timeSlot) {
            const futureDate = new Date(`${currentDate}T${slot}`).toISOString();
            utcTimeSlot.push(futureDate?.split(".")[0]?.split("T")[1]);
          }

          bodyPost.utcTimeSlots = utcTimeSlot;

          // Schedule the post
          try {
            const response = await postApi("schedule/create-auto-schedule-post", bodyPost, {
              headers: {
                Authorization: `Bearer ${linkedinAccessToken}`,
                "Content-Type": "application/json",
              },
            });
            toast.success(response.message, { autoClose: 1000 });
            dispatch(clearPostState());
            localStorage.removeItem("threadData");
            cancelSchedulePost();
            dispatch(isLoading(false));
            deleteApi("draft/draft-post-delete", { postId: post.draftPostId })
              .then((res) => console.log("draft delete success"))
              .catch((err) => console.warn(`err in draft :${err}`));
          } catch (error) {
            dispatch(isLoading(false));
            dispatch(clearPostState());
            console.error("Error sending message:", error);
            toast.error(error.response.data.message);
          }
        });
      } catch (error) {
        dispatch(isLoading(false));
        dispatch(clearPostState());
        console.error("Error sending message:", error);
        toast.error(error.response.data.message);
      }
    }
  };

  const scheduleOnSelectedDate = async () => {
    if (post.date_time) {
      try {
        const bodyPost = bodyPostHandler();
        bodyPost.date_time = post.date_time;
        const getPostIdPrefix = () => {
          switch (post.postGenerationType) {
            case POST_GENERATION_TYPES.AI_CONTENT:
              return "ai";
            case POST_GENERATION_TYPES.DISCOVER:
              return "discover";
            case POST_GENERATION_TYPES.ON_THE_GO:
              return "otg";
            case POST_GENERATION_TYPES.CUSTOM:
            default:
              return "custom";
          }
        };
        bodyPost.postId = post.draftPostId?.split("_")[0] === "schedule" ? post.draftPostId : `${getPostIdPrefix()}_${uuidv4()}`;
        bodyPost.postTag = POSTTAG_CUSTOM;
        console.log(bodyPost);
        const response = await postApi("schedule/create-schedule-post", bodyPost, {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        });
        toast.success(response.message, { autoClose: 1000 });
        dispatch(clearPostState());
        localStorage.removeItem("threadData");
        cancelSchedulePost();
        dispatch(isLoading(false));
        deleteApi("draft/draft-post-delete", { postId: post.draftPostId })
          .then((res) => console.log("draft delete success"))
          .catch((err) => console.warn(`err in draft :${err}`));
        return; // Exit the function since scheduling is done
      } catch (error) {
        dispatch(isLoading(false));
        dispatch(clearPostState());
        console.error("Error sending message:", error);
        toast.error(error.response.data.message);
        return; // Exit the function to avoid further execution
      }
    }
  };
  const handleSaveDraft = async () => {
    await saveDraft();
    setIsDraftFilled(true);
  };

  // Select the any previour comment through tags of user
  const onCommentTagHandler = (tag) => {
    // Set the selected tag in state
    setAutoPlugTags(tag);

    // Filter the previous comments to find the comment with the selected tag
    const filterComment = prevComments.filter((item) => item.tags === tag);

    // Set the auto-plugged comment text to the found comment's text
    setAutoPlugComment(filterComment[0].comment_text);
  };

  // Here we have to find position to where the emoji is use so that our post is looks emoji enable post
  const textareaRef = useRef(null);
  const [emoji, setEmoji] = useState(false);
  const [text, setText] = useState(post.postContent);
  const [prevCursorPos, setPrevCorsorPos] = useState(null);
  // const [prevCursorPosCount, setPrevCorsorPosCount] = useState(0)
  const handleEmojiSelect = (emoji) => {
    let cursorPos;

    // If the previous cursor position is not set, get the current cursor position
    if (prevCursorPos === null) {
      cursorPos = textareaRef.current.selectionStart;
      setPrevCorsorPos(cursorPos); // Store the current cursor position
    } else {
      cursorPos = prevCursorPos + 2; // Move the cursor position to the end of the inserted emoji
    }

    // Get the text before and after the cursor position
    const textBeforeCursor = text.substring(0, cursorPos);
    const textAfterCursor = text.substring(cursorPos);

    // Insert the emoji into the text at the cursor position
    const updatedText = textBeforeCursor + emoji.native + textAfterCursor;

    // Update the state with the updated text
    setText(updatedText);

    // Dispatch an action to update the AI post in the store
    dispatch(addAIPost(updatedText));

    // Hide the emoji picker
    // setPickerVisible(false);
  };

  // emoji manupulate end

  // useEffect(() => {
  //   setText(post.postContent)
  // }, [post.postContent])

  // This function handle to clear all the states or reset it
  const handlerClearAllState = () => {
    dispatch(clearPostState());
    setGetThread([]);
    setIsOnDist(false);
    setIsOnTweet(false);
    setIsOnFacebook(false);
    setIsOnInsta(false);
    setText("");
    setBtnCaroselPdf(false);
    localStorage.removeItem("threadData");
    setIsOnPlug(false);
    setAutoPlugComment("");
    setAutoPlugDelay(null);
    setPrevComments([]);
    setAutoPlugTags(null);
    setPickerVisible(false);
    setPrevCorsorPos(null);
  };
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = () => setShowDialog(false);
  const [showDialog1, setShowDialog1] = useState(false);
  const closeDialog1 = () => {
    setShowDialog1(false);
    setActive("upload");
    setSearch("");
  };

  const [selectedArray, setSelectedArray] = useState([]);

  const addToArray = (newItem) => {
    setSelectedArray([...selectedArray, newItem]);
  };

  const handleUploadSuccess = () => {
    console.log("Upload was successful");
    // Perform any necessary actions after successful upload
  };

  const handleTextAreaChange = (e) => {
    let currentText = e.target.value;
    currentText = currentText.replace(/^\n+/, "");
    setText(currentText);

    // This is the most important which willdispatch the content to the post slice for EditPostOverlay to access
    dispatch(updateOverlayContent(currentText));

    onPostDescriptionHandler({
      ...e,
      target: { ...e.target, value: currentText },
    });
  };

  return (
    <>
      <div className="w-[100%] p-2">
        <div className="flex justify-between items-center mb-4">
          <h3 className={isDark ? "text-black text-lg font-semibold m-0 underline" : "text-white text-lg font-semibold m-0 underline"}>Create Post</h3>
          <div>
            <select
              className={`${common.isDark ? "auto-plug-hr-light" : "auto-plug-hr-dark"} p-2 rounded-lg`}
              style={{ color: "white", width: "10rem", fontSize: "12px" }}
              id="exampleFormControlSelect1"
              value={post.connectionType}
              onChange={(e) => dispatch(changeConnectionType(e.target.value))}
            >
              <option value={"PUBLIC"}>Public</option>
              <option value={"CONNECTIONS"}>Connections</option>
            </select>
          </div>
        </div>

        <div className="form-group mb-2">
          <div className="post-icon-btn flex flex-wrap gap-1 mb-2">
            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.overlayPostContent;
                const selectedText = text.substring(start, end);
                // Map for bold characters
                const boldMap = {
                  a: "𝗮",
                  b: "𝗯",
                  c: "𝗰",
                  d: "𝗱",
                  e: "𝗲",
                  f: "𝗳",
                  g: "𝗴",
                  h: "𝗵",
                  i: "𝗶",
                  j: "𝗷",
                  k: "𝗸",
                  l: "𝗹",
                  m: "𝗺",
                  n: "𝗻",
                  o: "𝗼",
                  p: "𝗽",
                  q: "𝗾",
                  r: "𝗿",
                  s: "𝘀",
                  t: "𝘁",
                  u: "𝘂",
                  v: "𝘃",
                  w: "𝘄",
                  x: "𝘅",
                  y: "𝘆",
                  z: "𝘇",
                  A: "𝗔",
                  B: "𝗕",
                  C: "𝗖",
                  D: "𝗗",
                  E: "𝗘",
                  F: "𝗙",
                  G: "𝗚",
                  H: "𝗛",
                  I: "𝗜",
                  J: "𝗝",
                  K: "𝗞",
                  L: "𝗟",
                  M: "𝗠",
                  N: "𝗡",
                  O: "𝗢",
                  P: "𝗣",
                  Q: "𝗤",
                  R: "𝗥",
                  S: "𝗦",
                  T: "𝗧",
                  U: "𝗨",
                  V: "𝗩",
                  W: "𝗪",
                  X: "𝗫",
                  Y: "𝗬",
                  Z: "𝗭",
                  0: "𝟬",
                  1: "𝟭",
                  2: "𝟮",
                  3: "𝟯",
                  4: "𝟰",
                  5: "𝟱",
                  6: "𝟲",
                  7: "𝟳",
                  8: "𝟴",
                  9: "𝟵",
                };

                const newText =
                  text.substring(0, start) +
                  selectedText
                    .split("")
                    .map((char) => boldMap[char] || char)
                    .join("") +
                  text.substring(end);
                dispatch(updateOverlayContent(newText));
              }}
              title="Bold"
            >
              <i className="fa-solid fa-bold text-lg text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.overlayPostContent;
                const selectedText = text.substring(start, end);
                // Map for italic characters
                const italicMap = {
                  a: "𝘢",
                  b: "𝘣",
                  c: "𝘤",
                  d: "𝘥",
                  e: "𝘦",
                  f: "𝘧",
                  g: "𝘨",
                  h: "𝘩",
                  i: "𝘪",
                  j: "𝘫",
                  k: "𝘬",
                  l: "𝘭",
                  m: "𝘮",
                  n: "𝘯",
                  o: "𝘰",
                  p: "𝘱",
                  q: "𝘲",
                  r: "𝘳",
                  s: "𝘴",
                  t: "𝘵",
                  u: "𝘶",
                  v: "𝘷",
                  w: "𝘸",
                  x: "𝘹",
                  y: "𝘺",
                  z: "𝘻",
                  A: "𝘈",
                  B: "𝘉",
                  C: "𝘊",
                  D: "𝘋",
                  E: "𝘌",
                  F: "𝘍",
                  G: "𝘎",
                  H: "𝘏",
                  I: "𝘐",
                  J: "𝘑",
                  K: "𝘒",
                  L: "𝘓",
                  M: "𝘔",
                  N: "𝘕",
                  O: "𝘖",
                  P: "𝘗",
                  Q: "𝘘",
                  R: "𝘙",
                  S: "𝘚",
                  T: "𝘛",
                  U: "𝘜",
                  V: "𝘝",
                  W: "𝘞",
                  X: "𝘟",
                  Y: "𝘠",
                  Z: "𝘡",
                };

                const newText =
                  text.substring(0, start) +
                  selectedText
                    .split("")
                    .map((char) => italicMap[char] || char)
                    .join("") +
                  text.substring(end);
                // Ensure the post content is updated correctly
                dispatch(updateOverlayContent(newText)); // Update the post content with italicized text
              }}
              title="Italic"
            >
              <i className="fa-solid fa-italic text-lg text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.overlayPostContent;
                const selectedText = text.substring(start, end);

                // Include the space at the end if it exists
                const adjustedEnd = selectedText.endsWith(" ") ? end : end + 1;
                const adjustedSelectedText = text.substring(start, adjustedEnd);

                // Check if text already has underline
                const hasUnderline = adjustedSelectedText.split("").some((char) => char.includes("\u0332"));

                const newText =
                  text.substring(0, start) +
                  adjustedSelectedText
                    .split("")
                    .map((char) => {
                      if (hasUnderline) {
                        // Remove underline if present
                        return char.replace("\u0332", "");
                      } else {
                        // Add underline if not present
                        return char + "\u0332";
                      }
                    })
                    .join("") +
                  text.substring(adjustedEnd);
                dispatch(updateOverlayContent(newText));
              }}
              title="Underline"
            >
              <i className="fa-solid fa-underline text-lg text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.overlayPostContent;
                const selectedText = text.substring(start, end);

                // Include the space at the end if it exists
                const adjustedEnd = selectedText.endsWith(" ") ? end : end + 1;
                const adjustedSelectedText = text.substring(start, adjustedEnd);

                // Check if text already has strikethrough
                const hasStrikethrough = adjustedSelectedText.split("").every((char) => char.includes("\u0336"));

                const newText =
                  text.substring(0, start) +
                  adjustedSelectedText
                    .split("")
                    .map((char) => {
                      if (hasStrikethrough) {
                        // Remove strikethrough if present
                        return char.replace(/̷/g, "");
                      } else {
                        // Add strikethrough if not present
                        return char + "̷";
                      }
                    })
                    .join("") +
                  text.substring(adjustedEnd);
                dispatch(updateOverlayContent(newText));
              }}
              title="Strikethrough"
            >
              <i className="fa-solid fa-strikethrough text-lg text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.overlayPostContent;
                const selectedText = text.substring(start, end);

                // Process text to remove all formatting
                let normalText = selectedText;

                // Remove bold characters
                const boldChars = {
                  "𝗮": "a",
                  "𝗯": "b",
                  "𝗰": "c",
                  "𝗱": "d",
                  "𝗲": "e",
                  "𝗳": "f",
                  "𝗴": "g",
                  "𝗵": "h",
                  "𝗶": "i",
                  "𝗷": "j",
                  "𝗸": "k",
                  "𝗹": "l",
                  "𝗺": "m",
                  "𝗻": "n",
                  "𝗼": "o",
                  "𝗽": "p",
                  "𝗾": "q",
                  "𝗿": "r",
                  "𝘀": "s",
                  "𝘁": "t",
                  "𝘂": "u",
                  "𝘃": "v",
                  "𝘄": "w",
                  "𝘅": "x",
                  "𝘆": "y",
                  "𝘇": "z",
                  "𝗔": "A",
                  "𝗕": "B",
                  "𝗖": "C",
                  "𝗗": "D",
                  "𝗘": "E",
                  "𝗙": "F",
                  "𝗚": "G",
                  "𝗛": "H",
                  "𝗜": "I",
                  "𝗝": "J",
                  "𝗞": "K",
                  "𝗟": "L",
                  "𝗠": "M",
                  "𝗡": "N",
                  "𝗢": "O",
                  "𝗣": "P",
                  "𝗤": "Q",
                  "𝗥": "R",
                  "𝗦": "S",
                  "𝗧": "T",
                  "𝗨": "U",
                  "𝗩": "V",
                  "𝗪": "W",
                  "𝗫": "X",
                  "𝗬": "Y",
                  "𝗭": "Z",
                };

                // Remove italic characters
                const italicChars = {
                  "𝘢": "a",
                  "𝘣": "b",
                  "𝘤": "c",
                  "𝘥": "d",
                  "𝘦": "e",
                  "𝘧": "f",
                  "𝘨": "g",
                  "𝘩": "h",
                  "𝘪": "i",
                  "𝘫": "j",
                  "𝘬": "k",
                  "𝘭": "l",
                  "𝘮": "m",
                  "𝘯": "n",
                  "𝘰": "o",
                  "𝘱": "p",
                  "𝘲": "q",
                  "𝘳": "r",
                  "𝘴": "s",
                  "𝘵": "t",
                  "𝘶": "u",
                  "𝘷": "v",
                  "𝘸": "w",
                  "𝘹": "x",
                  "𝘺": "y",
                  "𝘻": "z",
                  "𝘈": "A",
                  "𝘉": "B",
                  "𝘊": "C",
                  "𝘋": "D",
                  "𝘌": "E",
                  "𝘍": "F",
                  "𝘎": "G",
                  "𝘏": "H",
                  "𝘐": "I",
                  "𝘑": "J",
                  "𝘒": "K",
                  "𝘓": "L",
                  "𝘔": "M",
                  "𝘕": "N",
                  "𝘖": "O",
                  "𝘗": "P",
                  "𝘘": "Q",
                  "𝘙": "R",
                  "𝘚": "S",
                  "𝘛": "T",
                  "𝘜": "U",
                  "𝘝": "V",
                  "𝘞": "W",
                  "𝘟": "X",
                  "𝘠": "Y",
                  "𝘡": "Z",
                };

                // Replace each character
                for (const [formatted, normal] of Object.entries(boldChars)) {
                  normalText = normalText.split(formatted).join(normal);
                }
                for (const [formatted, normal] of Object.entries(italicChars)) {
                  normalText = normalText.split(formatted).join(normal);
                }

                // Remove underline and strikethrough
                normalText = normalText.replace(/[\u0332\u0336\u0337\u0338̷]/g, "");

                const newText = text.substring(0, start) + normalText + text.substring(end);
                dispatch(updateOverlayContent(newText));
              }}
              title="Clear Formatting"
            >
              <i className="fa-solid fa-remove-format text-lg text-gray-600"></i>
            </button>

            <button
              className="p-1 rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.overlayPostContent;
                const selectedText = text.substring(start, end);

                // Check if the selected text ends with a space
                if (selectedText.endsWith(" ")) {
                  return;
                }

                // Get the content after the selected text
                const contentAfter = text.substring(end);
                const paragraphs = contentAfter.split("\n\n").filter(Boolean);

                // Check if text already has bullet points
                const hasBulletPoints = paragraphs.some((line) => line.trim().startsWith("●"));

                if (hasBulletPoints) {
                  // Remove bullet points
                  const cleanContent = paragraphs
                    .map((line) => {
                      if (line.trim().startsWith("#")) {
                        return line;
                      }
                      return line.trim().replace(/^●\s*/, "");
                    })
                    .filter(Boolean)
                    .join("\n\n");

                  const newText = text.substring(0, start) + selectedText + (cleanContent.length > 0 ? "\n\n" + cleanContent : "");
                  dispatch(updateOverlayContent(newText.replace(/^\n+/, "")));
                } else {
                  // Add bullet points
                  const bulletPointContent = paragraphs
                    .map((line) => {
                      if (line.trim().startsWith("#")) {
                        return line;
                      }
                      return line.trim() ? "●  " + line.trim() : "";
                    })
                    .filter(Boolean)
                    .join("\n\n");

                  const newText = text.substring(0, start) + selectedText + (bulletPointContent.length > 0 ? "\n\n" + bulletPointContent : "");
                  dispatch(updateOverlayContent(newText.replace(/^\n+/, "")));
                }
              }}
              title="Bullet Points"
            >
              <i className="fa-solid fa-list-ul text-lg text-gray-600"></i>
            </button>

            <button
              className="rounded-lg hover:bg-gray-100 transition-colors"
              onClick={(e) => {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const text = post.overlayPostContent;
                const selectedText = text.substring(start, end);

                // Check if it's a double click
                if (e.detail === 2) {
                  // Remove numbering from selected text
                  const unnumberedContent = selectedText
                    .split("\n\n")
                    .map((line) => line.replace(/^\d+\)\s+/, ""))
                    .join("\n\n");

                  const newText = text.substring(0, start) + unnumberedContent + text.substring(end);
                  dispatch(updateOverlayContent(newText));
                  return;
                }

                // Single click - add numbering to selected text
                // Check if the selected text ends with a space
                if (selectedText.endsWith(" ")) {
                  return; // Do not add numbering if selected text ends with a space
                }

                // Split selected text into paragraphs
                const paragraphs = selectedText.split("\n\n").filter(Boolean);

                // Add numbering to paragraphs, skipping hashtags
                const numberedContent = paragraphs
                  .map((line, index) => {
                    // Check if the line contains a hashtag
                    if (line.trim().startsWith("#")) {
                      return line; // Return the line unchanged if it starts with a hashtag
                    }
                    return `${index + 1})  ${line.trim()}`; // Add numbering otherwise
                  })
                  .filter(Boolean)
                  .join("\n\n");

                // Combine the text with numbered selected content
                const newText = text.substring(0, start) + numberedContent + text.substring(end);
                dispatch(updateOverlayContent(newText));
              }}
              title="Numbering (Double-click to remove)"
            >
              <i className="fa-solid fa-list-ol text-lg text-gray-600"></i>
            </button>
          </div>

          <textarea
            ref={textareaRef}
            className={`form-control w-full min-h-[120px] text-[1rem] resize-y outline-none p-3 rounded-lg mb-2 ${isDark ? "post-textarea-light" : "post-textarea-dark"}`}
            style={{
              border: "1px solid #e5e7eb",
              backgroundColor: isDark ? "#ffffff" : "#1a1a1a",
              color: isDark ? "#000" : "#fff",
              height: "auto",
              minHeight: "300px",
              maxHeight: "300px",
              lineHeight: "1.5",
              overflowY: "auto",
              fontSize: "13px",
            }}
            placeholder="Write your post here..."
            value={post.overlayPostContent}
            onClick={() => {
              setPickerVisible(false);
              setPrevCorsorPos(null);
            }}
            onChange={handleTextAreaChange}
          />
        </div>

        {isPickerVisible && (
          <div className="emoji-picker absolute z-50">
            <Picker data={emojiData} previewPosition={"none"} onEmojiSelect={handleEmojiSelect} className="emojiTray" />
          </div>
        )}

        {mediaPanel && (
          <div className={`autoPlug-Panel ${common.isDark ? "schedule-panel-light" : "schedule-panel-dark"}`}>
            <div className={`media-date-picker ${common.isDark ? "" : "schedule-dark"}`}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <i className={`fa-solid fa-xmark cursor-pointer ${common.isDark ? "" : "fa-xmark-dark"}`} onClick={() => setMediaPanel(false)}></i>
              </div>
              <Media isPreview={true} setMediaPanel={setMediaPanel} onUploadSuccess={handleUploadSuccess} />
            </div>
          </div>
        )}

        {urlPanel && (
          <div className="form-group url-link mb-2">
            <input className="form-control" typeof="text" placeholder="Article Link / URL" value={post.originalUrl} onChange={(e) => dispatch(addOriginalUrl(e.target.value))} />
            <i className="fa-solid fa-xmark" onClick={cancelURLPost} style={common.isDark ? { color: "#000" } : { color: "#fff" }}></i>
          </div>
        )}

        <div className="button-box">
          {btnCaroselPdf ? (
            <button className={`btn grediant-border publish-post ${common.isDark ? "publish-post-light" : "publish-post-dark"}`} onClick={convertPDF}>
              {btnCaroselPdfName}
            </button>
          ) : (
            <button className={`${common.isDark ? "publish-post-light" : "publish-post-dark"} p-2 gradientBorderFinal`} onClick={postToLinkedIn}>
              Publish Post
            </button>
          )}

          {/* <button className="btn grediant-button schedule-post" onClick={schedulePostHander}>Schedule Post</button> */}

          <div className="btn-group">
            <button className="gradient-button-new text-white p-2 px-3" style={{ borderRadius: "6px 0 0 6px", border: "1px solid gray" }} onClick={autoScheduleAndPost}>
              Auto-Schedule
            </button>
            <button className="btn btn-secondary dropdown-toggle dropdown-toggle-split" style={{ backgroundColor: "#39B2FF" }} id="dropdownMenuReference" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu create-post-page grediant-button" aria-labelledby="dropdownMenuReference" style={{ cursor: "pointer", padding: 0, marginTop: "-1rem" }}>
              <li
                onClick={schedulePostHander}
                className="flex justify-center items-center gradient-button-new p-2"
                style={{
                  fontSize: "0.8rem",
                  cursor: "pointer",
                  borderRadius: "6px",
                  color: "white",
                }}
              >
                Custom-Schedule
              </li>
              {/* {post.date_time && (
                <li
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    marginTop: "0.5rem",
                  }}
                  onClick={scheduleOnSelectedDate}
                >
                  Save/Edit
                </li>
              )} */}
            </ul>
          </div>
        </div>
        {/* {(planName!='Free-tier' && planName !=='Pro')&&( */}
      </div>

      {schedulePanelOpen && (
        <Modal show={schedulePanelOpen} onHide={() => setSchedulePanelOpen(false)} centered backdrop="static" keyboard={false} className={isDark ? " " : "isdark-model"}>
          <Modal.Header closeButton>
            <Modal.Title className={`${isDark ? "text-black" : "text-white"}`}>Schedule Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={`schedule-text ${isDark ? "text-dark" : " text-white"}`}>Schedule your post at the optimal times to engage with more of your customers or manually select a date and time in the future to publish your post.</p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Select date for Schedule</Form.Label>
                <Form.Control
                  type="date"
                  value={scheduleDate}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const currentDate = new Date().toISOString().split("T")[0];
                    if (e.target.value >= currentDate) {
                      setScheduleDate(e.target.value);
                    } else {
                      toast.error("Please select current or future date");
                    }
                  }}
                  className={isDark ? "bg-light text-dark" : "bg-dark text-white"}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className={isDark ? "text-dark" : "text-white"}>Select time for Schedule</Form.Label>
                <Form.Control type="time" value={scheduleTime} onClick={(e) => e.stopPropagation()} onChange={(e) => setScheduleTime(e.target.value)} className={isDark ? "bg-light text-dark" : "bg-dark text-white"} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={isDark ? "light" : "secondary"} onClick={() => setSchedulePanelOpen(false)}>
              Cancel
            </Button>
            <Button className="gradient-button-new" onClick={onScheduleConfirmHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {autoPlugOpen && (
        <div className={`autoPlug-Panel ${common.isDark ? "schedule-panel-light" : "schedule-panel-dark"}`}>
          <div className={`autoPlug-date-picker ${common.isDark ? "" : "schedule-dark"}`}>
            <div className="autoPlug-header">
              <h4>Auto Plug</h4>
              <i className={`fa-solid fa-xmark ${common.isDark ? "" : "fa-xmark-dark"}`} onClick={cancelAutoPlugPost}></i>
            </div>
            <div className="autoPlug-date">
              <div className="schedule-time">
                <div id="date-picker-example" className="col-sm-12 md-form md-outline mt-3 input-with-post-icon datepicker" inline="true">
                  {true && (
                    <input
                      placeholder="Select date for Schedule"
                      type="time"
                      onClick={(e) => {
                        e.target.type = "time";
                        e.target.showPicker();

                        setHidden({
                          ...hidden,
                          date: "none",
                        });
                      }}
                      onBlur={(e) => {
                        e.target.type = "time";
                        setHidden({
                          ...hidden,
                          date: "block",
                        });
                      }}
                      id="example"
                      className={`form-control grediant-border ${common.isDark ? "" : "form-control-dark"}`}
                      value={autoPlugDelay}
                      onChange={(e) => setAutoPlugDelay(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <select className={`${common.isDark ? "bg-white text-black" : "bg-black text-white"} w-full p-2 border-[1px] border-gray rounded-md`} id="exampleFormControlSelect1" onChange={(e) => onCommentTagHandler(e.target.value)}>
                <option
                  style={{
                    color: common.isDark ? "black" : "white",
                    backgroundColor: common.isDark ? "white" : "black",
                  }}
                >
                  Select Previous Plugs
                </option>
                {prevComments &&
                  prevComments?.map((comment, i) => {
                    return <option key={i}>{comment.tags}</option>;
                  })}
              </select>
            </div>
            <div className="mt-3">{<input type="text" placeholder="Type a tags" className="w-100 form-control" value={autoPlugTags} onChange={(e) => setAutoPlugTags(e.target.value)} />}</div>
            <div>
              <textarea className="autoPlug-text form-control mt-2" placeholder="Type your comment to your post" value={autoPlugComment} onChange={(e) => setAutoPlugComment(e.target.value)}></textarea>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                <button className="gradient-button-new text-white rounded-md p-2" style={{ width: "100%" }} onClick={onAutoPlugHandler}>
                  Create Plug
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {mediaPanel && (
        <div
          className={`autoPlug-Panel ${
            common.isDark ? "schedule-panel-light" : "schedule-panel-dark"
          }`}
        >
          <div
            className={`media-date-picker ${
              common.isDark ? "" : "schedule-dark"
            }`}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <i
                className={`fa-solid fa-xmark ${
                  common.isDark ? "" : "fa-xmark-dark"
                }`}
                onClick={() => setMediaPanel(false)}
              ></i>
            </div>
            <Media isPreview={true} setMediaPanel={setMediaPanel} />
          </div>
        </div>
      )} */}
      {/* {getThread?.length > 0 && (
        <>
          <div
            className="twitter-eye"
            onClick={() => setIsTweet(!isTweet)}
            style={
              common.isDark
                ? { backgroundColor: "#dce7f7" }
                : { backgroundColor: "#fff" }
            }
          >
            <img src={`${IMAGE_URL}/images/twitter.svg`} alt="" />
          </div>
          <div
            className="insta-eye"
            onClick={() => setIsInsta(!isInsta)}
            style={
              common.isDark
                ? { backgroundColor: "#dce7f7" }
                : { backgroundColor: "#fff" }
            }
          >
            <img src={`${IMAGE_URL}/images/instagram.svg`} alt="" />
          </div>
        </>
      )} */}

      <div className={isTweet ? "tweet-thread" : "tweet-thread-hide"}>
        <div className="tweet-thread-header pt-5">
          <h2 className="text-white">Twitter</h2>
          <i className="fa-regular fa-circle-xmark text-white fa-2x" onClick={() => setIsTweet(!isTweet)}></i>
        </div>
        <div className="tweet-thread-container mt-3">
          {getThread?.map((item, index) => {
            return (
              <div className="tweet-thread-item" key={index}>
                <div className="tweet-card-container">
                  <div className="tweet-card-header">
                    <img src={user?.userPhoto} alt="" />
                    <h4> {twitteruser?.name}</h4>
                  </div>
                  <div className="tweet-card-body">
                    <p>{item}</p>
                  </div>
                  <div className="tweet-card-footer">
                    <i className="fa-regular fa-comment"></i>
                    <i className="fa-solid fa-repeat"></i>
                    <i className="fa-regular fa-heart"></i>
                    <i className="fa-solid fa-chart-simple"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={isInsta ? "tweet-thread" : "tweet-thread-hide"}>
        <div className="tweet-thread-header pt-5">
          <h2 className="text-white">Instagram</h2>

          <select value={selectedStyle} onChange={handleChangeStyle}>
            {styles?.map((style) => (
              <option key={style.name} value={style.name}>
                {style.name}
              </option>
            ))}
          </select>
          <i className="fa-regular fa-circle-xmark text-white fa-2x" onClick={() => setIsInsta(!isInsta)}></i>
        </div>
        <div className="tweet-thread-container mt-3">
          {getThread?.map((item, index) => {
            return (
              <div className="tweet-thread-item" key={index}>
                <div className="tweet-card-container">
                  <div className="tweet-card-header">
                    <img src={user?.userPhoto} alt="" />
                    <h4> {twitteruser?.name}</h4>
                  </div>
                  <div className="tweet-card-body" style={styles.find((style) => style.name === selectedStyle)?.style || {}}>
                    <p style={{ color: "white" }}>{item}</p>
                  </div>
                  <div className="tweet-card-footer">
                    <i className="fa-regular fa-comment"></i>
                    <i className="fa-solid fa-repeat"></i>
                    <i className="fa-regular fa-heart"></i>
                    <i className="fa-solid fa-chart-simple"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

// This is to close the overlay after the post is created

OverlayCreatePost.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  getTwitterUser: PropTypes.object,
  getFacebookUser: PropTypes.object,
  getInstaUser: PropTypes.object,
};

export default OverlayCreatePost;
