import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBookmark } from "../../store/features/bookmark/bookmarkSlice";
import { isLoading } from "../../store/features/common/commonSlice";
import PostCard from "../../components/PostCard/HistoryPostCard";
import "./Bookmarks.css";
import { useState } from "react";
import { getApi } from "../../api/methods";
import { ColorRing } from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { clearPostState } from "../../store/features/post/postSlice";
import PreviewPost from "../../components/PostIdea/PreviewPost/PreviewPost";
import { IMAGE_URL } from "../../api/API";
import { Input } from "../../components/ui/input";
import searchImgWhite from "../../assets/searchImgWhite.svg";
import searchImg from "../../assets/searchImg.svg";

const Bookmarks = () => {
  const user = useSelector((state) => state.user);
  const post = useSelector((state) => state.post);
  const bookmarks = useSelector((state) => state.bookmarks);
  const dispatch = useDispatch();
  const { isDark, loading, layoutShift } = useSelector((state) => state.common);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [dataTotalLength, setDataTotalLength] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [filterItem, setFilterItem] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // useEffect(()=>{
  //   dispatch(isLoading(true))
  //   dispatch(fetchUserBookmark(user?.userData?.linkedinId))
  //   dispatch(isLoading(false))
  // },[])

  // fetch all the bookmark posts as per page
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(`book-marks/bookmark-posts/${user?.userData?.linkedinId}?page=${page}`);
      const newData = response.data;
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
      setDataTotalLength(response.totalLength);
      if (newData?.length === response.totalLength || data?.length === response.totalLength || newData?.length === 0) {
        setHasMore(false);
        return;
      } else {
        setHasMore(true);
      }
      setPage(page + 1);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // useEffect(() => {
  //   fetchData();
  //   if(dataTotalLength > 0 && data.length > 0 && dataTotalLength === data.length){
  //     setHasMore(false);
  //   }
  // }, [page]);

  // search functionality
  const searchHandler = () => {
    const filterItems = data.filter((item) => item.postContent?.toLowerCase().includes(searchItem.toLowerCase()));

    if (searchItem) {
      setFilterItem(filterItems);
    } else {
      setFilterItem([]);
    }
  };

  // render component
  useEffect(() => {
    searchHandler();
  }, [searchItem]);

  useEffect(() => {
    // dispatch(clearPostState())
    setData([]);
    setHasMore(true);
    if (user.userData.linkedinId) {
      fetchData();
    }
  }, [user.userData.linkedinId]);

  useEffect(() => {}, [data]);

  const getMediaType = (url) => {
    if (!url) return "none";
    const extension = url.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "webp"].includes(extension)) return "image";
    if (["mp4", "webm", "ogg"].includes(extension)) return "video";
    if (extension === "pdf") return "pdf";
    return "none";
  };

  return (
    <div className={`flex flex-col gap-4 py-12 ${layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"}`}>
      <div className="flex flex-col gap-4">
        <div className="sm:flex justify-between items-center sm:pl-1/5" style={{ paddingLeft: isMobile ? "15%" : "0%" }}>
          <h3 className="text-sm sm:text-xl">Here are your favourite ones</h3>
          <div className="flex">
            <Input
              type="text"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              placeholder="Search"
              className="p-3 w-64"
              style={{
                background: "transparent",
                color: isDark ? "black" : "white",
                borderRadius: "6px",
              }}
            />
            <button className="text-white relative -left-6">
              <img src={isDark ? searchImg : searchImgWhite}></img>
            </button>
          </div>
        </div>
        {dataTotalLength !== null && dataTotalLength === 0 && <h3 className="d-flex justify-content-center align-items-center w-100  mt-5">No post found!</h3>}
        {/* InfiniteScroll implemented */}
        <InfiniteScroll
          dataLength={data?.length}
          next={fetchData}
          hasMore={hasMore}
          style={{ overflow: "visible" }}
          loader={
            <div className="d-flex justify-content-center p-3">{isDark ? <img className="loading-logo" src={`${IMAGE_URL}/images/short_logo_dark.svg`} /> : <img className="loading-logo" src={`${IMAGE_URL}/images/short_logo_white.svg`} />}</div>
          }
          scrollableTarget="scrollableDiv"
        >
          {/* <div className="masonry-row grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 w-full"> */}
          <div className="masonry-grid w-full -ml-2">
            {searchItem && filterItem.length === 0 ? (
              <div className={`flex flex-col gap-4 ${layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"}`}>
                <h3 className="no-posts text-center my-5">No posts found!</h3>
              </div>
            ) : (
              (filterItem.length > 0 ? filterItem : data).map((item, i) => (
                <div className="masonry-grid-item flex-wrap flex gap-2 items-center" key={i} style={{ paddingTop: "1px" }}>
                  <PostCard key={i} des={item.postContent} image={item.originalUrl} postype={item.postType} data={item} setData={setData} isEvergreen={true} isBookmark={true} isDelete={true} />
                </div>
              ))
            )}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Bookmarks;
