import React, { useEffect } from "react";
import { BASE_URL, IMAGE_URL } from "../../api/API";
import Cookies from "universal-cookie";

const AuthenticateWithLinkedIn = () => {
  const img_src = [
    `${IMAGE_URL}/images/loginbgicons/calendar.jpg`,
    `${IMAGE_URL}/images/loginbgicons/star.jpg`,
    `${IMAGE_URL}/images/loginbgicons/discover.jpg`,
    `${IMAGE_URL}/images/loginbgicons/star.jpg`,
    `${IMAGE_URL}/images/loginbgicons/calendar.jpg`,
  ];

  /* The code snippet you provided is from a React component called `AuthenticateWithLinkedIn`. Here's
  what the code is doing: */

  const iconList = img_src.flatMap((item) => Array(7).fill(item));

  const handleLinkedIn = () => {
    const redirectURL = `${BASE_URL}auth/linkedin?isFromAccount=true`;
    window.location.href = redirectURL;
  };

  return (
    <div className="login-container">
      {iconList?.map((item, index) => (
        <div className="bg-icon" key={index}>
          <img src={item} alt="bg-icons" />
        </div>
      ))}
      <div className="login-box">
        <img src={`${IMAGE_URL}/images/logo.svg`} alt="" />
        <h3>An AI-fueled Professional growth awaits you!</h3>
        <button className="btn btn-primary" onClick={handleLinkedIn}>
          <i className="fa-brands fa-linkedin fa-2x"></i> <span>Connect</span>
        </button>
      </div>
    </div>
  );
};

export default AuthenticateWithLinkedIn;
