import React, { useEffect, useState } from "react";
import QueueItems from "../queueItems/QueueItems";
import { useDispatch, useSelector } from "react-redux";
import { addAIPost, addDraftPostId, addOriginalUrl, changeConnectionType, changePostSelection, changePostType } from "../../../../store/features/post/postSlice";
import { useNavigate } from "react-router-dom";
import { convertTo12HourFormat } from "../../../../utility/convertTo12HourFormat.js";
import { deleteApi } from "../../../../api/methods.js";
import { deleteMyQueueData, fetchQueueData } from "../../../../store/features/scheduleData/scheduleDataSlice.js";
import DraftPostPreview from "../../myDraft/DraftPostPreview";
import { addPreviewPost, togglePreview } from "../../../../store/features/previewPost/previewPostSlice";

const QueueContainer = (props) => {
  const { timeSlots } = useSelector((state) => state.user.userChosenTopics);
  const { userData } = useSelector((state) => state.user);
  const { isDark } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let convertDate = props?.date?.split("-");
  convertDate = [convertDate[2], convertDate[1], convertDate[0]].join("-");
  const [showPreview, setShowPreview] = useState(false);

  // Edit the draft post so redirect to post-idea page of composer
  const editPost = (item) => {
    // alert(JSON.stringify(item))
    const { postId, postContent, date_time, connectionType, postType, originalUrl } = item;
    dispatch(addAIPost(postContent));
    dispatch(changeConnectionType(connectionType));
    dispatch(addDraftPostId(postId));
    dispatch(changePostType(postType));
    dispatch(addOriginalUrl(originalUrl));
    if (item.status === "PENDING") {
      dispatch(changePostSelection("schedule-post"));
    }
    deleteApi(`schedule/delete-schedule-post/${item._id}`);
    dispatch(deleteMyQueueData(item._id));
    navigate("/ai-content");
  };

  // Preview the draft post
  const previewPost = (item) => {
    const { postId, postContent, connectionType, postType, originalUrl } = item;
    dispatch(addPreviewPost(item));
    setShowPreview(true);
  };

  // Close preview handler
  const handleClosePreview = () => {
    setShowPreview(false);
  };

  // Delete the draft post
  const deleteQueueConfirm = (id) => {
    const confirmation = confirm("Are you sure?");
    if (confirmation) {
      deleteApi(`schedule/delete-schedule-post/${id._id}`);
      dispatch(deleteMyQueueData(id._id));
    } else {
      return;
    }
  };
  // Time conversion of 24 hours to 12 hours
let timeSlotConvert = [];
if (timeSlots && Array.isArray(timeSlots)) {
  timeSlotConvert = timeSlots
    .filter((slot) => slot && slot.startTime) // Ensure slot and startTime exist
    .map((slot) => convertTo12HourFormat(slot.startTime)); // Pass startTime string
}

  // Function to format a date/time in a specific time zone
  function formatTimeInTimeZone(date, timeZone) {
    // Define options for formatting the date/time
    const options = {
      timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    // Format the date/time using the specified options and remove the comma
    return date.toLocaleString("en-US", options).replace(",", "");
  }
  // Function to format a UTC date/time in a specific time zone
  function formatUtcTimeInTimeZone(date, timeZone) {
    // Extract year, month, day, hours, and minutes from the UTC date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    // Create a new local date using the extracted values
    const localDate = new Date(Date.UTC(year, date.getUTCMonth(), day, hours, minutes));
    // Format the local date/time in the specified time zone
    return formatTimeInTimeZone(localDate, timeZone);
  }
  /**
   * The above functions determine if a given date is today, yesterday, or tomorrow.
   * @returns The functions `isToday`, `isYesterday`, and `isTomorrow` are returning a boolean value
   * indicating whether the input `date` is equal to today's date, yesterday's date, or tomorrow's date
   * respectively.
   */
  const isToday = (date) => {
    const today = new Date();
    return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
  };
  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getFullYear() === yesterday.getFullYear() && date.getMonth() === yesterday.getMonth() && date.getDate() === yesterday.getDate();
  };

  const isTomorrow = (date) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return date.getFullYear() === tomorrow.getFullYear() && date.getMonth() === tomorrow.getMonth() && date.getDate() === tomorrow.getDate();
  };

  // This helper function at the beginning of the component
  const convertTo24Hour = (time) => {
    const [timeStr, period] = time.split(" ");
    let [hours, minutes] = timeStr.split(":").map(Number);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  // Modify this function to filter out duplicate time slots
  const combineAndSortTimeSlots = (scheduledPosts, emptySlots) => {
    const scheduledTimes = new Set(scheduledPosts.map((post) => post.actualTime));

    const allSlots = [
      ...scheduledPosts.map((post) => ({
        time: post.actualTime,
        data: post,
        isScheduled: true,
      })),
      ...emptySlots
        .filter((slot) => !scheduledTimes.has(slot))
        .map((slot) => ({
          time: slot,
          data: { postContent: "You have not scheduled any post" },
          isScheduled: false,
        })),
    ];

    return allSlots.sort((a, b) => convertTo24Hour(a.time) - convertTo24Hour(b.time));
  };

  return (
    <>
      <div className="queue-post-container">
        <div className="queue-date">
          <p style={isDark ? { color: "#000" } : { color: "#fff" }}>
            {convertDate} {isYesterday(new Date(props.date)) ? " | Yesterday" : isToday(new Date(props.date)) ? " | Today" : isTomorrow(new Date(props.date)) ? " | Tomorrow" : ""}
          </p>
        </div>

        {/* This part of the code is an immediately invoked function expression (IIFE) in JavaScript. It
        is used to create a block of code that is executed immediately after it is defined. */}

        {(() => {
          const scheduledPosts = props.data
            ? props.data.map((queue) => {
                const standardData = new Date().toISOString();
                const convertDate = new Date(queue.date_time + "." + standardData.split(".")[1]);
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const formattedTimeInUserTimeZone = formatUtcTimeInTimeZone(convertDate, userTimeZone);
                const actualTime = `${formattedTimeInUserTimeZone.split(" ")[1]} ${formattedTimeInUserTimeZone.split(" ")[2]}`;
                return { ...queue, actualTime };
              })
            : [];

          const sortedSlots = combineAndSortTimeSlots(scheduledPosts, timeSlotConvert);

          return sortedSlots.map((slot, i) => (
            <QueueItems
              key={i}
              actualTime={slot.time}
              data={slot.data}
              editPost={slot.isScheduled ? editPost : undefined}
              previewPost={slot.isScheduled ? previewPost : undefined}
              deleteQueueConfirm={slot.isScheduled ? deleteQueueConfirm : undefined}
            />
          ));
        })()}
      </div>

      {/* Add Preview Modal */}
      {showPreview && <DraftPostPreview onClose={handleClosePreview} />}
    </>
  );
};

export default QueueContainer;
