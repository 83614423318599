import React from "react";
import "./Loader.css";
import { IMAGE_URL } from "../../api/API";

/**
 * The Loader component in JavaScript React displays a loading animation with a pulsating logo image.
 * @returns A React functional component named Loader is being returned. It renders a loading spinner
 * with a logo image inside a div with the class name "loading". The logo image has the class name
 * "loading-logo" and is sourced from `/images/short_logo_white.svg`.
 */
const Loader = () => {
  return (
    <div className="loading">
      <div className="loading-content">
        <img className="loading-logo animate-pulse" src={`${IMAGE_URL}/images/short_logo_white.svg`} alt="Loading logo" />
      </div>
    </div>
  );
};

export default Loader;
