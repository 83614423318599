import axios from 'axios'

const Pixabay_URL = import.meta.env.VITE_PIXABAY_BASE_URL
const Pixabay_API_KEY = import.meta.env.VITE_PIXABAY_API_KEY

const Pexels_URL = import.meta.env.VITE_PEXELS_BASE_URL
const Pexels_API_KEY = import.meta.env.VITE_PEXELS_API_KEY

const Unsplash_URL = import.meta.env.VITE_UNSPLASH_BASE_URL
const Unsplash_ACCESS_KEY = import.meta.env.VITE_UNSPLASH_ACCESS_KEY

export const pixabayImageData = (q, page = 1) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${Pixabay_URL}?key=${Pixabay_API_KEY}&q=${q}&image_type=photo&page=${page}`
    );
    dispatch({ 
      type: "SET_IMAGES", 
      payload: {
        images: response.data.hits,
        page: page,
        keyWord: "Pixabay"
      }
    });
  } catch (error) {
    console.error('Error fetching Pixabay images:', error);
  }
};

export const pexelImageData = (q, page = 1) => async (dispatch) => {
  try {
    const response = await axios.get(`${Pexels_URL}search?query=${q}&page=${page}`, {
      headers: {
        Authorization: Pexels_API_KEY,
      },
    });
    dispatch({
      type: 'SET_IMAGES',
      payload: {
        images: response.data.photos,
        page: page,
        keyWord: 'Pexels'
      }
    });
  } catch (error) {
    console.error('Error fetching Pexel images:', error);
  }
};

export const unsplashImageData = (q, page = 1) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${Unsplash_URL}search/photos?page=${page}&query=${q}`,
      {
        headers: {
          Authorization: `Client-ID ${Unsplash_ACCESS_KEY}`,
        },
      }
    );
    dispatch({ 
      type: "SET_IMAGES", 
      payload: {
        images: response.data.results,
        page: page,
        keyWord: "Unsplash"
      }
    });
  } catch (error) {
    console.error('Error fetching Unsplash images:', error);
  }
};

export const clearImages = () => ({
  type: 'CLEAR_IMAGES'
});