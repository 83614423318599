import React, { useEffect, useState } from "react";
import "./style.css";
import DefineTopics from "./DefineTopics";
import DescribeYourSelf from "./DescribeYourSelf";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../api/methods";
import { useDispatch } from "react-redux";
import { addTopics, fetchUserInfo } from "../../store/features/user/userSlice";
import { isLoading } from "../../store/features/common/commonSlice";
import { IMAGE_URL } from "../../api/API";
import { toast } from "react-toastify";

// This is the First file which will load after signup and email verification. This file uses two other files in the same directory DefineTopics and DescribeYourSelf.

const First = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSetNext, setIsSetNext] = useState(false);
  const [getUserTopics, setUserTpoics] = useState({
    loggeduserId: "",
    topics: [],
    userPromtAIProfession: "",
    language: "",
    timeZone: "",
    userAIPromtTopics: null,
    timeSlots: ["11:00", "16:00"],
  });

  // Handle the error part of the input boxes
  const handleSaveInfo = () => {
    const errors = validateFields(getUserTopics);

    if (Object.keys(errors).length > 0) {
      displayErrors(errors);
      return;
    }

    saveUserTopics(getUserTopics)
      .then(() => {
        dispatch(isLoading(true));
        dispatch(fetchUserInfo());
        toast.success("Congratulations, account setup complete!");
        navigate("/dashboard");
        dispatch(isLoading(false));
      })
      .catch((error) => {
        console.warn("Error in create-user-choosen-topics:", error);
        navigate("/dashboard");
      });
  };

  const validateFields = (userTopics) => {
    const errors = {};

    switch (true) {
      case !userTopics.userPromtAIProfession.trim():
        errors.userPromtAIProfession = "Please enter the topics of interest.";
        break;
      case !userTopics.language.trim():
        errors.language = "Language is required";
        break;
      case !userTopics.userAIPromtTopics.trim():
        errors.timeZone = "User descriptions are required";
        break;
      // Add more validations here as needed:
      // case !userTopics.timeZone.trim():
      //   errors.timeZone = "Time Zone is required";
      //   break;
      default:
        break;
    }

    return errors;
  };

  const displayErrors = (errors) => {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        toast.error(errors[key]);
      }
    }
  };

  //console.log("getUserTopics", getUserTopics)

  const saveUserTopics = (userTopics) => {
    return postApi("user/create-user-choosen-topics", userTopics);
  };

  // which component will render decide here
  const handleCompo = () => {
    setIsSetNext(!isSetNext);
  };

  return (
    <>
      <div className="popup-container ">
        {/* <img
          className="mt-1 img-popup-container"
          src={`${IMAGE_URL}/images/logo.svg`}
          height={40}
          width={250}
          alt=""
        /> */}

        {/* This is the conditional rendering of the components */}

        {isSetNext ? (
          <DescribeYourSelf click={handleCompo} setUserTpoics={setUserTpoics} getUserTopics={getUserTopics} handleSaveInfo={handleSaveInfo} />
        ) : (
          <DefineTopics click={handleCompo} getUserTopics={getUserTopics} setUserTpoics={setUserTpoics} />
        )}
      </div>
    </>
  );
};

export default First;
