import React, { useState, useEffect } from "react";
import { getApi } from "../../../api/methods";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Subscription = (props) => {
  const navigate = useNavigate();
  const planarr = ["Monthly", "Annual"];
  const [getsubscription, setSubscription] = useState([]);
  const [activePlan, setActivePlan] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(planarr[activePlan]);
  const { isDark } = useSelector((state) => state.common);

  /**
   * The function `getSubList` fetches subscription data from an API endpoint and sets it in the state.
   */
  const getSubList = () => {
    getApi("subscription/viewsubscription")
      .then((res) => {
        setSubscription(res.data);
      })
      .catch((err) => {
        console.error("Error fetching subscription list:", err);
      });
  };

  /* The `handleProceed` function is calculating the amount for a selected subscription plan based on
 whether it is a monthly or annual subscription. It uses a predefined object `fixedPrices` that
 contains fixed prices for different subscription plans (`Pro`, `Creator`, `Growth`) for both
 monthly and annual payment options. */
  const handleProceed = (item) => {
    const fixedPrices = {
      Pro: {
        monthly: 19,
        annual: 9 * 12,
      },
      Creator: {
        monthly: 39,
        annual: 19 * 12,
      },
      Growth: {
        monthly: 79,
        annual: 39 * 12,
      },
    };
    /* The code snippet you provided is part of a function called `handleProceed` in a React component.
Here's what it's doing: */

    const amount = selectedPlan === "Monthly" ? fixedPrices[item.planType].monthly : fixedPrices[item.planType].annual;

    const cartData = {
      ...item,
      cost: amount,
      planDuration: selectedPlan,
    };

    localStorage.setItem("cartdata", JSON.stringify(cartData));
    props.setIsShowSubs(false);
    navigate("/order-review");
  };

  const getMonthlyPlan = () => {
    // Sort the subscription plans to ensure Creator is in the middle
    const sortedSubscriptions = [...getsubscription].sort((a, b) => {
      const planOrder = { Pro: 1, Creator: 2, Growth: 3 };
      return planOrder[a.planType] - planOrder[b.planType];
    });

    // Define fixed prices for each plan
    const fixedPrices = {
      Pro: {
        monthly: 19,
        annual: 9,
      },
      Creator: {
        monthly: 39,
        annual: 19,
      },
      Growth: {
        monthly: 79,
        annual: 39,
      },
    };

    return sortedSubscriptions?.map((item, index) => {
      // Make Creator plan the featured card
      const isCreatorPlan = item.planType === "Creator";
      let planDescription;
      let planCredits = "";
      let planAccounts = "";
      let planTeamMembers = "";

      /* This `switch` statement is used to set different values for `planDescription`, `planCredits`,
      `planAccounts`, and `planTeamMembers` based on the value of `item.planType`. Here's a
      breakdown of what each `case` does: */
      switch (item.planType) {
        case "Pro":
          planDescription = "Begin producing top-performing content daily while spending 80% less time.";
          planCredits = "300 Credits";
          planAccounts = "1 Account";
          planTeamMembers = "1 User";
          break;
        case "Creator":
          planDescription = "Harness the potential of AI-crafted posts, and team insights and organic engagement.";
          planCredits = "1500 Credits";
          planAccounts = "5 Accounts";
          planTeamMembers = "3 Team Members";
          break;
        case "Growth":
          planDescription = "Perfect for managing large-scale accounts, offering tailored strategies and insights for optimal growth.";
          planCredits = "Unlimited Credits";
          planAccounts = "Unlimited Accounts";
          planTeamMembers = "10 Team Members";
          break;
        default:
          planDescription = "Build momentum on social media";
          planCredits = "N/A";
          planAccounts = "N/A";
          planTeamMembers = "N/A";
      }

      return (
        <div className={`w-full sm:w-1/3 lg:w-1/4 px-1 mb-8 ${isCreatorPlan ? "sm:order-2" : index === 0 ? "sm:order-1" : "sm:order-3"}`} key={index}>
          <div
            className={`relative rounded-2xl p-8 h-full flex flex-col justify-between ${
              isCreatorPlan ? "bg-gradient-to-b from-blue-600 to-purple-800 border-2 border-purple-400 shadow-xl transform translate-y-[-8px]" : `${isDark ? "bg-gray-800" : "bg-gray-800"} border ${isDark ? "border-gray-700" : "border-gray-200"}`
            }`}
          >
            {isCreatorPlan && <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-blue-600 text-white px-4 py-1 rounded-full text-sm font-semibold border-2 border-blue-400">Best Choice</span>}

            <div className="flex-grow">
              <div className="text-center">
                <h3 className={`text-2xl font-bold mb-2 ${isDark ? "text-white" : "text-gray-900"}`}>{item.planType}</h3>
                <div className="flex justify-center items-baseline mb-4">
                  <span className={`text-4xl font-extrabold ${isDark ? "text-white" : "text-gray-900"}`}>${selectedPlan === "Monthly" ? fixedPrices[item.planType].monthly : fixedPrices[item.planType].annual}</span>
                  <span className={`ml-1 text-xl ${isDark ? "text-gray-400" : "text-gray-500"}`}>/{selectedPlan === "Monthly" ? "mo" : "mo"}</span>
                </div>
                <p className={`mb-6 ${isDark ? "text-gray-300" : "text-gray-600"} text-sm`}>{planDescription}</p>
              </div>

              <div className="space-y-4">
                <div className={`text-sm text-white`}>
                  <div className="mb-2 font-medium">{planCredits}</div>
                  <div className="mb-2 font-medium">{planAccounts}</div>
                  <div className="mb-2 font-medium">{planTeamMembers}</div>
                </div>

                <div className={`border-t ${isDark ? "border-gray-700" : "border-gray-200"} pt-4`}>
                  <h4 className={`text-lg font-semibold mb-4 text-white`}>Features</h4>
                  <ul className="space-y-3">
                    {item.features?.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center">
                        <svg className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <span className={`text-sm ${isDark ? "text-gray-300" : "text-gray-600"}`}>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={() => handleProceed(item)}
                className={`w-full py-3 px-6 rounded-lg font-semibold transition-all duration-200 ${
                  isCreatorPlan ? "bg-white text-purple-600 hover:bg-gray-50" : `${isDark ? "bg-purple-600 hover:bg-purple-700 text-white" : "bg-purple-600 hover:bg-purple-700 text-white"}`
                }`}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  /**
   * The useEffect hook is used to call the getSubList function when the component mounts, and the
   * handlePlanClick function sets the active plan and selected plan based on the index parameter.
   */
  useEffect(() => {
    getSubList();
  }, []);

  const handlePlanClick = (index) => {
    setActivePlan(index);
    setSelectedPlan(planarr[index]);
  };

  return (
    <div>
      <div className="container mx-auto px-4 py-16">
        <div className="text-center max-w-2xl mx-auto mb-12">
          <h2 className={`text-4xl font-bold mb-4 ${isDark ? "text-gray-900" : "text-gray-900"}`}>Buy Subscription</h2>
          <p className={`text-lg ${isDark ? "text-gray-600" : "text-gray-600"}`}>From personal use to small businesses to enterprises. There's a Superhero for everyone!</p>
        </div>

        <div className="flex justify-center mb-12">
          <div className={`inline-flex rounded-lg p-1 ${isDark ? "bg-gray-800" : "bg-white"} shadow-sm`}>
            {planarr?.map((item, index) => (
              <button
                key={index}
                onClick={() => handlePlanClick(index)}
                className={`px-8 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${activePlan === index ? "bg-purple-600 text-white" : `${isDark ? "text-gray-300 hover:text-white" : "text-gray-600 hover:text-gray-900"}`}`}
              >
                {item}
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-stretch -mx-4">{getMonthlyPlan()}</div>
      </div>
    </div>
  );
};

export default Subscription;
