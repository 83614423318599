const initialState = {
  images: [],
  keyWord: "",
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IMAGES':
      return {
        ...state,
        images: action.payload.page > 1 
          ? [...state.images, ...action.payload.images]
          : action.payload.images,
        keyWord: action.payload.keyWord
      };
    case "CLEAR_IMAGES":
      return {
        ...state,
        images: [],
        keyWord: ""
      };
    default:
      return state;
  }
};

export default mediaReducer;  
