import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MailCheck, Send, ArrowLeft } from "lucide-react";
import { BASE_URL, IMAGE_URL } from "../../api/API";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    // This is the api to send the password reset email, to change the email template, Go to backend\src\Authentication\EmailUtils.js

    try {
      await axios.post(`${BASE_URL}userprofile/forgotPassword`, { email });
      setSuccess(true);
    } catch (error) {
      setError("Failed to send reset password email. Please try again.");
      console.error("Forget password error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-800 to-gray-300">
      <div className="w-full max-w-md p-8 space-y-6 bg-white bg-opacity-80 rounded-2xl shadow-2xl backdrop-blur-lg backdrop-filter">
        <div className="text-center">
          <div className="flex justify-center items-center">
            <img src={`${IMAGE_URL}/images/logo.svg`} alt="logo" className="w-36 h-auto p-2 mb-2" />
          </div>
          <h2 className="text-3xl font-extrabold text-gray-800 tracking-tight">{success ? "Email Sent" : "Reset Password"}</h2>
          <p className="mt-2 text-sm text-gray-600">{success ? "Check your inbox for password reset instructions" : "Enter your email to reset your password"}</p>
        </div>

        {success ? (
          <div className="text-center space-y-6">
            <div className="flex justify-center text-green-600">
              <MailCheck size={64} strokeWidth={1.5} />
            </div>
            <p className="text-gray-700 text-sm">
              We've sent a password reset link to <span className="font-semibold">{email}</span>. Please check your email and follow the instructions to reset your password.
            </p>
            <button onClick={() => navigate("/login")} className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors duration-300">
              Back to Login
            </button>
          </div>
        ) : (
          <form onSubmit={handleForgetPassword} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                id="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                placeholder="you@example.com"
              />
            </div>

            {error && <div className="p-3 text-sm text-red-700 bg-red-100 rounded-md">{error}</div>}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300 disabled:opacity-50"
            >
              {isLoading ? (
                <span className="animate-pulse">Sending Email...</span>
              ) : (
                <span className="flex items-center">
                  <Send className="mr-2" size={20} /> Send Reset Email
                </span>
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
