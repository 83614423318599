/**
 * CreditHistoryData Component
 *
 * Renders a single row of credit history data in a table format.
 * Shows credit point transactions with different styling for positive (credits added)
 * and negative (credits consumed) transactions.
 *
 * @param {Object} props
 * @param {Object} props.item - Credit history transaction data
 * @param {string} props.memberColorClass - CSS class for member color coding
 */

import React from "react";
import { useSelector } from "react-redux";
import "./creditHistory.css";

const CreditHistoryData = ({ item, memberColorClass }) => {
  // Get theme state from Redux store
  const { isDark } = useSelector((state) => state.common);

  // Format the date from ISO string (YYYY-MM-DD) to display format (DD-MM-YYYY)
  let dateFormat = item.createdAt.split("T")[0]; // Extract date part before 'T'
  dateFormat = dateFormat.split("-"); // Split into array [YYYY, MM, DD]
  dateFormat = [dateFormat[2], dateFormat[1], dateFormat[0]].join("-"); // Rearrange and join to DD-MM-YYYY

  return (
    <>
      {/* 
        Render transaction row with different styling based on credit point value
        - Positive values (credits added): Special gradient background
        - All transactions use consistent styling within their theme (dark/light)
      */}
      {Number(item.crpoint) > 0 ? (
        <tr
          className={`mt-2`}
          style={{
            width: "100%",
            // Apply gradient background based on theme
            background: isDark ? "linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)" : "#1E1E1E",
          }}
        >
          {/* Date column */}
          <th
            scope="row"
            style={{
              background: "transparent",
              color: isDark ? "black" : "white",
            }}
          >
            {dateFormat}
          </th>

          {/* Transaction name */}
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.name}</td>

          {/* Username */}
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.userName}</td>

          {/* Transaction type */}
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.transaction}</td>

          {/* Credit points changed */}
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.crpoint}</td>

          {/* Remaining balance */}
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.balance}</td>
        </tr>
      ) : (
        // Same structure for negative/zero credit transactions
        <tr
          className={`mt-2`}
          style={{
            width: "100%",
            background: isDark ? "linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)" : "#1E1E1E",
          }}
        >
          <th scope="row" style={{ background: "transparent", color: isDark ? "black" : "white" }}>
            {dateFormat}
          </th>

          {/* The code snippet you provided is rendering table data cells (`<td>`) within the
          CreditHistoryData component. Each `<td>` element represents a specific piece of credit
          history transaction data from the `item` object. */}

          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.name}</td>
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.userName}</td>
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.transaction}</td>
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.crpoint}</td>
          <td style={{ background: "transparent", color: isDark ? "black" : "white" }}>{item.balance}</td>
        </tr>
      )}
    </>
  );
};

export default CreditHistoryData;
